// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useEffect, useState } from 'react'

// Third party;
import { motion } from 'framer-motion'

// Components
import EventPostHorizontalCardComponent from '../../cards/post-horizontal-card/EventPostHorizontalCardComponent'

// Types
import { IEventInterface } from '../../../../models/events/event.interface'

// Models
import { EventBookmarkModel } from '../../../../models/event-bookmark/event-bookmark.model'
import { EventModel } from '../../../../models/events/event.model'

// Redux
import { getConvertedData, getUtcDate } from '../../../../models/interface.helper'
import {
  resetBookmarksGrid,
  selectBookmarkedEvents,
  setBookmarkedEvents,
} from '../../../../store/bookmarks/bookmarksSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectIsLoggedIn, selectUserId, selectUsername } from '../../../../store/user/userSlice'

// Custom hooks
import useToasterHelper from '../../../../helpers/ToasterHelper'

// Constants
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'

// Services
import { IonItem } from '@ionic/react'
import InfiniteScroll from 'react-infinite-scroll-component'
import helpers from '../../../../commonHelpers/helpers'
import FirestoreService from '../../../../services/firestoreService'
import { resetSelectedEventAc } from '../../../../store/events/eventsSlice'
import CardsLoader from '../../../loader/CardsLoader'
import { IUserInterface } from '../../../../models/users/user.interface'
import { TEventRegisteredUsers } from '../../../../models/event-registered-users/event-registered-users.interface'
import { where } from 'firebase/firestore'
import { EventRegisteredUsersModel } from '../../../../models/event-registered-users/event-registered-users.model'
import { UserModel } from '../../../../models/users/user.model'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventPostsHorizontalStackComponentProps {
  tags?: string[]
  hasMore: boolean
  competitions: IEventInterface[]
  fetchMore: () => void
}

const EVENT_BOOKMARKS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_BOOKMARKS
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPostsHorizontalCardStackComponent: React.FC<EventPostsHorizontalStackComponentProps> = (
  props
) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const toastMethods = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const userName = useAppSelector(selectUsername)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const bookmarkedEventsList = useAppSelector(selectBookmarkedEvents)
  const [bookmarkBeingProcessed, setBookmarkBeingProcessed] = useState<null | string>(null)
  const [bookmarkedEventsIdList, setBookmarkedEventsIdList] = useState<Set<string>>(new Set())
  const [users, setUsers] = useState<IUserInterface[]>([])
  const [registeredUsers, setRegisteredUsers] = useState<TEventRegisteredUsers[]>([])

  useEffect(() => {
    let idsList: Set<string> = new Set()
    bookmarkedEventsList.forEach((currBookmark) => {
      if (currBookmark.eventId) idsList.add(currBookmark.eventId ?? '')
    })
    setBookmarkedEventsIdList(idsList)
  }, [bookmarkedEventsList])

  // Functions

  /** @info Handles bookmark */
  const bookmarkEvent = async (eventId: string, bookmarkId: null | string): Promise<any> => {
    if (!isLoggedIn) return toastMethods.info({ message: MESSAGES_CONST.LOGIN_TO_BOOKMARK })

    try {
      setBookmarkBeingProcessed(eventId)

      let bookmarkedEventIdsList = new Set([...bookmarkedEventsIdList])
      const eventToBookmark = props.competitions.find(
        (currCompetetion) => currCompetetion.id === eventId
      )

      if (eventToBookmark) {
        if (userId) {
          if (!bookmarkId) {
            await addBookmark(eventId)
            bookmarkedEventIdsList.add(eventId)
          } else if (bookmarkId) {
            await removeBookmark(bookmarkId)
            bookmarkedEventIdsList.delete(eventId)
          }

          setBookmarkedEventsIdList(new Set([...bookmarkedEventIdsList]))
          dispatch(resetBookmarksGrid())
        } else throw Error('userId is not set')
      }
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
      toastMethods.success({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setBookmarkBeingProcessed(null)
    }
  }

  /** @info Bookmarks a event */
  const addBookmark = async (eventId: string) => {
    try {
      let updatedBookmarksList = [...bookmarkedEventsList]
      let structuredBookmark = new EventBookmarkModel({
        eventId,
        userId,
        createdAt: getUtcDate(),
        updatedAt: getUtcDate(),
        bookmarkedBy: userName ?? null,
        mailSent: 0,
      })

      const created = await FirestoreService.createItem(
        EVENT_BOOKMARKS.NAME,
        structuredBookmark.toFirestore()
      )

      structuredBookmark.id = created.id

      updatedBookmarksList = [
        ...updatedBookmarksList,
        getConvertedData(structuredBookmark.toObject()),
      ]

      dispatch(setBookmarkedEvents(updatedBookmarksList))
      toastMethods.success({ message: MESSAGES_CONST.BOOKMARKED_SUCCESSFULLY })
    } catch (error) {
      throw error
    }
  }

  /** @info Removes a bookmark */
  const removeBookmark = async (bookmarkId: string) => {
    try {
      let updatedBookmarksList = [...bookmarkedEventsList]
      await FirestoreService.deleteItem(EVENT_BOOKMARKS.NAME, bookmarkId)

      updatedBookmarksList = updatedBookmarksList.filter((bookmark) => bookmark.id !== bookmarkId)

      dispatch(setBookmarkedEvents(updatedBookmarksList))
      toastMethods.success({
        message: MESSAGES_CONST.BOOKMARk_REMOVED_SUCCESSFULLY,
      })
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
      throw error
    }
  }

  const getBookmarkId = (eventId: string) => {
    const bookmarkFound = bookmarkedEventsList.find((currBookmark) => {
      return currBookmark?.eventId === eventId
    })
    if (bookmarkFound) return bookmarkFound?.id ?? null
    else return null
  }

  const resetEventDetailPageData = () => {
    dispatch(resetSelectedEventAc())
  }

  const getRegisteredUsers = async () => {
    let registeredUsers: TEventRegisteredUsers[] = []
    let users: IUserInterface[] = []

    const eventIds = props?.competitions.map((event) => event.id)

    const registeredUsersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.KEY, 'in', [
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PAID,
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PENDING,
        ]),
      ]
    )

    registeredUsersSnaps.docs.forEach((currDoc) => {
      const event = EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject()
      if (event?.eventId && eventIds.includes(event.eventId))
        registeredUsers.push(getConvertedData(event))
    })

    const userIds: string[] = registeredUsers.map((user) => user?.userId ?? '')
    const userSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIds)

    userSnaps.forEach((currDoc) => {
      users.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
    })

    setRegisteredUsers(registeredUsers)
    setUsers(users)
  }
  useEffect(() => {
    getRegisteredUsers()
  }, [])

  return (
    <IonItem lines="none" color="#F6F7FB" className="mx-1  ">
      <div className="flex ">
        <div id="mainscrollDiv" className="transition-all overflow-auto no-scrollbar h-[70vh]">
          <InfiniteScroll
            dataLength={props.competitions.length}
            next={props.fetchMore}
            hasMore={props.hasMore}
            loader={<CardsLoader />}
            scrollableTarget="mainscrollDiv"
          >
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
              {props.competitions.map((competition) => {
                const registeredCountIncludingUnpaid = registeredUsers.filter(
                  (user) => user.eventId === competition?.id
                )

                let currentUsers: IUserInterface[] = []
                let currentUser: IUserInterface

                registeredCountIncludingUnpaid.forEach((registeredUser) => {
                  users.forEach((user) => {
                    if (registeredUser.userId === user.id) {
                      currentUser = user
                    }
                  })
                  if (currentUser && currentUser.id) currentUsers.push(currentUser)
                })

                const registeredUsersImages = currentUsers.map(
                  (item) => item.userProfilePicture ?? ''
                )

                return (
                  <motion.div
                    key={competition.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.1 }}
                    className="inline-block col-span-1 rounded-lg px-3 h-min-[430px] mb-4 sm:mb-6"
                  >
                    <EventPostHorizontalCardComponent
                      id={competition?.id}
                      city={competition?.city}
                      tags={competition?.tags}
                      state={competition?.state}
                      status={competition?.status}
                      bookmarkEvent={bookmarkEvent}
                      subType={competition?.subType}
                      category={competition?.category}
                      country={competition?.country}
                      isLoggedIn={isLoggedIn === true}
                      eventName={competition?.eventName}
                      eventImages={competition?.eventImages}
                      locationHash={competition.locationHash}
                      eventEndDate={competition?.eventEndDate}
                      waitlistCount={competition?.waitlistCount}
                      eventStartDate={competition?.eventStartDate}
                      bookmarkId={getBookmarkId(competition?.id ?? '')}
                      competitionCourseMapImages={competition?.eventImages}
                      resetEventDetailPageData={resetEventDetailPageData}
                      eventStartDateRange={competition?.eventStartDateRange}
                      registered={competition?.registeredCountIncludingUnpaid}
                      registeredUsersImages={registeredUsersImages}
                      eventCardCoverImageUrl={competition?.eventCardCoverImageUrl}
                      bookmarkBeingProcessed={bookmarkBeingProcessed === competition?.id}
                      isBookmarked={bookmarkedEventsIdList.has(competition?.id ?? '')}
                      registeredCountIncludingUnpaid={registeredCountIncludingUnpaid.length}
                      registrationOpenDate={competition?.registrationOpenDate}
                      registrationCloseDate={competition?.registrationCloseDate}
                      requiredFields={competition?.requiredFields || []}
                      requiredHorseFields={competition?.requiredHorseFields || []}
                    />
                  </motion.div>
                )
              })}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </IonItem>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
EventPostsHorizontalCardStackComponent.defaultProps = {
  competitions: [
    {
      ...EventModel.fromObject({}),
    },
  ],
}

export default EventPostsHorizontalCardStackComponent
