import { useAppSelector } from '../../store/hooks'
import { selectUserId } from '../../store/user/userSlice'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import { ChatContent, ChatFilters } from './components/DashboardChat'
import InfoLabels from './components/InfoLabels'
import NewMessage from './components/NewMessage'
import ChatStream from './components/ChatStream'
import { NewChatModal, AddMemberModal } from './modals/MembersModal'
import { AllButton, UnreadButton } from './components/FilterButtons'

import 'stream-chat-react/dist/css/v2/index.css'
import './components/layout.css'

// Types
type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

const MessagesPage = ({ handleModal }: Props) => {
  const userId = useAppSelector(selectUserId)

  return (
    <WrapperContainer
      title="Messages"
      noBg={true}
      removeShadow={true}
      removePadding={true}
      hideTitle={false}
    >
      {userId ? (
        <ChatContent userId={userId}>
          <div className="h-[86vh] w-full rounded-md px-4 bg-white pb-24">
            <div className="h-14 w-full flex flex-row items-center justify-between py-10 border-b-[1px] border-[#F6F7FB]">
              <ChatFilters>
                <AllButton />
                <UnreadButton />
              </ChatFilters>
              <div className="flex flex-row space-x-2">
                <InfoLabels />
                <NewMessage />
              </div>
            </div>

            <ChatStream />
            <NewChatModal />
            <AddMemberModal />
          </div>
        </ChatContent>
      ) : (
        <></>
      )}
    </WrapperContainer>
  )
}

export default MessagesPage
