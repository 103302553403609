// ############################################################
/**
 * @todo Document this
 */
// ############################################################
import {
  IonAvatar,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
// import { useLocation } from 'react-router';
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

// Third party
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { motion } from 'framer-motion'

// Redux
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { selectMenuCollapse, selectUseMode, setMenuCollapse } from '../store/system/systemSlice'

// Constants
import { CONST } from '../const/const'
import { PAGES } from './pages'

// Styles
import './SideMenuNavigation.css'
import { useEffect } from 'react'
import { registerMobileMenu, selectMenuToggle } from '../store/mobileMenu/mobileMenuSlice'
import { useWindowResize } from '../hooks/useWindowResize'
import { MD_WIDTH } from '../helpers/mdWidth'
import { useOutsideClick } from '../hooks/useOutsideClick'
import { IconClose } from '../components/icons/IconClose'
import { MODAL_CONSTS } from '../const/modal-const'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface SideMenuNavigationProps {
  handleModal?: (showHide: boolean, typeOfModal: string, data?: any) => void
  logo?: any
  menuCollapse?: any
  isLoggedIn?: boolean
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const SideMenuNavigation = (props: SideMenuNavigationProps) => {
  let highlight = false

  const history = useHistory()
  const location = useLocation()
  const eventId = location?.pathname?.substring(location?.pathname?.lastIndexOf('/') + 1)
  const menu = useAppSelector(selectMenuCollapse)
  const dispatch = useAppDispatch()
  const isOpenMobileMenu = useAppSelector(selectMenuToggle)
  const useMode = useAppSelector(selectUseMode)
  const { windowWidth } = useWindowResize()
  let descendentOf: null | string = null
  const ref = useOutsideClick(() => {
    // if (windowWidth < MD_WIDTH) {
    //   dispatch(registerMobileMenu.toggleMobileMenu(false))
    // }
  })

  useEffect(() => {
    setTimeout(() => {
      dispatch(setMenuCollapse(false))
    }, 1000)
  }, [])
  Object.keys(CONST.ROUTES).forEach((c) => {
    let currValue: any = CONST.ROUTES[c as keyof typeof CONST.ROUTES]
    if (currValue?.URL === location.pathname) descendentOf = currValue?.DESCENDANT_OF ?? null
  })

  // Functions

  const handleToggleMenu = () => {
    dispatch(setMenuCollapse(!menu))
  }
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      {isOpenMobileMenu && (
        <div
          onClick={() => dispatch(registerMobileMenu.toggleMobileMenu(false))}
          className={'fixed top-0 left-0 w-full h-full bg-black/[0.8] z-10'}
        />
      )}

      <IonMenu
        className={`fixed md:relative top-0 ${isOpenMobileMenu ? 'left-0' : 'left-[-100%]'} md:left-0 z-[99]`}
        contentId="main"
        type="overlay"
        ref={ref}
        id={props.menuCollapse ? 'main-menu1' : 'main-menu'}
      >
        <IonContent
          className={`bg-SeabiscuitMainThemeColor ${!menu || windowWidth < MD_WIDTH ? 'unCollapsed' : 'collapsed'}`}
        >
          <IonList
            id="inbox-list"
            style={{ margin: '0px !important' }}
            className="bg-SeabiscuitMainThemeColor"
          >
            <IonListHeader class="pb-0 w-full pl-0">
              <button
                onClick={() => dispatch(registerMobileMenu.toggleMobileMenu(false))}
                className={`absolute top-0 right-2 w-[20px] h-[20px] text-white  md:hidden`}
              >
                <IconClose />
              </button>
              <div className="flex mb-[60px] md:mb-[10px] lg:mb-[60px] flex-col w-full">
                <div className="flex justify-center w-full mx-auto">
                  {props.logo ? (
                    <IonAvatar
                      className={menu ? '' : 'scale-150 transition-transform duration-300 mt-4'}
                    >
                      <img src={props.logo} alt="logo" />
                    </IonAvatar>
                  ) : (
                    <IonAvatar>
                      <img
                        src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
                        alt="avatar"
                      />
                    </IonAvatar>
                  )}
                </div>

                <div className="relative hidden h-[60px] md:flex items-center togglerContainer">
                  <span
                    onClick={handleToggleMenu}
                    onMouseEnter={() => {
                      if (menu && windowWidth >= MD_WIDTH) {
                        dispatch(setMenuCollapse(false))
                      }
                    }}
                    className="togglerIcon cursor-pointer text-white border-solid rounded-full border-[1.5px] border-white h-[22px] flex items-center"
                  >
                    {!menu ? (
                      <KeyboardArrowLeft fontSize="small" />
                    ) : (
                      <KeyboardArrowRight fontSize="small" />
                    )}
                  </span>
                </div>
              </div>
            </IonListHeader>
            <motion.div
              className="w-full mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                delay: 0.1,
              }}
            >
              {useMode === CONST.USE_MODE.COMPETITOR && (
                <div className={'md:max-h-[50vh] lg:max-h-[70vh] overflow-auto'}>
                  {PAGES.COMPETITOR.map((appPage, index) => {
                    highlight = !!(descendentOf && descendentOf.includes(appPage.url))

                    return (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem
                          className={`${
                            location.pathname === appPage.url || highlight
                              ? 'selected'
                              : 'unSelected'
                          } sideNavigationItem whitespace-nowrap`}
                          routerLink={appPage.url}
                          onClick={() =>
                            appPage.modal && props.handleModal
                              ? props.handleModal(true, MODAL_CONSTS.AI_RULE_BOOK)
                              : history.push(appPage.url)
                          }
                          routerDirection="none"
                          onMouseEnter={() => {
                            if (menu && windowWidth >= MD_WIDTH) {
                              dispatch(setMenuCollapse(false))
                            }
                          }}
                          lines="none"
                          detail={false}
                        >
                          {appPage.activeIcon && appPage?.icon ? (
                            <img
                              className="mr-2 sideNavigationIcon shrink-0 w-[23px] h-[23px]"
                              src={
                                location.pathname === appPage.url || highlight
                                  ? appPage.activeIcon
                                  : appPage.icon
                              }
                              alt="alternate icon"
                            />
                          ) : (
                            <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                          )}
                          <IonLabel className="sideNavigationLabel">{appPage.title}</IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    )
                  })}
                </div>
              )}
              {useMode === CONST.USE_MODE.ORGANIZER && (
                <div className={'md:max-h-[50vh] lg:max-h-[70vh] overflow-auto'}>
                  {PAGES.ORGANIZER.map((appPage, index) => {
                    highlight = !!(descendentOf && descendentOf.includes(appPage.url))

                    return (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem
                          onMouseEnter={() => {
                            if (menu && windowWidth >= MD_WIDTH) {
                              dispatch(setMenuCollapse(false))
                            }
                          }}
                          className={`${
                            location.pathname === appPage.url || highlight
                              ? 'selected'
                              : 'unSelected'
                          } sideNavigationItem whitespace-nowrap`}
                          routerLink={
                            appPage.url === CONST.ROUTES.ORGANIZER_EDIT.URL
                              ? `${appPage.url}/${eventId}`
                              : appPage.url
                          }
                          onClick={() =>
                            history.push(
                              appPage.url === CONST.ROUTES.ORGANIZER_EDIT.URL
                                ? `${appPage.url}/${eventId}`
                                : appPage.url
                            )
                          }
                          routerDirection="none"
                          lines="none"
                          detail={false}
                        >
                          {appPage.activeIcon && appPage?.icon ? (
                            <img
                              className="mr-2 sideNavigationIcon shrink-0 w-[23px] h-[23px]"
                              src={
                                location.pathname === appPage.url || highlight
                                  ? appPage.activeIcon
                                  : appPage.icon
                              }
                              alt="navIcon"
                            />
                          ) : (
                            <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                          )}
                          <IonLabel className="sideNavigationLabel">{appPage.title}</IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    )
                  })}
                </div>
              )}

              {!props.isLoggedIn
                ? PAGES.PUBLIC.map((appPage, index) => {
                    highlight = !!(descendentOf && descendentOf.includes(appPage.url))

                    return (
                      <IonMenuToggle key={index} autoHide={false}>
                        <IonItem
                          onMouseEnter={() => {
                            if (menu && windowWidth >= MD_WIDTH) {
                              dispatch(setMenuCollapse(false))
                            }
                          }}
                          className={`${
                            location.pathname === appPage.url || highlight
                              ? 'selected'
                              : 'unSelected'
                          } sideNavigationItem whitespace-nowrap`}
                          routerLink={appPage.url}
                          onClick={() => history.push(appPage.url)}
                          routerDirection="none"
                          lines="none"
                          detail={false}
                        >
                          {appPage.activeIcon && appPage?.icon ? (
                            <img
                              className="mr-2 sideNavigationIcon shrink-0 w-[23px] h-[23px]"
                              src={
                                location.pathname === appPage.url || highlight
                                  ? appPage.activeIcon
                                  : appPage.icon
                              }
                              alt="navIcon"
                            />
                          ) : (
                            <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                          )}
                          <IonLabel className="sideNavigationLabel hover:text-SeabiscuitWhiteThemeColor">
                            {appPage.title}
                          </IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    )
                  })
                : null}
            </motion.div>
          </IonList>
        </IonContent>

        {(useMode === CONST.USE_MODE.COMPETITOR || useMode === CONST.USE_MODE.ORGANIZER) && (
          <div className="openMenuIcon absolute bottom-10 flex items-center justify-center w-full">
            <Link
              onMouseEnter={() => {
                if (menu && windowWidth >= MD_WIDTH) {
                  dispatch(setMenuCollapse(false))
                }
              }}
              to={CONST.ROUTES.EARN_COMMISSION.URL}
              className={`${
                location.pathname === CONST.ROUTES.EARN_COMMISSION.URL || highlight
                  ? 'bg-white text-SeabiscuitMainThemeColor'
                  : ''
              } border rounded-lg px-4 py-2 flex gap-3`}
            >
              <img
                src={`${location.pathname === CONST.ROUTES.EARN_COMMISSION.URL || highlight ? 'assets/cp_icons/ExpensivePrice-2.svg' : 'assets/cp_icons/Expensive Price-4.svg'}`}
                alt=""
              />
              {!menu && (
                <div className="text-white" style={{ color: '#fff !important' }}>
                  Earn
                </div>
              )}
            </Link>
          </div>
        )}
      </IonMenu>
    </>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
SideMenuNavigation.defaultProps = {
  logo: null,
}

export default SideMenuNavigation
