/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

// Component imports
import HorseRootEditCompetitionNumbersTab from './HorseRootEditCompetitionNumbersTab'
import HorseRootEditLineageTab from './HorseRootEditLineageTab'
import HorseRootEditTeamMemberTab from './HorseRootEditTeamMemberTab'
import ManageHorseForm from './ManageHorseForm'

// Redux
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  getSelectedHorse,
  horseSliceThunks,
  resetSelectedHorseDetails,
  selectHorseReducer,
  setIsAddHorsePage,
  setSelectedHorseCompetetionPaperwork,
  setSelectedHorseIndex,
} from '../../store/horses/horseSlice'

// Custom hooks
import helpers from '../../commonHelpers/helpers'
import NotFoundAlert from '../../components/common/alerts/not-found/NotFoundAlert'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import { PillButtonElement } from '../../components/elements/buttons/pill/PillButtonElement'
import ViewsLoader from '../../components/loader/ViewsLoader'
import { CONST } from '../../const/const'
import { ROUTES_CONST } from '../../const/routes-const'
import { HorseCompetitonPaperworkModel } from '../../models/horse-competiton-paperwork/horse-competiton-paperwork.model'
import { getConvertedData } from '../../models/interface.helper'
import FirestoreService from '../../services/firestoreService'
import { useIonRouter } from '@ionic/react'
import { useLocation } from 'react-router-dom'

type Props = {
  handleModal?: any
}

type IParams = {
  id: string
  userHorseMappingId: string
}

const TABS = ['Horse Data', 'Paperwork', 'Team', 'Lineage']

const HorseRootEditPage = (props: Props) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const params = useParams<IParams>()
  const router = useIonRouter()
  const location = useLocation()

  const [activeTab, setActiveTab] = useState<string>('1')
  const [selectedHorseIndex] = useState<number | null>(null)

  const currentHorse = useAppSelector(getSelectedHorse)
  const horseReducer = useAppSelector(selectHorseReducer)

  useEffect(() => {
    if (location.hash === '#paperwork') {
      setActiveTab('2')
    }
  }, [])

  useEffect(() => {
    if (params?.userHorseMappingId) {
      dispatch(setIsAddHorsePage(false))
      dispatch(
        horseSliceThunks.getHorseViaMappingDocIdThunk({
          horseMappingDocId: params?.userHorseMappingId,
        })
      )
    } else {
      dispatch(setIsAddHorsePage(true))
      dispatch(setSelectedHorseIndex(null))
    }
  }, [params?.userHorseMappingId])

  useEffect(() => {
    if (!horseReducer.loadedFromDb) {
      dispatch(horseSliceThunks.getMyHorsesThunk({}))
    }
  }, [horseReducer.loadedFromDb])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    dispatch(resetSelectedHorseDetails())
  }, [params?.id])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (currentHorse?.id) {
      const getHorsePaperworkData = async () => {
        try {
          return await FirestoreService.getItem(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME,
            currentHorse?.id ?? ''
          )
        } catch (err) {
          helpers.logger({
            isError: true,
            message: err,
          })
        }
      }
      getHorsePaperworkData()
        .then((res) => {
          if (res?.data()) {
            const dataToDispatch = HorseCompetitonPaperworkModel.fromFirestoreDoc(res).toObject()
            dispatch(
              setSelectedHorseCompetetionPaperwork({
                competitionPaperwork: getConvertedData(dataToDispatch),
              })
            )
          }
        })
        .catch((err) => {
          helpers.logger({
            isError: true,
            message: err,
          })
        })
    } else {
      dispatch(
        setSelectedHorseCompetetionPaperwork({
          reset: true,
        })
      )
    }
  }, [currentHorse?.id])

  if (horseReducer.selected.horse.status === 'failed') {
    return (
      <WrapperContainer
        title={horseReducer.isAddHorsePage ? 'Add Horse' : 'Edit Horse'}
        headLink={router.routeInfo.prevRouteLastPathname ?? ROUTES_CONST.ACCOUNT_SETTINGS.URL}
      >
        <NotFoundAlert
          title={
            <img
              src="/assets/placeholders/HorseNotFound.svg"
              alt="Horse Not Found"
              className="w-[200px] h-[200px] mx-auto"
            />
          }
          description={'Horse not found'}
          goBackUrl={router.routeInfo.prevRouteLastPathname ?? CONST.ROUTES.PROFILE_SETTINGS.URL}
        />
      </WrapperContainer>
    )
  }

  if (!!horseReducer.selected.horse.data && horseReducer.selected.horse.isMyHorse === false) {
    return (
      <WrapperContainer
        title={horseReducer.isAddHorsePage ? 'Add Horse' : 'Edit Horse'}
        headLink={router.routeInfo.prevRouteLastPathname ?? ROUTES_CONST.ACCOUNT_SETTINGS.URL}
      >
        <NotFoundAlert
          goBackUrl={router.routeInfo.prevRouteLastPathname ?? CONST.ROUTES.PROFILE_SETTINGS.URL}
          title={
            <img
              src="/assets/placeholders/HorseNotFound.svg"
              alt="Horse Not Found"
              className="w-[200px] h-[200px] mx-auto"
            />
          }
          description={
            currentHorse?.id
              ? 'You can not edit this horse because you are not the owner'
              : undefined
          }
        />
      </WrapperContainer>
    )
  }

  return (
    <WrapperContainer
      title={horseReducer.isAddHorsePage ? 'Add Horse' : 'Edit Horse'}
      headLink={router.routeInfo.pushedByRoute ?? ROUTES_CONST.ACCOUNT_SETTINGS.URL}
      onGoBackButtonClick={() => {
        router.routeInfo.pushedByRoute && dispatch(resetSelectedHorseDetails())
      }}
    >
      <>
        <div>
          {horseReducer.selected.horse.status === 'loading' ? (
            <ViewsLoader
              className="flex items-center w-full justify-center"
              size="xl"
              color="red"
            />
          ) : (
            <>
              <ul className="flex items-center mb-[30px]">
                {TABS.map((currTab, tabIndex) => {
                  return (
                    <PillButtonElement
                      key={`${currTab}${tabIndex}`}
                      text={currTab}
                      disabled={horseReducer.selected.horse.status === 'loading'}
                      Class="!h-[45px] w-fit border-none inline-block !px-5 rounded-3xl"
                      onButtonClicked={() => setActiveTab((tabIndex + 1).toString())}
                      value={tabIndex + 1}
                      selected={(tabIndex + 1).toString() === activeTab}
                    />
                  )
                })}
              </ul>

              <motion.div
                className="w-full mx-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                }}
              >
                {activeTab === '1' && (
                  <ManageHorseForm
                    horseId={params?.id ?? null}
                    handleModal={props.handleModal}
                    selectedHorseIndex={selectedHorseIndex}
                  />
                )}

                {activeTab === '2' && <HorseRootEditCompetitionNumbersTab />}

                {activeTab === '3' && (
                  <HorseRootEditTeamMemberTab
                    addHorse={true}
                    setActiveTab={setActiveTab}
                    horseId={params?.id ?? null}
                    selectedHorse={currentHorse}
                    handleModal={props.handleModal}
                  />
                )}

                {activeTab === '4' && (
                  <HorseRootEditLineageTab
                    setActiveTab={setActiveTab}
                    addHorse={true}
                    selectedHorse={currentHorse}
                    handleModal={props.handleModal}
                  />
                )}
              </motion.div>
            </>
          )}
        </div>
      </>
    </WrapperContainer>
  )
}

export default HorseRootEditPage
