// Constants
import { MODAL_CONSTS } from '../../const/modal-const'

// Modals
import EventSchedulingViewScheduleModal from '../../components/events/forms/scheduling/components/EventSchedulingViewScheduleModal'
import UserProfileHorseTabAddHorse from '../../components/home/modals/UserProfileHorseTabAddHorse'
import ScoringModal from '../../components/modals/ScoringModals'
import ShareDocumentModal from '../../components/modals/ShareDocumentmodal'
import SignDocumentModal from '../../components/modals/SignDocumentModal'
import ViewModal from '../../components/modals/ViewModal'
import ExhibitorScratchConfirmModal from '../../pages/organizer/exhibitorProfile/modal/ExhibitorScratchConfirmModal'

// Organizer
import SendEmailModal from '../../components/events/forms/send-notifications/SendEmailModal'
import SendEmailMessageModal from '../../components/events/forms/send-notifications/SendEmailMessageModal'
import SuccessModal from '../../components/events/forms/send-notifications/SuccessModal'
import UserSearchModal from '../../components/events/forms/send-notifications/UserSearchModal'
import UpdateDisciplineModal from '../../components/modals/UpdateDisciplineModal'
import ManageEventModal from '../../pages/organizer/manage/common/modals/ManageEventModal'

// Common
import EventDetailViewComponentSpectatorTicketsModal from '../../components/events/views/details/modals/EventDetailViewComponentSpectatorTicketsModal'
import UserProfileTeamTabAddTeamMember from '../../components/home/modals/UserProfileTeamTabAddTeamMember'
import BillDetailsModal from '../../components/modals/BillDetailsModal'
import ForgotPassword from '../../components/modals/Forgotpassword'
import HorseTeamMemberRoleChangeConfirmation from '../../components/modals/HorseTeamMemberRoleChangeConfirmation'
import LineageHorseAdd from '../../components/modals/LineageHorseAdd'
import ListRemoveModal from '../../components/modals/ListRemoveModal'
import ReviewAnnex from '../../components/modals/ReviewAnnex'
import UploadEventImagesModal from '../../components/modals/UploadEventImagesModal'
import ProfileLegalClauseManageMembershipModal from '../../components/user/views/profile/modals/ProfileLegalClauseManageMembershipModal'
import ProfileLegalClauseMembershipModal from '../../components/user/views/profile/modals/ProfileLegalClauseMembershipModal '
import ProfileLegalClauseModal from '../../components/user/views/profile/modals/ProfileLegalClauseModal'
import TicketsDistributionModal from '../../pages/myEvent/modal/TicketsDistributionModal'
import { CloseEventConfirmationModal } from '../../pages/organizer/manage/common/modals/CloseEventConfirmationModal'

import AccountPrivacyInformation from '../../components/modals/AccountPrivacyInformation'
import JudgingLicenceInformation from '../../components/modals/JudgingLicenceInformation'
import NewTransferModal from '../../components/modals/NewTransferModal'
import DeletecardModal from '../../components/user/views/profile/modals/DeletecardModal'
import UserEmailUpdate from '../../components/modals/UserEmailUpdate'
import OrganizerEmailInfo from '../../components/modals/common/OrganizerEmailInfo'
import RemoveHorseModel from '../../components/modals/RemoveHorseModel'
import SearchHorseByName from '../../components/modals/SearchHorseByName'
import { CancelEventConfirmationModal } from '../../pages/organizer/manage/common/modals/CancelEventConfirmationModal'
import ScratchStaffModal from '../../pages/organizer/staffProfile/modals/ScratchStaffModal'
import RegistrationTeamTabModal from '../../components/home/modals/RegistrationTeamTabModal'
import { useLocation } from 'react-router'
import ManageStripeAccountModal from '../../components/user/views/profile/Tabs/profile-root-page-finance-tab/modals/ManageStripeConnectAccountModal'
import TicketBuyerDetailsModal from '../../pages/ticket-dashboard/components/tabs/modals/TicketBuyerDetailsModal'
import SpectatorTicketReceiptModal from '../../pages/organizer/manage/revenue/spectator/modals/SpectatorTicketReceiptModal'
import TicketBuyerDetailsModalCompSide from '../../pages/ticket-dashboard/components/tabs/modals/TicketBuyerDetailsModalCompSide'
import SendMessageModal from '../../components/modals/SendMessageModal'
import ScratchEntryModal from '../../components/modals/ScratchEntryModal'
import NewsModal from '../../pages/sponsor-and-vendor/components/body/tabs/news/NewsModal'
import CreateAndUpdateNewsModal from '../../pages/sponsor-and-vendor/components/body/tabs/news/CreateAndUpdateNewsModal'
import CreateAndUpdateDealModal from '../../pages/sponsor-and-vendor/components/body/tabs/deals/CreateAndUpdateDealModal'
import DealModal from '../../pages/sponsor-and-vendor/components/body/tabs/deals/DealModal'
import ProductModal from '../../pages/sponsor-and-vendor/components/body/tabs/products/ProductModal'
import CreateAndUpdateProductModal from '../../pages/sponsor-and-vendor/components/body/tabs/products/CreateAndUpdateProdutModal'
import SwitchAccounts from '../../components/modals/authenticationModalComponent/SwitchAccounts'
import VideoPlayer from '../../components/common/players/VideoPlayer'
import AutoAssignModal from '../../components/modals/AutoAssignModal'
import { PublishOperationsModal } from '../../pages/organizer/manage/common/modals/PublishOperationsModal'
import { ConfirmRemoveDraftEventModal } from '../../components/modals/ConfirmRemoveDraftEventModal'
import DeleteImageModal from '../../components/modals/RemoveImageModal'
import { AddNewTeamMemberModal } from '../../components/home/modals/AddNewTeamMemberModal'
import { AddNewHorseModal } from '../../components/home/modals/AddNewHorseModal'
import { AddNewUserModal } from '../../components/home/modals/AddNewUserModal'
import SendMessageEntriesModal from '../../components/modals/SendMessageEntriesModal'
import { ResultsLockedModal } from '../../components/modals/ResultsLockedModal'
import { AiRuleBookModal } from '../../components/modals/AiRuleBookModal'
import { AddTeamMemberToPaperwork } from '../../components/modals/AddTeamMemberToPaperwork'
import { AddTeamMemberToPaperworkStep2 } from '../../components/modals/AddTeamMemberToPaperworkStep2'
import { AddTeamMemberToPaperworkStep3 } from '../../components/modals/AddTeamMemberToPaperworkStep3'
import { AddNewTeamMemberPaperworkModal } from '../../components/modals/AddNewTeamMemberPaperworkModal'
import { SwitchHorse } from '../../components/modals/SwitchHorse'
import { UpdateUserNumberModal } from '../../components/modals/UpdateUserNumberModal'
import RegistrationPeopleModal from '../../components/modals/RegistrationPeopleModal'
import RegistrationHorseModal from '../../components/modals/RegistrationHorseModal'
import { BulkAdd } from '../../components/modals/BulkAdd'
import { ClassMore } from '../../components/modals/ClassMore'
import { AnswersModal } from '../../components/modals/AnswersModal'
import { UpdateTeamDetails } from '../../components/modals/UpdateTeamDetails'
import { AddMembershipModal } from '../../components/modals/membership/AddMembershipModal'
import { JoinModal } from '../../components/modals/membership/JoinModal'
import { PurchaseMembershipModal } from '../../components/modals/membership/PurchaseMembershipModal'
import { CancelMembershipModal } from '../../components/modals/membership/CancelMembershipModal'
import { PauseRenewMembershipModal } from '../../components/modals/membership/PauseRenewMembershipModal'

// Types
type IProps = {
  modalsState: { [x: string]: boolean }
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

/**
 * @TODO todo
 */

const MainLayoutModals = (props: IProps) => {
  // Hooks and vars
  const location = useLocation()
  const eventId = location.pathname.includes('events-details')
    ? location.pathname.split('/')[2]
    : null
  const { modalsState, dataToPassOn, handleModal } = props

  return (
    <>
      {modalsState?.[MODAL_CONSTS.SEE_MORE] && (
        <ViewModal show={true} dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.VIEW_SCHEDULE_ORGANIZER] && (
        <EventSchedulingViewScheduleModal
          show={true}
          dataToPassOn={dataToPassOn}
          handleModal={handleModal}
        />
      )}

      {modalsState?.[MODAL_CONSTS.HANDLE_LEGAL_CLAUSE] && (
        <ProfileLegalClauseModal
          show={true}
          dataToPassOn={dataToPassOn}
          handleModal={handleModal}
        />
      )}

      {modalsState?.[MODAL_CONSTS.FORGOT_PASSWORD] && (
        <ForgotPassword show={true} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.SIGN_DOCUMENT] && (
        <SignDocumentModal show={true} dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.SHARE_DOCUMENT] && (
        <ShareDocumentModal show={true} dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.NEWS] && (
        <NewsModal show={true} dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.DEAL] && (
        <DealModal show={true} dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.PRODUCT] && (
        <ProductModal show={true} dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.CREATE_AND_UPDATE_NEWS] && (
        <CreateAndUpdateNewsModal
          show={true}
          dataToPassOn={dataToPassOn}
          handleModal={handleModal}
        />
      )}

      {modalsState?.[MODAL_CONSTS.CREATE_AND_UPDATE_DEAL] && (
        <CreateAndUpdateDealModal
          show={true}
          dataToPassOn={dataToPassOn}
          handleModal={handleModal}
        />
      )}

      {modalsState?.[MODAL_CONSTS.CREATE_AND_UPDATE_PRODUCT] && (
        <CreateAndUpdateProductModal
          show={true}
          dataToPassOn={dataToPassOn}
          handleModal={handleModal}
        />
      )}

      {modalsState?.[MODAL_CONSTS.HORSE] && (
        <UserProfileHorseTabAddHorse show={true} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS] && (
        <UserSearchModal dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL] && (
        <SendEmailModal dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.NEARBY_BUSSINESSES] && (
        <UserProfileHorseTabAddHorse show={true} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.SUCCESS] && (
        <SuccessModal handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.TICKET_BUYER_DETAILS] && (
        <TicketBuyerDetailsModal handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.TICKET_BUYER_DETAILS_COMPSIDE] && (
        <TicketBuyerDetailsModalCompSide handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL] && (
        <SendEmailModal dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.SEND_MESSAGE] && (
        <SendMessageModal dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}
      {modalsState?.[MODAL_CONSTS.SEND_MESSAGE_ENTRIES] && (
        <SendMessageEntriesModal dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.RESULTS_LOCKED] && (
        <ResultsLockedModal dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.SCRATCH_ENTRY] && (
        <ScratchEntryModal dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}
      {modalsState?.[MODAL_CONSTS.AUTO_ASSIGN_MODAL] && (
        <AutoAssignModal dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.SCORING] && (
        <ScoringModal dataToPassOn={dataToPassOn} show={true} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS] && (
        <UserSearchModal dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.SPECTATOR_TICKET_RECEIPT] && (
        <SpectatorTicketReceiptModal dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.MESSAGES_VIA_MAIL] && (
        <SendEmailMessageModal dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.UPDATE_DISCIPLINE] && (
        <UpdateDisciplineModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.REVIEW_ANNEX] && (
        <ReviewAnnex show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.MANAGE_EVENT_MODAL] && (
        <ManageEventModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.SHARE_DOCUMENT] && (
        <ShareDocumentModal
          show={true}
          dataToPassOn={dataToPassOn}
          handleModal={handleModal}
          eventId={eventId}
        />
      )}

      {modalsState?.[MODAL_CONSTS.MANAGE_STRIPE_CONNECT_ACCOUNT] && (
        <ManageStripeAccountModal
          show={true}
          dataToPassOn={dataToPassOn}
          handleModal={handleModal}
        />
      )}

      {modalsState?.[MODAL_CONSTS.SWITCH_ACCOUNT] && (
        <SwitchAccounts show={true} dataToPassOn={dataToPassOn} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.TICKETS_DISTRIBUTER_MODAL] && (
        <TicketsDistributionModal
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.LINEAGE_ADDING_HORSES] && (
        <LineageHorseAdd show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.EXHIBITOR_SCRATCH_CONFIRM_MODAL] && (
        <ExhibitorScratchConfirmModal
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.TEAM_MEMBER_ROLE_CHANGE_WARNING] && (
        <HorseTeamMemberRoleChangeConfirmation
          show={true}
          dataToPassOn={dataToPassOn}
          handleModal={handleModal}
        />
      )}

      {modalsState?.[MODAL_CONSTS.UPLOAD_EVENT_IMAGES] && (
        <UploadEventImagesModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.FORGOT_PASSWORD] && (
        <ForgotPassword show={true} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.MEMBERSHIP_ADD] && (
        <ProfileLegalClauseMembershipModal show={true} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.MEMBERSHIP_MANAGE] && (
        <ProfileLegalClauseManageMembershipModal show={true} handleModal={handleModal} />
      )}

      {modalsState?.[MODAL_CONSTS.REMOVE_CONFIRMATION] && (
        <ListRemoveModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.UPDATE_DISCIPLINE] && (
        <UpdateDisciplineModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.CLOSE_EVENT_CONFIRMATION] && (
        <CloseEventConfirmationModal
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.PUBLISH_OPERATIONS_MODAL] && (
        <PublishOperationsModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.TEAM] && (
        <UserProfileTeamTabAddTeamMember handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.TEAM_MEMBER_ROLE_CHANGE_WARNING] && (
        <HorseTeamMemberRoleChangeConfirmation
          show={true}
          dataToPassOn={dataToPassOn}
          handleModal={handleModal}
        />
      )}

      {modalsState?.[MODAL_CONSTS.SPECTATOR_TICKETS] && (
        <EventDetailViewComponentSpectatorTicketsModal
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.BILL_BRIEFE] && (
        <BillDetailsModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.CLOSE_EVENT_CONFIRMATION] && (
        <CloseEventConfirmationModal
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.CANCEL_EVENT_MODAL] && (
        <CancelEventConfirmationModal
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.NEW_TRANSFER] && (
        <NewTransferModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.CONFIRM_REMOVE_DRAFT_EVENT] && (
        <ConfirmRemoveDraftEventModal
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}
      {modalsState?.[MODAL_CONSTS.CONFIRM_REMOVE_IMAGE] && (
        <DeleteImageModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.JUDGING_LICENCE] && (
        <JudgingLicenceInformation
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.ACCOUNT_PRIVACY] && (
        <AccountPrivacyInformation
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.REGISTRATION_PEOPLE] && (
        <RegistrationPeopleModal
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.REGISTRATION_HORSES] && (
        <RegistrationHorseModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.REMOVE_USER_CARD_ACCOUNT_MODAL] && (
        <DeletecardModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.UPDATE_PROFILE_EMAIL] && (
        <UserEmailUpdate show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.VIDEO_PLAYER] && (
        <VideoPlayer show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.REMOVE_HORSE] && (
        <RemoveHorseModel show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.SEARCH_HORSE_BY_NAME] && (
        <SearchHorseByName show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.OPEN_ORGANIZER_MAIL_INFO] && (
        <OrganizerEmailInfo show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.SCRATCH_STAFF_MODAL] && (
        <ScratchStaffModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.REGISTER_TEAM] && (
        <RegistrationTeamTabModal
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK] && (
        <AddTeamMemberToPaperwork
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}
      {modalsState?.[MODAL_CONSTS.ANSWERS] && (
        <AnswersModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}
      {modalsState?.[MODAL_CONSTS.UPDATE_USER_NUMBER] && (
        <UpdateUserNumberModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}
      {modalsState?.[MODAL_CONSTS.UPDATE_TEAM_DETAILS] && (
        <UpdateTeamDetails handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}
      {modalsState?.[MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK_STEP_2] && (
        <AddTeamMemberToPaperworkStep2
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}
      {modalsState?.[MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK_STEP_3] && (
        <AddTeamMemberToPaperworkStep3
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.ADD_NEW_TEAM_MEMBER] && (
        <AddNewTeamMemberModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}
      {modalsState?.[MODAL_CONSTS.CREATE_NEW_TEAM_MEMBER_PAPERWORK] && (
        <AddNewTeamMemberPaperworkModal
          show={true}
          handleModal={handleModal}
          dataToPassOn={dataToPassOn}
        />
      )}

      {modalsState?.[MODAL_CONSTS.ADD_NEW_USER] && (
        <AddNewUserModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.ADD_NEW_HORSE_MEMBER] && (
        <AddNewHorseModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.AI_RULE_BOOK] && (
        <AiRuleBookModal show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.SWITCH_HORSE] && (
        <SwitchHorse show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.BULK_ADD] && (
        <BulkAdd show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.CLASS_MORE] && (
        <ClassMore show={true} handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}

      {modalsState?.[MODAL_CONSTS.ADD_MEMBERSHIP] && (
        <AddMembershipModal handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}
      {modalsState?.[MODAL_CONSTS.JOIN] && (
        <JoinModal handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}
      {modalsState?.[MODAL_CONSTS.PURCHASE_MEMBERSHIP] && (
        <PurchaseMembershipModal handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}
      {modalsState?.[MODAL_CONSTS.CANCEL_MEMBERSHIP] && (
        <CancelMembershipModal handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}
      {modalsState?.[MODAL_CONSTS.PAUSE_RENEW_MEMBERSHIP] && (
        <PauseRenewMembershipModal handleModal={handleModal} dataToPassOn={dataToPassOn} />
      )}
    </>
  )
}

export default MainLayoutModals
