import moment from 'moment'
import { PDFImage, StandardFonts, cmyk } from 'pdf-lib'
// @ts-ignore
import { collection, doc, where } from 'firebase/firestore'
import helpers from '../../../commonHelpers/helpers'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { IDocument } from '../../../fakeData/fakeDocumentList'
import STATE_CLAUSES, { IStateClauses } from '../../../fakeData/stateClauses'
import { CustomError, capitalize, createString, getUserFullName } from '../../../helpers/helpers'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { IUserDocument } from '../../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../../models/user-documents/user-documents.model'
import { ILegalClauseInterface } from '../../../models/user-legal-clauses/user-legal-clauses.interface'
import { IUserInterface } from '../../../models/users/user.interface'
import FirestoreService from '../../../services/firestoreService'
import PdfService from '../../../services/pdfService'
import FirebaseStorageService from '../../../services/storageService'
import { IUserInterfaceExtended } from '../../../store/storeHelpers/userSlice/types'
import { sendEmailForSign } from '../sendEmail/sendEmailForSign'
import FirebaseApp from '../../../services/firebaseApp'
import TimeLib from '../../../lib/Time'
import { getConvertedData } from '../../../models/interface.helper'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'

interface IPaperworkTemplateData {
  userData: IUserInterfaceExtended
  eventDraftId: string
  EventDetails: IEventDetails
  organizerDetails: IUserInterface | null
  paperwork: IDocument
  eventId: string
  ridersTeamMember: IRiderTeamMemberInterface
  registrationByDay?: IRegistrationByDayInterface
  isMature: boolean
  clauses?: ILegalClauseInterface[]
  isStaff?: boolean
  isSigned?: boolean
  stringArray?: string[]
  index?: number
}

type TCertificate = {
  SX: number
  SY: number
  EX: number
  EY: number
}

interface IStateClause {
  state: string | null
  stateClause: IStateClauses[keyof IStateClauses] | null
}

export const GeneralLiabilityWaiverPDFTeamplate = async (
  {
    userData,
    isStaff,
    eventDraftId,
    EventDetails,
    organizerDetails,
    paperwork,
    eventId,
    ridersTeamMember,
    registrationByDay,
    isMature,
    clauses,
    isSigned,
    stringArray,
    index,
  }: IPaperworkTemplateData,
  customDocId?: string,
  logs?: any[]
) => {
  let retunedData: any = {}
  const MAX_LOCATION_LENGTH = 36
  const d = new Date()
  let time = d.getTime()
  let decrementYBy = 0
  let remainingPartOfShortLocation = ''
  let pageNumberToSignOn: IUserDocument['pageNumberToSignOn']
  let coordinatesToSignOn: IUserDocument['coordinatesToSignOn']
  let competetionState = createString(EventDetails.competitionState)

  competetionState = capitalize(competetionState, {
    fullyCapitalize: true,
  })

  const STATE_CLAUSE: IStateClause = {
    state: capitalize(createString(competetionState).toLocaleLowerCase()),
    stateClause: STATE_CLAUSES[competetionState] ?? null,
  }

  let shortLocation = createString([
    EventDetails.competitionCity,
    EventDetails.competitionState,
    EventDetails.competitionCountry,
  ])

  if (shortLocation.length > MAX_LOCATION_LENGTH) {
    remainingPartOfShortLocation = shortLocation.substring(MAX_LOCATION_LENGTH)
    shortLocation = shortLocation.substring(0, MAX_LOCATION_LENGTH)
    decrementYBy = -20
  }

  const pdfService = new PdfService({
    pageWidth: 550,
    pageHeight: 760,
    distanceBetweenLines: 20,
    pageVerticalStartPoint: 720,
    pageHorizontalStartPoint: 45,
    pageHorizontalEndPoint: 480,
    pageVerticalEndPoint: 80,
  })

  const firstPage = await pdfService.addNewPdfPage()
  const secondPage = await pdfService.addNewPdfPage()
  const thirdPage = await pdfService.addNewPdfPage()
  const fourthPage = await pdfService.addNewPdfPage()
  const fifthPage = await pdfService.addNewPdfPage()

  // Fonts
  const jpgUrl = '/assets/Pegasus_app_logo.png'
  const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
  const fontPoppingsBytes = await fetch('/fonts/Poppins-Regular.ttf').then((res) =>
    res.arrayBuffer()
  )

  const jpgImage = await pdfService.embedPng(jpgImageBytes)
  const poppinsFont = await pdfService.embedFont(fontPoppingsBytes)
  const boldFont = await pdfService.embedFont(StandardFonts.TimesRomanBold)
  const timeRomanFont = await pdfService.embedFont(StandardFonts.TimesRoman)
  const fontByte = await fetch('/fonts/signature.ttf').then((res) => res.arrayBuffer())
  const signatureFont = await pdfService.embedFont(fontByte)

  /**
   * -----------------------------------------------------
   * First page
   * -----------------------------------------------------
   */
  firstPage.drawText('Pegasus App Inc.', {
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.pageVerticalStartPoint,
    font: poppinsFont,
    size: 12,
  })
  firstPage.drawLine({
    start: { x: pdfService.pageHorizontalStartPoint, y: 715 },
    end: { x: pdfService.pageHorizontalEndPoint, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })
  firstPage.drawImage(jpgImage, {
    x: pdfService.pageHorizontalEndPoint,
    y: 695,
    width: 40,
    height: 40,
    opacity: 1,
  })

  firstPage.drawText(`GENERAL LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`, {
    x: 110,
    y: 520,
    font: boldFont,
    size: 20,
  })

  // Event Name
  firstPage.drawText(`Event :`, { x: 230, y: 470, font: boldFont, size: 16 })
  firstPage.drawText(`${capitalize(EventDetails.competitionName)}`, {
    x: 290,
    y: 470,
    font: timeRomanFont,
    size: 16,
  })

  //Organizer Name
  firstPage.drawText(`Host :`, { x: 238, y: 450, font: boldFont, size: 16 })
  firstPage.drawText(
    `${capitalize(organizerDetails ? getUserFullName(organizerDetails) : 'No organizer on file')}`,
    { x: 290, y: 450, font: timeRomanFont, size: 16 }
  )

  // Event Location
  firstPage.drawText(`Event location :`, { x: 170, y: 430, font: boldFont, size: 16 })
  firstPage.drawText(`${shortLocation}`, { x: 290, y: 430, font: timeRomanFont, size: 16 })
  firstPage.drawText(`${remainingPartOfShortLocation}`, {
    x: 290,
    y: 410,
    font: timeRomanFont,
    size: 16,
  })

  // Event Date
  firstPage.drawText(`Event date :`, { x: 195, y: decrementYBy + 390, font: boldFont, size: 16 })
  firstPage.drawText(`${moment(EventDetails.competitionStartDate).format('MM-DD-YYYY')}`, {
    x: 290,
    y: decrementYBy + 390,
    font: timeRomanFont,
    size: 16,
  })

  // Horse Name
  firstPage.drawText(`Horse :`, { x: 229, y: decrementYBy + 370, font: boldFont, size: 16 })
  firstPage.drawText(
    `${isStaff ? 'No horse on file' : capitalize(registrationByDay?.horseName ?? 'No horse on file')}`,
    { x: 290, y: decrementYBy + 370, font: timeRomanFont, size: 16 }
  )

  // Rider Name
  firstPage.drawText(`Rider :`, { x: 229, y: decrementYBy + 350, font: boldFont, size: 16 })
  firstPage.drawText(`${isStaff ? 'No rider on file' : capitalize(ridersTeamMember.riderName)}`, {
    x: 290,
    y: decrementYBy + 350,
    font: timeRomanFont,
    size: 16,
  })

  // Rider nationality ////
  firstPage.drawText(`Rider nationality :`, {
    x: 150,
    y: decrementYBy + 330,
    font: boldFont,
    size: 16,
  })

  firstPage.drawText(
    `${capitalize(ridersTeamMember.teamMemberCountry && ridersTeamMember.teamMemberCountry !== '' ? ridersTeamMember.teamMemberCountry : 'No nationality on file')}`,
    { x: 290, y: decrementYBy + 330, font: timeRomanFont, size: 16 }
  )

  ////// Signer Role ////////
  firstPage.drawText(`Signer role at event :`, {
    x: 140,
    y: decrementYBy + 290,
    font: boldFont,
    size: 16,
  })
  firstPage.drawText(`${capitalize(stringArray?.slice(0, 3).toString())}`, {
    x: 290,
    y: decrementYBy + 290,
    font: timeRomanFont,
    size: 16,
  })
  firstPage.drawText(`${capitalize(stringArray?.slice(3, 6).toString())}`, {
    x: 290,
    y: decrementYBy + 270,
    font: timeRomanFont,
    size: 16,
  })

  ////// Signer name ////////
  firstPage.drawText(`Signer name :`, { x: 185, y: decrementYBy + 250, font: boldFont, size: 16 })
  firstPage.drawText(`${capitalize(ridersTeamMember.teamMemberName)}`, {
    x: 290,
    y: decrementYBy + 250,
    font: timeRomanFont,
    size: 16,
  })

  ////// Signer Phone number ////////
  firstPage.drawText(`Signer's phone number :`, {
    x: 118,
    y: decrementYBy + 230,
    font: boldFont,
    size: 16,
  })
  firstPage.drawText(
    `${ridersTeamMember.teamMemberPhoneNumber && ridersTeamMember.teamMemberPhoneNumber !== '' ? ridersTeamMember.teamMemberPhoneNumber : 'No number on file'}`,
    { x: 290, y: decrementYBy + 230, font: timeRomanFont, size: 16 }
  )

  /**
   * -----------------------------------------------------
   * Second page
   * -----------------------------------------------------
   */
  secondPage.drawText('Pegasus App Inc.', {
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.pageVerticalStartPoint,
    font: poppinsFont,
    size: 12,
  })
  secondPage.drawLine({
    start: { x: pdfService.pageHorizontalStartPoint, y: 715 },
    end: { x: pdfService.pageHorizontalEndPoint, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })
  secondPage.drawImage(jpgImage, {
    x: pdfService.pageHorizontalEndPoint,
    y: 695,
    width: 40,
    height: 40,
    opacity: 1,
  })

  secondPage.drawText('AGREEMENT FOR RELEASE, WAIVER', {
    x: pdfService.pageHorizontalStartPoint,
    y: 680,
    font: boldFont,
    size: 16,
  })
  secondPage.drawText('OF LIABILITY AND INDEMNIFICATION COMMITMENT', {
    x: pdfService.pageHorizontalStartPoint,
    y: 660,
    font: boldFont,
    size: 16,
  })
  secondPage.drawText(`General Liability Waiver, ${isMature ? '18+' : 'Minor'}`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 630,
    font: boldFont,
    size: 16,
  })

  secondPage.drawText(`${capitalize(EventDetails.competitionName ?? '')}`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 590,
    font: timeRomanFont,
    size: 14,
  })
  secondPage.drawText(`${moment(EventDetails.competitionStartDate).format('MM-DD-YYYY')}`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 570,
    font: timeRomanFont,
    size: 14,
  })
  secondPage.drawText(`${createString([shortLocation, remainingPartOfShortLocation], '')}`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 550,
    font: timeRomanFont,
    size: 14,
  })

  secondPage.drawText(
    `I, ${capitalize(ridersTeamMember.teamMemberName, {
      capitalizeAll: true,
    })} (from here on known as “THE UNDERSIGNED”), ${isMature ? 'in consideration for the right' : `legal guardian of ${isStaff ? '-N/A-' : ridersTeamMember.riderName} (from here`}`,
    { x: pdfService.pageHorizontalStartPoint, y: 520, font: timeRomanFont, size: 12 }
  )

  let differenceText = isMature
    ? 'to participate in Horseback Riding Activities at'
    : `on known as “ENTRANT”) in consideration for the right to participate in Horseback Riding`

  secondPage.drawText(
    `${differenceText} ${isMature ? (organizerDetails ? getUserFullName(organizerDetails) : 'Unknown') : ''}`,
    { x: pdfService.pageHorizontalStartPoint, y: 500, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `${isMature ? '' : `${organizerDetails ? getUserFullName(organizerDetails) : 'Unknown'} Activities at`} (from here on known as the “HOST”), located in the general vicinity of `,
    { x: pdfService.pageHorizontalStartPoint, y: 480, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `${createString([shortLocation, remainingPartOfShortLocation], '')} does hereby provide this Agreement for Release`,
    { x: pdfService.pageHorizontalStartPoint, y: 460, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `Waiver of Liability and Indemnification Commitment (from here on, known as`,
    { x: pdfService.pageHorizontalStartPoint, y: 440, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(`the “AGREEMENT”) and represent(s) and agree(s) as follows:`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 420,
    font: timeRomanFont,
    size: 12,
  })

  secondPage.drawText(
    `1. I, THE UNDERSIGNED, have inspected those facilities at the HOSTS property that I`,
    { x: pdfService.pageHorizontalStartPoint, y: 380, font: timeRomanFont, size: 12 }
  )

  secondPage.drawText(
    `desire to utilize, including, but not limited to, the parking areas, dressage ring, the stadium`,
    { x: pdfService.pageHorizontalStartPoint, y: 360, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `jumping area, and the cross-country course (collectively, the “HOSTs Property”) and am`,
    { x: pdfService.pageHorizontalStartPoint, y: 340, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `thoroughly familiar with the conditions and potential hazards of all of these facilities. I`,
    { x: pdfService.pageHorizontalStartPoint, y: 320, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `have inspected each obstacle/jump that I will interact with and am familiar with its design`,
    { x: pdfService.pageHorizontalStartPoint, y: 300, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `and status, and with the footing and other physical conditions around and near each such`,
    { x: pdfService.pageHorizontalStartPoint, y: 280, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `obstacle/jump. I have assessed the presence of medical personnel, or lack thereof,`,
    { x: pdfService.pageHorizontalStartPoint, y: 260, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `present at HOSTs property, deem that level to be acceptable, and understand that I`,
    { x: pdfService.pageHorizontalStartPoint, y: 240, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(`shall be responsible for my own medical care, assistance and expenses.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 220,
    font: timeRomanFont,
    size: 12,
  })

  secondPage.drawText(`2. I, THE UNDERSIGNED, on behalf of myself, my heirs, guardians and legal`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 160,
    font: timeRomanFont,
    size: 12,
  })
  secondPage.drawText(
    `representatives, fully understand that horse riding activities are by their nature `,
    { x: pdfService.pageHorizontalStartPoint, y: 140, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `very dangerous activities. Horseback riding is classified as a rugged adventure`,
    { x: pdfService.pageHorizontalStartPoint, y: 120, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `recreational sport activity and there are numerous obvious and non-obvious inherent`,
    { x: pdfService.pageHorizontalStartPoint, y: 100, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `risks always present in such activity despite all safety precautions. The term`,
    { x: pdfService.pageHorizontalStartPoint, y: 80, font: timeRomanFont, size: 12 }
  )

  /**
   * -----------------------------------------------------
   * Third page
   * -----------------------------------------------------
   */
  thirdPage.drawText('Pegasus App Inc.', {
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.pageVerticalStartPoint,
    font: poppinsFont,
    size: 12,
  })
  thirdPage.drawLine({
    start: { x: pdfService.pageHorizontalStartPoint, y: 715 },
    end: { x: pdfService.pageHorizontalEndPoint, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })

  thirdPage.drawImage(jpgImage, {
    x: pdfService.pageHorizontalEndPoint,
    y: 695,
    width: 40,
    height: 40,
    opacity: 1,
  })

  thirdPage.drawText(
    `“Horseback Riding Activities” as used herein includes all riding and handling of horses,`,
    { x: pdfService.pageHorizontalStartPoint, y: 680, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `ponies, mules, or donkeys, whether mounted or on the ground, including but not limited`,
    { x: pdfService.pageHorizontalStartPoint, y: 660, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `to taking lessons, or participating in competition, as well as all other activities`,
    { x: pdfService.pageHorizontalStartPoint, y: 640, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(`associated the riding, handling and competing of horses.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 620,
    font: timeRomanFont,
    size: 12,
  })
  thirdPage.drawText(
    `3. I, THE UNDERSIGNED, understand that in any Horseback Riding Activity I and/or`,
    { x: pdfService.pageHorizontalStartPoint, y: 580, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `my horse may be injured as a result of my negligence, the negligence of others,`,
    { x: pdfService.pageHorizontalStartPoint, y: 560, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `or due to fault of no one, and that horses, even when well trained, can be unpredictable`,
    { x: pdfService.pageHorizontalStartPoint, y: 540, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `and difficult to control. I accept and assume all risks of injury (even death)`,
    { x: pdfService.pageHorizontalStartPoint, y: 520, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(`to me and my property.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 500,
    font: timeRomanFont,
    size: 12,
  })
  thirdPage.drawText(
    `4. I, THE UNDERSIGNED, understand that, upon mounting a horse, the rider is in`,
    { x: pdfService.pageHorizontalStartPoint, y: 460, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `primary of the horse. I agree that I shall be responsible for the safety, of myself,`,
    { x: pdfService.pageHorizontalStartPoint, y: 440, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `my horse and other persons and their property, and I will take all reasonable precautions`,
    { x: pdfService.pageHorizontalStartPoint, y: 420, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `to protect against injury when participating in any Horseback Riding Activities.`,
    { x: pdfService.pageHorizontalStartPoint, y: 400, font: timeRomanFont, size: 12 }
  )

  thirdPage.drawText(
    `5. I, THE UNDERSIGNED, understand that participation includes possible exposure to`,
    { x: pdfService.pageHorizontalStartPoint, y: 340, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `and illness from infectious diseases including but not limited to MRSA, influenza, and`,
    { x: pdfService.pageHorizontalStartPoint, y: 320, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `COVID-19. While particular rules and personal discipline may reduce this risk, the risk of`,
    { x: pdfService.pageHorizontalStartPoint, y: 300, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(`serious illness and death does exist.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 280,
    font: timeRomanFont,
    size: 12,
  })

  thirdPage.drawText(`6. I, THE UNDERSIGNED, KNOWINGLY AND FREELY ASSUME ALL SUCH RISKS,`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 240,
    font: timeRomanFont,
    size: 12,
  })
  thirdPage.drawText(`both known and unknown, EVEN IF ARISING FROM THE NEGLIGENCE OF THE`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 220,
    font: timeRomanFont,
    size: 12,
  })
  thirdPage.drawText(`RELEASEES or others, and assume full responsibility for my participation.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 200,
    font: timeRomanFont,
    size: 12,
  })

  thirdPage.drawText(
    `7. I, THE UNDERSIGNED, willingly agree to comply with the stated and customary terms`,
    { x: pdfService.pageHorizontalStartPoint, y: 160, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `and conditions for participation as regards protection against infectious diseases. If, `,
    { x: pdfService.pageHorizontalStartPoint, y: 140, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `however, I observe and any unusual or significant hazard during my presence or participation,`,
    { x: pdfService.pageHorizontalStartPoint, y: 120, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `I will remove myself from participation and bring such to the attention of the nearest`,
    { x: pdfService.pageHorizontalStartPoint, y: 100, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(`official immediately.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 80,
    font: timeRomanFont,
    size: 12,
  })

  /**
   * -----------------------------------------------------
   * Fourth page
   * -----------------------------------------------------
   */
  fourthPage.drawText('Pegasus App Inc.', {
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.pageVerticalStartPoint,
    font: poppinsFont,
    size: 12,
  })
  fourthPage.drawLine({
    start: { x: pdfService.pageHorizontalStartPoint, y: 715 },
    end: { x: pdfService.pageHorizontalEndPoint, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })

  fourthPage.drawImage(jpgImage, {
    x: pdfService.pageHorizontalEndPoint,
    y: 695,
    width: 40,
    height: 40,
    opacity: 1,
  })

  fourthPage.drawText(
    `8. I, THE UNDERSIGNED, agree to wear ASTM-approved protective headgear when`,
    { x: pdfService.pageHorizontalStartPoint, y: 680, font: timeRomanFont, size: 12 }
  )

  fourthPage.drawText(
    `at the HOSTs property. I agree to comply with all applicable rules for use of HOSTs property.`,
    { x: pdfService.pageHorizontalStartPoint, y: 660, font: timeRomanFont, size: 12 }
  )

  fourthPage.drawText(
    `9. I confirm that I, the UNDERSIGNED, have read and agree to all details, statements,`,
    { x: pdfService.pageHorizontalStartPoint, y: 620, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `instructions and warning outlined by in Annex A of this AGREEMENT, which are`,
    { x: pdfService.pageHorizontalStartPoint, y: 600, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(`included in and pursuant too, all terms in this AGREEMENT.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 580,
    font: timeRomanFont,
    size: 12,
  })

  fourthPage.drawText(
    `10. By my signature below, I, THE UNDERSIGNED, on behalf of myself, my heirs,`,
    { x: pdfService.pageHorizontalStartPoint, y: 540, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `guardians and legal representative, release, exculpate and agree to indemnify and defend`,
    { x: pdfService.pageHorizontalStartPoint, y: 520, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `all owners of HOST, be they direct or indirect; all family members of HOST`,
    { x: pdfService.pageHorizontalStartPoint, y: 500, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `owners, (collectively, “HOST Owners”); and all heirs, devisees and other beneficiaries of`,
    { x: pdfService.pageHorizontalStartPoint, y: 480, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `all HOST Owners; and all of their officers, members, member families, employees,`,
    { x: pdfService.pageHorizontalStartPoint, y: 460, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `heirs and assigns, (collectively, the “Releasees”) against any claim of loss, injury, damage`,
    { x: pdfService.pageHorizontalStartPoint, y: 440, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `or other harm, of any nature, to the fullest extend permissible by applicable law, to myself,`,
    { x: pdfService.pageHorizontalStartPoint, y: 420, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `my horse or to third parties as a result of my Horseback Riding Activities.`,
    { x: pdfService.pageHorizontalStartPoint, y: 400, font: timeRomanFont, size: 12 }
  )

  fourthPage.drawText(
    `11. Neither I, THE UNDERSIGNED, nor anyone on behalf of myself, my heirs, guardians`,
    { x: pdfService.pageHorizontalStartPoint, y: 360, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `or legal representative, shall bring any claim, demand, action or litigation, of any nature,`,
    { x: pdfService.pageHorizontalStartPoint, y: 340, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `against the RELEASEES for any economic or non-economic loss due to bodily injury,`,
    { x: pdfService.pageHorizontalStartPoint, y: 320, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `death, property damage or any other damage sustained by me and/or my minor child`,
    { x: pdfService.pageHorizontalStartPoint, y: 300, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `associated with any Horseback Riding Activities at HOSTs Property. Should anyone`,
    { x: pdfService.pageHorizontalStartPoint, y: 280, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `make any claim because of any injury to me (including death), I (or my representatives)`,
    { x: pdfService.pageHorizontalStartPoint, y: 260, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `will indemnify those who are released by this AGREEMENTS, and I agree to pay `,
    { x: pdfService.pageHorizontalStartPoint, y: 240, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `reasonable attorneys’ fees, in addition to other damages, should I breach any part of`,
    { x: pdfService.pageHorizontalStartPoint, y: 220, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(`this AGREEMENT.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 200,
    font: timeRomanFont,
    size: 12,
  })

  fourthPage.drawText(
    `12. This AGREEMENT shall be legally binding upon each of the signatories below,`,
    { x: pdfService.pageHorizontalStartPoint, y: 160, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `my heirs, guardians and legal representative and any third parties who may otherwise have`,
    { x: pdfService.pageHorizontalStartPoint, y: 140, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(`claims due to their contractual or other relationship with any signatory.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 120,
    font: timeRomanFont,
    size: 12,
  })

  fourthPage.drawText(
    `13. I understand that this document is equal to the entire AGREEMENT between the`,
    { x: pdfService.pageHorizontalStartPoint, y: 80, font: timeRomanFont, size: 12 }
  )

  /**
   * -----------------------------------------------------
   * Fifth page
   * -----------------------------------------------------
   */
  fifthPage.drawText('Pegasus App Inc.', {
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.pageVerticalStartPoint,
    font: poppinsFont,
    size: 12,
  })
  fifthPage.drawLine({
    start: { x: pdfService.pageHorizontalStartPoint, y: 715 },
    end: { x: pdfService.pageHorizontalEndPoint, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })
  fifthPage.drawImage(jpgImage, {
    x: pdfService.pageHorizontalEndPoint,
    y: 695,
    width: 40,
    height: 40,
    opacity: 1,
  })

  fifthPage.drawText(
    `parties. This lease may not be modified or amended in any manner except by an instrument`,
    { x: pdfService.pageHorizontalStartPoint, y: 680, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(`in writing executed by the parties.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 660,
    font: timeRomanFont,
    size: 12,
  })

  fifthPage.drawText(
    `14. I confirm that I, THE UNDERSIGNED, understand that this document is being`,
    { x: pdfService.pageHorizontalStartPoint, y: 630, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(
    `provided to me by Pegasus App Inc. for my convenience and in no way involves,`,
    { x: pdfService.pageHorizontalStartPoint, y: 610, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(
    `includes, or infers inclusion of Pegasus App Inc. in any actions, events or activities related`,
    { x: pdfService.pageHorizontalStartPoint, y: 590, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(`to this AGREEMENT.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 570,
    font: timeRomanFont,
    size: 12,
  })

  fifthPage.drawText(
    `15. I confirm that I, THE UNDERSIGNED, agree that in the event that a civil, criminal or`,
    { x: pdfService.pageHorizontalStartPoint, y: 540, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(
    `common lawsuit arises from actions, activities, misunderstandings or events, pertaining`,
    { x: pdfService.pageHorizontalStartPoint, y: 520, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(
    `to this AGREEMENT, I indemnify Pegasus App Inc. of any and all liability and/or involvement`,
    { x: pdfService.pageHorizontalStartPoint, y: 500, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(`in the lawsuit, including prosecution in said lawsuit.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 480,
    font: timeRomanFont,
    size: 12,
  })

  fifthPage.drawText(
    `16. I confirm that I, the UNDERSIGNED, understand that this agreement shall be governed`,
    { x: pdfService.pageHorizontalStartPoint, y: 440, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(`by the laws of the State of ${shortLocation},`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 420,
    font: timeRomanFont,
    size: 12,
  })
  fifthPage.drawText(
    `however, the parties agree to required Mediation and Arbitration of any disputes relating`,
    { x: pdfService.pageHorizontalStartPoint, y: 400, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(
    `to this transaction. Any legal action commenced to enforce or interpret this AGREEMENT`,
    { x: pdfService.pageHorizontalStartPoint, y: 380, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(
    `shall be brought in state or federal courts with the appropriate jurisdiction,`,
    { x: pdfService.pageHorizontalStartPoint, y: 360, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(`located in ${shortLocation}.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 340,
    font: timeRomanFont,
    size: 12,
  })
  fifthPage.drawText(`The parties hereto consent to both venue and jurisdiction.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 320,
    font: timeRomanFont,
    size: 12,
  })

  fifthPage.drawText(
    `17. I confirm that I, the UNDERSIGNED, have read the applicable state warnings and/or`,
    { x: pdfService.pageHorizontalStartPoint, y: 280, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(
    `provisions set forth in paragraph eighteen (18), that correlates with the state listed in`,
    { x: pdfService.pageHorizontalStartPoint, y: 260, font: timeRomanFont, size: 12 }
  )
  fifthPage.drawText(`paragraph sixteen (16) of this AGREEMENT.`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 240,
    font: timeRomanFont,
    size: 12,
  })

  await pdfService.writeLine({
    content: `18. ${STATE_CLAUSE.state}`,
    x: pdfService.pageHorizontalStartPoint,
    y: 220 - pdfService.distanceBetweenLines,
    font: timeRomanFont,
    fontSize: 12,
  })

  if (STATE_CLAUSE.stateClause) {
    if (!!STATE_CLAUSE.stateClause.WARNING)
      await pdfService.writeLine({
        content: STATE_CLAUSE.stateClause.WARNING,
        x: pdfService.pageHorizontalStartPoint,
        y: pdfService.getCurrentY,
        font: timeRomanFont,
        fontSize: 12,
      })

    if (!!STATE_CLAUSE.stateClause?.POINTS_HEADING)
      await pdfService.writeLine({
        content: STATE_CLAUSE.stateClause?.POINTS_HEADING,
        x: pdfService.pageHorizontalStartPoint,
        y: pdfService.getCurrentY,
        font: timeRomanFont,
        fontSize: 12,
      })

    if (!!STATE_CLAUSE.stateClause?.POINTS.length)
      await helpers.asyncWhileLoop({
        loopCount: STATE_CLAUSE.stateClause?.POINTS.length,
        functionToFire: async (index) => {
          await pdfService.writeLine({
            content: STATE_CLAUSE.stateClause?.POINTS?.[index] ?? '',
            x: pdfService.pageHorizontalStartPoint,
            y: pdfService.getCurrentY,
            font: timeRomanFont,
            fontSize: 12,
          })
        },
      })
  }

  await pdfService.writeLine({
    content: `19. ACKNOWLEDGEMENT OF UNDERSTANDING. I UNDERSTAND THIS IS A LEGAL DOCUMENT AND THAT I AM SIGNING THIS AGREEMENT FREELY AND VOLUNTARILY. I UNDERSTAND I HAVE THE CHOICE NOT TO SIGN THIS AGREEMENT, AND THEREFORE NOT TO UTILIZE HOSTS PROPERTY. I HAVE READ THIS AGREEMENT AND FULLY UNDERSTAND ITS TERMS. I UNDERSTAND THAT I AM GIVING UP SUBSTANTIAL RIGHTS, INCLUDING MY RIGHT TO SUE ANY OF THE RELEASEES. I FURTHER ACKNOWLEDGE THAT I INTEND MY SIGNATURE TO BE A COMPLETE AND UNCONDITIONAL RELEASE OF ALL LIABILITY, INCLUDING THAT DUE TO ORDINARY NEGLIGENCE BY THE RELEASEES TO THE GREATEST EXTENT ALLOWED BY THE LAWS OF ${capitalize(
      createString(EventDetails.competitionCity),
      {
        fullyCapitalize: true,
        returnValueIfNotString: 'N/A',
      }
    )} (CITY) ${capitalize(createString(EventDetails.competitionState), {
      fullyCapitalize: true,
      returnValueIfNotString: 'N/A',
    })} (STATE) ${capitalize(createString(EventDetails.competitionCountry), {
      fullyCapitalize: true,
      returnValueIfNotString: 'N/A',
    })} (COUNTRY)`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY - pdfService.distanceBetweenLines,
    font: timeRomanFont,
    fontSize: 12,
    factor: 0.7,
  })

  await pdfService.writeLine({
    content: `Signers name:`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY - pdfService.distanceBetweenLines,
    font: boldFont,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: capitalize(createString(ridersTeamMember.teamMemberName), {
      capitalizeAll: true,
    }),
    x: pdfService.getCurrentX + 55,
    y: pdfService.getCurrentY + pdfService.distanceBetweenLines,
    font: timeRomanFont,
    fontSize: 12,
  })

  if (!isMature) {
    await pdfService.writeLine({
      content: `Signing as parent/guardian of :`,
      x: pdfService.pageHorizontalStartPoint,
      y: pdfService.getCurrentY,
      font: boldFont,
      fontSize: 12,
    })

    await pdfService.writeLine({
      content: capitalize(createString(ridersTeamMember.teamMemberName), {
        capitalizeAll: true,
      }),
      x: 205,
      y: pdfService.getCurrentY + pdfService.distanceBetweenLines,
      font: timeRomanFont,
      fontSize: 12,
    })
  }

  await pdfService.writeLine({
    content: `Date :`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY,
    font: boldFont,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: `${moment(getConvertedData({ date: TimeLib.utcTimestamp() }).date).format('MM-DD-YYYY')}`,
    x: 80,
    y: pdfService.getCurrentY + pdfService.distanceBetweenLines,
    font: timeRomanFont,
    fontSize: 12,
  })

  await pdfService.writeLine({
    content: `Signature:`,
    x: pdfService.pageHorizontalStartPoint,
    y: pdfService.getCurrentY,
    font: boldFont,
    fontSize: 12,
  })

  pageNumberToSignOn = pdfService.getCurrentPageNumber
  coordinatesToSignOn = {
    y: pdfService.getCurrentY - pdfService.distanceBetweenLines / 2,
    x: pdfService.pageHorizontalStartPoint,
  }

  await pdfService.writeLine({
    content: `${ridersTeamMember.teamMemberName}`,
    x: coordinatesToSignOn?.x ?? 45,
    y: coordinatesToSignOn?.y ?? 500,
    font: signatureFont,
    fontSize: 28,
  })

  // Adding space for signatures
  await helpers.asyncWhileLoop({
    loopCount: Array(8).length,
    functionToFire: async () => {
      await pdfService.writeLine({
        content: ``,
        x: pdfService.pageHorizontalStartPoint,
        y: pdfService.getCurrentY,
        font: boldFont,
        fontSize: 12,
      })
    },
  })

  if (clauses && clauses.length && isMature) {
    await pdfService.writeLine({
      content: `Annex A: Location specific liability clauses`,
      x: pdfService.pageHorizontalStartPoint,
      y: pdfService.getCurrentY,
      font: boldFont,
      fontSize: 18,
      stateFromNewPage: true,
    })

    await pdfService.writeLine({
      content: `Below is a list of specific labilities and risks specific to ${capitalize(createString(organizerDetails ? getUserFullName(organizerDetails) : 'Unknown'))} located at ${capitalize(
        createString(organizerDetails?.userAddress),
        {
          capitalizeAll: true,
        }
      )}`,
      x: pdfService.pageHorizontalStartPoint,
      y: pdfService.getCurrentY - pdfService.distanceBetweenLines,
      font: timeRomanFont,
      fontSize: 12,
    })

    await pdfService.writeLine({
      content: `By signing this agreement, I acknowledge that I have read and agree to all details, statements, instructions and warning outlined below in this list of labilities and risks in Annex A, as stated in Paragraph ten (10) of this AGREEMENT.`,
      x: pdfService.pageHorizontalStartPoint,
      y: pdfService.getCurrentY,
      font: timeRomanFont,
      fontSize: 12,
    })

    await pdfService.writeLine({
      content: `These liability and risks include:`,
      x: pdfService.pageHorizontalStartPoint,
      y: pdfService.getCurrentY,
      font: timeRomanFont,
      fontSize: 12,
    })

    await helpers.asyncWhileLoop({
      loopCount: clauses.length,
      functionToFire: async (index) => {
        await pdfService.writeLine({
          content: `Clause ${index + 1}. ${clauses[index]?.title}. ${createString(clauses[index].description)}`,
          x: pdfService.pageHorizontalStartPoint,
          y: pdfService.getCurrentY - pdfService.distanceBetweenLines,
          font: timeRomanFont,
          fontSize: 12,
        })
      },
    })
  }

  if (isSigned) {
    let AllLogs: {
      logs: any
      id?: string
      signatoryId?: string
      riderId?: string
      eventId?: string
      u?: boolean
    }

    AllLogs = {
      id: '',
      signatoryId: ridersTeamMember.teamMemberId ?? '',
      riderId: ridersTeamMember.riderId ?? '',
      eventId: ridersTeamMember.eventId ?? '',
      u: false,
      logs: [
        {
          time: TimeLib.utcTimestamp(),
          audit: `Document signed by ${ridersTeamMember.teamMemberName}`,
        },
      ],
    }

    const auditTrailPage = await pdfService.addNewPdfPage()
    auditTrailPage.drawText('Pegasus App Inc.', { x: 45, y: 720, font: poppinsFont, size: 14 })
    auditTrailPage.drawLine({
      start: { x: 45, y: 715 },
      end: { x: 480, y: 715 },
      thickness: 1,
      color: cmyk(0, 0, 0, 0.9),
      opacity: 1,
    })

    auditTrailPage.drawImage(jpgImage, {
      x: 480,
      y: 695,
      width: 40,
      height: 40,
      opacity: 1,
    })

    let certificateBorder = [
      { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
      { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
      { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
      { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
    ]

    certificateBorder.forEach((item: TCertificate) => {
      auditTrailPage.drawLine({
        start: { x: item.SX, y: item.SY },
        end: { x: item.EX, y: item.EY },
        thickness: 2,
        color: cmyk(0, 0, 0, 0.5),
        opacity: 0.8,
      })
    })

    let documentRef = doc(
      collection(FirebaseApp.firestore, CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME)
    )
    let id = documentRef.id
    let documentNameAsPerPdf = `GENERAL LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`
    auditTrailPage.drawText(`Signature Certificate`, {
      x: 80,
      y: 640,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 14,
    })
    auditTrailPage.drawText(`Document Name: ${documentNameAsPerPdf}`, {
      x: 80,
      y: 630,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 10,
    })
    auditTrailPage.drawText(`Unique Document Id: ${id}`, {
      x: 80,
      y: 620,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 8,
    })

    let Image = ridersTeamMember.teamMemberProfilePicture
      ? ridersTeamMember.teamMemberProfilePicture
      : '/assets/img/Empty user.png'
    let imagetype: string = ''

    const getImageArray = async (imageUrl: string) => {
      return await fetch(imageUrl).then((res) => {
        const contentType = res.headers.get('content-type') ?? ''
        imagetype = contentType?.split('/')[1]
        return res.arrayBuffer()
      })
    }

    let jpgImageBy = await getImageArray(Image)

    let userImage: PDFImage
    if (imagetype === 'jpeg') {
      userImage = await pdfService.embedJpeg(jpgImageBy)
    } else if (imagetype === 'png') {
      userImage = await pdfService.embedPng(jpgImageBy)
    } else {
      const defaultImage = await getImageArray('/assets/img/Empty user.png')
      userImage = await pdfService.embedPng(defaultImage)
    }

    auditTrailPage.drawImage(userImage, {
      x: 80,
      y: 480,
      width: 80,
      height: 80,
      opacity: 1,
    })

    // //%%%%%%%%%%%
    const capatlize_name: string = capitalize(createString(ridersTeamMember.teamMemberName), {
      capitalizeAll: true,
    })
    auditTrailPage.drawText(capatlize_name, { x: 170, y: 550, color: cmyk(0, 0, 0, 0.9), size: 10 })
    auditTrailPage.drawText(`Security Level: Email`, {
      x: 170,
      y: 535,
      color: cmyk(0, 0, 0, 0.9),
      size: 10,
    })

    let signatureBorder = [
      { SX: 330, SY: 560, EX: 460, EY: 560 }, // Top Border
      { SX: 330, SY: 560, EX: 330, EY: 490 }, // Left Border
      { SX: 460, SY: 560, EX: 460, EY: 490 }, // Right Border
      { SX: 330, SY: 490, EX: 460, EY: 490 }, // Bottom Border
    ]

    signatureBorder.forEach((item: TCertificate) => {
      auditTrailPage.drawLine({
        start: { x: item.SX, y: item.SY },
        end: { x: item.EX, y: item.EY },
        thickness: 1,
        color: cmyk(0, 0, 0, 0.5),
        opacity: 0.8,
      })
    })

    auditTrailPage.drawText(`Digital Signature:`, {
      x: 340,
      y: 540,
      color: cmyk(0, 0, 0, 0.9),
      font: boldFont,
      size: 11,
    })
    auditTrailPage.drawText(`${ridersTeamMember.teamMemberName}`, {
      x: 340,
      y: 510,
      color: cmyk(0, 0, 0, 0.9),
      font: signatureFont,
      size: 28,
    })

    if (AllLogs.logs && AllLogs.logs.length) {
      auditTrailPage.drawText(`Timestamp`, {
        x: 110,
        y: 440,
        color: cmyk(0, 0, 0, 0.9),
        font: boldFont,
        size: 15,
      })
      auditTrailPage.drawText(`Audit Trails`, {
        x: 270,
        y: 440,
        color: cmyk(0, 0, 0, 0.9),
        font: boldFont,
        size: 15,
      })
      let logY = 420
      AllLogs.logs.forEach((log: any) => {
        let parsedDate = Date.parse(getConvertedData({ time: log.time }).time)

        let firstLineText = ''
        let secondLineText = ''
        let thirdLineText = ''

        if (log.audit.length > 40 && log.audit.length < 80) {
          firstLineText = log.audit.slice(0, 40)
          secondLineText = log.audit.slice(41, 80)
        } else if (log.audit.length > 40 && log.audit.length < 120) {
          firstLineText = log.audit.slice(0, 40)
          secondLineText = log.audit.slice(41, 80)
          thirdLineText = log.audit.slice(81, 120)
        } else if (log.audit.length < 40) {
          firstLineText = log.audit
        }

        auditTrailPage.drawText(`${moment(parsedDate).format(`MMMM DD, YYYY h:mm:ss a`)}`, {
          x: 110,
          y: logY,
          color: cmyk(0, 0, 0, 0.9),
          size: 8,
        })

        if (firstLineText && firstLineText !== '') {
          auditTrailPage.drawText(`${firstLineText}`, {
            x: 270,
            y: logY,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        if (secondLineText && secondLineText !== '') {
          auditTrailPage.drawText(`${secondLineText}`, {
            x: 270,
            y: logY - 10,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        if (thirdLineText && thirdLineText !== '') {
          auditTrailPage.drawText(`${thirdLineText}`, {
            x: 270,
            y: logY - 20,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        logY -= 40
        if (logY < 140) {
          logY = 620
          // const auditTrailPage2 = await pdfService.addNewPdfPage();
          auditTrailPage.drawText('Pegasus App Inc.', {
            x: 45,
            y: 720,
            font: poppinsFont,
            size: 14,
          })
          auditTrailPage.drawLine({
            start: { x: 45, y: 715 },
            end: { x: 480, y: 715 },
            thickness: 1,
            color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
            opacity: 1,
          })

          auditTrailPage.drawImage(jpgImage, {
            x: 480,
            y: 695,
            width: 40,
            height: 40,
            opacity: 1,
          })

          let certificateBorder = [
            { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
            { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
            { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
            { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
          ]

          certificateBorder.forEach((item: TCertificate) => {
            auditTrailPage.drawLine({
              start: { x: item.SX, y: item.SY },
              end: { x: item.EX, y: item.EY },
              thickness: 2,
              color: cmyk(0, 0, 0, 0.5),
              opacity: 0.8,
            })
          })
        }
      })
    }

    auditTrailPage.drawLine({
      start: { x: 70, y: 140 },
      end: { x: 470, y: 140 },
      thickness: 2,
      color: cmyk(0, 0, 0, 0.5),
      opacity: 0.8,
    })

    auditTrailPage.drawText(`This audit trail report provides a detailed record of the`, {
      x: 160,
      y: 110,
      font: boldFont,
      size: 10,
    })
    auditTrailPage.drawText(`online activity and events recorded for this contact.`, {
      x: 170,
      y: 90,
      font: boldFont,
      size: 10,
    })

    auditTrailPage.drawText(`https://thepegasus.app/`, {
      x: 70,
      y: 50,
      color: cmyk(0, 0, 0, 0.5),
      font: boldFont,
      size: 8,
    })
  }

  const pdfBytes = await pdfService.generatedPdf

  // Step 1: Convert Uint8Array to Blob
  var blob = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })

  // const blobUrl = URL.createObjectURL(blob);
  // return
  // Create a URL for the Blob
  // window.open(blobUrl, "_blank");
  // URL.revokeObjectURL(blobUrl);

  // Open the Blob URL in a new tab
  // Step 2: Convert Blob to File
  var file = new File([blob], `${paperwork.document_name}.pdf`, { type: 'application/pdf' })

  const fileUrl = await FirebaseStorageService.uploadFile(
    file,
    `/user/documents/${ridersTeamMember.teamMemberRole}${time}/${ridersTeamMember.registrationDocId}/${ridersTeamMember.teamMemberId}/${eventId}/${paperwork.document_name}/${ridersTeamMember.riderId}-${index}`
  )

  const USER_DOCUMENT = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS_DOCUMENTS
  const queries = [
    where(USER_DOCUMENT.FIELDS.SIGNATORY_ID.KEY, '==', ridersTeamMember.teamMemberId),
    where(USER_DOCUMENT.FIELDS.EVENT_ID.KEY, '==', eventId),
    where(USER_DOCUMENT.FIELDS.RIDER_ID.KEY, '==', ridersTeamMember.riderId),
    where(USER_DOCUMENT.FIELDS.DOCUMENT_NAME.KEY, '==', paperwork.document_name),
    where(USER_DOCUMENT.FIELDS.SIGNATORY_DEFAULT_ROLE.KEY, '==', ridersTeamMember.teamMemberRole),
    where(USER_DOCUMENT.FIELDS.REGISTRATION_BY_DAY_DOC_ID.KEY, '==', registrationByDay?.id),
  ]

  try {
    const eventsSnapshot = await FirestoreService.filterItems(USER_DOCUMENT.NAME, queries)

    let obj: IUserDocument = {
      eventId,
      u: false,
      status: isSigned ? 'Signed' : 'Not Signed',
      documentName: paperwork.key,
      riderId: ridersTeamMember.riderId,
      activityUser: getUserFullName(userData),
      riderName: isStaff ? 'N/A' : ridersTeamMember.riderName,
      signatoryId: ridersTeamMember.teamMemberId,
      eventLogo: EventDetails.eventLogo,
      documentOwner: ridersTeamMember.teamMemberId,
      signatoryName: ridersTeamMember.teamMemberName,
      documentUrl: (fileUrl as string) ?? '',
      eventName: EventDetails.competitionName,
      documentOriginalName: paperwork.document_name,
      signatoryProfilePicture: ridersTeamMember.teamMemberProfilePicture,
      documentNameAsPerPdf: `GENERAL LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`,
      competitorId: userData.id,
      pageNumberToSignOn,
      coordinatesToSignOn,
      signatoryDefaultRole: ridersTeamMember.teamMemberRole,
      signatoryEmail: ridersTeamMember.teamMemberEmail,
      reminder: false,
      registrationDocId: ridersTeamMember.registrationDocId,
      riderTeamMemberDocId: ridersTeamMember.id ?? '',
      registrationByDayDocId: registrationByDay?.id ?? '',
      registrationByDayUuid: registrationByDay?.uuid ?? '',
    }

    if (eventsSnapshot.size) {
      let docId = ''
      eventsSnapshot.forEach((doc) => {
        docId = UserDocumentModel.fromFirestoreDoc(doc).toObject().id
      })

      await FirestoreService.updateItem(USER_DOCUMENT.NAME, customDocId || docId, obj)

      await FirestoreService.createItemWithCustomId(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        customDocId || docId,
        {
          id: customDocId || docId,
          riderId: ridersTeamMember.riderId,
          eventId,
          signatoryId: ridersTeamMember.teamMemberId,
          logs: logs || [],
        }
      )
      retunedData = {
        docId: customDocId || docId,
      }
    } else {
      const document = await FirestoreService.createItem(USER_DOCUMENT.NAME, obj)

      retunedData = {
        docId: customDocId || document.id,
      }
      await FirestoreService.createItemWithCustomId(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        document.id,
        {
          id: document.id,
          riderId: ridersTeamMember.riderId,
          eventId,
          signatoryId: ridersTeamMember.teamMemberId,
          logs: [
            {
              time: new Date(),
              audit: `GENERAL LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'} by ${organizerDetails?.userLegalPolicyName} - ${organizerDetails?.userEmail}`,
            },
          ],
        }
      )
    }
  } catch (err: any) {
    helpers.logger({
      message: CustomError.somethingWentWrong({
        moduleName: 'GeneralLiabilityWaiverPDFTeamplate',
        fileName: 'GeneralLiabilityWaiverPDFTeamplate',
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        devMessage: err?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      }),
    })
  }

  if (fileUrl && fileUrl !== '' && eventId) {
    await sendEmailForSign({
      ridersTeamMember,
      paperwork,
      paperworkUrl: fileUrl as string,
      eventId,
      isMature,
      isStaff,
      eventDraftId,
      senderData: {
        senderName: `${userData.userFirstName} ${userData.userLastName}`,
        senderEmail: userData.userEmail || '',
      },
    })
  }

  return retunedData
}
