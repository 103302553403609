type ICompetitor = 'competitor'

type IOrganizer = 'organizer'

type IVendor = 'vendor'

type IStaff = 'staff'

type IGuest = null

export type IUserMode = ICompetitor | IOrganizer | IGuest | IVendor

export const USE_MODE_CONST = {
  COMPETITOR: 'competitor' as ICompetitor,
  ORGANIZER: 'organizer' as IOrganizer,
  STAFF: 'staff' as IStaff,
  GUEST: null as IGuest,
}
