// Third party
import { useEffect, useState } from 'react'
import { where } from 'firebase/firestore'
import { groupBy } from 'lodash'

import ViewsLoader from '../../../components/loader/ViewsLoader'
import { CONST } from '../../../const/const'
import { getConvertedData } from '../../../models/interface.helper'
import FirestoreService from '../../../services/firestoreService'
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'
import { UserDocumentModel } from '../../../models/user-documents/user-documents.model'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { RiderTeamMemberModel } from '../../../models/rider-team-member/riderTeamMember.model'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  selectPaperworkRiderTeamMembers,
  selectRiderTeamMembersUpdates,
  setPaperworkRiderTeamMembers,
} from '../../../store/paperworks/paperworkSlice'
import { HorseAccordionWrapper } from './HorseAccordionWrapper'
import { RegistrationByDayModel } from '../../../models/registrations-by-day/registrationByDay.model'
import { IUserDocument } from '../../../models/user-documents/user-documents.interface'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IExhibitorProfileTeamTabProps = {
  exhibitorAllData?: TEventRegisteredUsers | null
  isRecipient: boolean
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  registrationsByDay: IRegistrationByDayInterface[]
}

const ExhibitorProfilePaperworkTab = ({
  exhibitorAllData,
  handleModal,
  registrationsByDay,
}: IExhibitorProfileTeamTabProps) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(true)

  const riderTeamMembers = useAppSelector(selectPaperworkRiderTeamMembers)
  const riderTeamMembersUpdates = useAppSelector(selectRiderTeamMembersUpdates)

  const getMembers = async () => {
    const riderTeamMembers_: IRiderTeamMemberInterface[] = []
    const riderTeamMembersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
      [
        where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.EVENT_ID.KEY, '==', exhibitorAllData?.eventId),
        where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.USER_ID.KEY, '==', exhibitorAllData?.userId),
        where(
          COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.REGISTRATION_DOC_ID.KEY,
          '==',
          exhibitorAllData?.id
        ),
      ]
    )

    if (riderTeamMembersSnaps.size) {
      riderTeamMembersSnaps.forEach((currDoc) => {
        const riderTeamMember = getConvertedData(
          RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject()
        )

        riderTeamMembers_.push(riderTeamMember)
      })
    }

    return riderTeamMembers_
  }

  useEffect(() => {}, [riderTeamMembersUpdates])

  const setPaperwork = async (eventId: string) => {
    setLoading(true)

    const membersData = await getMembers()
    const documents = await getDocumentLink(eventId)

    const newMembersData: (IRiderTeamMemberInterface & {
      documents: IUserDocument[]
      horseId: string
    })[] = []

    membersData.forEach((member) => {
      let riderRegistration: IRegistrationByDayInterface = new RegistrationByDayModel()
      const currentDocuments: IUserDocument[] = []

      registrationsByDay.forEach((registrationByDay_) => {
        if (registrationByDay_.id === member?.registrationByDayDocId) {
          riderRegistration = registrationByDay_
        }
      })

      documents.forEach((document) => {
        if (
          document.registrationByDayDocId === member.registrationByDayDocId &&
          document.signatoryId === member.teamMemberId &&
          document.signatoryDefaultRole === member.teamMemberRole
        ) {
          currentDocuments.push(document)
        }
      })

      const isExist = newMembersData.find(
        (newMember) =>
          newMember.registrationByDayDocId === member.registrationByDayDocId &&
          newMember.teamMemberId === member.teamMemberId &&
          newMember.teamMemberRole === member.teamMemberRole
      )
      if (!isExist)
        newMembersData.push({
          ...member,
          documents: currentDocuments,
          horseId: riderRegistration.horseId ?? '',
        })
    })

    const membersDataGrouped = groupBy(newMembersData, 'registrationByDayDocId')

    const finalResult = Object.values(membersDataGrouped)

    dispatch(setPaperworkRiderTeamMembers(finalResult))

    setLoading(false)
  }

  useEffect(() => {
    if (exhibitorAllData?.eventId) {
      setPaperwork(exhibitorAllData.eventId).then()
    }
    return () => {
      dispatch(setPaperworkRiderTeamMembers([]))
    }
  }, [exhibitorAllData?.eventId, riderTeamMembersUpdates])

  const getDocumentLink = async (eventId: string) => {
    let document_db = await FirestoreService.filterItems(COLLECTIONS.USERS_DOCUMENTS.NAME, [
      where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', eventId),
    ])

    return document_db.docs.map((currDoc) =>
      getConvertedData(UserDocumentModel.fromFirestoreDoc(currDoc).toObject())
    )
  }

  return (
    <>
      {loading ? (
        <div className="min-h-[100px] flex items-center justify-center">
          <ViewsLoader size="xl" color="red" />
        </div>
      ) : riderTeamMembers.length > 0 ? (
        riderTeamMembers.map((team, index) => {
          let registrationByDay: IRegistrationByDayInterface = new RegistrationByDayModel()

          registrationsByDay.forEach((registrationByDay_) => {
            if (registrationByDay_.id === team[0]?.registrationByDayDocId) {
              registrationByDay = registrationByDay_
            }
          })

          return (
            <HorseAccordionWrapper
              key={index}
              accordionIndex={index}
              team={team}
              addSignerHandler={() => {
                handleModal(true, MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK, {
                  teamMembers: team,
                  registrationByDay: registrationByDay,
                })
              }}
              registrationByDay={registrationByDay}
              eventId={exhibitorAllData?.eventId ?? null}
            />
          )
        })
      ) : (
        <div className="w-full flex justify-center items-center">No data found</div>
      )}
    </>
  )
}

export default ExhibitorProfilePaperworkTab
