/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material'
import { where } from 'firebase/firestore'
import { cloneDeep } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useIonRouter } from '@ionic/react'
import { useHistory } from 'react-router-dom'

import helpers from '../../../commonHelpers/helpers'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import TooltipIcon from '../../../helpers/TooltipIcon'
import TimeLib from '../../../lib/Time'
import { IAssignedTicket } from '../../../models/assigned-tickets/assigned-tickets.interface'
import { AssignTicketsModal } from '../../../models/assigned-tickets/assigned-tickets.model'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { TicketType } from '../../../models/event-ticketing/event-ticketing.interface'
import { IEventInterface } from '../../../models/events/event.interface'
import { EventModel } from '../../../models/events/event.model'
import { getConvertedData } from '../../../models/interface.helper'
import { SpectatorTicketModel } from '../../../models/spectator-tickets/spectator-tickets-model'
import { ISpectatorTickets } from '../../../models/spectator-tickets/spectator-tickets.interface'
import { TicketBuyersModel } from '../../../models/ticket-buyers/ticket-buyers.model'
import FirestoreService from '../../../services/firestoreService'
import { httpService } from '../../../services/httpService'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  selectAssignedPayTabTickets,
  selectSpectatorTickets,
  setPaymentDetails,
  setSpectatorAssignTickets,
} from '../../../store/tickets/ticketslice'
import { getGuestData } from '../../../store/user/userSlice'
import { handleTicketTemplate } from '../../myEvent/component/SendTicketComponent'
import SpectatorTickesPurchaseWrapper from '../SpectatorTickesPurchaseWrapper'
import useGetEventData from '../../../hooks/users/common/useGetEventData'
import {
  selectedEvent as selectedEventGetter,
  selectEventPaymentSettings,
} from '../../../store/events/eventsSlice'

type Props = {
  EventDetails: IEventDetails
  EventTickets: TicketType[]
  saveRef: any
  nextRef: any
  onSetEventTab: any
  handleModal: any
  setLoading: any
}

type IsenderData = {
  assignerName: string | null
  assignerEmail: string | null
  ticket_status: string | null
  eventId: string | null
  sendTicket_id: string | null
  ticketTemplateUrl: string | null
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const table_headings = [
  {
    col_id: 1,
    name: 'Ticket item',
    tooltip: '',
    styleclass: 'w-[25%] min-w-[200px] makegloballabelcolor',
  },
  {
    col_id: 2,
    name: 'Name on ticket',
    tooltip: '',
    styleclass: 'w-[37.5%] min-w-[270px] makegloballabelcolor',
  },
  {
    col_id: 3,
    name: 'Email address',
    tooltip: '',
    styleclass: 'w-[37.5%] min-w-[270px] makegloballabelcolor',
  },
  {
    col_id: 4,
    name: 'Price',
    tooltip: '',
    styleclass: 'w-[15%] min-w-[150px] makegloballabelcolor',
  },
]

const total_arr = [
  {
    tab: 1,
    title: 'Total',
    value: '2,860',
    additional_class: '',
  },
  {
    tab: 2,
    title: 'Sales tax',
    value: '2,860',
    additional_class: '',
  },
  {
    tab: 3,
    title: 'Grand total',
    value: '2,860',
    additional_class: 'font-bold',
  },
]

// const EVENT_TICKETS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS;

const SpeactatorTicketsPuchasePayTab = (props: Props) => {
  const { saveRef, nextRef } = props
  const { id } = useParams<{ id: string }>()

  const router = useIonRouter()
  const history = useHistory()

  const dispatch = useAppDispatch()
  const { getAllData } = useGetEventData()
  const toastFunctions = useToasterHelper()
  const spectatorTickets = useAppSelector(selectSpectatorTickets)
  const eventPaymentSettings = useAppSelector(selectEventPaymentSettings)
  const payTabTickets = useAppSelector(selectAssignedPayTabTickets)
  const { spectator_ticket } = useAppSelector((state) => state.tickets)
  const selectedEvent = useAppSelector(selectedEventGetter)

  let assignedTickets = payTabTickets

  const [loading, setLoading] = useState(true)

  const [total_price, set_total_price] = useState(0)
  const [sales_tax, set_sales_tax] = useState(0)
  const [payment_price, set_payment_price] = useState(0)

  const guestData = useAppSelector(getGuestData)

  const generateTickets = useCallback(
    ({ assignedTickets }: { assignedTickets: IAssignedTicket[] }) => {
      let assignedTicketsToBuy: IAssignedTicket[] = []

      assignedTickets.forEach((currAssignedTicket) => {
        assignedTicketsToBuy.push(getConvertedData(currAssignedTicket))
      })

      dispatch(setSpectatorAssignTickets(assignedTicketsToBuy))
    },
    [dispatch]
  )

  const fetchTickets = useCallback(
    async (spectatorTickets: ISpectatorTickets[]) => {
      setLoading(true)
      const assignedTickets: IAssignedTicket[] = []

      try {
        await getAllData(id, ['v01_event_payments_settings'])

        await helpers.asyncWhileLoop({
          loopCount: spectatorTickets.length,
          functionToFire: async (index) => {
            const assignedTicketSnapshots = await FirestoreService.filterItems(
              COLLECTIONS.ASSIGNED_TICKETS.NAME,
              [
                where(
                  COLLECTIONS.ASSIGNED_TICKETS.FIELDS.SEPECTATOR_TICKET_DOC_ID,
                  '==',
                  spectatorTickets[index].id!
                ),
                where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.PAYMENT_STATUS, '==', 'pending'),
              ]
            )

            assignedTicketSnapshots.forEach((currAssignedTicketSnapshot) => {
              assignedTickets.push(
                getConvertedData(
                  AssignTicketsModal.fromFirestoreDoc(currAssignedTicketSnapshot).toObject()
                )
              )
            })
          },
        })
      } catch (error) {
        console.error(error)
      } finally {
        generateTickets({
          assignedTickets,
        })
        setLoading(false)
      }
    },
    [generateTickets]
  )

  useEffect(() => {
    if (spectatorTickets.length) fetchTickets(spectatorTickets)
    else {
      setLoading(false)
    }
  }, [fetchTickets, spectatorTickets])

  useEffect(() => {
    let cost = 0
    let salesTax

    assignedTickets.forEach((data) => {
      cost += data.ticketPrice
    })

    salesTax = cost * (selectedEvent?.EventPaymentSettings?.chargeSalesTax ? 0.02 : 0)
    set_total_price(cost)
    set_sales_tax(salesTax)
    set_payment_price(cost + salesTax)
  }, [assignedTickets, eventPaymentSettings?.paymentOption])

  let assigned_user = false

  const handleSave = () => {}

  const handleNext = () => {}

  useEffect(() => {
    saveRef.current = handleSave
    nextRef.current = handleNext
  }, [])

  const handlePayment = () => {
    if (!assignedTickets.length) {
      toastFunctions.info({
        message: MESSAGES_CONST.PLEASE_ADD_UNITS,
      })
      return
    }

    dispatch(
      setPaymentDetails({
        payment_detail: {
          price: payment_price,
          type: 'spectator',
        },
      })
    )

    props.handleModal(true, MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD, {
      type: 'ticketPurchase',
      eventId: id,
    })
  }

  const handlePayment_function = async () => {
    let toastId = toastFunctions.loading({
      message: MESSAGES_CONST.PLEASE_WAIT,
    })

    let ticketsSent = false
    let payment_price_ = payment_price
    let assignedTickets_ = cloneDeep([...assignedTickets])
    let payment_status_ = cloneDeep(spectator_ticket.payment_status)
    let guestData_ = cloneDeep(guestData)
    let multiplesenders: IsenderData[] = []
    let ticketBuyerDocId: string = null as any
    let event: IEventInterface | null = null
    let currspectator: ISpectatorTickets | null = null

    try {
      let ticketBoughtCount = 0
      let ticketBuyer: TicketBuyersModel
      let uniqueSpectatorIds: string[] = []
      let assignedTicket: IAssignedTicket | null = null
      let spectatorWiseSharedTicketCount = new Map<string, number>()

      ticketBoughtCount = assignedTickets_.length

      await helpers.asyncWhileLoop({
        loopCount: assignedTickets_.length,
        functionToFire: async (index) => {
          if (!assignedTickets_[index].id) {
            assignedTickets_[index].id = (
              await FirestoreService.createItem(
                COLLECTIONS.ASSIGNED_TICKETS.NAME,
                new AssignTicketsModal(assignedTickets_[index]).toFirestore()
              )
            ).id
          }
        },
      })

      ticketBuyer = new TicketBuyersModel({
        amountPaid: payment_price_,
        eventId: id,
        amountRefunded: 0,
        type: 'spectator',
        refundStatus: null,
        paymentStatus: 'paid',
        usedTicketsCount: 0,
        recipientDocId: null,
        registrationDocId: null,
        ticketBuyerNameNGrams: [],
        deletedSpectatorTicketsIds: [],
        ticketBoughtCount: ticketBoughtCount,
        ticketBuyerName: guestData_.fullName,
        unusedTicketCount: ticketBoughtCount,
        spectatorTicketsIds: uniqueSpectatorIds,
        ticketBuyerEmailId: guestData_.userEmail,
        isPlatformUser: guestData_.isPlatformUser,
        ticketBuyerProfilePicture: guestData_.userProfilePic,
        receipetUrl: payment_status_?.data?.receipt_url ?? null,
        userId: (guestData_.isPlatformUser ? guestData_.userId : guestData_.id) ?? null,
        purchasedOn: TimeLib.utcTimestamp(),
      })

      ticketBuyerDocId = (
        await FirestoreService.createItem(COLLECTIONS.TICKET_BUYERS.NAME, ticketBuyer.toFirestore())
      ).id

      ticketBuyer.id = ticketBuyerDocId

      await helpers.asyncWhileLoop({
        loopCount: assignedTickets_.length,
        functionToFire: async (index) => {
          assignedTicket = cloneDeep(assignedTickets_[index])
          assignedTicket.paymentStatus = 'paid'
          assignedTicket.ticketBuyerName = guestData_.fullName
          assignedTicket.ticketBuyerEmailId = guestData_.userEmail
          assignedTicket.chargeId = payment_status_?.data?.id ?? null
          assignedTicket.receipetUrl = payment_status_?.data?.receipt_url ?? null
          assignedTicket.ticketBuyerDocId = ticketBuyerDocId
          assignedTicket.assignmentDate = new Date()

          if (assignedTicket.eventDocId) {
            const eventSnapshot = await FirestoreService.getItem(
              CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
              assignedTicket.eventDocId
            )

            if (eventSnapshot.exists()) {
              event = EventModel.fromFirestoreDoc(eventSnapshot).toObject()
            }
          }

          if (assignedTicket.spectatorTicketDocId) {
            uniqueSpectatorIds.push(assignedTicket.spectatorTicketDocId)
          }

          if (assignedTicket.ticketHolderEmailId && assignedTicket.ticketHolderName) {
            assignedTicket.ticketStatus = 'assigned'

            const ticketTemplate: any = await handleTicketTemplate(
              assignedTicket.ticketHolderName,
              assignedTicket.ticketHolderEmailId,
              getConvertedData({
                eventName: event?.eventName ?? 'N/A',
                ticketPrice: assignedTicket.ticketPrice,
                created: event?.eventStartDate ?? 'N/A',
                ticketTitle: assignedTicket?.ticketTitle,
                eventId: assignedTicket.eventDocId,
              }),
              assignedTicket
            )

            if (assignedTicket.spectatorTicketDocId) {
              spectatorWiseSharedTicketCount.set(
                assignedTicket.spectatorTicketDocId,
                (spectatorWiseSharedTicketCount.get(assignedTicket.spectatorTicketDocId) ?? 0) + 1
              )
            }

            multiplesenders.push({
              assignerName: assignedTicket.ticketHolderName,
              assignerEmail: assignedTicket.ticketHolderEmailId,
              ticket_status: 'assigned',
              eventId: assignedTicket.eventDocId,
              sendTicket_id: assignedTicket.id ?? null,
              ticketTemplateUrl: ticketTemplate ?? null,
            })
          }

          await FirestoreService.updateItem(
            COLLECTIONS.ASSIGNED_TICKETS.NAME,
            assignedTicket.id!,
            new AssignTicketsModal(assignedTicket).toFirestore()
          )
        },
      })

      uniqueSpectatorIds = [...new Set(uniqueSpectatorIds)]

      await helpers.asyncWhileLoop({
        loopCount: uniqueSpectatorIds.length,
        functionToFire: async (index) => {
          const boughtTicketCount = await FirestoreService.getDocumentsCount({
            collectionName: COLLECTIONS.ASSIGNED_TICKETS.NAME,
            queries: [
              where(
                COLLECTIONS.ASSIGNED_TICKETS.FIELDS.SEPECTATOR_TICKET_DOC_ID,
                '==',
                uniqueSpectatorIds[index]
              ),
            ],
          })

          const currentSpecsnaps = await FirestoreService.getItem(
            COLLECTIONS.SPECTATOR_TICKETS.NAME,
            uniqueSpectatorIds[index]
          )

          if (currentSpecsnaps.exists()) {
            currspectator = SpectatorTicketModel.fromFirestoreDoc(currentSpecsnaps).toObject()
            currspectator = new SpectatorTicketModel({
              ...currspectator,
              ticketBuyerDocId: ticketBuyerDocId,
              boughtTicketCount: boughtTicketCount,
              paymentStatus: 'paid',
              purchasedOn: TimeLib.utcTimestamp(),
              userEmailId: guestData_.userEmail ?? null,
              transactionDocId: payment_status_?.data?.transactionDocId,
              sharedTicketsCount: spectatorWiseSharedTicketCount.get(currspectator.id!) ?? 0,
            }).toFirestore()

            await FirestoreService.updateItem(
              COLLECTIONS.SPECTATOR_TICKETS.NAME,
              uniqueSpectatorIds[index],
              currspectator
            )
          }
        },
      })

      if (multiplesenders.length) {
        toastFunctions.updateLoading({
          loadingToastId: toastId,
          message: MESSAGES_CONST.SEND_TICKET_TO_RECEIVER,
          isError: false,
        })

        const sendTicketsResponse = await httpService({
          data: multiplesenders,
          url: 'sendtickets',
          method: 'post',
        })

        ticketsSent = sendTicketsResponse.status
      }

      await FirestoreService.updateItem(COLLECTIONS.TICKET_BUYERS.NAME, ticketBuyerDocId, {
        ...(ticketsSent && {
          usedTicketsCount: multiplesenders.length,
          unusedTicketCount: ticketBuyer.unusedTicketCount - multiplesenders.length,
        }),
        spectatorTicketsIds: uniqueSpectatorIds,
        modified: TimeLib.utcTimestamp(),
      })

      await httpService({
        data: {
          ticketBuyerDocId: ticketBuyer.id,
        },
        url: 'on_ticket_buyer_doc_create',
        method: 'post',
      })
    } catch (error) {
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      toastFunctions.stopLoading({
        loadingToastId: toastId,
        isError: multiplesenders.length ? !ticketsSent : false,
        message: multiplesenders.length
          ? ticketsSent
            ? MESSAGES_CONST.TICKETS_SENT
            : MESSAGES_CONST.SOMETHING_WENT_WRONG
          : 'Done',
      })
    }

    router.push(`${CONST.ROUTES.EVENT_DETAILS.URL}/${id}`)
    history.push(`${CONST.ROUTES.EVENT_DETAILS.URL}/${id}`)
  }

  useEffect(() => {
    if (spectator_ticket.payment_status.status) {
      handlePayment_function().then()
    }
  }, [spectator_ticket.payment_status])

  return (
    <SpectatorTickesPurchaseWrapper
      title="Pay"
      description="Review and pay"
      over_flow={false}
      height_full={true}
    >
      <div className="w-full h-auto">
        <div className="w-full flex sticky top-[-19px]">
          {table_headings.map((item, index) => {
            // @ts-ignore
            if (item?.col_id === 5 && assigned_user === true) return null

            return (
              <div
                key={`${JSON.stringify(item)}${index}`}
                className={`${item?.styleclass} mx-1 flex items-center bg-[#fff]`}
              >
                <span className="text-[12px] font-semibold makegloballabelcolor mr-1">
                  {item?.name}
                </span>
                <Tooltip
                  title={<h1 className="tooltip_title">{item?.name}</h1>}
                  placement="top"
                  arrow
                >
                  <button>
                    <TooltipIcon color="#3C4B71" />
                  </button>
                </Tooltip>
              </div>
            )
          })}
        </div>
        <div className="w-full">
          {loading ? (
            <div className="flex justify-center pt-[40px]">
              <ViewsLoader color="#ff2d55" size="lg" />
            </div>
          ) : (
            <>
              {assignedTickets.length ? (
                <>
                  {assignedTickets.map((currAssignedTicket, index) => {
                    return (
                      <div key={`displayData${index}`} className={`w-full flex`}>
                        <div
                          className={`w-[25%] text-[#122B46] min-w-[200px] mb-2 mx-1 text-nr text-[#122B46]-900 border-[#D3DAEE] rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 bg-[#F6F7FB]`}
                        >
                          {currAssignedTicket?.ticketTitle ?? 'N/A'}
                        </div>

                        <div className="w-[37%] min-w-[270px] mx-1">
                          <input
                            className={`w-full mb-2  text-nr !text-[#122B46] outline-0 rounded-md !ring-0  p-4 disabled:bg-[#F6F7FB] text-start capitalize`}
                            disabled={true}
                            placeholder="Enter name..."
                            value={currAssignedTicket.ticketHolderName ?? 'N/A'}
                          />
                        </div>

                        <div className="w-[37%] min-w-[270px] mx-1">
                          <input
                            className={`w-full mb-2  text-nr !text-[#122B46] outline-0 rounded-md !ring-0  p-4 disabled:bg-[#F6F7FB] text-start border-[#D3DAEE] focus:border-[#122B46]`}
                            value={currAssignedTicket.ticketHolderEmailId ?? 'N/A'}
                            disabled={true}
                            placeholder="Enter email address..."
                          />
                        </div>

                        <div
                          className={`shrink-0 text-[#122B46] w-[160px] mb-2 mx-1 text-nr text-[#122B46]-900  border-[#D3DAEE] rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 
                   text-center bg-[#F6F7FB]`}
                        >
                          {currAssignedTicket?.ticketPrice}
                        </div>
                      </div>
                    )
                  })}

                  {total_arr &&
                    total_arr.map((item, totalItemItem) => {
                      return (
                        <div className="flex" key={`totalItem${totalItemItem}`}>
                          <div
                            className={`grow text-[#122B46] min-w-[150px] mb-2 mx-1 text-nr text-[#122B46]-900   rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 
                border-2-[#00B6AA] bg-[#00b6aa1a] 
                       ${item?.additional_class}       `}
                          >
                            {item?.title}
                          </div>

                          <div
                            className={`shrink-0 text-[#122B46] w-[160px] mb-2 mx-1 text-nr text-[#122B46]-900 rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 text-center 
                border-2-[#00B6AA] bg-[#00b6aa1a]
                            ${item?.additional_class}  `}
                          >
                            {item.tab === 1
                              ? `$${total_price.toFixed(2)}`
                              : item.tab === 2
                                ? `$${sales_tax.toFixed(2)}`
                                : `$${payment_price.toFixed(2)}`}
                          </div>
                        </div>
                      )
                    })}

                  <div>
                    <button
                      onClick={handlePayment}
                      disabled={spectator_ticket.payment_status.show_payment_done}
                      className="w-full h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:bg-[#d70443] focus:outline-none !ring-0 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor mt-2"
                    >
                      {spectator_ticket.payment_status.show_payment_done ? 'Paid' : 'Pay'}
                    </button>

                    <button
                      onClick={() => {
                        props.onSetEventTab(CONST.UI.SPECTATOR_TABS.TABS.EMAIL)
                      }}
                      className="w-full h-12 mx-auto flex items-center justify-center py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:shadow-lg focus:outline-none !ring-0 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor mt-2"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <div className="text-[14px] text-SeabiscuitLightParagraphTextColor mt-2">
                  You dont have any tickets whose amount is not paid
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </SpectatorTickesPurchaseWrapper>
  )
}

export default SpeactatorTicketsPuchasePayTab
