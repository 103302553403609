import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useAppSelector } from '../../../../store/hooks'
import { selectedEvent, selectPublishedEvent } from '../../../../store/events/eventsSlice'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import { RequiredFieldsHeader } from '../../../../pages/shows/detail/components/RequiredFieldsHeader'
import { IconUser } from '../../../icons/IconUser'
import { REQUIERED_FILEDS } from '../../../../const/requeredFields'
import { RequiredFieldRow } from '../../../../pages/shows/detail/components/RequiredFieldRow'
import { IconHorseImg } from '../../../icons/IconHorseImg'
import useGetEventData from '../../../../hooks/users/common/useGetEventData'

interface IProps {
  data: any
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  nextEventTab?: string
  eventTab?: string
}

export const EventRequiredFieldFormComponent: React.FC<IProps> = (props) => {
  const { eventId } = useParams<{ eventId: string }>()
  const { getAllData } = useGetEventData()

  const publishedEvent = useAppSelector(selectPublishedEvent)
  const event = useAppSelector(selectedEvent)
  const [userReqFields, setUserReqFields] = React.useState<string[]>([])
  const [horseReqFields, setHorseReqFields] = React.useState<string[]>([])

  React.useEffect(() => {
    if (event?.Event) {
      if (Array.isArray(event?.Event?.requiredFields))
        setUserReqFields(event?.Event?.requiredFields || [])
      if (Array.isArray(event?.Event?.requiredHorseFields))
        setHorseReqFields(event?.Event?.requiredHorseFields || [])
    }
  }, [event?.Event])

  const clickUserHandler = (value: string) => {
    if (userReqFields.includes(value)) {
      setUserReqFields((v) => v.filter((v1) => v1 !== value))
    } else {
      setUserReqFields((v) => [...v, value])
    }
  }
  const clickHorseHandler = (value: string) => {
    if (horseReqFields.includes(value)) {
      setHorseReqFields((v) => v.filter((v1) => v1 !== value))
    } else {
      setHorseReqFields((v) => [...v, value])
    }
  }

  useEffect(() => {
    if (eventId) {
      getAllData(eventId, ['v01_events']).then()
    }
  }, [eventId])

  return (
    <EventFormContainerComponent>
      <div className="mr-4 mb-4 w-full mx-auto">
        <EventFormHeaderComponent
          title="Required fields"
          description="Select the profile fields that are required to register for this event"
        >
          <EventFormFooterCommonComponent
            publishedEvent={publishedEvent}
            publishButton={false}
            loading={false}
            onSaveAndExit={() => {
              return null
            }}
            onNext={(e: any, publishEvent) => {
              return publishEvent({
                dataToSave: {
                  v: event?.Event?.v || '',
                  id: event?.Event?.id || '',
                  owner: event?.Event?.owner || '',
                  requiredFields: userReqFields,
                  requiredHorseFields: horseReqFields,
                },
                tabName: 'EventRequiredFields',
                validFormHandler: props.onValid,
              })
            }}
          />
        </EventFormHeaderComponent>

        <div className="flex flex-col lg:flex-row flex-1 gap-5">
          <div className={'flex border flex-col rounded-md border-[#D3DAEE] p-4 flex-1 gap-3'}>
            <RequiredFieldsHeader
              title={'People'}
              desc={'Select required fields'}
              count={userReqFields.length}
              icon={<IconUser />}
            />
            <div className={'flex flex-col gap-3 mt-3'}>
              {REQUIERED_FILEDS.people.map((itm) => (
                <RequiredFieldRow
                  onClick={() => clickUserHandler(itm.value)}
                  key={`p-${itm.value}`}
                  title={itm.title}
                  isActive={userReqFields.includes(itm.value)}
                />
              ))}
            </div>

            <div className={'flex flex-col gap-3 mt-3 border-t pt-5'}>
              {REQUIERED_FILEDS.peopleNumbers.map((itm) => (
                <RequiredFieldRow
                  onClick={() => clickUserHandler(itm.value)}
                  key={`p-${itm.value}`}
                  src={itm.img}
                  title={itm.title}
                  isActive={userReqFields.includes(itm.value)}
                />
              ))}
            </div>
          </div>
          <div className={'flex border flex-col rounded-md border-[#D3DAEE] p-4 flex-1 gap-3'}>
            <RequiredFieldsHeader
              title={'Horses'}
              desc={'Select required fields'}
              count={horseReqFields.length}
              icon={<IconHorseImg />}
            />

            <div className={'flex flex-col gap-3 mt-3'}>
              {REQUIERED_FILEDS.horseSecondary.map((itm) => (
                <RequiredFieldRow
                  key={`p-${itm.value}`}
                  onClick={() => clickHorseHandler(itm.value)}
                  src={itm.img}
                  title={itm.title}
                  isActive={horseReqFields.includes(itm.value)}
                />
              ))}
            </div>

            <div className={'flex flex-col gap-3 mt-3 border-t pt-5'}>
              {REQUIERED_FILEDS.horseNumbers.map((itm) => (
                <RequiredFieldRow
                  key={`p-${itm.value}`}
                  onClick={() => clickHorseHandler(itm.value)}
                  src={itm.img}
                  title={itm.title}
                  isActive={horseReqFields.includes(itm.value)}
                />
              ))}
            </div>

            <div className={'flex flex-col gap-3 border-t pt-5 mt-3'}>
              {REQUIERED_FILEDS.horseMain.map((itm) => (
                <RequiredFieldRow
                  onClick={() => clickHorseHandler(itm.value)}
                  key={`p-${itm.value}`}
                  title={itm.title}
                  isActive={horseReqFields.includes(itm.value)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </EventFormContainerComponent>
  )
}
