import React, { useRef, useState } from 'react'
import { IHorseData } from '../../../../models/horse/horse.interface'
import customImageComponent from '../../../common/CustomImageComponent'
import { StepTitle } from './StepTitle'
import { IRider } from '../../../../types/entryRider'
import { IconChevron } from '../../../icons/IconChevron'
import { IconCheck } from '../../../icons/IconCheck'

interface IProps {
  horses: IHorseData[]
  selectedHorse: IHorseData | null
  selectHandler: (itm: IHorseData) => void
  rider: IRider
  selectedRiderMultiHorse: IHorseData | null
  setSelectedRiderMultiHorse: (itm: IHorseData) => void
  isMultiHorseShown: boolean
  setIsMultiHorseShown: (val: boolean) => void
}
const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

export const SelectNewHorse: React.FC<IProps> = ({
  horses,
  selectHandler,
  selectedHorse,
  rider,
  isMultiHorseShown,
  selectedRiderMultiHorse,
  setSelectedRiderMultiHorse,
}) => {
  const searchBoxRef = useRef<HTMLInputElement>(null)
  const [searchValue, setSearchValue] = useState('')
  const [listToShow, setListToShow] = useState<IHorseData[]>([])

  React.useEffect(() => {
    if (selectedHorse) {
      setSearchValue(selectedHorse.horseName || '')
      filterHorses(selectedHorse.horseName || '')
    }
  }, [])

  const handleHorses = (strToSearch: string) => {
    if (strToSearch === '') {
      setListToShow(horses)
    }
    const matched = horses.filter((horse) => {
      return horse?.horseName?.toLowerCase().includes(strToSearch.toLowerCase())
    })

    setListToShow(matched)
  }

  const filterHorses = (strToSearch: string) => {
    setSearchValue(strToSearch)
    handleHorses(strToSearch)
  }
  return (
    <>
      <StepTitle
        title={
          rider.horses.length < 2
            ? 'Select new horse to switch'
            : 'Which horse are you swapping out?'
        }
        desc={''}
      />

      {!isMultiHorseShown ? (
        <>
          <div className="inputSearch flex items-center relative">
            <input
              ref={searchBoxRef}
              type="text"
              onChange={(e) => filterHorses(e.target.value)}
              value={searchValue}
              placeholder="Search horse name"
              className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
            />
          </div>

          <div>
            {searchValue &&
              listToShow[0] &&
              listToShow.map((horse) => {
                const selected = selectedHorse && selectedHorse.id === horse.id
                return (
                  <div
                    onClick={() => selectHandler(horse)}
                    key={horse.id}
                    className="form-check mb-3 cursor-pointer"
                  >
                    <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                      <div className="min-w-fit flex items-center">
                        {customImageComponent(
                          horse.horseProfilePicture,
                          horse.horseName,
                          'w-[40px] h-[40px] rounded-full mr-2 avatarImg object-cover'
                        )}
                        <div className="hourseDetails w-10/12">
                          <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                            <p className="text-SeabiscuitDark200ThemeColor text-lg capitalize">
                              {horse.horseName}
                            </p>
                          </div>
                        </div>
                      </div>

                      {selected ? (
                        <img
                          src={CHECKED_ICON}
                          className="relative right-[0px] top-[0px]"
                          width={20}
                          height={20}
                          alt="checked"
                        />
                      ) : null}

                      {/*<input*/}
                      {/*  onChange={(_e) => {*/}
                      {/*    // console.log(e)*/}
                      {/*    selectHandler(horse);*/}
                      {/*  }}*/}
                      {/*  // checked={!!condition}*/}
                      {/*  // disabled={!!condition}*/}
                      {/*  id={horse.id}*/}
                      {/*  className={`ml-2 form-check-input appearance-none mr-[2px] h-4 w-4 border active:bg-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer right-0 checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor hidden`}*/}
                      {/*  type="checkbox"*/}
                      {/*/>*/}
                    </div>
                  </div>
                )
              })}
          </div>
        </>
      ) : (
        <ul className={'list-none m-0 p-0 flex flex-col gap-3'}>
          {rider.horses.map((horse) => (
            <li
              key={horse.horseId}
              className={'flex items-center gap-2 justify-between text-black cursor-pointer'}
              onClick={() => setSelectedRiderMultiHorse(horse)}
            >
              <div className={'flex items-center gap-2'}>
                <span>
                  {customImageComponent(
                    horse.horseProfilePicture,
                    horse.horseName,
                    'w-[45px] h-[45px] avatarImg rounded-[100%] object-cover'
                  )}
                </span>
                <span>{horse.horseName}</span>
              </div>

              <div
                className={`border-[2px]  border-solid border-[${selectedRiderMultiHorse && selectedRiderMultiHorse.id === horse.id ? '#00b6aa' : '#122B4680'}] rounded-[100%] w-[24px] h-[24px] flex items-center justify-center`}
              >
                {selectedRiderMultiHorse && selectedRiderMultiHorse.id === horse.id && (
                  <IconCheck color={'text-[#00b6aa]'} />
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}
