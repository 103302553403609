import * as pdfjs from 'pdfjs-dist'
import { CONFIG } from '../config/config'
import { MESSAGES_CONST } from '../const/messages-const'
import {CustomError, getUserFullName} from '../helpers/helpers'
import { httpService } from '../services/httpService'
import FirestoreService from '../services/firestoreService'
import { CONST } from '../const/const'
import { UserModel } from '../models/users/user.model'
import { IUserInterface } from '../models/users/user.interface'
import { userSliceIniState } from '../store/storeHelpers/userSlice/data'
import { ModelBaseModel } from '../models/model-base/model-base.model'

type IFileObjToBase64Fn = (file: File) => Promise<string | ArrayBuffer | null>
type splitFileNameAndExtFn = (
  fullFileName: string,
  addDotToExtension: boolean
) => {
  fileName: string
  extension: string
}
type ILogger = (args: ILoggerProps) => void
type ILoggerProps = {
  message: any
  color?: string
  isError?: boolean
  forceShow?: boolean
}

type IAsyncWhileLoopArgs = {
  loopCount: number
  functionToFire: (index: number) => Promise<any>
}

type IAsyncWhileLoopFn = (args: IAsyncWhileLoopArgs) => Promise<void>

// Converts image url to base64
const fileObjToBase64: IFileObjToBase64Fn = async (file) => {
  return await new Promise((resolve) => {
    var reader = new FileReader()
    reader.onloadend = function () {
      resolve(reader.result)
    }
    reader.readAsDataURL(file)
  })
}

/**
 *
 * @param fullFileName Name of the file with or withnot extension
 * @param addDotToExtension If true, returns extension with '.' Dot prefixed
 * @returns Object containing filname and extension
 */
const splitFileNameAndExt: splitFileNameAndExtFn = (fullFileName, addDotToExtension) => {
  const valueToReturn: { fileName: string; extension: string } = {
    fileName: fullFileName,
    extension: '',
  }

  let lastStrSegment: any = valueToReturn.fileName?.split('/')
  lastStrSegment = lastStrSegment?.[lastStrSegment?.length - 1] as any
  const tempFileNameSegments = lastStrSegment?.split('.') ?? []

  valueToReturn.fileName = tempFileNameSegments.slice(0, tempFileNameSegments.length - 1).join('-')
  valueToReturn.extension = tempFileNameSegments[tempFileNameSegments.length - 1] ?? ''

  if (addDotToExtension) valueToReturn.extension = '.' + valueToReturn.extension

  return valueToReturn
}

const AnyArrayBufferToPngArrayBuffer = (arrayBuffer: ArrayBuffer): Promise<ArrayBuffer> => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const blob = new Blob([arrayBuffer])
    const url = URL.createObjectURL(blob)

    const image = new Image()
    image.src = url

    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height

      const context = canvas.getContext('2d')

      if (context) {
        context.drawImage(image, 0, 0)
        canvas.toBlob(
          (pngBlob) => {
            if (pngBlob) {
              const reader = new FileReader()
              reader.onloadend = () => {
                const pngArrayBuffer = reader.result as ArrayBuffer
                resolve(pngArrayBuffer)
              }
              reader.onerror = reject
              reader.readAsArrayBuffer(pngBlob)
            } else {
              reject(new Error('Failed to create PNG blob.'))
            }
          },
          'image/png',
          1
        )
      }
    }

    image.onerror = reject
  })
}

const ArrayBufferToPngBase64 = (arrayBuffer: ArrayBuffer) => {
  return new Promise<any>((resolve) => {
    const blob = new Blob([arrayBuffer])
    const reader = new FileReader()
    reader.onloadend = () => {
      const dataUrl = reader.result
      if (dataUrl) {
        const base64String = (dataUrl as string).split(',')[1]
        resolve(base64String)
      }
    }
    reader.readAsDataURL(blob)
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const isValidJSONFn = (json: any) => {
  try {
    JSON.parse(json)
    return true
  } catch {
    return false
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
export const asyncWhileLoop: IAsyncWhileLoopFn = async (args) => {
  let currLoopIndex = 0

  let { loopCount, functionToFire } = args

  while (currLoopIndex < loopCount) {
    await functionToFire(currLoopIndex)
    currLoopIndex++
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const logger: ILogger = (args) => {
  let {
    message: error,
    isError,
    // forceShow,
    color,
  } = args

  if (isError === undefined) isError = true

  let stackLines: string[] = []
  let lineNumber: string | null = null
  let stackTrace: string | null = null
  let fileAndLine: string | null = null

  if (error instanceof CustomError) {
    let { devMessage, message, fileName, lineNumber, moduleName } = error
    error = `${devMessage ?? message} \nError occured in: File:${fileName}~module:${moduleName} at line-no: ${lineNumber}`
  } else if (typeof error !== 'string') {
    stackTrace = error?.stack

    if (stackTrace && Array.isArray(stackTrace) && stackTrace.length) {
      stackLines = stackTrace.split('\n')
      fileAndLine = stackLines[1].trim().split('at ')?.[1]
      if (stackLines.length >= 2) {
        const errorLine = stackLines[1].trim()
        const match = errorLine.match(/at (.*):(\d+):(\d+)/)
        if (match && match.length >= 2) {
          lineNumber = match[2]
        }
      }
    }

    if (error && (isValidJSONFn(error) || 'message' in error)) {
      error = error?.message ?? JSON.stringify(error)
      if (fileAndLine && lineNumber)
        error += `\nError occured in: ${fileAndLine} at line-no: ${lineNumber}`
    } else error = MESSAGES_CONST.SOMETHING_WENT_WRONG
  }

  color = color ?? (isError ? '#FF0800' : '#318CE7')
  error =
    '%c' +
    `[${CONFIG.APP_NAME}] ${error.substring(0, 1).toUpperCase()}${error.substring(1)}`.toString()

  // if (CONFIG.RUN_MODE !== "production" || forceShow) {
  console.log(error, 'font-weight: bold; color: ' + color + ';')
  // }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const capitalizeFirstLetter = (string: string, lowercaseRemaining?: boolean) => {
  let firstLetter = ''
  let remainingLetters = ''

  if (!!!string || typeof string !== 'string') return string

  firstLetter = string.substring(0, 1).toUpperCase()
  remainingLetters = string.substring(1) ?? ''

  if (lowercaseRemaining) remainingLetters = remainingLetters.toLocaleLowerCase()

  return `${firstLetter}${remainingLetters}`.toString()
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const clearAllArr = (length: number, sIndex: number) => {
  return [...Array(length)].filter((item: any, index: number) => {
    if (index !== sIndex) {
      return true
    } else {
      return false
    }
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const urlToDataUrl = async (url: string) => {
  return await new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => {
        response.blob().then((blob) => {
          let reader = new FileReader()
          reader.onload = (e) => {
            const data = atob((e?.target?.result as any).replace(/.*base64,/, ''))
            resolve(data)
          }
          reader.readAsDataURL(blob)
        })
      })
      .catch(reject)
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
function dataURItoBlob(dataURI: string) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const pdfBlobToImages = async (dataOfBlobType: any) => {
  if (!pdfjs?.getDocument) return null

  let pagesCount = 0
  const imagesList = []
  const canvas = document.createElement('canvas')
  canvas.setAttribute('className', 'canv')

  const pdf = await pdfjs.getDocument({ data: dataOfBlobType }).promise

  for (let i = 1; i <= pdf.numPages; i++) {
    pagesCount++
    const page = await pdf.getPage(i)
    const viewport = page.getViewport({ scale: 1.5 })
    canvas.height = viewport.height
    canvas.width = viewport.width
    const canvasContext = canvas.getContext('2d')
    if (canvasContext !== null)
      await page.render({
        canvasContext,
        viewport,
      }).promise
    let img = canvas.toDataURL('image/png')
    imagesList.push(img)
  }
  return {
    imagesList,
    pagesCount,
  }
}

const calculateStripeTaxAmount = (amount: number) => {
  // Stripe transaction fees
  return new ModelBaseModel().getNum(amount * 0.029 + 0.3)
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const getStripeId = async (userId: string, profileDetails: IUserInterface) => {
  let newUserStripeId: string = ''
  const USER = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS
  let userData: IUserInterface = userSliceIniState['profileDetails']
  const checkstripeId: {
    status: boolean
    stripeId?: string
  } = await (async () => {
    const getStipeId = await FirestoreService.getItem(USER.NAME, userId || '')

    if (getStipeId.exists()) {
      userData = UserModel.fromFirestoreDoc(getStipeId).toObject()

      if (userData.userStripeId && userData.userStripeId !== '') {
        return {
          status: true,
          stripeId: userData.userStripeId,
        }
      } else {
        return {
          status: false,
        }
      }
    } else {
      return {
        status: false,
      }
    }
  })()

  if (checkstripeId.status) {
    newUserStripeId = checkstripeId?.stripeId || ''
  } else {
    const customerData = await httpService({
      url: `get_stripe_customer`,
      method: 'POST',
      data: {
        userEmail: profileDetails.userEmail,
        userName: getUserFullName(profileDetails),
        userPhone: profileDetails.userPhoneNumber,
        userStripeAccountId: profileDetails.userStripeAccountId,
      },
    })

    newUserStripeId = (customerData as any).customer
    userData = {
      ...userData,
      userStripeId: newUserStripeId,
    }

    const updateUserStripeId = new UserModel(userData).toObject()
    await FirestoreService?.updateItem(
      USER.NAME,
      userId,
      new UserModel(updateUserStripeId).toFirestore()
    )
  }

  return newUserStripeId
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const findEmptyVal = (args: Record<any, any>, valuesToIgnore: any[] = []) => {
  let emptyVarName = null
  let emptyVarValue = null

  Object.keys(args).find((currKey) => {
    if (!!!args[currKey] && !valuesToIgnore.includes(args[currKey])) {
      emptyVarName = currKey
      emptyVarValue = args[currKey]
      return true
    }
    return false
  })

  return {
    emptyVarName,
    emptyVarValue,
  }
}

const firebaseFileCheck = async (url: string) => {
  try {
    const validFileUrl = await fetch(url)
    return validFileUrl?.ok
  } catch (error) {
    helpers.logger({
      isError: true,
      message: error,
    })
    return false
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const urlToBlob = async (url: string) => {
  const response = await fetch(url, { mode: 'cors' })
  const blob = await response.blob()
  return blob
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const generateName = (fullName: string | undefined) => {
  return fullName
    ? fullName
        .split(' ')
        .map((name) => name[0])
        .join('')
        .toUpperCase()
    : ''
}

const helpers = {
  logger,
  findEmptyVal,
  asyncWhileLoop,
  generateName,
  capitalizeFirstLetter,
  firebaseFileCheck,
}

export default helpers

export {
  calculateStripeTaxAmount,
  AnyArrayBufferToPngArrayBuffer,
  ArrayBufferToPngBase64,
  urlToDataUrl,
  urlToBlob,
  dataURItoBlob,
  pdfBlobToImages,
  clearAllArr,
  fileObjToBase64,
  splitFileNameAndExt,
  getStripeId,
}
