import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import Select from 'react-select'

import { IHorseCompetitorDocument } from '../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'

import { customStyles } from '../../../components/customStyles/ReactSelectCustomStyle'

const HorseInfluenzaStatus = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

interface InfluenzaCurrentProps {
  control: Control<IHorseCompetitorDocument>
  saved: boolean
}

export const Influenza: FC<InfluenzaCurrentProps> = ({ control, saved }) => {
  return (
    <div className="flex items-center mt-2 w-full mb-8 gap-4">
      <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
        <img src="/assets/og_icons/InsulinPen.svg" className="mr-4 w-6" alt="measurementCard" />
        Influenza current?
      </label>
      <Controller
        name="horseInfluenzaStatus"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            isMulti={false}
            onChange={onChange}
            onBlur={onBlur}
            value={HorseInfluenzaStatus.find((c) => c.value === ((value as any)?.value ?? value))}
            placeholder="Select..."
            options={HorseInfluenzaStatus}
            isDisabled={saved}
            className={`border-[#D3DAEE] border rounded-md w-60 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
            isClearable={false}
            styles={customStyles}
            isSearchable={true}
            menuPlacement="auto"
            menuPortalTarget={document.body}
          />
        )}
      />
    </div>
  )
}
