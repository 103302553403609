import { Dispatch, SetStateAction } from 'react'

export default function SearchBar({
  search,
  setSearch,
}: {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
}) {
  return (
    <div className={`h-[48px] bg-[#F6F7FB] rounded-lg flex flex-row items-center px-4 space-x-4`}>
      <img className="h-[18px] w-[18px]" src="/assets/chat/search.svg" alt="searchIcon" />
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="outline-none h-full grow text-sm"
        placeholder="Search"
      />
    </div>
  )
}
