import React from 'react'
import clsx from 'clsx'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IBillsSliceInitialStateInterface as ibsisi } from '../../../store/bills/billsSlice.types'
import BillsHeader from './common/BillsHeader'
import { CONST } from '../../../const/const'
import { toFixed } from '../../../helpers/helpers'
import { useAppSelector } from '../../../store/hooks'
import { selectHorses } from '../../../store/horses/horseSlice'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

type IBillDetailsRegistrationDataProps = {
  countDetails: ibsisi['selected']['countDetails']
  registrationsByDay: IRegistrationByDayInterface[]
  modalThemeColor: `#${string}`
  currentUser: string
  showRefund: boolean
  refundregistrationTotalState: number
}

type ITotalRowArgs = {
  modalThemeColor: `#${string}`
  item: string
  horse: string
  rider: string
  price: string | number
  showRefund?: boolean
  refundregistrationTotalState: number
}

type IDetailRow = {
  item: string
  horse: string
  rider: string
  price: string
  refunded: number
  scratched: number
  showRefund: boolean
  paymentStatus: string
  currOneDayRegistration: IRegistrationByDayInterface
}

const EventReviewPublishInput: React.FC<{
  className?: string
  data?: any
  price?: string | number
  icon?: string
  style?: React.CSSProperties
}> = ({ className, data, price, icon, style = {} }) => {
  return (
    <div className={`${className} text-SeabiscuitDark200ThemeColor`} style={style}>
      {icon ? <img className="w-5 h-5 absolute left-3" src={icon} alt="icon" /> : null}
      <div className="text-SeabiscuitDark200ThemeColor font-normal mr-1">{price}</div>
      <div>{data}</div>
    </div>
  )
}

const DetailRow = ({
  item,
  horse,
  rider,
  price,
  refunded,
  paymentStatus,
  scratched,
  showRefund,
  currOneDayRegistration,
}: IDetailRow) => {
  const split = rider.length > 10 ? rider.substring(0, 10) : null
  return (
    <div className="flex flex-col md:flex-row w-full mb-5 md:mb-0">
      <EventReviewPublishInput
        className={clsx(
          `rounded-lg min-h-11 bg-SeabiscuitGrayThemeColor text-sm p-3 m-1 ml-0 w-full md:w-4/12 `
        )}
        data={
          <div className="flex flex-wrap items-center gap-1">
            <span>{item}</span>
            {currOneDayRegistration.isSratched ? (
              <span
                className={'bg-[#122B461A] text-[#122B46] text-[10px] px-2 py-1 rounded-[20px]'}
              >
                Scratched
              </span>
            ) : (
              ''
            )}
            {currOneDayRegistration.isQualify ? (
              <span
                className={
                  'border border-SeabiscuitDark200ThemeColor text-[#122B46] text-[10px] px-2 py-1 rounded-[20px]'
                }
              >
                Qualifying
              </span>
            ) : (
              ''
            )}
          </div>
        }
      />
      <EventReviewPublishInput
        className="rounded-lg bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full md:w-3/12"
        data={horse}
        icon={'/assets/og_icons/YearofHorse-1.svg'}
      />
      <EventReviewPublishInput
        className="rounded-lg bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full md:w-3/12 capitalize"
        data={split ? `${split}...` : rider}
        icon={'/assets/cp_icons/User-1.svg'}
      />
      <EventReviewPublishInput
        className="relative rounded-lg text-center bg-SeabiscuitGrayThemeColor flex items-center justify-center text-sm pl-7 p-3 m-1 w-full md:w-3/12"
        data={
          <span>
            ${price}{' '}
            {currOneDayRegistration.isQualify && (
              <span> • ${currOneDayRegistration.qualifyFee} qual</span>
            )}
          </span>
        }
        icon={
          paymentStatus === PAYMENT_STATUSES.PAID
            ? '/assets/cp_icons/Ok-3.svg'
            : '/assets/cp_icons/Cancel-2.svg'
        }
      />

      {showRefund && (
        <>
          {' '}
          <EventReviewPublishInput
            className="rounded-lg text-center bg-SeabiscuitGrayThemeColor text-sm p-3 m-1 w-full md:w-3/12"
            data={`${scratched > 0 ? 'Scratched' : 'No'}`}
          />
          <EventReviewPublishInput
            className="rounded-lg text-center bg-SeabiscuitGrayThemeColor text-sm p-3 m-1 w-full md:w-3/12"
            data={`$${refunded}`}
          />
        </>
      )}
    </div>
  )
}

const TotalRow = (args: ITotalRowArgs) => {
  const { item, horse, rider, price, showRefund, refundregistrationTotalState } = args

  return (
    <>
      {showRefund ? (
        <>
          <div className="flex flex-col md:flex-row md:items-start w-full">
            <EventReviewPublishInput
              className={
                'rounded-lg text-[#122B46] text-sm p-3 m-1 w-full md:w-[21%] font-semibold bg-SeabiscuitGreenLightThemeColor'
              }
              // style={{
              //     backgroundColor: args.modalThemeColor
              // }}
              data={`Total`}
            />
            <EventReviewPublishInput
              className="rounded-lg text-[#122B46] text-sm p-3 m-1 w-full md:w-10/12 font-semibold flex md:justify-end bg-SeabiscuitGreenLightThemeColor"
              data={`$${refundregistrationTotalState} refunded`}
              price={`$${price} paid,`}
            />
          </div>
        </>
      ) : (
        <div className="flex flex-col md:flex-row md:items-start w-full">
          <EventReviewPublishInput
            className="rounded-lg text-[#122B46] text-sm p-3 mb-3 md:md-0 md:m-1 ml-0 w-full md:w-4/12 font-semibold"
            style={{
              backgroundColor: args.modalThemeColor,
            }}
            data={`Total ${item} Registration`}
          />
          <EventReviewPublishInput
            className="rounded-lg text-[#122B46] relative flex items-center justify-center text-sm p-3 mb-3 md:md-0 md:m-1 w-full md:w-3/12 font-semibold"
            style={{
              backgroundColor: args.modalThemeColor,
            }}
            data={`${horse} horse`}
          />
          <EventReviewPublishInput
            className="rounded-lg text-[#122B46] relative flex items-center justify-center text-sm p-3 mb-3 md:md-0 md:m-1 w-full md:w-3/12 font-semibold"
            style={{
              backgroundColor: args.modalThemeColor,
            }}
            data={`${rider} rider`}
          />
          <EventReviewPublishInput
            className="rounded-lg text-center text-[#122B46] text-sm p-3 md:m-1 w-full md:w-3/12 font-semibold"
            style={{
              backgroundColor: args.modalThemeColor,
            }}
            data={`$${price}`}
          />
        </div>
      )}
    </>
  )
}

const BillDetailsRegistrationData = ({
  registrationsByDay,
  showRefund,
  refundregistrationTotalState,
  modalThemeColor,
}: IBillDetailsRegistrationDataProps) => {
  const horses = useAppSelector(selectHorses)

  // Hooks and vars
  let horseCount = new Set()
  let registerCount = new Set()
  let price = 0
  let qualifyFee = 0

  const uniqueHorses: string[] = []

  registrationsByDay.forEach((registration) => {
    registration.horseIds &&
      registration.horseIds.forEach((horseId) => {
        if (!uniqueHorses.includes(horseId)) {
          uniqueHorses.push(horseId)
        }
      })
  })

  return (
    <>
      {registrationsByDay && registrationsByDay?.length ? (
        <div className="flex items-center">
          <BillsHeader title="Class" tooltipText="Registration" />
          <BillsHeader title="Horse" tooltipText="Registered horse" />
          <BillsHeader title="Rider" tooltipText="Registered rider" />
          <BillsHeader title="Price" tooltipText="Price" />
          {showRefund && (
            <>
              {' '}
              <BillsHeader title="Scratched" tooltipText="Scratched" />
              <BillsHeader title="Refunded" tooltipText="Refunded" />{' '}
            </>
          )}
        </div>
      ) : null}

      {registrationsByDay && registrationsByDay?.length ? (
        registrationsByDay?.map((currOneDayRegistration, index: number) => {
          const riderHorses = horses
            .filter((h) => currOneDayRegistration.horseIds?.includes(h.id || ''))
            .map((h) => h.horseName)

          horseCount.add(currOneDayRegistration.horseId)
          registerCount.add(currOneDayRegistration.riderId)
          if (currOneDayRegistration.registrationPrice)
            price += currOneDayRegistration.registrationPrice

          if (currOneDayRegistration.isQualify)
            qualifyFee += Number(currOneDayRegistration.qualifyFee)

          return (
            <DetailRow
              key={index}
              price={
                currOneDayRegistration.registrationPrice
                  ? currOneDayRegistration.registrationPrice?.toString()
                  : '0'
              }
              item={currOneDayRegistration.registrationByDayName ?? 'N/A'}
              horse={riderHorses[0] ? riderHorses.join(', ') : 'N/A'}
              rider={currOneDayRegistration.riderName ?? 'N/A'}
              refunded={currOneDayRegistration.amountRefunded ?? '0'}
              scratched={currOneDayRegistration.amountScratched ?? '0'}
              paymentStatus={currOneDayRegistration.paymentStatus}
              showRefund={showRefund ?? false}
              currOneDayRegistration={currOneDayRegistration}
            />
          )
        })
      ) : (
        <span className="w-full text-sm text-SeabiscuitLightParagraphTextColor text-center">
          No fees added
        </span>
      )}

      {registrationsByDay?.length ? (
        <TotalRow
          modalThemeColor={modalThemeColor}
          item={`${registrationsByDay.length}`}
          horse={`${uniqueHorses.length}`}
          rider={`${registerCount.size}`}
          price={toFixed(price + qualifyFee, 2)}
          showRefund={showRefund ?? false}
          refundregistrationTotalState={refundregistrationTotalState}
        />
      ) : null}
    </>
  )
}

export default BillDetailsRegistrationData
