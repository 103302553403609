import { where } from 'firebase/firestore'

import FirestoreService from '../services/firestoreService'

import { UserLegalClausesModel } from '../models/user-legal-clauses/user-legal-clauses.model'

import { CONST } from '../const/const'

export const getAllClauses = async (ownerId: string) => {
  const respData: any[] = []
  const clauses = await FirestoreService.filterItems(
    CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_LEGAL_CLAUSES.NAME,
    [
      where(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_LEGAL_CLAUSES.FIELDS.OWNER_ID.KEY,
        '==',
        ownerId
      ),
    ]
  )

  if (clauses.size) {
    clauses?.forEach((doc) => {
      let clauseDataModel = UserLegalClausesModel.fromFirestoreDoc(doc).toObject()
      respData.push(clauseDataModel)
    })
    return respData
  }
}
