import IFormHeaderTypes from './FormHeader.types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const FormHeader = (props: IFormHeaderTypes['IBaseProps']) => {
  // Hooks and vars
  const { title, description } = props

  return (
    <>
      <div className="mb-2 flex lg:items-center justify-between gap-2 flex-col lg:flex-row">
        <div className="w-full flex items-start flex-wrap md:flex-nowrap">
          <div className="grow mb-2">
            <h1 className="text-SeabiscuitDark200ThemeColor text-[19px] font-bold mb-1">{title}</h1>

            {description ? (
              <div className="text-SeabiscuitLightParagraphTextColor font-normal text-md max-w-[92%]">
                {description}
              </div>
            ) : null}
          </div>
        </div>
        {props?.headerButtonsContainer ? (
          <div className="w-full flex justify-start lg:justify-end">
            {props.headerButtonsContainer}
          </div>
        ) : null}
      </div>
      {!props.removeBreakLine && <hr className="mt-2 mb-8" style={props?.dividerStyles ?? {}} />}
    </>
  )
}

export default FormHeader
