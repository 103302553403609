import { Dispatch, SetStateAction } from 'react'
import {
  ChannelHeaderProps,
  useTranslationContext,
  useChannelPreviewInfo,
  DefaultStreamChatGenerics,
  useChannelStateContext,
} from 'stream-chat-react'
import ProfileImage from './ProfileImage'
import { IconPlus } from '../../../components/icons/IconPlus'
import { useChat } from './DashboardChat'

export default function CustomChannelHeader(
  props: ChannelHeaderProps & {
    onClickPerson: Dispatch<SetStateAction<boolean>>
  }
) {
  const { image: overrideImage, live, title: overrideTitle } = props
  const { channel, watcher_count } =
    useChannelStateContext<DefaultStreamChatGenerics>('ChannelHeader')
  const { setMemberModalOpen } = useChat()

  const { t } = useTranslationContext('ChannelHeader')
  const { displayImage, displayTitle } = useChannelPreviewInfo({
    channel,
    overrideImage,
    overrideTitle,
  })

  const { member_count, subtitle } = channel?.data || {}

  return (
    <div className="str-chat__channel-header">
      <ProfileImage
        src={displayImage}
        lazyLoad={true}
        className={'h-12 w-12 object-cover rounded-full'}
      />
      <div className="str-chat__channel-header-end">
        <div className="flex flex-row items-center space-x-2">
          <p className="str-chat__channel-header-title">
            {displayTitle}{' '}
            {live && (
              <span className="str-chat__header-livestream-livelabel">{t<string>('live')}</span>
            )}
          </p>
          <button onClick={() => props.onClickPerson((s) => !s)}>
            <img
              className="h-[15px] w-[15px] object-cover"
              src={'/assets/chat/info.svg'}
              alt="infoIcon"
            />
          </button>
          {channel.data?.member_count && channel.data.member_count > 2 && (
            <button onClick={() => setMemberModalOpen(true)}>
              <IconPlus />
            </button>
          )}
        </div>
        {subtitle && <p className="str-chat__channel-header-subtitle">{subtitle}</p>}
        <p className="str-chat__channel-header-info">
          {!live && !!member_count && member_count > 0 && (
            <>
              {t('{{ memberCount }} members', {
                memberCount: member_count,
              })}
              ,{' '}
            </>
          )}
          {t<string>('{{ watcherCount }} online', { watcherCount: watcher_count })}
        </p>
      </div>
    </div>
  )
}
