/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIonRouter } from '@ionic/react'
import { Search } from '@mui/icons-material'
import axios from 'axios'
import { CircleF, GoogleMap, InfoWindowF, MarkerF } from '@react-google-maps/api'
import Autocomplete from 'react-google-autocomplete'
import { where } from 'firebase/firestore'
import _, { cloneDeep } from 'lodash'

import FirestoreService from '../../../services/firestoreService'

import { getConvertedData, getGeohashRange } from '../../../models/interface.helper'
import { IEventInterface } from '../../../models/events/event.interface'
import { EventModel } from '../../../models/events/event.model'
import { IReactAutoComplete } from '../../events/forms/detail/EventDetailFormComponent'

import useToasterHelper from '../../../helpers/ToasterHelper'

import { CONST } from '../../../const/const'
import {
  resetLocationModalDataAc,
  selectMapFocusedMarkerIdR,
  selectedfilters,
  setActiveFilters,
  setMapFocusedMarkedIdAc,
  setOrganizerFilters,
  selectedOrganizerFilter,
  resetOrganizerLocation,
} from '../../../store/filters/filterSlice'
import { useAppSelector } from '../../../store/hooks'

import moment from 'moment'
import helpers from '../../../commonHelpers/helpers'
import { capitalize, createString } from '../../../helpers/helpers'
import MainModal from '../../modals/common/MainModal'
import { HOME_ROOT_GET_LOCATION_FILTER_DATA as hrglfd } from './HomeRootGetLocationFilter.data'
import { IHomeRootGetLocationFilterTypes as hrglft } from './HomeRootGetLocationFilter.types'
import { selectOrganizers } from '../../../store/organizers/organizersSlice'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type ISetLocationOnLoadArgs = {
  lat: number | null
  lng: number | null
  location: string | null
}

// Constants
const EVENT_CONST = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS

// United States
const DEFAULT_LOCATION = {
  lat: 37.09024,
  lng: -95.712891,
  location: 'Independence, KS 67301, USA',
}

interface IProps {
  dataToPassOn?: any
  show: boolean
  onHide?: () => void
  handleModal?: any
}
const HomeRootGetLocationFilter = (props: IProps) => {
  // Hooks and vars
  // const history = useIonRouter()
  const toastFunctions = useToasterHelper()
  const googleMapRef = useRef<GoogleMap>(null)
  const filterData = useSelector(selectedfilters)
  const organizerFiltes = useAppSelector(selectedOrganizerFilter)
  const focusedMarkedId = useAppSelector(selectMapFocusedMarkerIdR)
  const organizers = useAppSelector(selectOrganizers)
  const history = useHistory()

  const [radiusRange, setRadiusRange] = useState<number>(0)
  const setFilteredEvent = useState<IEventInterface[]>([])[1]
  const [filterDataLocal, setFilterDataLocal] = useState(filterData)
  const [locationChange, setLocationChange] = useState<boolean>(false)
  const [searchedEvents, setSearchedEvents] = useState<IEventInterface[]>([])
  const [searchedLocationFormatted, setSearchedLocationFormatted] = useState('')
  const [competetionLocationMarkers, setCompetetionLocationMarkers] = useState<
    hrglft['ILocationMarker'][]
  >([])
  const [searchedLoc, setSearchedLocation] = useState<hrglft['ISearchedLocation'] | null>()

  const centre = useState({
    lat: filterDataLocal.location.lat,
    lng: filterDataLocal.location.lng,
  })[0]

  const { profileDetails } = useAppSelector((state) => state.user)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFn = useCallback(
    _.debounce(() => {
      handleDebounceFn()
    }, 800),
    []
  )

  const dispatch = useDispatch()

  const radiusRef = useRef(radiusRange)
  const centerToQueryRef = useRef(searchedLoc ? searchedLoc : filterDataLocal.location)

  useEffect(() => {
    return () => {
      dispatch(setMapFocusedMarkedIdAc(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let modified = { ...filterData }
    if (searchedLoc)
      modified = {
        ...modified,
        location: {
          ...modified.location,
          lat: searchedLoc?.lat,
          lng: searchedLoc?.lng,
        },
      }
    setFilterDataLocal(modified)
  }, [searchedLoc])

  const getLocation = async (latitude: number, longitude: number) => {
    try {
      const { data } = await axios(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      )

      return data.results[0].formatted_address
    } catch (error) {
      console.error('Error with reverse geocoding: ', error)
    }
  }

  useEffect(() => {
    let lat: null | number = null
    let lng: null | number = null
    let location: null | string = null

    if (navigator?.geolocation) {
      navigator?.geolocation?.getCurrentPosition(
        async function (position) {
          lat = position.coords.latitude ?? Number(profileDetails.userLat)
          lng = position.coords.longitude ?? Number(profileDetails.userLong)

          await getLocation(lat, lng).then((data) => {
            location = data
          })

          setLocationOnLoad({
            lat,
            lng,
            location,
          })
        },
        async function (error) {
          if (lat && lng)
            await getLocation(lat, lng).then((data) => {
              location = data
            })

          setLocationOnLoad({
            lat,
            lng,
            location,
          })
          helpers.logger({
            isError: true,
            message: error,
          })
        }
      )
    }

    radiusRef.current = filterData?.location?.radius
    setRadiusRange(filterData?.location?.radius)
    handleDebounceFn()
  }, [])

  useEffect(() => {
    setSearchedLocation(
      filterData.location.lat && filterData.location.lng
        ? {
            lat: filterData.location.lat,
            lng: filterData.location.lng,
          }
        : { ...DEFAULT_LOCATION }
    )
  }, [filterData])

  useEffect(() => {
    if (radiusRange) compareDistance()
  }, [searchedEvents, radiusRange])

  // Functions
  const setZoomLevel = (currRadius: number) => {
    let zoomLevel
    let maxRadius = 250
    let minZoomLevel = 5
    let maxZoomLevel = 8

    zoomLevel = (maxZoomLevel - minZoomLevel) / maxRadius
    zoomLevel = zoomLevel * currRadius
    zoomLevel = maxZoomLevel - zoomLevel

    googleMapRef.current?.state?.map?.setZoom?.(zoomLevel)
    googleMapRef.current?.state?.map?.setCenter?.(centerToQueryRef.current)
  }

  const setLocationOnLoad = (args: ISetLocationOnLoadArgs) => {
    let { lat, lng, location } = args

    if (!lat || !lng) {
      lat = DEFAULT_LOCATION.lat
      lng = DEFAULT_LOCATION.lng
    }
    if (!location) {
      location = DEFAULT_LOCATION.location
    }

    if (filterData.location.status) {
      lat = filterData.location.lat
      lng = filterData.location.lng
      location = filterData.location.location
    }

    centerToQueryRef.current = { lat, lng }

    setFilterDataLocal({
      ...filterData,
      location: {
        ...filterData.location,
        lat,
        lng,
        location,
      },
    })
  }

  /**
   * @info Gets fired when the event radius changes,
   * and gets the event on the basis of radius selected from the db
   */
  const handleDebounceFn = () => {
    const searchEvent = async () => {
      try {
        const { lat, lng } = centerToQueryRef.current

        const range =
          lat && lng
            ? getGeohashRange(lat, lng, radiusRef.current)
            : getGeohashRange(hrglfd.TEMP_LOCATION.lat, hrglfd.TEMP_LOCATION.lng, radiusRef.current)

        const docs = await FirestoreService.filterItems(EVENT_CONST.NAME, [
          where(EVENT_CONST.FIELDS.LOCATION_HASH.KEY, '>=', range.lower),
          where(EVENT_CONST.FIELDS.LOCATION_HASH.KEY, '<=', range.upper),
          where(EVENT_CONST.FIELDS.STATUS.KEY, '==', 'current'),
        ])

        if (docs.size) {
          const events: IEventInterface[] = []
          docs?.forEach((doc) =>
            events.push(getConvertedData(EventModel.fromFirestoreDoc(doc).toObject()))
          )
          setSearchedEvents([...events])
        } else {
          setSearchedEvents([])
        }
      } catch (error) {
        helpers.logger({
          isError: true,
          message: error,
        })
      }
    }
    searchEvent()
  }

  /**
   *
   * @info Sets the value of the target to radiusRange state
   */
  const handleRadiusRange = (e: any) => {
    setRadiusRange(e.target.value)
  }

  /**
   *
   * @info Whenever the user clicks on the searched event marker,
   * this will redirect the user to that particular event detail page
   * @param eventId
   */
  const handleRedirect = (eventId: string | null) => {
    if (props.dataToPassOn.isOrganizes && eventId) {
      history.push(`/userprofile/${eventId}`)
    } else if (eventId) history.push(`/events-details/${eventId}`)
    else
      toastFunctions.info({
        message: 'Sorry for the inconvenience, we are unable to take you to the event',
      })
  }

  /**
   *
   * @info Gets fired on searchedEvents list or radiusRange change, and filters the search events acc to selected radius range
   */
  const compareDistance = async () => {
    try {
      const markerDetails: any[] = []
      let filteredEvents_: IEventInterface[] = []

      let position: hrglft['ISearchedLocation'] = { lat: 0, lng: 0 }
      let marker: hrglft['ILocationMarker'] = {
        position,
        eventName: null,
        eventId: null,
        tags: [],
        registrationOpenDate: null,
        category: undefined,
      }
      let positionIsValid: boolean | number = false
      let centerIsValid: boolean | number = false

      if (props.dataToPassOn.isOrganizes) {
        organizers.forEach((item) => {
          position = { lat: +(item?.userLat ?? ''), lng: +(item?.userLong ?? '') }
          marker = {
            position,
            eventName: null,
            eventId: null,
            tags: [],
            registrationOpenDate: null,
            category: undefined,
          }
          positionIsValid = Object.values(position).filter((x) => !isNaN(x))?.length ?? 0
          centerIsValid = Object.values(centre).filter((x) => !isNaN(x))?.length ?? 0
          if (positionIsValid && centerIsValid) {
            // const dis = getDistance(position as any, centre, 100) / hrglfd.ONE_MILE

            marker.position = position
            marker.eventName =
              !item.userFirstName && !item.userLastName
                ? item.userName || ''
                : `${item.userFirstName} ${item.userLastName}`
            marker.eventId = item.id
            marker.tags = []
            marker.category = item.userCategory
            marker.registrationOpenDate = null

            // filteredEvents_ = [...filteredEvents_, getConvertedData(item)]
            markerDetails.push(marker)
          }
        })
      } else {
        searchedEvents.forEach((item) => {
          position = { lat: +(item?.competitionLat ?? ''), lng: +(item?.competitionLong ?? '') }
          marker = {
            position,
            eventName: null,
            eventId: null,
            tags: [],
            registrationOpenDate: null,
            category: undefined,
          }
          positionIsValid = Object.values(position).filter((x) => !isNaN(x))?.length ?? 0
          centerIsValid = Object.values(centre).filter((x) => !isNaN(x))?.length ?? 0
          if (positionIsValid && centerIsValid) {
            // const dis = getDistance(position as any, centre, 100) / hrglfd.ONE_MILE

            marker.position = position
            marker.eventName = item.eventName ?? ''
            marker.eventId = item.id
            marker.tags = item.tags
            marker.category = item.category
            marker.registrationOpenDate = item.registrationOpenDate

            filteredEvents_ = [...filteredEvents_, getConvertedData(item)]
            markerDetails.push(marker)
          }
        })
      }

      setCompetetionLocationMarkers(markerDetails)
      setFilteredEvent([...filteredEvents_])
    } catch (error: any) {
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  /**
   * @param place Details got from React auto complete library
   * @info Sets from values according to the details got from third party lib
   */
  const handleLocation = (place: IReactAutoComplete | React.ChangeEvent<HTMLInputElement>) => {
    if (typeof place === 'object') {
      let data = place as any as IReactAutoComplete
      let lat = data?.geometry?.location?.lat()
      let lng = data?.geometry?.location?.lng()
      let formatted_address = data?.formatted_address
      if (lat && lng && formatted_address) {
        setSearchedLocation({
          lat: data?.geometry?.location?.lat(),
          lng: data?.geometry?.location?.lng(),
        })
        setSearchedLocationFormatted(
          formatted_address ? formatted_address : filterData?.location?.location
        )

        centerToQueryRef.current = {
          lat: data?.geometry?.location?.lat(),
          lng: data?.geometry?.location?.lng(),
        }
        googleMapRef.current?.state?.map?.panTo(centerToQueryRef.current)
        debouncedFn()
      }
      setLocationChange(true)
    }
  }

  useEffect(() => {
    setSearchedLocationFormatted(filterData.location.location)
  }, [filterData])

  /**
   * @info Closes the modal,
   * and sets the filteredEvents that are in the selected radius to FilteredEvents in redux
   */
  const closeModal = (applyFilter: any) => {
    if (props.dataToPassOn.isOrganizes) {
      if (!applyFilter) {
        dispatch(resetOrganizerLocation())
      } else {
        dispatch(
          setOrganizerFilters({
            ...organizerFiltes,
            location: { ...filterDataLocal.location, radius: radiusRange, status: true },
          })
        )
      }
    } else {
      if (applyFilter !== false) {
        let activeFilters = cloneDeep(filterDataLocal)

        if (locationChange) {
          if (!searchedLocationFormatted.trim()) {
            toastFunctions.error({
              message: 'Please select location',
            })
          }
          activeFilters = {
            ...activeFilters,
            location: {
              ...activeFilters.location,
              location: searchedLocationFormatted,
              lng: searchedLoc?.lng ?? DEFAULT_LOCATION.lng,
              lat: searchedLoc?.lat ?? DEFAULT_LOCATION.lat,
              radius: radiusRange,
              status: searchedLocationFormatted !== '',
            },
          }
        } else {
          activeFilters = {
            ...activeFilters,
            location: {
              ...activeFilters.location,
              radius: radiusRange,
              status: true,
            },
          }
        }
        activeFilters = {
          ...activeFilters,
          map: {
            ...activeFilters.map,
            locationModalZoomLevel:
              googleMapRef.current?.state?.map?.getZoom() ??
              activeFilters.map.locationModalZoomLevel,
          },
        }

        dispatch(setActiveFilters(activeFilters))
      } else {
        dispatch(resetLocationModalDataAc())
      }
    }
    props.handleModal(false)
  }

  return (
    <MainModal
      title="Location"
      size="lg"
      type="LOCATION"
      titleClassName="border-b-[#1f41734d] border-b-[1px] border-solid pb-2 justify-center !text-[15px]"
      show={props.show}
      closeButtonClassName="right-[12px]"
      setHeightAsPerContent={true}
      customPadding={true}
      footerClassName="md:!flex-row-reverse items-center justify-between border-t border-solid border-t-[#1f41734d]"
      buttons={[
        {
          label: 'Apply',
          className: 'md:max-w-[174px] !m-4',
          bgClass: '!bg-SeabiscuitMainThemeColor',
          onClick: closeModal,
          textClass: '!text-white',
          onHoverClass: 'hover:shadow-slate-300',
        },
        {
          label: 'CANCEL',
          bgClass: '!bg-SeabiscuitLightThemeColor',
          className: 'md:max-w-[174px] !m-4',
          borderClass: 'border border-transparent',
          textClass: '!text-SeabiscuitLightTextColor',
          onClick: () => closeModal(false),
          onHoverClass: 'hover:shadow-slate-300',
        },
      ]}
    >
      <div className="pb-4">
        <h5 className="text-xl font-normal leading-normal text-SeabiscuitDark200ThemeColor p-4 pb-0">
          Select location
        </h5>

        <div className="relative w-[96%] mt-4 flex items-center flex-row mr-2 ml-[13px]">
          <Autocomplete
            className="text-SeabiscuitDark200ThemeColor rounded-md bg-SeabiscuitGrayThemeColor border border-transparent focus:border-SeabiscuitMainThemeColor
                                    focus:outline-none placeholder:text-SeabiscuitDark200ThemeColor pl-10 text-sm w-full h-11 placeholder:text-sm"
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            placeholder="Search by city of zipcode..."
            defaultValue={filterDataLocal?.location?.location}
            onPlaceSelected={(place: any) => {
              handleLocation(place)
            }}
            onChange={(e: any) => {
              handleLocation(e)
            }}
            options={hrglfd.AUTO_COMPLETE_INPUT_OPTIONS}
          />

          <Search className="text-SeabiscuitDark200ThemeColor absolute left-2" fontSize={'small'} />
        </div>
      </div>
      <div className="h-[300px] w-full">
        <GoogleMap
          ref={googleMapRef}
          mapContainerClassName="h-[100%]"
          center={centerToQueryRef.current ?? googleMapRef.current?.state?.map?.getCenter()}
          zoom={
            googleMapRef.current?.state?.map?.getZoom() ?? filterData.map.locationModalZoomLevel
          }
          options={{ streetViewControl: false }}
        >
          <MarkerF
            position={centerToQueryRef.current}
            title={`Home`}
            icon="/assets/og_icons/markerIcon.svg"
          />
          {competetionLocationMarkers.length > 0 &&
            competetionLocationMarkers.map((item, index) => {
              return (
                <MarkerF
                  onMouseOut={() => dispatch(setMapFocusedMarkedIdAc(null))}
                  onMouseOver={() => dispatch(setMapFocusedMarkedIdAc(item.eventId))}
                  key={`${JSON.stringify(item)}${index}`}
                  position={item?.position}
                  icon="/assets/cp_icons/FinalMapPin.svg"
                  title={`${item?.eventName ?? item?.position?.lat}`}
                  onClick={() => {
                    handleRedirect(item.eventId)
                    closeModal(false)
                  }}
                >
                  {focusedMarkedId === item.eventId && (
                    <InfoWindowF position={item?.position}>
                      <div className="marker-tooltip">
                        {props.dataToPassOn.isOrganizes
                          ? item.eventName
                          : capitalize(
                              createString([
                                item.category,
                                item?.tags?.find((tag) => tag === 'All')
                                  ? 'All Disciplines'
                                  : item.tags?.toString(),
                                item.registrationOpenDate
                                  ? moment(item.registrationOpenDate).format('MMM D')
                                  : '',
                              ])
                            )}
                      </div>
                    </InfoWindowF>
                  )}
                </MarkerF>
              )
            })}

          <CircleF
            center={centerToQueryRef.current}
            options={{
              ...hrglfd.MAP_CIRCLE_OPTIONS,
              radius: radiusRange * hrglfd.ONE_MILE,
            }}
          />
        </GoogleMap>
      </div>

      <div className="radiusMeter pt-1 pb-3">
        <h3 className="text-SeabiscuitDark200ThemeColor flex justify-between pt-4 pl-2 pr-2">
          Select radius{' '}
          {radiusRange !== 0 && <span className="text-right">{radiusRange} miles</span>}
        </h3>

        <div className="radiusRange pl-2 pr-2 pb-4">
          <input
            id="default-range"
            type="range"
            className="w-full h-2 rounded-lg appearance-none cursor-pointer"
            style={{
              background: `linear-gradient(to right, #F7074F ${(radiusRange * 100) / 250}%, #fdf2f4 calc(${(radiusRange * 100) / 250}% - 1px), #fdf2f4 100%)`,
            }}
            value={radiusRange}
            min="0"
            max="250"
            onChange={(e) => {
              setZoomLevel(parseInt(e.target.value))
              handleRadiusRange(e)
              debouncedFn()
              radiusRef.current = Number(e.target.value)
            }}
          />
        </div>
      </div>
    </MainModal>
  )
}

export default HomeRootGetLocationFilter
