export const CardType = [
  { label: 'Combined Driving Pony', value: 'Combined Driving Pony' },
  { label: 'Combined Driving Pony(Temporary)', value: 'Combined Driving Pony(Temporary)' },
  { label: 'Dressage Pony', value: 'Dressage Pony' },
  { label: 'Dressage Pony(Temporary)', value: 'Dressage Pony(Temporary)' },
  { label: 'Hackney Pony, Roadster Pony', value: 'Hackney Pony, Roadster Pony' },
  {
    label: 'Hackney Pony, Roadster Pony(Temporary)',
    value: 'Hackney Pony, Roadster Pony(Temporary)',
  },
  {
    label: 'Hunter / Jumper Horse or Welsh Cob(Standard)',
    value: 'Hunter / Jumper Horse or Welsh Cob(Standard)',
  },
  {
    label: 'Hunter / Jumper Horse or Welsh Cob(Temporary)',
    value: 'Hunter / Jumper Horse or Welsh Cob(Temporary)',
  },
  {
    label: 'Hunter / Jumper Pony or Welsh(Standard)',
    value: 'Hunter / Jumper Pony or Welsh(Standard)',
  },
  {
    label: 'Hunter / Jumper Pony or Welsh Cob(Temporary)',
    value: 'Hunter / Jumper Pony or Welsh Cob(Temporary)',
  },
  { label: 'Saddlebred Pony ', value: 'Saddlebred Pony ' },
  { label: 'Saddlebred Pony(Temporary)', value: 'Saddlebred Pony(Temporary)' },
  { label: 'Welsh Pony Permanent', value: 'Welsh Pony Permanent' },
  { label: 'Welsh Pony Temporary', value: 'Welsh Pony Temporary' },
]

export const ShodStatus = [
  { label: 'Unshod', value: 'Unshod' },
  { label: 'Shod All Around', value: 'Shod All Around' },
  { label: 'Shod in Front', value: 'Shod in Front' },
  { label: 'Shod Behind', value: 'Shod Behind' },
]

export const HeelSize = [
  { label: 'N/A', value: 'N/A' },
  { label: '1/8', value: '1/8' },
  { label: '1/4', value: '1/4' },
  { label: '3/8', value: '3/8' },
  { label: '1/2', value: '1/2' },
  { label: '5/8', value: '5/8' },
  { label: '3/4', value: '3/4' },
  { label: '7/8', value: '7/8' },
  { label: '1', value: '1' },
  { label: '1 1/8', value: '1 1/8' },
  { label: '1 1/4', value: '1 1/4' },
  { label: '1 3/8', value: '1 3/8' },
  { label: '1 1/2', value: '1 1/2' },
  { label: '1 5/8', value: '1 5/8' },
  { label: '1 3/4', value: '1 3/4' },
  { label: '1 7/8', value: '1 7/8' },
  { label: '2', value: '2' },
  { label: '2 1/8', value: '2 1/8' },
  { label: '2 1/4', value: '2 1/4' },
  { label: '2 3/8', value: '2 3/8' },
  { label: '2 1/2', value: '2 1/2' },
  { label: '2 5/8', value: '2 5/8' },
  { label: '2 3/4', value: '2 3/4' },
  { label: '2 7/8', value: '2 7/8' },
  { label: '3', value: '3' },
  { label: '3 1/8', value: '3 1/8' },
  { label: '3 1/4', value: '3 1/4' },
  { label: '3 3/8', value: '3 3/8' },
  { label: '3 1/2', value: '3 1/2' },
  { label: '3 5/8', value: '3 5/8' },
  { label: '3 3/4', value: '3 3/4' },
  { label: '3 7/8', value: '3 7/8' },
  { label: '4', value: '4' },
  { label: '4 1/8', value: '4 1/8' },
  { label: '4 1/4', value: '4 1/4' },
  { label: '4 3/8', value: '4 3/8' },
  { label: '4 1/2', value: '4 1/2' },
  { label: '4 5/8', value: '4 5/8' },
  { label: '4 3/4', value: '4 3/4' },
  { label: '4 7/8', value: '4 7/8' },
]
