import { useContext, useEffect, useState } from 'react'
import { where } from 'firebase/firestore'

import { IFollowingInterface } from '../../../../models/following/following.interface'
import { FollowingModel } from '../../../../models/following/following.model'

import helpers from '../../../../commonHelpers/helpers'
import useToasterHelper from '../../../../helpers/ToasterHelper'

import {
  selectVisitedUserDetailsR,
  selectVisitedUserProfileDetailsR,
} from '../../../../store/profilePage/profilePageSlice'
import { useAppSelector } from '../../../../store/hooks'
import { selectIsLoggedIn, selectProfileData } from '../../../../store/user/userSlice'

import { CustomError, getUserFullName } from '../../../../helpers/helpers'

import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

import FirestoreService from '../../../../services/firestoreService'

import { DATA_FIRESTORE_V01_FOLLOWING } from '../../../../const/data/v01/following/firestore-v01-following-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { CONST } from '../../../../const/const'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const FILE_NAME = 'RegisterForCompetitionPayByCardModal'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const useProfileDetailCard = () => {
  const toastMethods = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)

  const [loading, setLoading] = useState(true)
  const [following, setFollowing] = useState<boolean>(false)
  const [alreadyFollowed, setAlreadyFollowed] = useState<null | boolean>(null)
  const [followingDoc, setfollowingDoc] = useState<IFollowingInterface | null>(null)

  const userData = useAppSelector(selectProfileData)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const visitedUserDetails = useAppSelector(selectVisitedUserDetailsR)
  const visitedUserProfileDetails = useAppSelector(selectVisitedUserProfileDetailsR)

  const loggedInUserId = userData.id
  const loggedInUserType = userData.userType
  const visitedUserId = visitedUserProfileDetails?.id
  const followingUserType = visitedUserProfileDetails?.userType
  const isMyProfile = visitedUserDetails?.isMyProfile ?? false
  const profileDetails = visitedUserDetails?.profileDetails ?? null

  useEffect(() => {
    if (isMyProfile) {
      setLoading(false)
      setAlreadyFollowed(false)
      return
    }

    if (visitedUserId && loggedInUserId) {
      checkAlreadyFollowed().then()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitedUserId, loggedInUserId, isMyProfile])

  useEffect(() => {
    if (!isLoggedIn) {
      setLoading(false)
      setFollowing(false)
      setAlreadyFollowed(false)
    }
  }, [isLoggedIn])

  const validateAction = () => {
    if (!isLoggedIn) {
      toastMethods.info({
        message: 'This service is only available to registered users',
      })
      return false
    }
    return true
  }

  const messageUser = () => {
    if (validateAction()) {
      handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
        emailId: profileDetails?.userEmail,
        user_name:
          (profileDetails?.userFirstName ?? '') + ' ' + (profileDetails?.userLastName ?? ''),
        profilePicture: profileDetails?.userProfilePicture,
      })
    }
  }

  const checkAlreadyFollowed = async () => {
    try {
      if (!loggedInUserId || !visitedUserId) return 0

      setLoading(true)

      const docSnaps = await FirestoreService.filterItems(COLLECTIONS.FOLLOWING.NAME, [
        where(COLLECTIONS.FOLLOWING.FIELDS.FOLLOWER_USER_ID.KEY, '==', loggedInUserId),
        where(COLLECTIONS.FOLLOWING.FIELDS.FOLLOWING_USER_ID.KEY, '==', visitedUserId),
      ])

      setAlreadyFollowed(!!docSnaps.size)

      if (docSnaps.size)
        setfollowingDoc(FollowingModel.fromFirestoreDoc(docSnaps.docs[0]).toObject())
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })

      toastMethods.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }

  const onFollowBtnClick = async () => {
    let docId: null | string = null

    try {
      if (!validateAction()) {
        return 0
      }

      if (!loggedInUserId || !visitedUserId) throw new Error()

      let docToSave = new FollowingModel({
        usedFor:
          followingUserType ===
            DATA_FIRESTORE_V01_FOLLOWING.FIELDS.FOLLOWING_USER_TYPE.VALUES.ORGANIZER ||
          followingUserType ===
            DATA_FIRESTORE_V01_FOLLOWING.FIELDS.FOLLOWING_USER_TYPE.VALUES.COMPETITOR ||
          followingUserType ===
            DATA_FIRESTORE_V01_FOLLOWING.FIELDS.FOLLOWING_USER_TYPE.VALUES.VENDOR
            ? 'user'
            : 'horse',
        followingHorseId: null,
        followingUserId: visitedUserId,
        followerUserId: loggedInUserId,
        followerUserType: loggedInUserType as any,
        followingUserType: followingUserType as any,
      })

      setFollowing(true)

      if (!alreadyFollowed) {
        const docRef = await FirestoreService.createItem(
          COLLECTIONS.FOLLOWING.NAME,
          docToSave.toFirestore()
        )

        docId = docRef.id
        docToSave = docToSave.cloneDeep()
        docToSave.id = docId

        setfollowingDoc(docToSave)

        toastMethods.success({
          message: MESSAGES_CONST.FOLLOWED.replace(
            '[USER_NAME]',
            getUserFullName(visitedUserProfileDetails)
          ),
        })

        setAlreadyFollowed(true)
      } else if (followingDoc?.id) {
        await FirestoreService.deleteItem(COLLECTIONS.FOLLOWING.NAME, followingDoc.id)

        toastMethods.success({
          message: MESSAGES_CONST.UNFOLLOWED.replace(
            '[USER_NAME]',
            getUserFullName(visitedUserProfileDetails) ?? 'Unknown'
          ),
        })

        setAlreadyFollowed(false)
      } else throw new Error('Already followed but following doc or its id is null')
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'onFollowBtnClick',
          devMessage: error,
        }),
      })
      toastMethods.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setFollowing(false)
    }
  }

  return {
    loading,
    following,
    isMyProfile,
    messageUser,
    alreadyFollowed,
    onFollowBtnClick,
    profileDetails,
  }
}

export default useProfileDetailCard
