import { useChat } from './DashboardChat'

export default function NewMessage() {
  const { setNewChatModal } = useChat()
  return (
    <button
      onClick={() => setNewChatModal(true)}
      className="w-[154px] h-[50px] bg-[#F6F7FB] rounded-md flex flex-row items-center justify-center space-x-2"
    >
      <img src="/assets/chat/newMessage.svg" alt="searchIcon" />
      <span className="text-xs">New Message</span>
    </button>
  )
}
