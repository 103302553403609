import { useEffect, useState } from 'react'

//third-party
import { yupResolver } from '@hookform/resolvers/yup'
import { AutorenewRounded } from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'

// Type imports
import {
  ICompetitorProfileAccountForm,
  INotificationSettings,
} from '../../../../types/competitor_types'

//custom components
import { competitorProfileAccountSchema } from '../../../../validations'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import {
  selectUserStripeAccountId,
  setUserAccountPrivacy,
  setUserNotificationSettings,
} from '../../../../store/user/userSlice'

// Styles
import './UserProfileViewComponent.css'

// Helpers
import MessageHelperComp from '../../../../helpers/MessageHelper'
import ProfileRootPasswordUpdateForm from './ProfileRootPasswordUpdateForm'

// Custom hooks
import useToasterHelper from '../../../../helpers/ToasterHelper'
import useProfileHook from '../../../../hooks/users/competitor/profile/useProfileHook'

import { Tooltip } from '@mui/material'
import { collection, getCountFromServer, query, where } from 'firebase/firestore'
import moment from 'moment'
import helpers from '../../../../commonHelpers/helpers'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import FirebaseApp from '../../../../services/firebaseApp'
import { httpService } from '../../../../services/httpService'
import { useAppSelector } from '../../../../store/hooks'
import IStripeInterface from '../../../../types/stripe.types'
import TogglesElement from '../../../elements/toggles/toggles/TogglesElement'
import formFunctions from '../../../ui/form/form-functions/formFunctions'
import FormHeader from '../../../ui/form/form-header/FormHeader'
import Card from './components/card/Card'

type Props = {
  setActiveTab: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const initialState = {
  newMessages: false,
  weatherUpdates: false,
  resultUpdates: false,
  courseUpdates: false,
  paymentUpdates: false,
}

const ProfileRootPageAccountTab = (props: Props) => {
  // Hooks and vars
  const dispatch = useDispatch()

  const toasterFunctions = useToasterHelper()

  const userReducer = useSelector((state: RootState) => state.user)
  const stripeConnectAccountId = useAppSelector(selectUserStripeAccountId)

  const { userType, userId } = userReducer
  const { getModelledUserDetails, updateUserDetails } = useProfileHook({ dontFetch: true })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
  } = useForm<ICompetitorProfileAccountForm>({
    mode: 'onChange',
    resolver: yupResolver(competitorProfileAccountSchema),
  })

  const [loading, setLoading] = useState(false)
  const [getBalanceLoading, setGetBalanceLoading] = useState(false)
  const [userNotificationsSettings, setUserNotificationsSettings] =
    useState<INotificationSettings>(initialState)
  const [saved, isSaved] = useState(true)
  const formStyles = formFunctions.getFormStyles()
  const EVENTS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Sets form values
   */
  useEffect(() => {
    if (userReducer) {
      reset({
        userEmail: userReducer?.profileDetails?.userEmail,
        userAccountPrivacy: userReducer?.profileDetails?.userAccountPrivacy ?? false,
        userNotificationsSettings: userReducer?.userNotificationsSettings ?? { ...initialState },
      })
      setUserNotificationsSettings(userReducer?.userNotificationsSettings ?? { ...initialState })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReducer.userNotificationsSettings, userReducer?.profileDetails?.userEmail])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Handles data on form submission
   */
  const onSubmit = async (data: any) => {
    setLoading(true)

    data = { ...data, userNotificationsSettings }

    try {
      dispatch(setUserAccountPrivacy(data.userAccountPrivacy))
      let dataToUpdate = { ...getModelledUserDetails(data) }

      const updateRes = await updateUserDetails(dataToUpdate)

      if (updateRes.status) {
        dispatch(setUserNotificationSettings(userNotificationsSettings))
        toasterFunctions.success({
          message: MESSAGES_CONST.ACCOUNT_SETTINGS_UPDATED,
        })
      } else throw new Error(MESSAGES_CONST.UNABLE_TO_UPDATE_ACCOUNT_SETTINGS)
    } catch (error) {
      console.log(error, 'error')
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
      isSaved(true)
    }
  }

  const handleModelToggleCond = () => {
    const finalage = moment(userReducer.profileDetails.userDOB).fromNow()
    const age = finalage.split(' ')

    console.log('the age', +age[0])

    if (+age[0] >= 18) {
      setValue('userAccountPrivacy', !watch('userAccountPrivacy'))
    } else if (+age[0] < 18) {
      toasterFunctions.error({
        message:
          'You are under eighteen years of age, your profile is set to Private automatically.',
      })
    } else {
      toasterFunctions.error({
        message:
          'You need to add your age in profile settings before you can set your profile to public',
      })
    }
  }

  const getAvailableStripeBalance = async (): Promise<
    IStripeInterface['IStripeBalance'] | null
  > => {
    if (!stripeConnectAccountId) return null

    setGetBalanceLoading(true)

    let balanceResponse: IStripeInterface['IStripeBalance'] | null = null

    try {
      const response: any = await httpService({
        method: 'get',
        url: `get_stripe_connect_balance/${stripeConnectAccountId}`,
      })

      balanceResponse = response?.data?.balance ?? null
    } catch (error: any) {
      let message = error?.response?.data?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG

      toasterFunctions.error({
        message,
      })

      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setGetBalanceLoading(false)
      return balanceResponse
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info checks if the account can be removed from stripe
   * @param remove true means remove the account, else its called for updating the email
   */
  const checkAccountCanBeRemoved = async (): Promise<boolean> => {
    let canBeRemoved = true
    let availableFunds_ = 0
    let foundFoundAtIndex = -1

    let message = MESSAGES_CONST.SOMETHING_WENT_WRONG

    try {
      const availableBalance = await getAvailableStripeBalance()

      if (!availableBalance) return canBeRemoved

      const { available, instant_available, pending } = availableBalance

      const pendingFunds = pending?.[0]?.amount ?? 0
      const availableFunds = available?.[0]?.amount ?? 0
      const instantAvailableFunds = instant_available?.[0]?.amount ?? 0

      const fundsKeysArr = ['pending funds', 'available funds', 'instant funds']
      const fundsArr = [pendingFunds, availableFunds, instantAvailableFunds].map((cf) =>
        Number((cf / 100).toFixed(2))
      )

      const areFundsAvailable = !!fundsArr.find((currentFund, index) => {
        if (currentFund > 0) {
          foundFoundAtIndex = index
          availableFunds_ = currentFund
          return true
        }
        return false
      })

      if (areFundsAvailable) {
        canBeRemoved = false

        message = MESSAGES_CONST.UNABLE_TO_UPDATE_EMAIL
        message = message
          .replace('[AVAILABLE_FUNDS]', `$${availableFunds_}`)
          .replace('[BALANCE_TYPE]', fundsKeysArr[foundFoundAtIndex])

        if (0) console.log({ message })
        // toasterFunctions.error({
        //   message
        // });
      } else canBeRemoved = true
    } catch (error) {
      canBeRemoved = false
    } finally {
      return canBeRemoved
    }
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const checkEventAreLive = async () => {
    let EventsTotalCount = 0
    let firestoreQuery = query(
      collection(FirebaseApp.firestore, EVENTS.NAME),
      ...[
        where(EVENTS.FIELDS.OWNER.KEY, '==', userId),
        where(EVENTS.FIELDS.STATUS.KEY, '==', EVENTS.FIELDS.STATUS.VALUE.CURRENT),
      ]
    )

    try {
      let regUsersCountSnap = await getCountFromServer(firestoreQuery)
      EventsTotalCount = regUsersCountSnap.data()?.count ?? 0
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      return EventsTotalCount
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onUpdateEmailClickFn = async () => {
    const isOrganizer = userReducer.userType === COLLECTIONS.USERS.FIELDS.USER_TYPE.VALUES.ORGANIZER

    // if (!connectAccountExists || !isOrganizer)
    //   return props.handleModal(true, MODAL_CONSTS.UPDATE_PROFILE_EMAIL);

    const canAccountBeRemoved = await checkAccountCanBeRemoved()
    const liveEventsCount = await checkEventAreLive()

    // if (!canAccountBeRemoved || liveEventsCount > 0)
    //   return

    props.handleModal(true, MODAL_CONSTS.UPDATE_PROFILE_EMAIL, {
      canAccountBeRemoved,
      type: isOrganizer ? 'organizer' : '',
      checkEvents: liveEventsCount,
      setActiveTab: props?.setActiveTab,
    })

    return 0
  }

  return (
    <>
      <FormHeader
        title={'Account settings'}
        description={'Update your log in and security details'}
        headerButtonsContainer={
          <div className="flex items-center gap-2">
            {saved ? (
              <button
                type="button"
                onClick={() => isSaved(false)}
                className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              >
                Edit
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  handleSubmit(onSubmit)()
                }}
                disabled={loading}
                className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn min-w-[150px]"
              >
                {loading ? (
                  <AutorenewRounded fontSize="small" className="animate-spin" />
                ) : (
                  'Save changes'
                )}
              </button>
            )}
          </div>
        }
      />

      <div className={formStyles.className} style={formStyles.styles}>
        <div className="relative w-full text-SeabiscuitDark200ThemeColor xl:text-nr 2xl:text-base">
          <div className="flex flex-col md:grid gap-3">
            {/* Account email */}
            <div className="AccountEmail flex flex-wrap items-center">
              <label className=" md:mr-8 lg:w-1/4 flex">
                <img src={'assets/og_icons/Mail-1.svg'} className="w-6 h-6 mr-2" alt="emailIcon" />
                Account email
              </label>
              <div className="flex w-full md:w-[initial] flex-col lg:flex-row  lg:items-center">
                <div className="w-full md:w-[initial] md:mr-5">
                  <input
                    type="email"
                    disabled={true}
                    {...register('userEmail')}
                    placeholder="your account email"
                    className={`mt-3 md:mt-0 w-full md:w-96 border text-SeabiscuitDark200ThemeColor rounded-xl p-3 h-[51px] !ring-0 ${saved ? 'border-white text-SeabiscuitDark200ThemeColor bg-white' : '!border-[#D3DAEE] bg-gray-50'}`}
                  />
                  {errors.userEmail && (
                    <MessageHelperComp isError={true} message={errors.userEmail.message} />
                  )}
                </div>
                {!saved && (
                  <button
                    type="button"
                    disabled={loading}
                    onClick={onUpdateEmailClickFn}
                    className="flex items-center mt-2 lg:mt-0 w-[200px] justify-center px-4 font-normal  border border-solid border-SeabiscuitGray500ThemeColor hover:border-SeabiscuitMainThemeColor xl:text-nr 2xl:text-base rounded-xl shadow-sm text-nr text-SeabiscuitDark200ThemeColor hover:text-SeabiscuitMainThemeColor !hover:bg-transparent py-3"
                  >
                    {getBalanceLoading ? (
                      <AutorenewRounded fontSize="small" className="animate-spin" />
                    ) : (
                      'Change email'
                    )}
                  </button>
                )}
              </div>
            </div>

            {/* Password Section */}

            <ProfileRootPasswordUpdateForm saved={saved} />

            {/* Account Privacy */}
            {!!userType && userType !== 'organizer' && (
              <div className="flex flex-wrap flex-col sm:flex-row sm:items-center profile-root-page-account-tab">
                <label className="mr-8 lg:w-1/4 flex ">
                  <img
                    src={'assets/og_icons/UserShield.svg'}
                    className="w-6 h-6 mr-2"
                    alt="cardIcon"
                  />
                  Account privacy
                  <Tooltip title={''} placement="top" arrow>
                    <button
                      type="button"
                      className=""
                      onClick={() => props.handleModal(true, MODAL_CONSTS.ACCOUNT_PRIVACY)}
                    >
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </label>

                <Controller
                  name={`userAccountPrivacy`}
                  control={control}
                  render={() => {
                    return (
                      <TogglesElement
                        checkedMessage="Private"
                        uncheckedMessage={'\u00A0\u00A0\u00A0\u00A0\u00A0Public'}
                        buttonClassName="mt-2 sm:mt-0 w-[150px]"
                        textClassName={
                          +moment(userReducer.profileDetails.userDOB).fromNow().split(' ')[0] >= 18
                            ? watch('userAccountPrivacy')
                              ? ' !w-[88px] text-[#fff] px-1'
                              : ' !w-[88px] text-[#1F417380] px-1'
                            : ' !w-[88px] text-[#fff] px-1'
                        }
                        on={
                          +moment(userReducer.profileDetails.userDOB).fromNow().split(' ')[0] >= 18
                            ? watch('userAccountPrivacy')
                            : true
                        }
                        onToggle={() => handleModelToggleCond()}
                      />
                    )
                  }}
                />
              </div>
            )}

            <Card saved={saved} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileRootPageAccountTab
