export const DATA_FIRESTORE_V01_SUBSCRIBERS = {
  NAME: 'v01_subscribers',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    ID: {
      KEY: 'id',
    },

    MEMBERSHIP_ID: {
      KEY: 'membershipId',
    },

    OWNER_ID: {
      KEY: 'ownerId',
    },

    OWNER_EMAIL: {
      KEY: 'ownerEmail',
    },

    USER_ID: {
      KEY: 'userId',
    },

    USER_EMAIL: {
      KEY: 'userEmail',
    },

    LAST_PAYMENT: {
      KEY: 'lastPayment',
    },

    USER_STRIPE_ACCOUNT_ID: {
      KEY: 'userStripeAccountId',
    },

    STRIPE_PRODUCT_ID: {
      KEY: 'stripeProductId',
    },

    STRIPE_SUBSCRIPTION_ID: {
      KEY: 'stripeSubscriptionId',
    },

    STATUS: {
      KEY: 'status',
      VALUE: {
        ACTIVE: 'active',
        CANCELED: 'canceled',
        PAUSED: 'paused',
        CURRENT: 'current',
        PAID: 'paid',
      },
    },

    CREATED: {
      KEY: 'created',
    },

    MODIFIED: {
      KEY: 'modified',
    },
  },
}
