import { initializeApp, getApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { CONFIG } from '../config/config'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
function createFirebaseApp() {
  try {
    return getApp()
  } catch {
    return initializeApp(CONFIG.FIREBASE.APP)
  }
}

const app = createFirebaseApp()
const firestore = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)
const functions = getFunctions(app)
const runLocal = process.env.REACT_APP_LOCAL === 'LOCAL'

if (runLocal) {
  connectFirestoreEmulator(firestore, 'localhost', 8081)
  connectAuthEmulator(auth, 'http://localhost:9099', {
    disableWarnings: true,
  })
  connectStorageEmulator(storage, 'localhost', 9199)
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

const FirebaseApp = {
  app,
  firestore,
  auth,
  storage,
  functions,
}
export default FirebaseApp
