import { UseIonRouterResult, useIonRouter } from '@ionic/react'
import { InfoWindowF, MarkerF } from '@react-google-maps/api'
import moment from 'moment'
import { capitalize, createString } from '../../../../helpers/helpers'
import { IEventInterface } from '../../../../models/events/event.interface'
import {
  selectMapDataR,
  selectMapFocusedMarkerIdR,
  setMapFocusedMarkedIdAc,
} from '../../../../store/filters/filterSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectOrganizers } from '../../../../store/organizers/organizersSlice'

type IProps = {
  onEventMarkerClick: (router: UseIonRouterResult, eventId: IEventInterface['id']) => void
  isOrganizers?: boolean
  searchedLocation: {
    lat: number
    lng: number
  } | null
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
/**
 * @TODO Document this
 */
const Markers = (props: IProps) => {
  // Hooks and vars
  const router = useIonRouter()

  const { onEventMarkerClick, searchedLocation } = props
  const organizers = useAppSelector(selectOrganizers)

  const dispatch = useAppDispatch()
  const events = useAppSelector(selectMapDataR)
  const focusedMarkedId = useAppSelector(selectMapFocusedMarkerIdR)

  return (
    <>
      {searchedLocation ? (
        <MarkerF
          position={{
            lat: searchedLocation.lat,
            lng: searchedLocation.lng,
          }}
          title="Home"
          icon="/assets/og_icons/markerIcon.svg"
        />
      ) : null}

      {props.isOrganizers
        ? organizers.map((organizer) => (
            <div key={organizer.id}>
              <MarkerF
                onMouseOut={() => dispatch(setMapFocusedMarkedIdAc(null))}
                onMouseOver={() => dispatch(setMapFocusedMarkedIdAc(organizer.id))}
                position={{
                  lat: Number(organizer.userLat),
                  lng: Number(organizer.userLong),
                }}
                onClick={() => onEventMarkerClick(router, organizer.id)}
                icon="/assets/cp_icons/FinalMapPin.svg"
              >
                {focusedMarkedId === organizer.id && (
                  <InfoWindowF
                    position={{
                      lat: Number(organizer.userLat),
                      lng: Number(organizer.userLong),
                    }}
                  >
                    <div className="marker-tooltip">
                      {!organizer.userFirstName && !organizer.userLastName
                        ? organizer.userName || ''
                        : `${organizer.userFirstName} ${organizer.userLastName}`}
                    </div>
                  </InfoWindowF>
                )}
              </MarkerF>
            </div>
          ))
        : events.map((currEvent) => {
            if (!currEvent.competitionLat || !currEvent.competitionLong) return null

            return (
              <div key={JSON.stringify(currEvent)}>
                <MarkerF
                  onMouseOut={() => dispatch(setMapFocusedMarkedIdAc(null))}
                  onMouseOver={() => dispatch(setMapFocusedMarkedIdAc(currEvent.id))}
                  position={{
                    lat: Number(currEvent.competitionLat),
                    lng: Number(currEvent.competitionLong),
                  }}
                  onClick={() => onEventMarkerClick(router, currEvent.id)}
                  icon="/assets/cp_icons/FinalMapPin.svg"
                >
                  {focusedMarkedId === currEvent.id && (
                    <InfoWindowF
                      position={{
                        lat: Number(currEvent.competitionLat),
                        lng: Number(currEvent.competitionLong),
                      }}
                    >
                      <div className="marker-tooltip">
                        {capitalize(
                          createString([
                            currEvent.category,
                            currEvent?.tags?.find((tag) => tag === 'All')
                              ? 'All Disciplines'
                              : currEvent.tags?.toString(),
                            currEvent.registrationOpenDate
                              ? moment(currEvent.registrationOpenDate).format('MMM D')
                              : '',
                          ])
                        )}
                      </div>
                    </InfoWindowF>
                  )}
                </MarkerF>
              </div>
            )
          })}
    </>
  )
}

export default Markers
