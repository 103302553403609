// Component imports
import { HandleModalContext } from '../../layout/mainlayout/MainLayout'
// Constants
import { useContext, useEffect, useState } from 'react'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { IUserDocument } from '../../models/user-documents/user-documents.interface'

import FirestoreService from '../../services/firestoreService'

import helpers from '../../commonHelpers/helpers'
import { createString } from '../../helpers/helpers'
import { getConvertedData } from '../../models/interface.helper'
import MainModal from './common/MainModal'
import fakeDocumentList from '../../fakeData/fakeDocumentList'
import { ILightboxProps } from '../../types/competitor_types'
import LightboxCommonComponent from '../common/dialogs/full-screen/LightboxCommonComponent'
import { IRiderTeamMemberInterface } from '../../models/rider-team-member/riderTeamMember.interface'
import { RiderTeamMemberModel } from '../../models/rider-team-member/riderTeamMember.model'
import { where } from 'firebase/firestore'
import { useAppSelector } from '../../store/hooks'
import { selectProfileData } from '../../store/user/userSlice'
import useGetEventData from '../../hooks/users/common/useGetEventData'
import { createMailLog, generatePaperworkDocument } from '../../helpers/sign'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../models/registrations-by-day/registrationByDay.model'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IViewModalProps = {
  show: boolean
  dataToPassOn: {
    docIndex: any
    handleDataUpdate: any
    activeTab: string
    type: string
    documentUrl: string
    data: any
  }
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  openLightBox?: any
  setLightBox?: any
  handleDataUpdate?: (row: number, activeTab: string) => void
  activeTab?: string
}
const SignDocumentModal = (props: IViewModalProps) => {
  const toastFunctions = useToasterHelper()
  const { getAllData } = useGetEventData()

  const userData = useAppSelector(selectProfileData)

  const [loading, setLoading] = useState(false)
  const [lightBox, setLightBox] = useState<ILightboxProps>({ visible: false, slides: [] })
  const [registrationsByDay, setRegistrationsByDay] = useState<
    IRegistrationByDayInterface[] | null
  >(null)

  const { docIndex, handleDataUpdate, activeTab } = props.dataToPassOn
  const data = props.dataToPassOn?.data as IUserDocument
  const isCalledFromRegistration = props.dataToPassOn?.type === 'Registration'

  const handleAnnex = () => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.REVIEW_ANNEX, {
      data: {
        eventId: data.eventId ?? '',
        eventName: data.eventName ?? '',
      },
      dataToPassOn: props.dataToPassOn,
    })
    handleModalContext?.handleModal(false, MODAL_CONSTS.SIGN_DOCUMENT)
  }

  const handleModalContext = useContext(HandleModalContext)

  async function getRiderTeamMembersFromDb(registrationId: string) {
    let riderTeamMembers: IRiderTeamMemberInterface[] = []
    try {
      const ticketSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ]
      )

      if (ticketSnapShots.size) {
        ticketSnapShots.forEach((currDoc) => {
          riderTeamMembers.push(
            getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return riderTeamMembers
    }
  }

  const getRegistrationsByDay = async () => {
    let registrationsByDay_: IRegistrationByDayInterface[] = []

    const registrationsByDaySnaps = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      [where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', data.eventId)]
    )

    registrationsByDaySnaps.docs.forEach((currDoc) => {
      registrationsByDay_.push(
        getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    setRegistrationsByDay(registrationsByDay_)
  }
  useEffect(() => {
    getRegistrationsByDay().then()
  }, [])

  const handleSendMail = async () => {
    let registrationByDay: IRegistrationByDayInterface | null = null

    try {
      if (!data.eventId) return null
      setLoading(true)
      const ridersTeamMembersInDb__ = await getRiderTeamMembersFromDb(data?.registrationDocId ?? '')

      const currRidersTeamMember = ridersTeamMembersInDb__.find(
        (member) => member.teamMemberName === data.signatoryName
      )

      registrationsByDay?.forEach((registrationByDay_) => {
        if (registrationByDay_.id === currRidersTeamMember?.registrationByDayDocId) {
          registrationByDay = registrationByDay_
        }
      })

      if (!registrationByDay) throw new Error('Error registration by day not found')

      if (!currRidersTeamMember) throw new Error('Error get Rider Team Member')

      function getDocumentType(): string {
        if (data.documentOriginalName?.includes('USEF Entry')) {
          return 'usefentry'
        }
        return data.documentOriginalName?.split(' ')[0].toLowerCase() || ''
      }

      const paperwork = fakeDocumentList.find((d) => d.document_type === getDocumentType())

      if (!paperwork) throw new Error('Error getting paperwork')

      const eventData = await getAllData(data.eventId, ['v01_event_details'])

      if (!eventData || !eventData.v01_event_details)
        throw new Error('Error getting v01_event_details')

      await generatePaperworkDocument({
        paperwork,
        currRidersTeamMember,
        registrationByDay,
        userData,
        eventId: data.eventId,
        EventDetails: eventData.v01_event_details,
      })

      const teamMembersWithMailLog = await createMailLog({
        ridersTeamMembers: [currRidersTeamMember],
        teamMemberId: currRidersTeamMember.teamMemberId ?? '',
        paperworkDocument: paperwork,
      })

      if (!teamMembersWithMailLog) throw new Error('Error get Rider Team Member')

      await FirestoreService.updateItem(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        teamMembersWithMailLog[0].id,
        new RiderTeamMemberModel(teamMembersWithMailLog[0]).toFirestore()
      )

      handleDataUpdate(docIndex, activeTab)
      setLoading(false)
      toastFunctions.success({ message: MESSAGES_CONST.SIGNED_SUCCESSFULLY })
      handleModalContext?.handleModal(false, MODAL_CONSTS.SIGN_DOCUMENT)
    } catch (error: any) {
      console.log('=>(SignDocumentModal.tsx:151) e', error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      setLoading(false)
      helpers.logger({
        message: error?.message,
      })
    }
  }

  const downloadPdf = (link: string) => {
    var a = document.createElement('a')
    a.setAttribute('download', 'sample.pdf')
    a.setAttribute('href', link)
    a.setAttribute('target', '_blank')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const handleLightBox = (props: ILightboxProps) => {
    setLightBox({
      ...props,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param documentName of the paperwork doc whose image needs to be open in lightbox
   * @info Opens lightbox with paperwork document image
   */
  const openLightBox = (documentName: string) => {
    var index = 0
    if (documentName === 'General Liability Waiver') {
      index = 0
    } else if (documentName === '') {
      index = 1
    } else if (documentName === 'USEF Waiver and Release of Liability') {
      index = 2
    } else if (documentName === 'USEF Entry Agreement') {
      index = 3
    } else if (documentName === 'USDF Waiver and Release of Liablity') {
      index = 4
    } else if (documentName === 'USEA Waiver and Release of Liability') {
      index = 5
    } else if (documentName === 'USHJA Waiver and Release of Liability') {
      index = 6
    }

    let paperworkImages = fakeDocumentList[index].document_image

    if (!Array.isArray(paperworkImages)) paperworkImages = [paperworkImages]

    const slides = paperworkImages.reduce((acc: any[], current: string) => {
      acc.push({ src: current, title: documentName })
      return acc
    }, [])

    handleLightBox({
      visible: true,
      slides,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Document this
   */
  // Closes lightbox
  const onLightBoxClose = () => {
    handleLightBox({ visible: false, slides: [] })
  }

  return (
    <MainModal
      title={isCalledFromRegistration ? 'More' : 'Review and Sign'}
      show={props.show}
      type="SIGN_DOCUMENT"
      size="md"
      footerClassName="justify-between pt-6"
      buttons={[
        ...(!isCalledFromRegistration
          ? [
              {
                label: 'Sign',
                className: '',
                bgClass: 'bg-SeabiscuitMainThemeColor',
                fullWidth: true,
                onClick: handleSendMail,
                onHoverClass: 'hover:bg-SeabiscuitMainThemeColorDark',
                textClass: 'text-white',
                disabled: loading,
                loading,
              },
            ]
          : []),
        {
          label: 'Cancel',
          fullWidth: true,
          bgClass: '!bg-SeabiscuitLightThemeColor',
          borderClass: '!border border-transparent',
          textClass: '!text-SeabiscuitLightTextColor',
          disabled: loading,
          onClick: () => handleModalContext?.handleModal(false, MODAL_CONSTS.SIGN_DOCUMENT),
        },
      ]}
    >
      <div>
        <div className="flex items-center border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] px-2 py-0.5 mt-2">
          <img
            onError={(e) => ((e.target as any).src = '/assets/Pegasus_app_logo.svg')}
            src={
              data?.eventLogo && data?.eventLogo !== ''
                ? data?.eventLogo
                : '/assets/Pegasus_app_logo.svg'
            }
            className="w-[42px] h-[42px] rounded-lg shrink-0 mr-3  my-1"
            alt="Pegasus app logo"
          />
          <span
            className="w-full text-ellipsis text-SeabiscuitDark200ThemeColor font-normal overflow-hidden capitalize whitespace-wrap"
            title={'hello'}
          >
            {createString(data.eventName)}
          </span>
        </div>
        <div className="flex-col items-center text-SeabiscuitDark200ThemeColor border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] mt-2 p-4">
          <div className="font-bold mb-3">Document Details:</div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                <img src="/assets/og_icons/DocumentFlat.svg" className="w-7 h-7" alt="logo" />
              </span>
              <span
                className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                title={'General Liability Waiver'}
              >
                {data.documentOriginalName}
              </span>
            </div>
            <div
              className="underline cursor-pointer"
              onClick={() => {
                if (isCalledFromRegistration) {
                  openLightBox(data.documentOriginalName ?? '')
                } else downloadPdf(props.dataToPassOn.documentUrl)
              }}
            >
              View {'>'}
            </div>
          </div>

          {data.documentOriginalName === 'General Liability Waiver' && (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                  <img src="/assets/og_icons/DocumentFolded.svg" className="w-7 h-7" alt="logo" />
                </span>
                <span
                  className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                  title={'Signing as'}
                >
                  Annex
                </span>
              </div>
              <div className="underline cursor-pointer" onClick={handleAnnex}>
                View {'>'}
              </div>
            </div>
          )}

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                <img src="/assets/og_icons/Quill Pen-1.svg" className="w-7 h-7" alt="logo" />
              </span>
              <span
                className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                title={'Signing as'}
              >
                Signing as:
              </span>
            </div>
            <div>{data.signatoryDefaultRole}</div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                <img src="/assets/og_icons/People-1.svg" className="w-7 h-7" alt="logo" />
              </span>
              <span
                className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                title={'On Behalf of'}
              >
                On Behalf of:
              </span>
            </div>
            <div>{data.signatoryName}</div>
          </div>
        </div>
        <div className="flex border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] p-2 mt-2">
          <span className="max-h-[30px] mt-2 p-1 rounded-  shrink-0 mr-3 overflow-hidden flex items-center justify-center">
            <img src="/assets/og_icons/People-1.svg" className="w-7 h-7" alt="peopleIcon" />
          </span>
          <div className="flex flex-col ">
            <span
              className="w-40 mb-4 mt-2 text-ellipsis text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
              title={'Who must sign?'}
            >
              {'Who must sign?'}
            </span>

            <span className="font-normal leading-[25px] mb-3  text-SeabiscuitDark200ThemeColor">
              All people attending the event in person, as well as riders, trainers, owners, coaches
              or legal guardians associated with a horse competing in this event.
            </span>
          </div>
        </div>
        <div className="flex  border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] p-2 mt-2">
          <span className="max-h-[30px] p-1 mt-2 rounded-  shrink-0 mr-3 overflow-hidden flex items-center justify-center">
            <img src="/assets/og_icons/Error-1.svg" className="w-7 h-7" alt="errorIcon" />
          </span>
          <div className="flex flex-col ">
            <span
              className="w-40 mt-2 text-ellipsis text-SeabiscuitDark200ThemeColor font-normal leading-[25px] overflow-hidden mb-4 whitespace-nowrap"
              title={'Disclaimer'}
            >
              {'Disclaimer'}
            </span>
            <span className="font-normal leading-[25px] mb-3 text-SeabiscuitDark200ThemeColor">
              By clicking sign, you confirm you have read and agree to the terms and conditions in
              both this document and annex.
            </span>
          </div>
        </div>
      </div>

      {/* -----------LightBox----------- */}
      <LightboxCommonComponent
        visible={lightBox.visible}
        onClose={onLightBoxClose}
        slides={lightBox.slides}
      />
    </MainModal>
  )
}

export default SignDocumentModal
