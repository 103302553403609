// Types

import { FC, ReactNode } from 'react'

type IProps = {
  title: string
  onSubmit?: any
  minHeight?: string
  width?: string
  margin?: string
  description?: string
  children: React.ReactElement
  dividerStyles?: React.CSSProperties
  headerButtonsContainer?: React.ReactElement | null
  removeBreakLine?: boolean
  noPaddingRt?: boolean
}

interface ChildComponentProps {
  title: string
  description?: string
  children: ReactNode
  headerButtonsContainer: ReactNode
  noPaddingRt?: boolean
}

const ChildComponent: FC<ChildComponentProps> = ({
  title,
  description,
  children,
  headerButtonsContainer,
  noPaddingRt,
}) => (
  <>
    <div className="mb-2 md:mb-0 flex items-start flex-wrap md:flex-nowrap">
      <div className="grow mb-3">
        <h1 className="text-SeabiscuitDark200ThemeColor text-[19px] font-bold mb-1 mt-3 ml-1">
          {title}
        </h1>
        <div className="text-SeabiscuitLightParagraphTextColor font-normal text-md max-w-[92%]">
          {description}
        </div>
      </div>
      {headerButtonsContainer ? (
        <div className="w-fit mb-2 md:mb-0">{headerButtonsContainer}</div>
      ) : null}
    </div>
    <div
      className={`${
        ['Paid bills', 'Unpaid bills', 'Refunds'].find((data) => data === title)
          ? 'overflow-auto h-[80%] bg-white'
          : 'bg-white'
      }`}
      style={{ ...(noPaddingRt ? {} : { paddingRight: '8px' }) }}
    >
      {children}
    </div>
  </>
)

const WrapperWithHeader = (props: IProps) => {
  const { title, description, children } = props

  return (
    <form
      onSubmit={props?.onSubmit}
      style={{
        ...(props?.minHeight && { minHeight: props?.minHeight }),
        ...(props?.width && { width: props?.width }),
        ...(props?.margin && { margin: props?.margin }),
      }}
      className={`${
        ['Paid bills', 'Unpaid bills', 'Refunds'].find((data) => data === title) ? 'h-[85%]' : ''
      }`}
    >
      <ChildComponent
        children={children}
        description={description}
        headerButtonsContainer={props?.headerButtonsContainer}
        noPaddingRt={props?.noPaddingRt}
        title={title}
      />
    </form>
  )
}

export default WrapperWithHeader
