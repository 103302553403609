import { disciplineData } from '../../../../fakeData/disciplineList'
import { IAppState } from '../../../../store/system/systemSlice'

export const OrganizationsFiltersCategories = (useMode: IAppState['useMode']) => {
  return [
    // {
    //   name: 'associations',
    //   icon: '/assets/og_icons/Associations.svg',
    //   activeIcon: '/assets/og_icons/Associations-2.svg',
    //   isVisible: useMode === 'competitor' || !useMode,
    // },
    // {
    //   name: 'facilities',
    //   icon: '/assets/og_icons/Facilities.svg',
    //   activeIcon: '/assets/og_icons/Facilities-2.svg',
    //   isVisible: useMode === 'competitor' || !useMode,
    // },
    // {
    //   name: 'barns',
    //   icon: '/assets/og_icons/Barns.svg',
    //   activeIcon: '/assets/og_icons/Barns-2.svg',
    //   isVisible: useMode === 'competitor' || !useMode,
    // },
    {
      name: 'following',
      icon: '/assets/og_icons/Bookmark.svg',
      activeIcon: '/assets/og_icons/Bookmark-2.svg',
      isVisible: useMode === 'competitor',
    },
  ]
}

export const OrganizationsFilters = {
  discipline: [{ label: 'All', value: 'all' }, ...disciplineData],
}
