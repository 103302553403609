import React, { FC, useEffect, useState } from 'react'

import { Tooltip } from '@mui/material'
import { where } from 'firebase/firestore'

import MainModal from '../common/MainModal'
import { MembershipIcon } from '../../membership/MembershipIcon/MembershipIcon'
import ViewsLoader from '../../loader/ViewsLoader'

import { IUserInterfaceExtended } from '../../../store/storeHelpers/userSlice/types'

import { getUserFullName } from '../../../helpers/helpers'
import FirestoreService from '../../../services/firestoreService'

import { IMembershipsInterface } from '../../../models/users/user.interface'
import { ISubscribersInterface } from '../../../models/subscribers/subscribers.interface'
import { getConvertedData } from '../../../models/interface.helper'
import { SubscribersModel } from '../../../models/subscribers/subscribers.model'

import { MODAL_CONSTS } from '../../../const/modal-const'
import { CONST } from '../../../const/const'

interface JoinModalProps {
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: { memberships: IMembershipsInterface[]; organiserProfile: IUserInterfaceExtended }
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const JoinModal: FC<JoinModalProps> = ({ handleModal, dataToPassOn }) => {
  const { organiserProfile, memberships } = dataToPassOn

  const [subscribers, setSubscribers] = useState<ISubscribersInterface[]>([])
  const [loading, setLoading] = useState(false)

  const getSubscribers = async () => {
    setLoading(true)
    let subscribers_: ISubscribersInterface[] = []

    const subscribersSnaps = await FirestoreService.filterItems(COLLECTIONS.SUBSCRIBERS.NAME, [
      where(COLLECTIONS.SUBSCRIBERS.FIELDS.OWNER_ID.KEY, '==', organiserProfile.id),
    ])

    subscribersSnaps.docs.forEach((currDoc) => {
      subscribers_.push(getConvertedData(SubscribersModel.fromFirestoreDoc(currDoc).toObject()))
    })

    setSubscribers(subscribers_)
    setLoading(false)
  }

  useEffect(() => {
    getSubscribers().then()
  }, [])

  return (
    <MainModal
      customTitle={
        <div className="text-SeabiscuitDark200ThemeColor">
          <h4 className="font-bold text-[25px] capitalize">
            Join {getUserFullName(organiserProfile)}
          </h4>
          <p className="opacity-50">Select an item to join</p>
        </div>
      }
      show={true}
      type="JOIN"
      size="4xl"
      className="!px-0"
    >
      <div className="text-SeabiscuitDark200ThemeColor">
        {loading ? (
          <div className="relative py-10">
            <ViewsLoader />
          </div>
        ) : (
          <div className="flex flex-wrap gap-x-[2%] gap-y-6 py-6">
            {memberships?.map((membership, index) => {
              const amount = subscribers?.filter(
                (subscriber) => subscriber.membershipId === membership.id
              )

              const isSoldOUt = membership?.amount > 0 && amount.length > membership?.amount
              return (
                <div
                  key={index}
                  className="flex flex-col w-full sm:w-[49%] lg:w-[23.5%] p-[18px] border border-SeabiscuitLightThemeColorD3 rounded-xl"
                >
                  <div className="mb-5 flex justify-between items-center gap-2">
                    <div className="text-SeabiscuitDark200ThemeColor/50">
                      <span className="text-lg text-SeabiscuitDark200ThemeColor">
                        ${membership.price ?? 0}
                      </span>
                      <p className="text-[12px]">{membership?.type ?? 'N/A'}</p>
                    </div>
                    <div className="bg-SeabiscuitGrayThemeColor rounded-full w-10 p-2">
                      <MembershipIcon membershipType={membership?.type} />
                    </div>
                  </div>
                  <h6 className="font-bold text-[18px]">{membership.name}</h6>
                  <p className="text-[12px] text-SeabiscuitDark200ThemeColor/50">
                    {membership?.pricingDescription ?? 'N/A'}
                  </p>
                  <hr className="my-5 border-SeabiscuitLightGrayThemeColor" />
                  <div className="text-[14px] mb-2">
                    {membership.description && membership.description.length > 100 ? (
                      <p>
                        {membership.description.slice(0, 100)}...
                        <Tooltip
                          title={
                            <p className="text-[14px] max-w-[250px]">{membership.description}</p>
                          }
                          placement="top"
                          arrow
                        >
                          <button type="button" className="opacity-50 underline hover:no-underline">
                            More
                          </button>
                        </Tooltip>
                      </p>
                    ) : (
                      <p>{membership.description}</p>
                    )}
                  </div>
                  <button
                    disabled={isSoldOUt}
                    type="button"
                    className="mt-auto disabled:opacity-70 disabled:bg-SeabiscuitGrayThemeColor disabled:text-SeabiscuitDark200ThemeColor rounded-md bg-SeabiscuitGrayThemeColor p-2 w-full hover:bg-SeabiscuitMainThemeColor hover:text-white transition-all"
                    onClick={() => {
                      handleModal?.(false, MODAL_CONSTS.JOIN)
                      handleModal?.(true, MODAL_CONSTS.PURCHASE_MEMBERSHIP, {
                        membership,
                        organiserProfile,
                      })
                    }}
                  >
                    {isSoldOUt ? 'Sold out' : 'Select'}
                  </button>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </MainModal>
  )
}
