import { useEffect, useState } from 'react'

// Styles
import 'react-datepicker/dist/react-datepicker.css'
import './HomeRootAllFilters.css'

// Third party
import { ArrowForwardIos, PinDropOutlined } from '@mui/icons-material'
import clsx from 'clsx'

// Components
import HomeRootFiltersCategoryCard from './HomeRootFiltersCategoryCard'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

// Services

// Models

// Helpers
import { getConvertedData } from '../../../models/interface.helper'

// Constants
import { CONST } from '../../../const/const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import {
  selectedfilters,
  selectedOrganizerFilter,
  setActiveFilters,
  setOrganizerFilters,
} from '../../../store/filters/filterSlice'
import CustomDatePicker from '../../common/inputs/CustomDatePicker'
import { tags } from '../../events/dialogs/create-show-initial/Tags'
import MainModal from '../../modals/common/MainModal'
import { filters } from './data/AllFilters'
import { selectUseMode } from '../../../store/system/systemSlice'
import { OrganizationsFiltersCategories } from './data/OrganizationsFilters'
// import RangeSlider from '../../common/sliders/RangeSlider';

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5

// Types
type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

// Constants
const ALL = 'All'
const EVENT = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS
const DEFAULT_CATEGORY = EVENT.FIELDS.CATEGORIES.VALUES.CLINIC
const CURRENT_DATE = (() => {
  let date = new Date(Date.now())
  date.setHours(0, 0, 0, 0)
  return date
})()

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5

const HomeRootOrganizersFilters = (props: Props) => {
  const dispatch = useAppDispatch()
  const existFilters = useAppSelector(selectedOrganizerFilter)

  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [priceRange, setPriceRange] = useState<string | null>('0')
  const [disciplineFilter, setDisciplineFilter] = useState<string[]>([])
  const [dateCategoryFilter, setDateCategoryFilter] = useState<string[]>([])
  const [categoriesFilter, setCategoriesFilter] = useState<string[]>([DEFAULT_CATEGORY])
  const [allCategories, setAllCategories] = useState<
    { name: string; icon: string; activeIcon: string; isVisible: boolean }[]
  >([])

  const [existFiltersLocal, setExistFiltersLocal] = useState(existFilters)
  const filtersInRedux = useAppSelector(selectedOrganizerFilter)
  const useMode = useAppSelector(selectUseMode)

  useEffect(() => {
    setAllCategories(
      OrganizationsFiltersCategories(useMode).filter((category) => category.isVisible)
    )
  }, [useMode])

  useEffect(() => {
    if (filtersInRedux) {
      const { groupFilters } = filtersInRedux
      setCategoriesFilter(groupFilters.categoriesFilter)
      setDisciplineFilter(groupFilters.disciplineFilter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Clears the filters applied
   */
  const handleClearFilters = () => {
    setCategoriesFilter([])
    setDisciplineFilter([])
    setDateCategoryFilter([])
    setStartDate(null)
    setEndDate(null)
    setPriceRange('0')

    const ele: NodeListOf<HTMLElement> = document.getElementsByName('checkbox')
    for (var i = 0; i < ele.length; i++) {
      if ((ele[i] as HTMLInputElement).type === 'checkbox')
        (ele[i] as HTMLInputElement).checked = false
    }

    setExistFiltersLocal({
      ...existFiltersLocal,
      groupFilters: {
        ...existFiltersLocal.groupFilters,
        categoriesFilter,
        disciplineFilter,
      },
    })
    // dispatch(setEventFilter({ categoriesFilter, disciplineFilter, dateCategoryFilter, startDate, endDate, priceRange } ?? []))
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO todo
   */
  useEffect(() => {
    if (existFilters === null) {
      handleClearFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existFilters])

  // Functions

  /** @info Handles the change of radius, and sets it to state */
  const handlepriceRange = (value: number) => {
    setPriceRange(value.toFixed())
  }

  if (0) console.log({ handlepriceRange })

  /**
   * @info creates groupFilter key on the basis on filters selected,
   * and pushed them into redux
   */
  const setGroupFilters = () => {
    dispatch(
      setOrganizerFilters({
        ...existFilters,
        groupFilters: {
          status: true,
          categoriesFilter: categoriesFilter,
          disciplineFilter: disciplineFilter,
        },
      })
    )
  }

  /* @info Handles click on card */
  const handleChangeCard = (categoryName: string, type: string) => {
    setStartDate(null)
    setEndDate(null)
    if (type === 'cat') setCategoriesFilter([categoryName])
    else if (type === 'date') {
      const newDateCatFileter = [...dateCategoryFilter]
      const dateCatFileterIndex = dateCategoryFilter.findIndex((d) => d === categoryName)
      if (dateCatFileterIndex < 0) {
        newDateCatFileter.push(categoryName)
      } else {
        newDateCatFileter.splice(dateCatFileterIndex, 1)
      }
      setDateCategoryFilter(newDateCatFileter)
    }
  }

  /* @info Handles click on checkboxes */
  const handleChangeDiscipline = (name: string) => {
    const allClicked = name === ALL
    const exists = !!disciplineFilter.filter((item) => item === name).length

    let allTagIsChecked = false
    let allCheckedExceptAll = false
    let remainingData: string[] = []

    if (allClicked && !exists) return setDisciplineFilter([...tags])
    else if (exists && allClicked) return setDisciplineFilter([])

    if (!exists) {
      remainingData = [...disciplineFilter, name]
      allCheckedExceptAll = remainingData?.length === tags.length - 1
      allCheckedExceptAll = allCheckedExceptAll && remainingData.indexOf('All') === -1

      /** If all checkboxes are checked,
              except the checkbox with "All" name then the check all too */
      if (allCheckedExceptAll) setDisciplineFilter([...tags])
      else setDisciplineFilter((prevState) => [...prevState, name])

      return
    }

    if (allClicked) return setDisciplineFilter([])
    else {
      remainingData = disciplineFilter.filter((item) => item !== name)
      allTagIsChecked = remainingData.indexOf(ALL) !== -1

      /** If "All" tag is checked,
             but not all the tags are check then uncheck "All" checkbox */
      if (allTagIsChecked) remainingData.splice(tags.indexOf(ALL), 1)

      setDisciplineFilter([...remainingData])
    }
  }

  /**
     @param visible true or false
     @info Handles the visiblility of location filter modal
    */
  const handleLocationFilterModal = (visible: boolean) => {
    props.handleModal(visible, MODAL_CONSTS.LOCATION)
    props.handleModal(false, MODAL_CONSTS.FILTERS_ORGANIZERS)
  }

  const handleSubmit = async () => {
    setGroupFilters()
    props.handleModal(false, MODAL_CONSTS.FILTERS_ORGANIZERS)
  }

  return (
    <MainModal
      title={'Filter Organizers'}
      titleClassName="border-b-[#1f41734d] border-b-[1px] border-solid pb-2 justify-center !text-[15px]"
      show={props.show}
      type="FILTERS_ORGANIZERS"
      size="lg"
      closeButtonClassName="right-[12px]"
      customPadding={true}
      footerClassName="!flex-row justify-between border-t-[#1f41734d] border-t-[1px] border-solid"
      buttons={[
        {
          label: 'Clear filters',
          className: 'md:max-w-[174px] m-4',
          bgClass: 'bg-transparent cursor-pointer',
          onClick: handleClearFilters,
          borderClass: 'unset',
          textClass: 'text-SeabiscuitDark200ThemeColor underline',
        },
        {
          label: 'Apply',
          bgClass: 'bg-SeabiscuitMainThemeColor cursor-pointer',
          textClass: 'text-white',
          className: 'md:max-w-[174px] m-4',
          onClick: handleSubmit,
        },
      ]}
    >
      <div>
        <h5 className="text-xl  mb-2 font-normal leading-normal text-SeabiscuitDark200ThemeColor p-4 pb-1">
          Category
        </h5>

        <div className="p-4 pt-0">
          {allCategories.length && (
            <div className="flex flex-wrap gap-2 pb-6 border-b border-b-[#1F41731A]">
              {allCategories.map((item, index: number) => {
                return (
                  <HomeRootFiltersCategoryCard
                    type="cat"
                    name={item.name}
                    icon={item.icon}
                    filter={categoriesFilter}
                    activeIcon={item.activeIcon}
                    handleChangeCard={handleChangeCard}
                    key={`${JSON.stringify(item)}${index}`}
                    lastDiv={allCategories.length - 1 === index}
                  />
                )
              })}
            </div>
          )}
          <div className="py-4 rounded-t-md">
            <h5 className="text-xl font-normal leading-normal text-SeabiscuitDark200ThemeColor">
              Discipline
            </h5>
          </div>
          {tags.length && (
            <div className="grid gap grid-cols-2 md:grid-cols-3 gap-1 pb-8 border-b border-b-[#1F41731A]">
              {tags.map((item) => {
                return (
                  <div className="displineCheckBoxfilter" key={JSON.stringify(item)}>
                    <input
                      className="form-check-input rounded_checkboxes appearance-none h-4 w-4 border border-[#D3DAEE] rounded-sm bg-white checked:bg-blue-600 checked:border-none focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      name="checkbox"
                      checked={disciplineFilter.includes(item)}
                      value={item}
                      id={item}
                      onChange={() => handleChangeDiscipline(item)}
                    />
                    <label
                      htmlFor={item}
                      className={clsx(
                        'labelFilter cursor-pointer text-sm',
                        disciplineFilter.includes(item)
                          ? 'text-SeabiscuitMainThemeColor'
                          : 'text-[#122B46]'
                      )}
                    >
                      {item}
                    </label>
                  </div>
                )
              })}
            </div>
          )}

          {/* {/ Location fields /} */}

          {0 ? (
            <>
              <div className="py-4 rounded-t-md">
                <h5 className="text-xl font-normal leading-normal text-SeabiscuitDark200ThemeColor">
                  {' '}
                  Location{' '}
                </h5>
              </div>
              <div
                className="locationBtn flex justify-between items-center cursor-pointer border border-[#BCC6D5] rounded-md p-2 mb-4"
                onClick={() => handleLocationFilterModal(true)}
              >
                <PinDropOutlined className="text-[#BCC6D5]" fontSize="small" />
                <p className="text-SeabiscuitDark200ThemeColor">Select location</p>
                <ArrowForwardIos className="text-[#BCC6D5]" fontSize="small" />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </MainModal>
  )
}

export default HomeRootOrganizersFilters
