import { useEffect, useRef, useState } from 'react'

// Third party
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'

// Types
import {
  IEventDetailData,
  IPaperworkTab,
} from '../../../../models/event-drafts/event-draft.interface'
import { ILightboxProps } from '../../../../types/competitor_types'

// Constants
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import fakeDocumentList, { IDocument } from '../../../../fakeData/fakeDocumentList'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { ITeamMember, IUserInterface } from '../../../../models/users/user.interface'
import {
  IEventsRegisterData,
  ISelectedEvent,
  selectedEvent,
} from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

import { AutorenewRounded } from '@mui/icons-material'

import helpers from '../../../../commonHelpers/helpers'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { CustomError } from '../../../../helpers/helpers'
import {
  IPaperworkTab_,
  IRegistrationTabs,
  ISignatoryForMail as isfm,
} from '../../../../models/event-registered-users/event-registered-users.interface'
import { isMinorFn } from '../../../../models/interface.helper'
import {
  IRiderTeamMemberInterface,
  ISignedStatuses,
} from '../../../../models/rider-team-member/riderTeamMember.interface'
import {
  selectFilteredPaperworkDocuments,
  selectPaperworkTabData,
  selectRidersTeamMembersR,
  selectSendMailLoadingR,
  selectSignTabDataR,
  setSendMailLoading,
  setSignTab,
} from '../../../../store/registration/registrationSlice'
import { selectProfileData } from '../../../../store/user/userSlice'
import { DOCUMENT_TYPES } from './CompetitorEventsRegisterPaperworkTab'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'
import { MEMBER_ROLES } from '../../../../const/ui/ui-register.const'
import { createMailLog, eligibleToSign } from '../../../../helpers/sign'
import { getAllClauses } from '../../../../helpers/clauses'

/**
 * @param SIGNED Means user have got email and have signed
 * @param MAIL_SENT Means user have sent email
 * @param NOT_SIGNED Means user have sent email but the user haven't signed yet
 * @param REMOVED Means user have sent email, but have been removed from list on paperwork tab
 */

type ICompetitorEventRegisterSignTabProps = {
  step?: number
  title?: string
  eventName?: string
  description?: string
  message: string | null
  signTab?: IPaperworkTab
  documents: IPaperworkTab
  paperworkTab: IPaperworkTab
  usersHolder: IUserInterface[]
  saveRidersTeamMembers: (args: {
    ridersTeamMembersToAdd?: IRiderTeamMemberInterface[]
    ridersTeamMembersToDelete?: IRiderTeamMemberInterface[]
    ridersTeamMembersToUpdate?: IRiderTeamMemberInterface[]
  }) => void
  registerFormData?: IEventsRegisterData
  allRiderTeamMembers: ISignatoryForMail[]
  mergeRidersTeamMembersWithTabData: (
    paperworkTabData_: IRegistrationTabs['IPaperworkTab'][],
    ridersTeamMembers: IRiderTeamMemberInterface[]
  ) => IPaperworkTab_[]
  handleLightBox: (props: ILightboxProps) => void
  EventPaperwork?: ISelectedEvent['EventPaperwork']
  handleDocument: (args: { index: number; signedStatus: ISignedStatuses }) => void
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  userId: string
}

export interface ISignatoryForMail extends isfm {}

// Constants
const widths = ['w-[300px] shrink-0', 'w-[135px] shrink-0', 'flex-grow', 'w-[160px] shrink-0']

const CompetitorEventRegisterSignTab = (props: ICompetitorEventRegisterSignTabProps) => {
  // Hooks and vars
  const signTabData = useAppSelector(selectSignTabDataR)
  const ridersTeamMembers = useAppSelector(selectRidersTeamMembersR)
  const paperworkTabData = useAppSelector(selectPaperworkTabData)
  const filteredPaperworksDocuments = useAppSelector(selectFilteredPaperworkDocuments)
  const sendMailLoading = useAppSelector(selectSendMailLoadingR)
  const [message, setMessage] = useState<string | null>(null)

  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectProfileData)
  const [allClauses, setAllClauses] = useState<any[]>([])
  const { EventDetails } = useAppSelector(selectedEvent)
  const eventId = useAppSelector(selectedEvent).basicEventDetails.id

  const toastShown = useRef(false)
  const toastMethods = useToasterHelper()

  const [registerFormData, setRegisterFormData] = useState(() => props.registerFormData)

  useEffect(() => {
    let showGuardianMessage = false
    let eventOwnerIsMinor = false
    let eventOwnerAdded = false

    let paperworkTabData_ = cloneDeep(paperworkTabData)
    if (props.isManage) paperworkTabData_ = props.manageInfo.paperwork

    paperworkTabData_.forEach((currPaperworkTabRow) => {
      currPaperworkTabRow.ridersTeamMembers.forEach((currRidersTeamMember) => {
        if (currRidersTeamMember.teamMemberId === userData.id) eventOwnerAdded = true
      })
    })

    if (eventOwnerAdded) {
      let ownerIsMinor = isMinorFn(userData.userDOB)
      if (ownerIsMinor) eventOwnerIsMinor = true
    }

    paperworkTabData_.forEach((currPaperworkTabRow) => {
      let riderIsMinor = isMinorFn(currPaperworkTabRow.riderDob)

      if (riderIsMinor) {
        const haveGuardian = currPaperworkTabRow.ridersTeamMembers.filter(
          (currRidersTeamMember) => currRidersTeamMember.teamMemberRole === MEMBER_ROLES.GUARDIAN
        )
        if (haveGuardian.length <= 0) showGuardianMessage = true
      }
    })

    dispatch(
      setSignTab({
        showGuardianMessage,
        eventOwnerIsMinor,
        eventOwnerAdded,
      })
    )
  }, [paperworkTabData, props.manageInfo.paperwork, userData, props.isManage])

  useEffect(() => {
    let message_ = null

    switch (true) {
      case signTabData.eventOwnerIsMinor:
        message_ =
          'Your date of birth identifies you as a minor. Your paperwork will be sent to the person you identified as your guardian in the previous section. If you did not identify a guardian, go back and add one now.'
        break

      case !signTabData.eventOwnerAdded:
        message_ =
          'You have no paperwork to sign because you have not listed yourself as a rider or team member. If you are attending this event, please list yourself as a team member to receive paperwork to sign.'
        break

      case signTabData.showGuardianMessage:
        message_ =
          'Your one rider date of birth identifies you as a minor. So paperwork will be sent to the person you identified as minor rider guardian in the previous section. If you did not identify a guardian, go back and add one now.'
        break
    }

    setMessage(message_)
  }, [signTabData])

  useEffect(() => {
    let membersOfOneDayRegistration: ITeamMember[] = []
    let localRegisterFormData: IEventsRegisterData | null = null

    if (props.registerFormData?.events) localRegisterFormData = { ...props.registerFormData }

    if (!localRegisterFormData) return

    localRegisterFormData.events = localRegisterFormData.events?.reduce(
      (acc: IEventDetailData[], currOneDayRegistration) => {
        membersOfOneDayRegistration = []
        currOneDayRegistration.members.forEach((currMember) => {
          if (currMember?.horses?.length) membersOfOneDayRegistration.push(currMember)
        })

        if (membersOfOneDayRegistration.length)
          acc.push({
            ...currOneDayRegistration,
            members: membersOfOneDayRegistration,
          })

        return acc
      },
      []
    )

    setRegisterFormData(localRegisterFormData)
  }, [props.registerFormData])

  useEffect(() => {
    let count: number = 0
    if (!registerFormData?.events || toastShown.current) return

    registerFormData.events?.forEach((currOneDayRegistration: IEventDetailData) => {
      currOneDayRegistration.members.forEach((currMember) => {
        if (!currMember?.horses?.length) count++
      })
    }, [])

    if (!count) return

    toastShown.current = true
    toastMethods.error({
      message: MESSAGES_CONST.MEMBERS_WITHOUT_HORSE.replace('[COUNT]', `${count}`).replace(
        '[MEMBER]',
        count > 1 ? 'members' : 'member'
      ),
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (EventDetails?.owner)
      getAllClauses(EventDetails.owner).then((data) => {
        if (data) setAllClauses(data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EventDetails])

  /**
   * @param index Index of the paperwork doc whose image needs to be open in lightbox
   * @info Opens lightbox with paperwork document image
   */
  const openLightBox = (index: number) => {
    let paperworkImages = fakeDocumentList[1].document_image

    if (!Array.isArray(paperworkImages)) paperworkImages = [paperworkImages]

    const slides = paperworkImages.reduce((acc: any[], current: string) => {
      acc.push({ src: current, title: filteredPaperworksDocuments[index]?.document_name })
      return acc
    }, [])

    props.handleLightBox({
      visible: true,
      slides,
    })
  }

  /**
   * @info Sends mail by taking out the signatories from the document
   */
  const handleSendMail = async (filteredPaperworkIndex: number) => {
    dispatch(
      setSendMailLoading({
        sendMailLoading: true,
        sendMailIndex: filteredPaperworkIndex,
      })
    )

    let paperworkTabData_ = cloneDeep(paperworkTabData)

    if (props.isManage) {
      paperworkTabData_ = props.manageInfo.paperwork
    }

    if (EventDetails && eventId) {
      try {
        let ridersTeamMembers_ = cloneDeep(ridersTeamMembers)

        if (props.isManage) {
          const ridersTeamMembersManage: IRiderTeamMemberInterface[] = []
          props.manageInfo.paperwork.forEach((paperwork) => {
            if (paperwork.ridersTeamMembers.length > 0)
              ridersTeamMembersManage.push(...paperwork.ridersTeamMembers)
          })

          ridersTeamMembers_ = ridersTeamMembersManage
        }

        const userTeamMembersWithMailLog = await createMailLog({
          ridersTeamMembers: ridersTeamMembers_,
          teamMemberId: props.userId,
          paperworkDocument: filteredPaperworksDocuments[filteredPaperworkIndex],
        })

        if (!userTeamMembersWithMailLog) throw new Error('Error get Rider Team Member')

        const paperworkTabData__ = props.mergeRidersTeamMembersWithTabData(
          paperworkTabData_,
          userTeamMembersWithMailLog
        )

        if (props.isManage) {
          props.setManageInfo({ ...props.manageInfo, paperwork: paperworkTabData__ })
        } else {
          props.saveRidersTeamMembers({ ridersTeamMembersToUpdate: userTeamMembersWithMailLog })
        }

        setTimeout(() => {
          dispatch(
            setSendMailLoading({
              sendMailLoading: false,
              sendMailIndex: null,
            })
          )
        }, 1000)
      } catch (error: any) {
        helpers.logger({
          message: CustomError.somethingWentWrong({
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
            devMessage: error?.devMessage ?? error?.message,
            moduleName: 'handleSendMail',
            fileName: 'CompetitorEventRegisterSignTab',
          }),
        })
        dispatch(
          setSendMailLoading({
            sendMailLoading: false,
            sendMailIndex: null,
          })
        )
      }
    }
  }

  const getSignColumn = (
    filteredPaperworkIndex: number,
    paperworkDocument: IDocument
  ): ReactJSXElement => {
    let textToShow
    let canBeSigned = false
    let { notSignedOwner } = eligibleToSign({
      paperworkDocuments: [paperworkDocument],
      userId: props.userId,
      ridersTeamMembers,
      isManage: props.isManage,
      manageInfo: props.manageInfo,
    })
    let loading =
      sendMailLoading.sendMailLoading && sendMailLoading.sendMailIndex === filteredPaperworkIndex
    if (notSignedOwner) {
      textToShow =
        'By clicking sign, you confirm you have read and agree to the terms and conditions in this document and annex.'
      canBeSigned = true
    } else {
      textToShow = 'Signed'
    }

    return (
      <div
        className={clsx(
          'mt-2 py-4 px-4 bg-SeabiscuitGrayThemeColor rounded-lg text-SeabiscuitDark200ThemeColor font-normal items-center mb-1 flex relative',
          notSignedOwner ? 'pr-[30px] justify-between' : 'justify-center',
          widths[2]
        )}
      >
        <div title={textToShow}>{textToShow}</div>

        {loading ? <AutorenewRounded fontSize="small" className="animate-spin mx-auto" /> : null}

        {canBeSigned && !loading ? (
          <button
            disabled={!notSignedOwner || sendMailLoading.sendMailLoading}
            className="border border-solid rounded-2xl bg-transparent border-SeabiscuitDark200ThemeColor ml-4 py-1 px-3 hover:text-white hover:bg-SeabiscuitDark200ThemeColor transition-all disabled:bg-[#ccc] disabled:text-white disabled:border-transparent"
            onClick={() => handleSendMail(filteredPaperworkIndex)}
          >
            Sign
          </button>
        ) : null}
      </div>
    )
  }

  function addClassToDocumentTypes(text: any) {
    const words = text.split(' ')
    const updatedWords = words.map((word: any) => {
      if (DOCUMENT_TYPES.includes(word.toUpperCase())) {
        return word.toUpperCase()
      }
      return word.toLowerCase()
    })
    return updatedWords.join(' ')
  }

  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      {/* Render when the organizer do not add any paperwork in the event */}
      {!filteredPaperworksDocuments.length ? (
        <DataNotAvailable
          mode="text"
          containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
          text="No Paperworks have been added to this event"
        />
      ) : null}

      {!!filteredPaperworksDocuments.length && message ? (
        <div className="text-[#122B46]">{message}</div>
      ) : null}

      {!!filteredPaperworksDocuments.length && !message ? (
        <>
          <header className="hidden lg:flex shrink-0 gap-3 flex-wrap mt-4">
            <div className={clsx('registration_tabs_column_title', widths[0])}>
              Document
              <Tooltip
                title={
                  <h1 className="tooltip_title">
                    Documents the event organizer requires you to sign
                  </h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>

            <div className={clsx('registration_tabs_column_title', widths[1])}>
              Review Document
              <Tooltip
                title={
                  <h1 className="tooltip_title">Click to review the document before signing</h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>

            <div className={clsx('registration_tabs_column_title', widths[1])}>
              Review Annex
              <Tooltip
                title={
                  <h1 className="tooltip_title">Click to review the document before signing</h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>

            <div className={clsx('registration_tabs_column_title', widths[2])}>
              Sign
              <Tooltip
                title={
                  <h1 className="tooltip_title">
                    Click ‘sign’ to sign electronically sign and submit this document
                  </h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>
          </header>

          {filteredPaperworksDocuments.map((paperwork, index) => {
            return (
              <div
                key={`${JSON.stringify(paperwork)}${index}`}
                className={`border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-5 lg:p-0 flex flex-col lg:flex-row shrink-0 gap-3 text-sm text-SeabiscuitDark200ThemeColor ${index !== 0 ? 'mt-5' : ''} lg:mt-0`}
              >
                <div className="lg:hidden mb-[-15px] ml-1 font-medium text-[12px]">Document</div>
                <div
                  className={clsx(
                    'w-full lg:w-[300px] mt-2 py-4 px-4 bg-SeabiscuitGrayThemeColor font-normal rounded-lg flex flex-wrap items-center mb-1 capitalize',
                    widths[0]
                  )}
                >
                  {addClassToDocumentTypes(paperwork.document_name)}
                </div>
                <div className="lg:hidden mb-[-15px] ml-1 font-medium text-[12px]">
                  Review Document
                </div>
                <div
                  className={clsx(
                    'w-full lg:w-[135px] mt-2 py-4 px-4 bg-SeabiscuitGrayThemeColor font-normal rounded-lg flex flex-wrap items-center mb-1 cursor-pointer',
                    widths[1]
                  )}
                  title="Click to view the document"
                  onClick={() => openLightBox(index)}
                >
                  <img
                    src={'assets/og_icons/FullScreen-1.svg'}
                    alt="fullScreenIcon"
                    className="w-6 h-6 mr-3"
                  ></img>
                  View doc
                </div>
                <div className="lg:hidden mb-[-15px] ml-1 font-medium text-[12px]">
                  Review Annex
                </div>
                <div
                  className={clsx(
                    'w-full lg:w-[135px] mt-2 py-4 px-4 bg-SeabiscuitGrayThemeColor font-normal rounded-lg flex flex-wrap items-center mb-1 cursor-pointer',
                    widths[3]
                  )}
                  title="Click to view the document"
                  onClick={() =>
                    allClauses.length
                      ? props.handleModal(true, MODAL_CONSTS.REVIEW_ANNEX, {
                          ...EventDetails,
                          data: {
                            eventId: eventId,
                            eventName: EventDetails?.competitionName,
                          },
                        })
                      : null
                  }
                >
                  {allClauses && !!allClauses.length && (
                    <img
                      src={'assets/og_icons/FullScreen-1.svg'}
                      alt="fullScreenIcon"
                      className="w-6 h-6 mr-3"
                    ></img>
                  )}
                  {allClauses && allClauses.length ? 'View Annex' : 'No Annex'}
                </div>
                <div className="lg:hidden mb-[-15px] ml-1 font-medium text-[12px]">Sign</div>
                {getSignColumn(index, paperwork)}
              </div>
            )
          })}
        </>
      ) : null}
    </CompetitorEventRegisterWrapper>
  )
}

export default CompetitorEventRegisterSignTab
