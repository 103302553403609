import React, { useRef, useState } from 'react'
import { motion } from 'framer-motion'
import HomeRootPageContentCategoriesSection from './HomeRootPageContentCategoriesSection'
import SwiperCore from 'swiper'
import { Link } from 'react-router-dom'
import customImageComponent from '../../../components/common/CustomImageComponent'
import clsx from 'clsx'
import { useOrganizers } from './hooks/useOrganizers'
import { disciplineData } from '../../../fakeData/disciplineList'
import { useAppSelector } from '../../../store/hooks'
import { selectIsLoggedIn, selectProfileData } from '../../../store/user/userSlice'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { FollowingModel } from '../../../models/following/following.model'
import { DATA_FIRESTORE_V01_FOLLOWING } from '../../../const/data/v01/following/firestore-v01-following-const'
import FirestoreService from '../../../services/firestoreService'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { CustomError, getUserFullName } from '../../../helpers/helpers'
import helpers from '../../../commonHelpers/helpers'
import {
  selectVisitedUserDetailsR,
  selectVisitedUserProfileDetailsR,
} from '../../../store/profilePage/profilePageSlice'
import CardsLoader from '../../../components/loader/CardsLoader'
import { CONST } from '../../../const/const'
import { IOrganizer } from '../../../store/organizers/organizersSlice'
import { useSelector } from 'react-redux'
import { selectedfilters, setMapVisibilityAc } from '../../../store/filters/filterSlice'
import MapContainer from './map/MapContainer'
const DEFAULT_CARD_IMAGE_URL = 'assets/img/Empty Event Portrait.png'

interface IProps {
  handleModal?: any
}

interface ExtendedSwiper extends SwiperCore {
  swiper?: any
}

export const OrganizersRootPage: React.FC<IProps> = ({ handleModal }) => {
  const {
    organizers,
    searchHandler,
    followHandler,
    unfollowHandler,
    loading,
    loggedInUserId,
    onMapButtonClick,
    mapIsOpened,
  } = useOrganizers()

  const getEventDetails = (organizer: IOrganizer) => {
    return (
      <>
        <div className="flex items-center mb-1">
          <img
            src={'/assets/og_icons/YearofHorse-1.svg'}
            alt={'TypeOfHorse'}
            className="object-cover mr-2 xl:w-[15px] xl:h-[15px] 2xl:w-[18px] 2xl:h-[18px]"
          />
          {organizer.userDiscipline
            ? disciplineData.find((d) => d.value === organizer.userDiscipline)?.label || 'N/A'
            : 'N/A'}
        </div>
        <div className="flex items-center mb-1">
          <img
            src={'/assets/og_icons/Location-1.svg'}
            alt={'Location'}
            className="object-cover mr-2 xl:w-[15px] xl:h-[15px] 2xl:w-[18px] 2xl:h-[18px]"
          />
          {organizer.userNationality || organizer.userState
            ? `${organizer.userNationality}${organizer.userNationality && organizer.userState ? ', ' + organizer.userState : organizer.userState}`
            : 'N/A'}
        </div>
        <div className="flex items-center mb-1">
          <img
            src={'/assets/og_icons/Prize-1.svg'}
            alt={'Prize'}
            className="object-cover mr-2 xl:w-[15px] xl:h-[15px] 2xl:w-[18px] 2xl:h-[18px]"
          />
          {Array.isArray(organizer.events) ? organizer.events.length : 0} events open now
        </div>
        <div className="flex items-center mb-1">
          <img
            src={'/assets/og_icons/eventsIcon.svg'}
            alt={'Events'}
            className="object-cover mr-2 xl:w-[15px] xl:h-[15px] 2xl:w-[18px] 2xl:h-[18px]"
          />
          {Array.isArray(organizer.pastEvents) ? organizer.pastEvents.length : 0} past events
        </div>
      </>
    )
  }

  const getName = (organizer: IOrganizer) => {
    return !organizer.userFirstName && !organizer.userLastName
      ? organizer.userName || ''
      : `${organizer.userFirstName} ${organizer.userLastName}`
  }

  const BtnMap = (
    <div
      className={
        'fixed z-10 bottom-3 right-[48.5%] flex items-center flex-row rounded-[30px] p-2 px-[13px] cursor-pointer md:w-max 2xl:h-[50px] xl:h-[45px] 2xl:w-[110px] xl:w-[100px] bg-[#F7074F] text-white hover:bg-[#ed094c] transform hover:scale-105 transition-transform duration-200'
      }
      onClick={onMapButtonClick}
    >
      <img
        src={`assets/cp_icons/Map-2.svg`}
        alt="Map"
        className="mr-1 w-6 h-6"
        style={{
          filter:
            'invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)',
        }}
      />
      <p className="ml-1 2xl:text-[16px] xl:text-[14px] w-[70%] text-center">
        {mapIsOpened ? 'Close' : 'Map'}
      </p>
    </div>
  )

  const renderBody = () => {
    if (loading) {
      return <CardsLoader />
    }
    if (!organizers[0]) {
      return (
        <div className="mb-6 px-5 min-h-[70vh] flex items-center justify-center flex-col w-full ">
          <div className="w-[70vh] md:max-w-[600px] max-w-full my-8">
            <img
              src={'assets/placeholders/noEventsFoundBanner.svg'}
              className="w-full h-full object-cover"
              alt="no events found"
            />
          </div>
          <div className="text-SeabiscuitDark200ThemeColor text-nr">
            No organizers have been created. Check back soon!
          </div>
        </div>
      )
    }

    if (mapIsOpened) {
      return (
        <>
          <MapContainer isOrganizer={true} />
          {BtnMap}
        </>
      )
    }

    return (
      <>
        <div className=" max-h-[80vh] overflow-auto grid py-5 px-5 grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {organizers.map((organizer) => (
            <motion.div
              key={organizer.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.1 }}
              className="inline-block col-span-1 rounded-lg px-3 h-min-[430px] mb-4 sm:mb-6"
            >
              <div className="event-post-horizontal-card-component transition-shadow duration-300 ease-in-out relative mb-4 h-full w-auto">
                <div className="mb-4 group rounded-xl w-full h-auto aspect-square max-h-[26vh] xl:h-[26vh] 2xl:h-[26vh] xl:max-h-[26vh] 2xl:max-h-[26vh] overflow-hidden">
                  <Link className="w-full" to={`${CONST.ROUTES.USER_PROFILE.URL}/${organizer.id}`}>
                    {customImageComponent(
                      organizer?.userProfilePicture || DEFAULT_CARD_IMAGE_URL,
                      organizer.userName,
                      'event-post-horizontal-card-component__image object-cover cursor-pointer swiper-lazy w-full !h-full sliderImage',
                      false
                    )}
                  </Link>
                </div>

                {loggedInUserId ? (
                  <div
                    onClick={() =>
                      organizer.followingDocId
                        ? unfollowHandler(
                            organizer.followingDocId,
                            organizer.id,
                            getName(organizer)
                          )
                        : followHandler(organizer.id, getName(organizer))
                    }
                    className={clsx(
                      'event-post-horizontal-card-component__bookmark-label flex items-center justify-center z-50 cursor-pointer',
                      0 && 'animate-pulse'
                    )}
                  >
                    {organizer.followingDocId ? (
                      <img
                        src={'assets/og_icons/Bookmark-6.svg'}
                        className="w-[11px] h-7 mx-auto"
                        alt="bookmark"
                      />
                    ) : (
                      <img
                        src={'assets/og_icons/Bookmark-2.svg'}
                        className="w-[19px] h-7 mx-auto"
                        alt="bookmark"
                      />
                    )}
                  </div>
                ) : null}

                <Link to={`${CONST.ROUTES.USER_PROFILE.URL}/${organizer.id}`}>
                  <div className="event-post-details">
                    <div className="event-post-horizontal-card-component__card__title w-full !mb-4 text-SeabiscuitDark200ThemeColor cursor-pointer 2xl:text-[20px] xl:text-[14px] capitalize">
                      {getName(organizer)}
                    </div>
                    {getEventDetails(organizer)}
                  </div>
                </Link>
              </div>
            </motion.div>
          ))}
        </div>

        {BtnMap}
      </>
    )
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="w-full mx-auto relative"
      transition={{
        delay: 0.1,
      }}
    >
      <HomeRootPageContentCategoriesSection
        contentType={'organizers'}
        useMode={'competitor'}
        searchHandler={searchHandler}
        handleModal={handleModal}
      />

      {renderBody()}
    </motion.div>
  )
}
