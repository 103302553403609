import React, { FC, useState } from 'react'

import MainModal from '../common/MainModal'

import { ISubscribersInterface } from '../../../models/subscribers/subscribers.interface'
import { IMembershipsInterface } from '../../../models/users/user.interface'

import useToasterHelper from '../../../helpers/ToasterHelper'

import { httpService } from '../../../services/httpService'

import { MODAL_CONSTS } from '../../../const/modal-const'

interface PauseRenewMembershipModalProps {
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    subscription: ISubscribersInterface
    membership: IMembershipsInterface
    type: 'pause' | 'renew'
    onHandlePauseRenew: () => Promise<void>
  }
}

const description = {
  DEFAULT: [
    'Will continue to be listed on the members page',
    'Will continue to be eligible for leaderboard points',
    'Will continue to be eligible for volunteer hours',
  ],
  MONTHLY_BOARD: [
    'Will continue to be listed on the members page',
    'Will continue to be eligible for leaderboard points',
    'Will continue to be accessible in the stall management dashboard',
    'Will continue to be eligible for volunteer hours',
  ],
}

export const PauseRenewMembershipModal: FC<PauseRenewMembershipModalProps> = ({
  handleModal,
  dataToPassOn,
}) => {
  const { subscription, membership, type } = dataToPassOn
  const toastFunctions = useToasterHelper()

  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
      await httpService({
        url: 'update_subscription',
        method: 'POST',
        data: {
          subscriptionId: subscription.id,
          userStripeAccountId: subscription.userStripeAccountId,
          stripeSubscriptionId: subscription.stripeSubscriptionId,
          dataToUpdate: {
            pause_collection: type === 'pause' ? { behavior: 'void' } : '',
          },
        },
      })
      await dataToPassOn.onHandlePauseRenew()

      toastFunctions.success({
        message: `Membership ${type}ed successfully`,
      })

      handleModal(false, MODAL_CONSTS.PAUSE_RENEW_MEMBERSHIP)
    } catch (error) {
      console.error(error, 'error')
      toastFunctions.error({
        message: 'Error purchase membership',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <MainModal
      customTitle={
        <div className="text-SeabiscuitDark200ThemeColor">
          <h4 className="flex items-center gap-3 font-bold text-[25px] capitalize">
            <span className="capitalize">{type}</span> membership
          </h4>
        </div>
      }
      show={true}
      type="PAUSE_RENEW_MEMBERSHIP"
      size="md"
      className="!px-0"
      buttons={[
        {
          label: `${type.toUpperCase()} MEMBERSHIP`,
          fullWidth: true,
          loading,
          disabled: loading,
          onClick: onSubmit,
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitIconThemeColor/50',
          fullWidth: true,
          disabled: loading,
          onClick: () => handleModal(false, MODAL_CONSTS.PAUSE_RENEW_MEMBERSHIP),
        },
      ]}
    >
      <div className="pt-5 text-SeabiscuitDark200ThemeColor">
        <div className="flex flex-col gap-3 bg-SeabiscuitGrayThemeColor p-5 rounded-lg">
          <p className="text-[14px] mb-1">By {type}ing this membership, this member:</p>
          {(membership.type === 'Board' ? description.MONTHLY_BOARD : description.DEFAULT).map(
            (item, index) => (
              <div key={index} className="flex items-center gap-3">
                <img
                  className="w-5"
                  src={`/assets/cp_icons/${type === 'pause' ? 'cancel-filled' : 'ok-filled'}.svg`}
                  alt=""
                />
                <p className="text-[14px]">{item}</p>
              </div>
            )
          )}
        </div>
      </div>
    </MainModal>
  )
}
