import { useMessageInputContext } from 'stream-chat-react'
import { ModalAttach } from '../modals/ModalAttach'
import { useState } from 'react'

export default function WriteMessage() {
  const { text, handleChange, handleSubmit } = useMessageInputContext()
  const [showAttachModal, setShowAttachModal] = useState(false)

  return (
    <>
      <div className="w-full flex flex-row justify-center pb-8">
        <div className="h-[48px] flex flex-row border-2 items-center max-w-[400px] 2xl:max-w-[604px] grow">
          <img
            className="h-[30px] w-[30px] m-2 cursor-pointer"
            src="/assets/chat/attach.svg"
            alt="searchIcon"
            onClick={() => setShowAttachModal(true)}
          />
          <textarea
            value={text}
            onChange={handleChange}
            className="h-full grow px-4 text-sm outline-none resize-none border-none focus:outline-none focus:ring-0 focus:border-transparent"
            placeholder="Write a message"
            onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault()
                handleSubmit()
              }
            }}
          />
          <button onClick={handleSubmit}>
            <img
              className="bg-[#F7074F] rounded-full h-[30px] w-[30px] m-2"
              src="/assets/chat/gps.svg"
              alt="sendMessage"
            />
          </button>
        </div>
      </div>
      <ModalAttach show={showAttachModal} onHide={() => setShowAttachModal(false)} />
    </>
  )
}
