import React, { FC, useEffect, useState } from 'react'

import moment from 'moment/moment'

import MainModal from '../common/MainModal'
import CustomDatePicker from '../../common/inputs/CustomDatePicker'

import { ISubscribersInterface } from '../../../models/subscribers/subscribers.interface'
import { IMembershipsInterface, MEMBERSHIP_TYPE } from '../../../models/users/user.interface'
import { getReactPickerDate } from '../../../models/interface.helper'

import useToasterHelper from '../../../helpers/ToasterHelper'

import { httpService } from '../../../services/httpService'

import { MODAL_CONSTS } from '../../../const/modal-const'
import { daysBetweenDates } from '../../../helpers/time'

interface CancelMembershipModalProps {
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    subscription: ISubscribersInterface
    membership: IMembershipsInterface
    onHandleCancel: () => Promise<void>
  }
}

const description = {
  DEFAULT: [
    { icon: 'cancel-filled', text: 'May no longer be listed on the members page' },
    { icon: 'cancel-filled', text: 'May no longer be eligible for leaderboard points' },
    { icon: 'cancel-filled', text: 'May no longer be eligible for volunteer hours' },
  ],
  MONTHLY_BOARD: [
    { icon: 'cancel-filled', text: 'May no longer be listed on the members page' },
    { icon: 'cancel-filled', text: 'May no longer be eligible for leaderboard points' },
    {
      icon: 'cancel-filled',
      text: 'May no longer be accessible in the stall management dashboard',
    },
    { icon: 'cancel-filled', text: 'May no longer be eligible for volunteer hours' },
  ],
}

export const CancelMembershipModal: FC<CancelMembershipModalProps> = ({
  handleModal,
  dataToPassOn,
}) => {
  const { subscription, membership } = dataToPassOn
  const minDate =
    new Date().getTime() +
    (membership.cancellationPeriod ? membership.cancellationPeriod * 86400000 : 0)

  const toastFunctions = useToasterHelper()

  const [loading, setLoading] = useState(false)
  const [cancellationDate, setCancellationDate] = useState('')
  const [remainingPayments, setRemainingPayments] = useState(0)

  const onSubmit = async () => {
    setLoading(true)
    try {
      if (cancellationDate) {
        await httpService({
          url: 'update_subscription',
          method: 'POST',
          data: {
            subscriptionId: subscription.id,
            userStripeAccountId: subscription.userStripeAccountId,
            stripeSubscriptionId: subscription.stripeSubscriptionId,
            dataToUpdate: {
              cancel_at: new Date(cancellationDate).getTime() / 1000,
            },
          },
        })
      } else {
        await httpService({
          url: 'cancel_subscription',
          method: 'POST',
          data: {
            subscriptionId: subscription.id,
            userStripeAccountId: subscription.userStripeAccountId,
            stripeSubscriptionId: subscription.stripeSubscriptionId,
          },
        })
      }

      await dataToPassOn.onHandleCancel()

      toastFunctions.success({
        message: 'Membership cancelled successfully',
      })

      handleModal(false, MODAL_CONSTS.CANCEL_MEMBERSHIP)
    } catch (error) {
      console.error(error, 'error')
      toastFunctions.error({
        message: 'Error purchase membership',
      })
    } finally {
      setLoading(false)
    }
  }

  const getRemainingPayments = (count: number) => {
    let remaining = 0
    if (membership.type === MEMBERSHIP_TYPE.ANNUAL) {
      remaining = Math.ceil(count / 30)
    } else if (
      membership.type === MEMBERSHIP_TYPE.BOARD ||
      membership.type === MEMBERSHIP_TYPE.MONTHLY
    ) {
      remaining = Math.ceil(count / 365)
    }

    setRemainingPayments(remaining)
  }

  useEffect(() => {
    if (cancellationDate)
      getRemainingPayments(daysBetweenDates(new Date(), new Date(cancellationDate)))
  }, [cancellationDate])

  return (
    <MainModal
      customTitle={
        <div className="text-SeabiscuitDark200ThemeColor">
          <h4 className="flex items-center gap-3 font-bold text-[25px] capitalize">
            Cancel membership
          </h4>
          <p className="opacity-50">Review cancellation terms to cancel</p>
        </div>
      }
      show={true}
      type="CANCEL_MEMBERSHIP"
      size="md"
      className="!px-0"
      buttons={[
        {
          label: 'CANCEL MEMBERSHIP',
          fullWidth: true,
          loading,
          disabled: loading,
          onClick: onSubmit,
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitIconThemeColor/50',
          fullWidth: true,
          disabled: loading,
          onClick: () => handleModal(false, MODAL_CONSTS.CANCEL_MEMBERSHIP),
        },
      ]}
    >
      <div className="pt-5 text-SeabiscuitDark200ThemeColor">
        <div className="mb-2 border border-SeabiscuitLightThemeColorD3 px-5 py-3 rounded-lg">
          <div className="flex justify-between gap-1 items-center">
            <p className="text-[14px]">Select cancellation date</p>
            <div className="relative w-[90px] min-w-[90px]">
              <CustomDatePicker
                onChange={(value) => setCancellationDate(value)}
                selected={getReactPickerDate(cancellationDate)}
                minDate={new Date(minDate)}
                placeholderText="Select date"
                className="border-none text-right p-0"
                dateFormat="dd MMM, yyyy"
              />
            </div>
          </div>
          {membership.cancellationPeriod && (
            <div>
              <p className="text-[14px] opacity-50">
                This membership requires {membership.cancellationPeriod} days notice to cancel.
              </p>
              <p className="text-[14px]">
                <span className="opacity-50">The earliest cancellation date is</span>{' '}
                {moment(minDate).format('DD MMM, yyyy')}.
              </p>
            </div>
          )}
        </div>
        {cancellationDate && (
          <div className="mb-2 border border-SeabiscuitLightThemeColorD3 px-5 py-3 rounded-lg">
            <p className="text-[14px] mb-1">Cancellation details:</p>
            <ul className="pl-1">
              <li className="text-[14px] flex w-full gap-2 items-start mt-2">
                <img className="w-5" src={'assets/cp_icons/Tear-Off Calendar-1.svg'} alt="" />
                <p>
                  {moment(cancellationDate).format('DD MMM, yyyy')}{' '}
                  <span className="opacity-50">• Membership cancellation date</span>
                </p>
              </li>
              <li className="text-[14px] flex w-full gap-2 items-start mt-2">
                <img className="w-5" src={'assets/img/dark/Dollarcoin.svg'} alt="" />
                <p>
                  ${membership.cancellationPeriod ? membership.price * remainingPayments : 0} due{' '}
                  <span className="opacity-50">
                    •{' '}
                    {membership.cancellationPeriod
                      ? `${remainingPayments} payments remaining, `
                      : 0}{' '}
                    day cancellation policy
                  </span>
                </p>
              </li>
            </ul>
          </div>
        )}
        <div className="flex flex-col gap-3 bg-SeabiscuitGrayThemeColor p-5 rounded-lg">
          <p className="text-[14px] mb-1">By cancelling this membership, this member:</p>
          {(membership.type === 'Board' ? description.MONTHLY_BOARD : description.DEFAULT).map(
            (item, index) => (
              <div key={index} className="flex items-center gap-3">
                <img className="w-5" src={`/assets/cp_icons/${item.icon}.svg`} alt="" />
                <p className="text-[14px]">{item.text}</p>
              </div>
            )
          )}
        </div>
      </div>
    </MainModal>
  )
}
