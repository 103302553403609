import clsx from 'clsx'

export default function ProfileImage({
  src,
  className,
  lazyLoad,
}: {
  src: string | undefined | null
  className?: string
  lazyLoad?: boolean
}) {
  return lazyLoad ? (
    <img
      alt="chatProfileImage"
      loading="lazy"
      src={src ?? '/assets/chat/avatar.svg'}
      onError={(e) => ((e.target as any).src = '/assets/chat/avatar.svg')}
      className={clsx('object-cover', className)}
    />
  ) : (
    <img
      alt="chatProfileImage"
      src={src ?? '/assets/chat/avatar.svg'}
      onError={(e) => ((e.target as any).src = '/assets/chat/avatar.svg')}
      className={clsx(className, 'object-cover')}
    />
  )
}
