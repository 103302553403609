import clsx from 'clsx'
import { ITeamMember } from '../../../../../models/users/user.interface'
import { ITeamMemberRoles } from '../../../../../types/competitor_types'
import { CONST } from '../../../../../const/const'
import { useIonRouter } from '@ionic/react'
import {
  selectHorseTeamIds,
  selectUserId,
  setHorseIdList,
} from '../../../../../store/user/userSlice'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import useHorses from '../../../../../hooks/users/competitor/profile/useHorses'
import { setMappings } from '../../../../../store/horses/horseSlice'
import { useHistory } from 'react-router-dom'

// Constants
const SELECT_PLACEHOLDER_ICON_ACTIVE = 'assets/cp_icons/Public Safety-1.svg'
const SELECT_PLACEHOLDER_ICON_INACTIVE = 'assets/cp_icons/Public Safety.svg'

type IUserProfileProps = {
  user: ITeamMember
  hideCross: boolean
  isOwner: boolean
  removeTeamMember: ({ type, id }: IRemoveTeamMemberArgs) => void
}

type IRemoveTeamMemberArgs = {
  type: ITeamMemberRoles
  id: string | null
  update?: boolean
  data?: { memberRole: ITeamMemberRoles; memberAuthorized: string }
}

type IRemoveTeamMemberFn = (args: {
  update: boolean
  e?: React.ChangeEvent<HTMLSelectElement>
  changeAuth?: boolean
  user?: ITeamMember
}) => void

const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES

const ProfileTeamTabUserProfileSection = ({
  user,
  removeTeamMember,
  hideCross,
  isOwner,
}: IUserProfileProps) => {
  // Functions
  const router = useIonRouter()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const horseIdlist = useAppSelector(selectHorseTeamIds)
  const userId = useAppSelector(selectUserId)

  const { mappings, updateHorseTeamMember } = useHorses(userId)
  /**
   *
   * @param update If update is true then updates the user in redux
   * @info Remove or updates the user on the basis of update value
   */
  const removeTeamMemberFn: IRemoveTeamMemberFn = (args) => {
    if (!user?.memberRole) return null

    if (args.user && args.user.id && args.user.userHorseMappingId) {
      dispatch(setHorseIdList([...horseIdlist, args.user.id]))
      const update_mapping = mappings.data.map((HorseData) => {
        if (HorseData.id === args.user?.userHorseMappingId) {
          let update: any[] = []
          if (HorseData.teamMembers) {
            update = HorseData.teamMembers.map((team) => {
              if (team?.id === args?.user?.id) {
                const temp = {
                  ...team,
                  authorized: args.e?.target.value === '1' ? 'authorized' : 'unauthorized',
                  memberAuthorized: args.e?.target?.value,
                }
                updateHorseTeamMember({
                  teamData: temp as any,
                })
                return temp
              } else return team
            })
          }
          return { ...HorseData, teamMembers: update }
        } else {
          return HorseData
        }
      })

      dispatch(setMappings(update_mapping))
    }

    const { update, e, changeAuth } = args
    const value = e?.target.value
    if (value) {
      let data = {
        memberRole: !changeAuth ? value : user?.memberRole,
        memberAuthorized: changeAuth ? value : (user?.memberAuthorized ?? '0'),
      }

      removeTeamMember({
        type: user?.memberRole as ITeamMemberRoles,
        id: user?.memberId,
        update,
        data,
      })
    } else removeTeamMember({ type: user?.memberRole as ITeamMemberRoles, id: user?.memberId })
  }

  if (!user?.memberRole) return null

  const handleTeamMemberClicked = (value: any) => {
    router.push(CONST.ROUTES.ALL_EVENT.URL, 'none', 'push', {
      as: value,
    })
    history.push(CONST.ROUTES.ALL_EVENT.URL)
  }

  return (
    <div className="flex userList">
      <div className="flex flex-wrap w-full items-center">
        <div className="flex items-center w-1/4 mr-2 my-2">
          <img
            alt="userIcon"
            className="mr-2 w-6 h-6"
            src={isOwner ? 'assets/og_icons/User-1.svg' : 'assets/cp_icons/People-1.svg'}
          />
          <div className="text-SeabiscuitDark200ThemeColor ">
            {user?.memberRole === 'Owner' ? 'You' : user?.memberRole}
            <br />
            <p className="text-[14px] text-[#A3ABB9]">
              {![TEAM_MEMBER_ROLES.TEAM_MEMBER, TEAM_MEMBER_ROLES.OWNER].includes(user?.memberRole)
                ? 'Add/edit in horse settings'
                : null}
            </p>
          </div>
        </div>

        <div className="user flex flex-row items-center rounded-xl py-1.5 px-2 border border-SeabiscuitGray500ThemeColor text-md mr-2 my-2 w-full md:w-[275px] h-[51px]">
          <div className="mr-2 shrink-0">
            <img
              src={
                user?.memberProfilePicture && user?.memberProfilePicture !== ''
                  ? user?.memberProfilePicture
                  : '/assets/og_icons/User-1.svg'
              }
              className={clsx(
                'rounded-md object-cover w-full h-full avatarImg  cursor-pointer',
                !(user?.memberProfilePicture && user?.memberProfilePicture !== '') ? 'p-1.5' : ''
              )}
              alt="User profile pic"
              onClick={() => handleTeamMemberClicked('team')}
            />
          </div>
          <div className="hourseDetails w-10/12">
            <div className="hourseTitle text-SeabiscuitDark200ThemeColor capitalize">
              {user.memberName}
            </div>
          </div>
        </div>

        {0 ? (
          <div className="user flex flex-row items-center mr-2 my-2 w-full md:w-auto">
            <select
              className="form-select appearance-none block w-full bg-gray-50 border border-[#D3DAEE] text-gray-900 rounded-xl focus:ring-blue-500 focus:border-blue-500 p-3.5 "
              aria-label="Default select example pl-10"
              defaultValue={user.defaultRole}
              disabled={hideCross}
              onChange={(e) => {
                if (!hideCross) removeTeamMemberFn({ update: true, e })
              }}
            >
              {hideCross ? (
                <option value="Owner">Owner</option>
              ) : (
                <>
                  <option value="Rider">Rider</option>
                  <option value="Coach">Coach</option>
                  <option value="Trainer">Trainer</option>
                </>
              )}
            </select>
          </div>
        ) : null}

        {0 ? (
          <div className="user flex flex-row items-center mr-2 my-2 w-full md:w-[275px] relative">
            <img
              width={22}
              className="absolute left-3.5"
              src={
                user?.memberAuthorized === '0'
                  ? SELECT_PLACEHOLDER_ICON_INACTIVE
                  : SELECT_PLACEHOLDER_ICON_ACTIVE
              }
              alt="badge"
            />

            {hideCross ? (
              <div className="inline-flex items-center w-full border border-SeabiscuitGray500ThemeColor rounded-xl focus:ring-0 px-4  text-SeabiscuitDark200ThemeColor pl-10 h-[51px]">
                Authorized
              </div>
            ) : (
              <>
                <select
                  defaultValue={hideCross ? '1' : user?.memberAuthorized}
                  disabled={hideCross}
                  onChange={(e) => {
                    if (!hideCross) removeTeamMemberFn({ update: true, e, changeAuth: true, user })
                  }}
                  className={clsx(
                    'form-select appearance-none block w-full border border-SeabiscuitGray500ThemeColor xl:text-nr 2xl:text-base rounded-xl focus:ring-0 px-4 h-[51px] focus:border-[#D3DAEE] pl-10',
                    user?.memberAuthorized === '0'
                      ? 'text-SeabiscuitLightParagraphTextColor'
                      : 'text-SeabiscuitDark200ThemeColor'
                  )}
                  aria-label="Default select example"
                >
                  <option value="0">Select...</option>
                  <option value="1">Authorized</option>
                  <option value="2">Unauthorized</option>
                </select>
              </>
            )}
          </div>
        ) : (
          <></>
        )}

        {!user.horseId && !hideCross ? (
          <img
            src={'assets/og_icons/Cancel.svg'}
            className="cursor-pointer"
            onClick={() => removeTeamMemberFn({ update: false })}
            alt="remove"
          />
        ) : null}
      </div>
    </div>
  )
}

export default ProfileTeamTabUserProfileSection
