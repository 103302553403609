import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
// Third party
import { useIonRouter } from '@ionic/react'
import { KeyboardBackspace } from '@mui/icons-material'
import { collection, documentId, getDocs, query, where } from 'firebase/firestore'
import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { Prompt, useParams } from 'react-router-dom'
import { CONST } from '../../const/const'
import { EventTicketingModel } from '../../models/event-ticketing/event-ticketing.model'
import { EventModel } from '../../models/events/event.model'
import { getConvertedData } from '../../models/interface.helper'
import FirebaseApp from '../../services/firebaseApp'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { allDataReset, setSpectatorMainData } from '../../store/tickets/ticketslice'
import { resetUserData } from '../../store/user/userSlice'
import SpectatorTicketsPurchaseTabs from './SpectatorTicketsPurchaseTabs'
import useGetBasicEventDetails from '../../hooks/users/common/useGetBasicEventDetails'

type Props = {
  handleModal: (showHide: boolean, typeOfModal: string) => void
  isLoggedIn: boolean
}

const SpectatorTicketsPurchaseRootPage = (props: Props) => {
  // Hooks and vars
  const router = useIonRouter()
  const { isLoggedIn } = props

  const params: any = useParams()
  const dispatch = useAppDispatch()
  useGetBasicEventDetails(params.id)

  const eventTitleNodeRef = useRef<HTMLSpanElement>(null)
  const [eventMainData, setEventMainData] = useState<any>({})
  const [event_tickets, set_event_tickets] = useState<any[]>([])
  const { eventTicketData } = useAppSelector((state) => state?.tickets?.spectator_ticket)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(allDataReset())
      if (params.id) sessionStorage.setItem('eventId', params.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  const getEvents = async function () {
    const singleEvent: any = query(
      collection(FirebaseApp.firestore, CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.NAME),
      ...[where(documentId(), '==', params?.id)]
    )

    const allDocs = await getDocs(singleEvent)

    if (allDocs.size === 0) {
      return
    }

    const fetched_events =
      allDocs?.docs.map((doc: any) => {
        let EventsData = EventModel.fromFirestoreDoc(doc).toObject()
        return getConvertedData(EventsData)
      }) ?? []

    if (fetched_events.length) {
      const eventDetailData: any = fetched_events[0]
      setEventMainData(eventDetailData)
      dispatch(
        setSpectatorMainData({
          eventMainData: eventDetailData,
        })
      )
    }
  }

  const getEventTicket = async function () {
    const eventTicket: any = query(
      collection(FirebaseApp.firestore, CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_TICKETING.NAME),
      ...[where(documentId(), '==', params?.id)]
    )

    const allDocs = await getDocs(eventTicket)

    if (allDocs.size === 0) {
      return
    }

    const fetched_tickets =
      allDocs?.docs.map((doc: any) => {
        let EventsData = EventTicketingModel.fromFirestoreDoc(doc).toObject()
        return getConvertedData(EventsData)
      }) ?? []

    if (fetched_tickets.length) {
      const eventTicketsData: any = fetched_tickets[0]
      dispatch(
        setSpectatorMainData({
          eventTicketData: eventTicketsData,
        })
      )
    }
  }

  useEffect(() => {
    if (params?.id) {
      getEventTicket().then()
      getEvents().then()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  /////////////////////////////////////////////
  useEffect(() => {
    if (Object.keys(eventTicketData).length > 1) {
      if (eventTicketData?.tickets?.length) {
        let array_data: any = []

        array_data = eventTicketData?.tickets
        // .filter((item: any) => {
        //     return item.name.toLowerCase() === EVENT_TICKETING.SPECTATOR_TICKETS.KEY
        // })

        set_event_tickets(array_data)
        dispatch(
          setSpectatorMainData({
            event_tickets: array_data,
          })
        )
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventTicketData])

  const handleBeforeUnload = () => {
    const confirmed = window.confirm(
      'Are you sure you want to leave this page? Your changes may not be saved.'
    )
    if (confirmed) {
      dispatch(resetUserData())
      dispatch(allDataReset())
      if (params.id) sessionStorage.setItem('eventId', params.id)
    }

    return confirmed
  }

  // Returns header
  const getCustomHeader = (): ReactJSXElement => {
    return (
      <div className="text-SeabiscuitDark200ThemeColor event_comp flex items-start md:items-center pt-[30px] pb-[18px] 2xl:pb-[30px] px-[45px]">
        <span onClick={() => router.goBack()} className="md:pt-2 lg:pt-2 xl:pt-1">
          <KeyboardBackspace className="text-SeabiscuitDark200ThemeColor mr-2" />
        </span>

        <div className="flex md:flex-nowrap flex-wrap">
          <span
            ref={eventTitleNodeRef}
            className="mr-4 xl:text-[22px] 2xl:text-2xl min-w-fit pt-0 md:pt-2 lg:pt-2 xl:pt-1"
          >
            Purchase tickets for{' '}
            <h1 className="font-bold inline-block">
              {eventMainData?.eventName ? eventMainData?.eventName : ''}
            </h1>
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
      <WrapperContainer
        noBg={true}
        removeShadow={true}
        customHeader={getCustomHeader()}
        removePadding={true}
      >
        <motion.div
          className="w-full mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 0.1,
          }}
        >
          <SpectatorTicketsPurchaseTabs
            handleModal={props.handleModal}
            isLoggedIn={isLoggedIn}
            EventDetails={eventMainData}
            EventTickets={event_tickets}
          />
        </motion.div>
      </WrapperContainer>

      <Prompt message={handleBeforeUnload} when={!isLoggedIn} />
    </>
  )
}

export default SpectatorTicketsPurchaseRootPage
