import { Clear } from '@mui/icons-material'
import clsx from 'clsx'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'

import { AutorenewRounded } from '@mui/icons-material'
import useMedia from '../../../hooks/useMedia'
import ViewsLoader from '../../loader/ViewsLoader'
import nextIcon from './assets/next.svg'

interface IMainModalButton {
  label: string
  bgClass?: string
  loading?: boolean
  textClass?: string
  icon?: string
  type?: number
  arrowIcon?: boolean
  disabled?: boolean
  className?: string
  /** @info true sets the width of the button to the width of the modal */
  fullWidth?: boolean
  borderClass?: string
  hidden?: boolean
  onHoverClass?: string
  onClick?: (...args: any) => void
}

export interface PropTypes {
  /** @info id to add on the modal container */
  id?: string
  /** @info Class to apply on close button */
  closeButtonClassName?: string
  /** @info If `true` then removes the x button from modal */
  hideCloseButton?: boolean
  /** React node if passed then added in front of title */
  goBackBtn?: React.ReactNode | null
  /** @info Shows the loader on true, else shows the body */
  loading?: boolean
  onTransitionEnd?: Function
  /** @info Classlist to apply on the title text */
  titleClassName?: string
  /** @info Classlist to apply on the footer container */
  footerClassName?: string
  /** @info true or false, true means show the modal, otherwise hide it */
  show: boolean
  /** @info Jsx or any thing like text etc */
  children: any
  /** @info The title to show in the header */
  title?: string
  /** @info Function the needs to be called when the modal gets closed */
  onHide?: () => void
  /** @info Classes to apply on the main modal container */
  modalClassName?: string
  /** @info Class to apply on the `body` or the jsx passed as children to modal */
  className?: string | null
  fullMinHeight?: boolean
  /** @info Array of objects, each representing a button */
  buttons?: IMainModalButton[]
  /** @info Jsx or anything the would be shown just below to `title` argument */
  customTitle?: React.ReactNode
  /** @info String specifying the name of the modal as per `modal-const` var */
  type: keyof typeof MODAL_CONSTS
  /** @info Size of the modal, this would affect modal width */
  size: 'md' | 'sm' | 'lg' | 'xl' | '2xl' | '3xl' | 'xs' | '4xl' | '5xl'
  /** @info If true the modal will be of the height as per of its content */
  setHeightAsPerContent?: boolean
  /** @info If true the modal default padding removed */
  customPadding?: boolean
}

type IInitialLocationInfo = {
  top?: any
  left?: any
  right?: any
  bottom?: any
}

// Constants

const INITIAL_LOCATION_INFO: IInitialLocationInfo = {
  top: '200%',
  left: '50%',
  right: '0%',
  bottom: '0%',
}

const INITIAL_TRANSFORM_INFO = 'translateX(-50%) translateY(0%)'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @TODO Document this
 */
const MainModal: React.FC<PropTypes> = (props: PropTypes) => {
  // Hooks and vars
  const bodyRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null)
  const { customPadding } = props
  const coards = JSON.stringify(containerRef.current?.getBoundingClientRect() ?? {})

  const isMobileScreen = useMedia('(max-width: 768px)')
  const modalName = MODAL_CONSTS[props.type]

  const handleModalContext = useContext(HandleModalContext)

  const [locationInfo, setLocationInfo] = useState<IInitialLocationInfo>(() => {
    if (isMobileScreen) {
      return {
        ...INITIAL_LOCATION_INFO,
        left: '50%',
        bottom: '-200%',
      }
    } else {
      return {
        ...INITIAL_LOCATION_INFO,
        left: '50%',
        bottom: '-200%',
      }
    }
  })

  const [transformInfo, setTransformInfo] = useState(INITIAL_TRANSFORM_INFO)

  useEffect(() => {
    if (!headerRef.current || !footerRef.current || !bodyRef.current) return

    const footerHeight = footerRef.current.clientHeight
    const headingHeight = headerRef.current.clientHeight

    if (!props.setHeightAsPerContent)
      bodyRef.current.setAttribute(
        'style',
        `height:calc(100vh - ${footerHeight + headingHeight}px)`
      )
  }, [props])

  useEffect(() => {
    if (!props?.onTransitionEnd) return

    function handleTransition() {
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = setTimeout(() => {
        props?.onTransitionEnd?.()
      }, 600)
    }

    handleTransition()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coards])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  function transformCalculator(usedAsGetter?: any) {
    let transformInfo_
    let locationInfo_

    if (isMobileScreen) {
      if (props.show) {
        locationInfo_ = { left: '0%', bottom: '0%' }
        transformInfo_ = 'translateX(0%) translateY(0%)'
      } else {
        locationInfo_ = { left: '0%', bottom: '-200%' }
        transformInfo_ = 'translateX(0%) translateY(200%)'
      }
    } else {
      if (props.show) {
        locationInfo_ = { left: '50%', top: '50%' }
        transformInfo_ = 'translateX(-50%) translateY(-50%)'
      } else {
        locationInfo_ = { ...INITIAL_LOCATION_INFO, left: '50%' }
        transformInfo_ = 'translateX(-50%) translateY(200%)'
      }
    }

    if (typeof usedAsGetter !== 'boolean') {
      setLocationInfo(locationInfo_)
      setTransformInfo(transformInfo_)
    }

    return {
      locationInfo_,
      transformInfo_,
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    transformCalculator()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show, isMobileScreen])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeModal = () => {
    if (!handleModalContext) return

    handleModalContext.handleModal(false, modalName)

    props?.onHide?.()
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const size =
    (function () {
      let sizeToReturn: string = clsx(
        'w-full max-h-[90vh]',
        props?.setHeightAsPerContent ? 'md:h-fit h-full' : 'h-full'
      )

      switch (props.size) {
        case 'xs':
          sizeToReturn += ' md:max-w-[400px]'
          break
        case 'sm':
          sizeToReturn += ' md:max-w-[450px]'
          break
        case 'md':
          sizeToReturn += ' md:max-w-[600px]'
          break
        case 'lg':
          sizeToReturn += ' md:max-w-[700px]'
          break
        case 'xl':
          sizeToReturn += ' md:max-w-[800px]'
          break
        case '2xl':
          sizeToReturn += ' md:max-w-[900px]'
          break
        case '3xl':
          sizeToReturn += ' md:max-w-[1000px]'
          break
        case '4xl':
          sizeToReturn += ' md:max-w-[1200px]'
          break
        case '5xl':
          sizeToReturn += ' md:max-w-[1600px]'
          break
      }

      return sizeToReturn
    })() ?? null

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <div
        ref={containerRef}
        id={props?.id}
        className={clsx(
          `fixed bg-white ${customPadding ? '' : 'p-2 md:p-7'} pt-6 rounded-t-3xl md:rounded-xl z-50 flex flex-col bottom-[0] mini:bottom-auto m-0 mini:m-2 left-[50%]`,
          size,
          props?.modalClassName && props?.modalClassName
        )}
        style={{
          transition: 'all .5s',
          top: locationInfo.top ?? 'unset',
          left: locationInfo.left ?? 'unset',
          right: locationInfo.right ?? 'unset',
          bottom: locationInfo.bottom ?? 'unset',
          transform: transformInfo,
          opacity: props.show ? 1 : 0,
          visibility: props.show ? 'visible' : 'hidden',
        }}
      >
        <div ref={headerRef}>
          {isMobileScreen ? (
            <div className="text-center">
              <button
                className="h-[30px] border-none outline-none bg-transparent"
                onClick={closeModal}
              >
                <div className="w-[30px] h-[8px] bg-gray-300 rounded-full"></div>
              </button>
            </div>
          ) : null}

          <div className="flex flex-col relative">
            {!!props.title ? (
              <div
                className={clsx(
                  'flex-1 font-bold text-sm md:text-xl xl:text-xl flex items-center gap-3',
                  props?.titleClassName && props?.titleClassName
                )}
              >
                {props?.goBackBtn ? props.goBackBtn : null}
                <div className="text-SeabiscuitDark200ThemeColor">{props.title}</div>
              </div>
            ) : null}

            {props?.customTitle ?? null}

            {!isMobileScreen && !props.hideCloseButton ? (
              <button
                className={clsx(
                  `absolute top-[-7px] ${customPadding ? 'right-[-7px]' : 'right-[-10px]'}`,
                  props.closeButtonClassName
                )}
                type="button"
                onClick={closeModal}
              >
                <Clear
                  fontSize={'small'}
                  className={`absolute top-[-7px] ${customPadding ? 'right-[-7px]' : 'right-[-10px]'} `}
                  style={{
                    color: '#122B46',
                    fontWeight: '400',
                  }}
                />
              </button>
            ) : null}
          </div>
        </div>

        <div
          ref={bodyRef}
          className={clsx(
            `overflow-auto max-h-screen ${customPadding ? '' : 'px-2 mt-2'}`,
            !!props?.className && props.className
          )}
        >
          {props?.loading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <ViewsLoader size="xl" color="red" />
            </div>
          ) : (
            props.children
          )}
        </div>

        {/* Footer */}
        <div
          ref={footerRef}
          className={clsx(
            'flex flex-col items-center px-3 py-3 md:px-[0] gap-3 md:gap-0',
            props?.footerClassName && props.footerClassName
          )}
        >
          {props.buttons?.map((currButton, buttonIndex) => {
            return (
              <button
                key={`${modalName}-${buttonIndex}`}
                onClick={currButton?.onClick}
                disabled={currButton.disabled}
                className={clsx(
                  'items-center py-3 md:my-1 text-nr rounded-xl w-full border-solid cursor-pointer transition-all disabled:!text-SeabiscuitLightTextColor disabled:!bg-SeabiscuitLightThemeColor disabled:border-transparent relative',
                  currButton?.className,
                  !currButton?.fullWidth && 'md:w-[250px]',
                  currButton?.onHoverClass
                    ? currButton?.onHoverClass
                    : 'hover:bg-[#D70443] hover:text-white',
                  currButton?.bgClass ? currButton?.bgClass : 'bg-SeabiscuitMainThemeColor ',
                  currButton?.textClass ? currButton?.textClass : 'text-white',
                  currButton?.borderClass
                    ? currButton?.borderClass
                    : 'border border-1 border-SeabiscuitMainThemeColor border-solid',
                  currButton?.hidden && 'hidden'
                )}
              >
                {typeof currButton?.loading === 'boolean' ? (
                  currButton.loading ? (
                    <AutorenewRounded fontSize="small" className="animate-spin mx-auto" />
                  ) : (
                    currButton.label
                  )
                ) : (
                  currButton.label
                )}

                {currButton?.arrowIcon && !currButton.loading && (
                  <img
                    src={nextIcon}
                    alt="next"
                    className="absolute right-2 top-[50%] translate-y-[-50%]"
                  />
                )}
              </button>
            )
          })}
        </div>
        {/* Footer */}
      </div>

      <div
        className={`fixed bg-black/30 w-screen h-screen transition-all`}
        style={{
          opacity: props.show ? 1 : 0,
        }}
        onClick={closeModal}
      ></div>
    </>
  )
}

export default MainModal
