import React, { FC, useState } from 'react'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ArrowBack } from '@mui/icons-material'
import clsx from 'clsx'
import moment from 'moment'

import MainModal from '../common/MainModal'
import CustomDatePicker from '../../common/inputs/CustomDatePicker'
import { SelectPaymentMethod } from '../../payment/SelectPaymentMethod/SelectPaymentMethod'
import { MembershipIcon } from '../../membership/MembershipIcon/MembershipIcon'

import { IUserInterfaceExtended } from '../../../store/storeHelpers/userSlice/types'
import { useAppSelector } from '../../../store/hooks'
import { selectProfileData } from '../../../store/user/userSlice'

import useToasterHelper from '../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../helpers/helpers'

import { getReactPickerDate } from '../../../models/interface.helper'
import {
  IMembershipsInterface,
  IMembershipType,
  IUserCards,
  MEMBERSHIP_TYPE,
} from '../../../models/users/user.interface'
import { SubscribersModel } from '../../../models/subscribers/subscribers.model'

import { httpService } from '../../../services/httpService'
import FirestoreService from '../../../services/firestoreService'

import { MODAL_CONSTS } from '../../../const/modal-const'
import { ISubscriptionStatus } from '../../../models/subscribers/subscribers.interface'
import { CONST } from '../../../const/const'
import { daysBetweenDates } from '../../../helpers/time'

interface IPurchaseMembership {
  startDate: string
}
interface PurchaseMembershipModalProps {
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    membership: IMembershipsInterface
    organiserProfile: IUserInterfaceExtended
    defaultStep?: 'startDate' | 'payment' | 'terms'
    defaultStartDate?: 'startDate' | 'payment' | 'terms'
  }
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const getProrated = (monthlyPrice: number, startDate: Date, type: IMembershipType) => {
  let totalDays = 0

  if (type === MEMBERSHIP_TYPE.MONTHLY || type === MEMBERSHIP_TYPE.BOARD) {
    // Calculate the total days in the current month
    totalDays = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate()
  } else if (type === MEMBERSHIP_TYPE.ANNUAL) {
    // Calculate the total days in the current year
    totalDays = 365
  }

  // Calculate the remaining days from today until the end of the month
  const daysRemaining = daysBetweenDates(new Date(), startDate)

  // Calculate the daily rate and prorated price
  const dailyRate = monthlyPrice / totalDays
  const proratedPrice = (dailyRate * daysRemaining).toFixed(2)

  return `$${proratedPrice} now (${daysRemaining} days this ${type === MEMBERSHIP_TYPE.ANNUAL ? 'year' : 'month'})`
}

export const PurchaseMembershipModal: FC<PurchaseMembershipModalProps> = ({
  handleModal,
  dataToPassOn,
}) => {
  const toastFunctions = useToasterHelper()
  const { organiserProfile, membership } = dataToPassOn
  const userProfile = useAppSelector(selectProfileData)

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState<'startDate' | 'payment' | 'terms'>(
    (dataToPassOn.defaultStep ??
      (membership.type === MEMBERSHIP_TYPE.FREE || membership.type === MEMBERSHIP_TYPE.PAYMENT))
      ? 'payment'
      : 'startDate'
  )
  const [selectedCard, setSelectedCard] = useState<IUserCards | null>(null)

  const { handleSubmit, control, watch } = useForm<IPurchaseMembership>({
    // resolver: yupResolver(addMembershipSchema),
    defaultValues: {
      startDate: dataToPassOn.defaultStartDate ?? '',
    },
    mode: 'onChange',
  })

  const onSubmit: SubmitHandler<IPurchaseMembership> = async (values) => {
    setLoading(true)

    try {
      if (step === 'startDate') {
        setStep('payment')
      } else if (step === 'payment') {
        const membershipData = {
          ...values,
          userStripeAccountId: organiserProfile?.userStripeAccountId,
          userEmail: userProfile.userEmail,
          userId: userProfile.id,
          ownerEmail: organiserProfile.userEmail,
          ownerId: organiserProfile.id,
          priceId: membership?.stripePriceId,
          membershipId: membership?.id,
          selectedCard: selectedCard,
          startDate: values?.startDate ? new Date(values?.startDate).getTime() / 1000 : null,
        }

        const subscriber = new SubscribersModel({
          membershipId: membership.id,
          ownerId: organiserProfile.id,
          ownerEmail: organiserProfile.userEmail,
          userId: userProfile.id,
          userEmail: userProfile.userEmail,
          lastPayment: new Date(),
          nextPayment: null,
          status: COLLECTIONS.SUBSCRIBERS.FIELDS.STATUS.VALUE.PAID as ISubscriptionStatus,
          userStripeAccountId: organiserProfile.userStripeAccountId ?? null,
          userStripeId: userProfile.userStripeId ?? null,
          stripeProductId: membership.stripeProductId ?? null,
          created: new Date(),
          modified: new Date(),
        }).toFirestore()

        if (membership.type === MEMBERSHIP_TYPE.FREE) {
          subscriber.stripeSubscriptionId = MEMBERSHIP_TYPE.FREE
          await FirestoreService.createItem(
            CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.SUBSCRIBERS.NAME,
            subscriber
          )
        } else if (membership.type === MEMBERSHIP_TYPE.PAYMENT) {
          await httpService({
            url: 'membership_one_time_payment',
            method: 'POST',
            data: {
              userStripeAccountId: organiserProfile?.userStripeAccountId,
              userEmail: userProfile.userEmail,
              userName: getUserFullName(userProfile),
              userPhone: userProfile.userPhoneNumber,
              amount: membership.price * 100,
              pmId: selectedCard?.pmId,
              description: `Payment for "${membership.name}" membership`,
            },
          })
          subscriber.stripeSubscriptionId = MEMBERSHIP_TYPE.PAYMENT

          await FirestoreService.createItem(
            CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.SUBSCRIBERS.NAME,
            subscriber
          )
        } else {
          const response = await httpService({
            url: 'subscribe_membership_plan',
            method: 'POST',
            data: membershipData,
          })

          if (!response?.subscription) {
            toastFunctions.error({
              message: 'Error adding membership',
            })
            return
          }

          subscriber.stripeSubscriptionId = response.subscription.id
        }

        toastFunctions.success({
          message: 'Membership purchased successfully',
        })
        handleModal(false, MODAL_CONSTS.PURCHASE_MEMBERSHIP)
      }
    } catch (error: any) {
      console.error(error, 'error')
      toastFunctions.error({
        message: error.response?.data?.error ?? 'Error purchase membership',
      })
    } finally {
      setLoading(false)
    }
  }

  const openAddNewCardModal = () => {
    handleModal(false, MODAL_CONSTS.PURCHASE_MEMBERSHIP)
    handleModal(true, MODAL_CONSTS.CARD, {
      add_card: true,
      re_open_modal: true,
      modal_name: MODAL_CONSTS.PURCHASE_MEMBERSHIP,
      ...dataToPassOn,
      organizerId: organiserProfile.id,
      defaultStep: step,
      defaultStartDate: watch('startDate'),
    })
  }

  return (
    <MainModal
      customTitle={
        <div className="text-SeabiscuitDark200ThemeColor">
          <h4 className="flex items-center gap-3 font-bold text-[25px] capitalize">
            {step === 'payment' && (
              <button type="button" onClick={() => setStep('startDate')}>
                <ArrowBack fontSize="small" className="hover:opacity-70 transition-all" />
              </button>
            )}
            {step === 'terms' && (
              <button type="button" onClick={() => setStep('payment')}>
                <ArrowBack fontSize="small" className="hover:opacity-70 transition-all" />
              </button>
            )}
            {step === 'startDate' && 'Purchase membership'}
            {step === 'payment' && 'Confirmation'}
            {step === 'terms' && 'Terms and Conditions'}
          </h4>
          <p className="opacity-50">
            {step === 'startDate' && 'Select start date to continue'}
            {step === 'payment' && 'Confirm your choice and pay'}
            {step === 'terms' && `${getUserFullName(organiserProfile)} • ${membership.name}`}
          </p>
        </div>
      }
      show={true}
      type="PURCHASE_MEMBERSHIP"
      size="md"
      className="!px-0"
      buttons={[
        {
          label:
            step === 'startDate'
              ? 'Next >'
              : membership.type === MEMBERSHIP_TYPE.FREE
                ? 'COMPLETE'
                : 'PAY',
          fullWidth: true,
          loading,
          hidden: step === 'terms',
          disabled:
            loading ||
            (step === 'startDate'
              ? !watch('startDate')
              : membership.type !== MEMBERSHIP_TYPE.FREE
                ? !selectedCard
                : false),
          onClick: handleSubmit(onSubmit),
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitIconThemeColor/50',
          fullWidth: true,
          hidden: step === 'terms',
          disabled: loading,
          onClick: () => handleModal(false, MODAL_CONSTS.PURCHASE_MEMBERSHIP),
        },
      ]}
    >
      <div className="pt-5 text-SeabiscuitDark200ThemeColor">
        {membership.type !== MEMBERSHIP_TYPE.FREE &&
          membership.type !== MEMBERSHIP_TYPE.PAYMENT && (
            <div className={clsx(step !== 'startDate' && 'hidden')}>
              <div className="flex justify-between gap-1 items-center rounded-lg py-3 px-5 border border-SeabiscuitLightThemeColorD3">
                <p className="text-[14px]">When do you want to start your membership?</p>
                <div className="relative w-[90px] min-w-[90px]">
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={getReactPickerDate(value)}
                          minDate={new Date()}
                          placeholderText="Select date..."
                          className="border-none text-right p-0"
                          dateFormat="dd MMM, yyyy"
                        />
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        <div className={clsx(step !== 'payment' && 'hidden')}>
          <div className="text-[14px] w-full border-solid p-3 border-SeabiscuitGray500ThemeColor border rounded-2xl">
            <p className="mb-2">You will be charged:</p>
            <div className="flex w-full gap-2 items-start mt-2">
              <img className="w-5" src={'assets/img/dark/Dollarcoin.svg'} alt="" />
              {membership.type === MEMBERSHIP_TYPE.FREE ||
              membership.type === MEMBERSHIP_TYPE.PAYMENT ? (
                <p>
                  ${membership?.price ?? 0}{' '}
                  <span className="opacity-50">{membership.type?.toLowerCase()}</span>
                </p>
              ) : (
                <p>
                  {getProrated(membership.price, new Date(watch('startDate')), membership.type)}{' '}
                  <span className="opacity-50">
                    • ${membership?.price ?? 0} {membership.type?.toLowerCase()} starting{' '}
                    {moment(watch('startDate')).format('DD MMM, yyyy')}
                  </span>
                </p>
              )}
            </div>
            <div className="flex w-full gap-2 items-start mt-2">
              <div className="w-5">
                <MembershipIcon membershipType={membership?.type} />
              </div>
              <p>{membership.name}</p>
            </div>
            {membership.type !== 'Payment' && membership.type !== 'Free' && (
              <div className="flex w-full gap-2 items-start mt-2">
                <img className="w-5" src={'assets/cp_icons/Info-1.svg'} alt="" />
                <p>
                  This payment renews{' '}
                  <span className="font-bold">{membership.type?.toLowerCase()}</span>. You can
                  cancel your subscription at any time in your membership settings.
                </p>
              </div>
            )}
            <div className="flex w-full gap-2 items-start mt-2">
              <img className="w-5" src={'assets/img/dark/Error.svg'} alt="" />
              <p>
                By purchasing this item, you are agreeing to its{' '}
                <span
                  className="underline hover:no-underline cursor-pointer"
                  onClick={() => setStep('terms')}
                >
                  Terms and Conditions
                </span>
                .
              </p>
            </div>
          </div>
          {membership.type !== MEMBERSHIP_TYPE.FREE && (
            <SelectPaymentMethod
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              openAddNewCardModal={openAddNewCardModal}
              userProfile={userProfile}
              organiserProfile={organiserProfile}
            />
          )}
        </div>
        <div className={clsx(step !== 'terms' && 'hidden')}>
          {membership.cancellationPeriod && (
            <div className="mb-2 flex gap-2 items-center rounded-lg bg-SeabiscuitGrayThemeColor p-3 text-[14px]">
              <img className="w-5" src={'assets/img/dark/Error.svg'} alt="" />
              <p>
                This membership requires{' '}
                <span className="font-bold">{membership.cancellationPeriod} days</span> notice to
                cancel.
              </p>
            </div>
          )}
          <div className="rounded-lg border border-SeabiscuitLightThemeColorD3 p-3 text-[14px]">
            {membership.termsAndConditions ?? 'N/A'}
          </div>
        </div>
      </div>
    </MainModal>
  )
}
