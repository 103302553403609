import React from 'react'
import { MODAL_CONSTS } from '../../const/modal-const'
import MainModal from './common/MainModal'
import { IconUser } from '../icons/IconUser'
import { IconEmailImg } from '../icons/IconEmailImg'
import { IconDate } from '../icons/IconDate'
import { NationalityIconImg } from '../icons/NationalityIconImg'
import { IconError } from '../icons/IconError'
import { InputUploadMemberPictureRow } from '../inputs/InputUploadMemberPictureRow'
import { InputNewMemberRow, IOption } from '../inputs/InputNewMemberRow'
import { CountryList } from '../../fakeData/countryList'
import { InputCheckMemberInfo } from '../inputs/InputCheckMemberInfo'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import FirebaseStorageService from '../../services/storageService'
import helpers from '../../commonHelpers/helpers'
import useToasterHelper from '../../helpers/ToasterHelper'
import { IInputData } from '../home/types/inputData'
import { IconRole } from '../icons/IconRole'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import FirebaseApp from '../../services/firebaseApp'
import { httpService } from '../../services/httpService'
import FirestoreService from '../../services/firestoreService'
import { getConvertedData } from '../../models/interface.helper'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import useProfileHook from '../../hooks/users/competitor/profile/useProfileHook'
import { IRiderTeamMemberInterface } from '../../models/rider-team-member/riderTeamMember.interface'
import { IUserDocument } from '../../models/user-documents/user-documents.interface'
import { IPaperworkTeam, setRidersTeamMembersUpdates } from '../../store/paperworks/paperworkSlice'
import { IDocumentList } from '../../fakeData/fakeDocumentList'
import { CustomError, getUserFullName } from '../../helpers/helpers'
import { RiderTeamMemberModel } from '../../models/rider-team-member/riderTeamMember.model'
import { where } from 'firebase/firestore'
import { UserDocumentModel } from '../../models/user-documents/user-documents.model'
import { IUserInterface } from '../../models/users/user.interface'
import { getFilteredPaperworks } from '../../helpers/documents'
const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES
const FILE_NAME = 'addTeamMemberToPaperwork'

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    rider: IPaperworkTeam
  }
}
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export const AddNewTeamMemberPaperworkModal = (props: Props) => {
  const ROLES = Object.values(TEAM_MEMBER_ROLES)
    .filter((role) => role !== TEAM_MEMBER_ROLES.RIDER)
    .map((value) => ({
      value,
      label: value,
    }))
  const [loading, setLoading] = React.useState(false)
  const userIcon = '/assets/img/User2.png'
  const [picture, setPicture] = React.useState('')
  const dispatch = useAppDispatch()

  const [inputData, setInputDate] = React.useState<IInputData>({
    name: {
      name: 'name',
      value: '',
      required: true,
      placeholder: 'Enter name...',
      type: 'text',
      label: 'Name',
      icon: <IconUser />,
    },
    email: {
      name: 'email',
      value: '',
      required: true,
      placeholder: 'Enter address...',
      type: 'email',
      label: 'Email address',
      icon: <IconEmailImg />,
    },
    date: {
      name: 'date',
      value: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      required: true,
      placeholder: 'Select date...',
      type: 'date',
      icon: <IconDate />,
      label: 'Date of birth',
    },
    nationality: {
      name: 'nationality',
      value: '',
      required: false,
      placeholder: 'Select nationality...',
      type: 'select',
      label: 'Nationality',
      icon: <NationalityIconImg />,
      selectData: CountryList,
    },
    role: {
      name: 'role',
      value: '',
      required: true,
      placeholder: 'Select role....',
      type: 'select',
      label: 'Role',
      icon: <IconRole />,
      selectData: ROLES,
    },
  })
  const [isValid, setIsValid] = React.useState(false)
  const [isConfirmed, setIsConfirmed] = React.useState(false)
  const { getModelledUserDetails } = useProfileHook({ dontFetch: true })
  const toastFunctions = useToasterHelper()
  const [imgLoading, setImgLoading] = React.useState(false)
  const exibitorR = useAppSelector((store) => store.exhibitor.registration)
  const rider = props.dataToPassOn.rider

  const createRiderTeamMember = async (ridersTeamMember: IRiderTeamMemberInterface) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.RIDER_TEAM_MEMBER.NAME, ridersTeamMember))
        ?.id ?? null
    )
  }

  const createUserDocuments = async (userDocuments: IUserDocument) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.USERS_DOCUMENTS.NAME, userDocuments))?.id ??
      null
    )
  }

  React.useEffect(() => {
    const checkValidity = () => {
      for (const key in inputData) {
        const field = inputData[key]
        if (field.required && !field.value) {
          return false
        }
      }
      return true
    }

    setIsValid(isConfirmed && checkValidity())
  }, [inputData, isConfirmed])

  const onChangeHandler = (key: string, value: string | Date | IOption) => {
    const newInputData = { ...inputData }
    if (newInputData[key]) {
      newInputData[key].value = value
      newInputData[key].hasError = false
      setInputDate(newInputData)
    }
  }

  const savePaperworkTabData = async ({
    registeredUser,
    filteredPaperworkDocuments,
    ridersTeamMembersToAdd,
  }: {
    ridersTeamMembersToAdd: IRiderTeamMemberInterface[]
    filteredPaperworkDocuments: IDocumentList
    registeredUser: IUserInterface
  }) => {
    let id: string | null = null

    if (ridersTeamMembersToAdd && ridersTeamMembersToAdd.length > 0) {
      let paperworkTabDataNotFilled = ridersTeamMembersToAdd.filter(
        (ridersTeamMember) => !ridersTeamMember.teamMemberRole || !ridersTeamMember.teamMemberId
      )

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        paperworkTabDataNotFilled: paperworkTabDataNotFilled && !paperworkTabDataNotFilled.length,
      })

      if (emptyVarName) {
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'savePaperworkTabData',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
          message: 'Please change empty Rider or Role.',
        })
      }
    }

    try {
      // Add
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await createRiderTeamMember(
            new RiderTeamMemberModel(ridersTeamMembersToAdd![currIndex]).toFirestore()
          )
          ridersTeamMembersToAdd![currIndex] = getConvertedData({
            ...ridersTeamMembersToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      const userDocumentsToAdd: IUserDocument[] = []

      for (const document of filteredPaperworkDocuments) {
        if (ridersTeamMembersToAdd) {
          for (const ridersTeamMember of ridersTeamMembersToAdd) {
            const eventsSnapshot = await FirestoreService.filterItems(
              COLLECTIONS.USERS_DOCUMENTS.NAME,
              [
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_ID.KEY,
                  '==',
                  ridersTeamMember.teamMemberId
                ),
                where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', exibitorR!.eventId),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.RIDER_ID.KEY,
                  '==',
                  ridersTeamMember.riderId
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.DOCUMENT_NAME.KEY,
                  '==',
                  document.document_name
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_DEFAULT_ROLE.KEY,
                  '==',
                  ridersTeamMember.teamMemberRole
                ),
              ]
            )

            if (eventsSnapshot.size <= 0) {
              userDocumentsToAdd.push(
                getConvertedData({
                  eventId: props.dataToPassOn.rider!.eventId!,
                  u: false,
                  status: 'Not Signed',
                  riderId: ridersTeamMember.riderId,
                  activityUser: registeredUser ? getUserFullName(registeredUser) : '',
                  riderName: ridersTeamMember.riderName,
                  eventLogo: exibitorR!.eventLogo!,
                  documentOwner: ridersTeamMember.teamMemberId,
                  signatoryId: ridersTeamMember.teamMemberId,
                  signatoryName: ridersTeamMember.teamMemberName,
                  documentName: document.key,
                  documentUrl: document.document_image[0],
                  documentOriginalName: document.document_name,
                  documentNameAsPerPdf: '',
                  eventName: exibitorR!.eventName!,
                  competitorId: registeredUser?.id,
                  signatoryProfilePicture: ridersTeamMember.teamMemberProfilePicture,
                  signatoryDefaultRole: ridersTeamMember.teamMemberRole,
                  signatoryEmail: ridersTeamMember.teamMemberEmail,
                  reminder: false,
                  registrationDocId: rider.registrationDocId,
                  registrationByDayDocId: ridersTeamMember.registrationByDayDocId,
                  registrationByDayUuid: ridersTeamMember.registrationByDayUuid || '',
                  riderTeamMemberDocId: ridersTeamMember.id ?? null,
                  pageNumberToSignOn: null,
                  coordinatesToSignOn: { x: null, y: null },
                })
              )
            }
          }
        }
      }
      if (userDocumentsToAdd.length > 0) {
        await helpers.asyncWhileLoop({
          loopCount: userDocumentsToAdd?.length ?? 0,
          functionToFire: async (currIndex) => {
            id = await createUserDocuments(
              new UserDocumentModel(userDocumentsToAdd![currIndex]).toFirestore()
            )
          },
        })
      }

      return true
    } catch (error: any) {
      helpers.logger({ message: `${error} in savePaperworkTabData` })
    }
  }

  const submitOnlyMemberHandler = async (
    isSetLoading?: boolean
  ): Promise<{
    id: string | null
  } | null> => {
    // const { email, name, date, nationality, discipline } = props.dataToPassOn.inputData

    setLoading(true)
    try {
      const userCredential = await createUserWithEmailAndPassword(
        FirebaseApp.auth,
        `${inputData.email.value}`,
        'defaultPassword123'
      )
      const user = userCredential.user

      await updateProfile(userCredential.user, {
        displayName: `${inputData.name.value}`,
      })

      const resp = await httpService({
        url: `on_auth_user_create`,
        method: 'POST',
        data: {
          uuid: user.uid,
          userName: `${inputData.name.value}`,
          email: `${inputData.email.value}`,
          userType: 'competitor',
        },
      })

      if (!resp.data.user) {
        alert('No User')
        return null
      }
      const respUser = resp.data.user

      // await FirestoreService.updateItem(
      //   CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
      //   respUser.id,
      //   {
      //     userProfilePicture: props.dataToPassOn.userPicture,
      //     userName: `${name.value}`,
      //     userDOB: date.value,
      //     userNationality: (nationality?.value as IOption).label || '',
      //     userDiscipline: (discipline?.value as IOption).label || '',
      //   }
      // )

      const dataTOUpdate = {
        defaultRole: (inputData.role.value as IOption).label || '',
        memberAddress: '',
        memberAuthorized: '1',
        memberCountry: '',
        memberDob: inputData.date.value,
        memberEmail: respUser.userEmail,
        memberId: respUser.id,
        memberName: respUser.userName,
        memberPhoneNumber: respUser.userPhoneNumber,
        memberProfilePicture: respUser.userProfilePicture,
        memberRole: CONST.UI.TEAM_MEMBERS_ROLES.TEAM_MEMBER,
        memberSafeSupportTraining: false,
        memberShipActive: false,
        memberStatus: '1',
        memberUserName: respUser.userName,
        memberprofileSynced: false,
        newSelected: true,
        selected: true,
        signedStatus: 'Not sent',
      }

      const structuredData = getModelledUserDetails(respUser)

      if (Array.isArray(structuredData.userTeamMembers)) {
        structuredData.userTeamMembers.push(dataTOUpdate)
      } else {
        structuredData.userTeamMembers = [dataTOUpdate]
      }

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        respUser.id,
        {
          ...structuredData,
          userProfilePicture: picture,
          userName: `${inputData.name.value}`,
          userDOB: inputData.date.value,
          teamMemberRole: (inputData.role.value as IOption).label || '',
          teamMemberName: `${inputData.name.value}`,
          userNationality: (inputData.nationality?.value as IOption).label || '',
        }
      )

      respUser.userProfilePicture = picture

      let universalOrganizerWaver = false
      let USEFWaiverAndReleaseOfLiability = false
      let USEFEntryAgreement = false
      let USDFWaiverAndReleaseOfLiability = false
      let USEAWaiverAndReleaseOfLiability = false
      let USHJAWaiverAndReleaseOfLiability = false

      if (rider.documents && Array.isArray(rider.documents)) {
        universalOrganizerWaver = !!rider.documents.find(
          (r) => r.documentName === 'universalOrganizerWaver'
        )
        USEFEntryAgreement = !!rider.documents.find((r) => r.documentName === 'USEFEntryAgreement')
        USHJAWaiverAndReleaseOfLiability = !!rider.documents.find(
          (r) => r.documentName === 'USHJAWaiverAndReleaseOfLiability'
        )
        USDFWaiverAndReleaseOfLiability = !!rider.documents.find(
          (r) => r.documentName === 'USDFWaiverAndReleaseOfLiability'
        )
        USEAWaiverAndReleaseOfLiability = !!rider.documents.find(
          (r) => r.documentName === 'USEAWaiverAndReleaseOfLiability'
        )
        USEFWaiverAndReleaseOfLiability = !!rider.documents.find(
          (r) => r.documentName === 'USEFWaiverAndReleaseOfLiability'
        )
      }

      if (!exibitorR) return null

      const teamMember: IRiderTeamMemberInterface = new RiderTeamMemberModel({
        mailLog: [],
        userId: exibitorR.userId,
        eventId: exibitorR.eventId!,
        eventName: exibitorR.eventName ?? null,
        riderDob: `${inputData.date.value}` ?? null,
        riderId: null,
        riderName: `${inputData.name.value}`,
        registrationDocId: rider.registrationDocId ?? null,
        registrationByDayDocId: rider.registrationByDayDocId ?? null,
        registrationByDayUuid: '',
        teamMemberId: respUser.id ?? null,
        teamMemberDob: `${inputData.date.value}` ?? null,
        teamMemberName: `${inputData.name.value}`,
        teamMemberRole: `${(inputData.role.value as IOption).label || ''}`,
        teamMemberEmail: respUser.userEmail ?? null,
        teamMemberProfilePicture: respUser.userProfilePicture || '',
      }).toObject()

      await savePaperworkTabData({
        filteredPaperworkDocuments: getFilteredPaperworks({
          universalOrganizerWaver,
          USEFWaiverAndReleaseOfLiability,
          USEFEntryAgreement,
          USDFWaiverAndReleaseOfLiability,
          USEAWaiverAndReleaseOfLiability,
          USHJAWaiverAndReleaseOfLiability,
        }),
        registeredUser: respUser,
        ridersTeamMembersToAdd: [teamMember],
      })
      dispatch(setRidersTeamMembersUpdates())
      props.handleModal(false, MODAL_CONSTS.CREATE_NEW_TEAM_MEMBER_PAPERWORK)
      toastFunctions.success({ message: MESSAGES_CONST.TEAM_MEMBER_CREATED })
      return {
        id: respUser.id || null,
      }
    } catch (e) {
      console.log('=>(AddNewTeamMemberModal.tsx:122) e', e)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })

      return null
    } finally {
      isSetLoading && setLoading(false)
    }
  }

  const updateProfilePic = async (event: any) => {
    const files = event.target.files
    const file = files[0]
    const PATH = CONST.DATA.STORAGE.USERS.COMPETITOR_PROFILE_IMAGE_URL.PREFIX

    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    setImgLoading(true)

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${PATH}/${new Date().getTime()}`,
        (percent: number) => {
          if (0) console.log(percent)
        }
      )
      if (downloadUrl) {
        setPicture(`${downloadUrl}`)
      }

      toastFunctions.success({ message: MESSAGES_CONST.PIC_UPDATED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setImgLoading(false)
    }
  }

  return (
    <>
      <MainModal
        title="Add new team member"
        show={props.show}
        type="CREATE_NEW_TEAM_MEMBER_PAPERWORK"
        size="md"
        onTransitionEnd={() => null}
        titleClassName="!font-normal"
        buttons={[
          {
            loading,
            label: 'Create',
            bgClass: 'bg-SeabiscuitMainThemeColor',
            onClick: submitOnlyMemberHandler,
            className: 'outline-none !w-full',
            textClass: 'text-white',
            disabled: !isValid,
          },
          {
            label: 'CANCEL',
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none !w-full',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor',
            onClick: () => props.handleModal(false, MODAL_CONSTS.CREATE_NEW_TEAM_MEMBER_PAPERWORK),
          },
        ]}
      >
        <div className={'flex flex-col gap-4 mt-4'}>
          <div className={'flex flex-col gap-2 border-b border-[#D3DAEE] pb-4'}>
            <InputUploadMemberPictureRow
              title={'Profile picture'}
              isLoading={imgLoading}
              picture={picture}
              onchangeHandler={updateProfilePic}
              userIcon={userIcon}
            />
            {Object.keys(inputData).map((key) => (
              <InputNewMemberRow
                key={key}
                value={inputData[key].value}
                onChangeHandler={onChangeHandler}
                placeholder={inputData[key].placeholder}
                inputName={inputData[key].name}
                name={inputData[key].label}
                isError={inputData[key].hasError}
                icon={inputData[key].icon}
                isRequired={inputData[key].required}
                type={inputData[key].type}
                selectData={inputData[key].selectData || []}
              />
            ))}
          </div>

          <InputCheckMemberInfo
            isChecked={isConfirmed}
            onClick={() => setIsConfirmed(!isConfirmed)}
            text={
              'By ticking this box I confirm that I have been given permission to create a Pegasus\n' +
              '                account on behalf of this person, and I am authorized to agree to Pegasus’ terms and\n' +
              '                conditions on their behalf, as well as register them for events.'
            }
          />

          <div className={'flex mt-4 gap-4 bg-[#F6F7FB] py-[12px] px-[20px] rounded-[12px]'}>
            <div className={'mt-1'}>
              <IconError />
            </div>

            <p className={'flex-1 text-[#122B46] text-[14px] m-0'}>
              The email address listed above will be notified of this account creation and provided
              with login details to join Pegasus and complete their profile.
            </p>
          </div>
        </div>
      </MainModal>
    </>
  )
}
