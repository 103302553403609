import React, { useState, createContext, useContext } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ChannelFilters, DefaultGenerics } from 'stream-chat'
import { DefaultStreamChatGenerics } from 'stream-chat-react'

interface ChatContextType {
  chatFilters: ChannelFilters<DefaultGenerics | DefaultStreamChatGenerics>
  setChatFilters: React.Dispatch<React.SetStateAction<ChannelFilters>>
  memberModalOpen: boolean
  setMemberModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  newChatModal: boolean
  setNewChatModal: React.Dispatch<React.SetStateAction<boolean>>
  showProfile: boolean
  setShowProfile: React.Dispatch<React.SetStateAction<boolean>>
  eventId?: string
  userId: string
}

const queryClient = new QueryClient()

const ChatContext = createContext<ChatContextType | null>(null)

export function useChat() {
  const context = useContext(ChatContext)
  if (!context) {
    throw new Error('useChat must use used within a chat <Chat />')
  }
  return context
}

export function ChatContent({
  children,
  eventId,
  userId,
}: {
  children: React.ReactNode
  eventId?: string
  userId: string
}) {
  const [chatFilters, setChatFilters] = useState<
    ChannelFilters<DefaultGenerics | DefaultStreamChatGenerics>
  >({})
  const [memberModalOpen, setMemberModalOpen] = useState(false)
  const [newChatModal, setNewChatModal] = useState(false)
  const [showProfile, setShowProfile] = useState(true)

  return (
    <ChatContext.Provider
      value={{
        chatFilters,
        setChatFilters,
        eventId,
        memberModalOpen,
        setMemberModalOpen,
        userId,
        newChatModal,
        setNewChatModal,
        showProfile,
        setShowProfile,
      }}
    >
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ChatContext.Provider>
  )
}

export function ChatFilters({ children }: { children: React.ReactNode }) {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div className="space-x-2">
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child as React.ReactElement, {
          focused: index === selectedTab,
          onClick: () => setSelectedTab(index),
        })
      })}
    </div>
  )
}
