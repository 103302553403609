import React from 'react'

import FormHeader from '../../../../ui/form/form-header/FormHeader'
import { PaymentsItems } from '../OrganizerMembershipsTab/components/PaymentsItems/PaymentsItems'

export const CompetitorMembershipsTab = () => {
  return (
    <>
      <FormHeader
        title="Memberships"
        description="Review and manage your memberships"
        removeBreakLine
      />
      <PaymentsItems />
    </>
  )
}
