import React, { useContext, useEffect, useState } from 'react'

// Third party
import clsx from 'clsx'
import { v4 } from 'uuid'

// Types
import { IEventDetailsUi } from '../../../../types/competitor_types'

// Redux
import { setScratchEvents } from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectRegistrationR } from '../../../../store/exhibitor/exhibitorSlice'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { Tooltip } from '@mui/material'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { IHorseData } from '../../../../models/horse/horse.interface'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { IconSwitch } from '../../../../components/icons/IconSwitch'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

type IExhibitorRegistrationAccordionProps = {
  registrationsByDay: IRegistrationByDayInterface[]
  registrationPrice: number
  showScratchedView: boolean
  setListToScratch: React.Dispatch<React.SetStateAction<string[]>>
  listToScratch: string[]
  horses: IHorseData[]
}

type IDetailRowProps = {
  title: string
  cost?: number | null
  showScratchedView: boolean
  listToScratch: string[]
  eventDate: Date | string | null
  member: IRegistrationByDayInterface
  handleScratchList: (eventDate: string | Date, memberId: string) => void
  horses: IHorseData[]
}

type IEventsToScratch = {
  memberId: string
  eventDate: string | Date
}

const ExhibitorRegisrationInput: React.FC<{
  className?: string
  data?: any
  data1?: any
  icon?: string
}> = ({ className, icon, data }) => {
  return (
    <div className={`${className}`}>
      {icon ? <img className="w-5 h-5 absolute left-3" src={icon} alt="icon" /> : null}
      <div className="ml-2">{data}</div>
    </div>
  )
}

const DetailRow = (props: IDetailRowProps) => {
  let id = `${v4()}${Date.now()}`
  let { title, cost, member, showScratchedView, listToScratch } = props
  const [memberDate, setMemberDate] = React.useState(member)

  let checked = memberDate.id ? listToScratch.find((data) => data === memberDate.id) : false
  const handleModalContext = useContext(HandleModalContext)
  const [horseData, setHorseData] = useState<IHorseData | null>(null)

  React.useEffect(() => {
    const horse = props.horses.find((h) => h.id === memberDate.horseId)
    setHorseData(horse || null)
  }, [props.horses])

  if (isNaN(Number(cost))) return null

  return (
    <div className="flex flex-col md:flex-row w-full mb-5 md:mb-0">
      <ExhibitorRegisrationInput
        className="rounded-lg flex items-center text-sm text-SeabiscuitDark200ThemeColor min-h-[48px] bg-SeabiscuitGrayThemeColor p-3 m-1 w-full md:w-1/4"
        data={
          <div className="flex flex-wrap items-center gap-1">
            <span>{title}</span>
            {member.isSratched || member.isScratched ? (
              <span
                className={'bg-[#122B461A] text-[#122B46] text-[10px] px-2 py-1 rounded-[20px]'}
              >
                Scratched
              </span>
            ) : (
              ''
            )}
            {member.isQualify ? (
              <span
                className={
                  'border border-SeabiscuitDark200ThemeColor text-[#122B46] text-[10px] px-2 py-1 rounded-[20px]'
                }
              >
                Qualifying
              </span>
            ) : (
              ''
            )}
          </div>
        }
      />

      <ExhibitorRegisrationInput
        className="rounded-lg flex items-center justify-center text-sm text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-full md:w-1/4 text-center relative"
        data={
          <div className={'flex items-center gap-3'}>
            <span>{props.horses[0] ? props.horses.map((h) => h.horseName).join(', ') : 'N/A'}</span>
            {/*<button*/}
            {/*  className={'group'}*/}
            {/*  onClick={() => {*/}
            {/*    const newMember = { ...member }*/}

            {/*    const newRider = {*/}
            {/*      ...newMember,*/}
            {/*      classes: [title],*/}
            {/*    }*/}
            {/*    handleModalContext?.handleModal(true, MODAL_CONSTS.SWITCH_HORSE, {*/}
            {/*      rider: newRider,*/}
            {/*      cb: (horse: IHorseData) => {*/}
            {/*        newMember.horseName = horse.horseName*/}
            {/*        newMember.horseId = horse.horseId || ''*/}
            {/*        newMember.horseProfilePicture = horse.horseProfilePicture || ''*/}
            {/*        setMemberDate(newMember)*/}
            {/*        handleModalContext?.handleModal(false, MODAL_CONSTS.SWITCH_HORSE)*/}
            {/*        setHorseData(horse)*/}
            {/*      },*/}
            {/*      registrationByDay: member,*/}
            {/*    })*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <IconSwitch />*/}
            {/*</button>*/}
          </div>
        }
        icon={'/assets/og_icons/YearofHorse-1.svg'}
      />

      <ExhibitorRegisrationInput
        className="rounded-lg flex items-center justify-center text-sm text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-full md:w-1/4 text-center relative"
        data={horseData?.trainer ? horseData.trainer : 'N/A'}
      />

      <ExhibitorRegisrationInput
        className="rounded-lg flex items-center justify-center text-sm text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-full md:w-1/4 text-center relative"
        data={member.backNumber}
      />

      {/*<ExhibitorRegisrationInput*/}
      {/*  className="relative rounded-lg flex items-center justify-center text-sm text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/4 text-center"*/}
      {/*  data={member?.isSratched ? 'Scratched' : `$${cost}`}*/}
      {/*  icon={*/}
      {/*    member.paymentStatus === PAYMENT_STATUSES.PAID*/}
      {/*      ? '/assets/cp_icons/Ok-3.svg'*/}
      {/*      : '/assets/cp_icons/Cancel-2.svg'*/}
      {/*  }*/}
      {/*/>*/}

      {showScratchedView ? (
        <label
          htmlFor={id}
          className={clsx(
            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-transparent border border-[#d3daee] p-3 m-1 w-1/4 text-center relative flex items-center justify-center',
            (!member?.isSratched || !member?.isScratched) && 'cursor-pointer'
          )}
        >
          {member?.isSratched || member?.isScratched ? (
            'Scratched'
          ) : (
            <>
              Scratch
              <div
                className={clsx(
                  'ml-2 !ring-0 !shadow-none absolute shrink-0 flex items-center justify-center',
                  !checked
                    ? 'border-2 border-SeabiscuitDark200ThemeColor rounded-full w-5 h-5 right-[14px]'
                    : 'w-6 h-6 right-3'
                )}
              >
                {checked ? (
                  <img
                    src="/assets/og_icons/Ok-3.svg"
                    alt="Ok"
                    className="w-full h-full object-cover"
                  />
                ) : null}
              </div>
              <input
                type="checkbox"
                className="hidden"
                id={id}
                checked={!!checked}
                onChange={() =>
                  props.handleScratchList(
                    props?.eventDate ?? '',
                    member?.id ? member?.id.toString() : ''
                  )
                }
              />
            </>
          )}
        </label>
      ) : null}
    </div>
  )
}

const ExhibitorRegistrationAccordionNew = ({
  registrationsByDay,
  showScratchedView,
  setListToScratch,
  listToScratch,
  horses,
}: IExhibitorRegistrationAccordionProps) => {
  // Hooks and vars
  const toastMethods = useToasterHelper()

  const dispatch = useAppDispatch()
  const registrationR = useAppSelector(selectRegistrationR)

  const handleShowToast = () => {
    return toastMethods.info({ message: 'Selected member Bill has not yet paid' })
  }

  useEffect(() => {
    setListToScratch([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showScratchedView, registrationR])

  const handleScratchList = (eventDate: string | Date, memberId: string) => {
    let updatedDataList_: IEventDetailsUi[] = []
    let listToScratch_ = new Set(listToScratch)
    let newstr = [...listToScratch]
    let objToAdd: IEventsToScratch = { eventDate, memberId }
    let stringifiedObj = JSON.stringify(objToAdd)
    let alreadyPresent = listToScratch_.has(stringifiedObj)

    if (alreadyPresent) listToScratch_.delete(stringifiedObj)
    else listToScratch_.add(stringifiedObj)

    if (listToScratch.find((data) => data === memberId)) {
      let filter = listToScratch.filter((data) => data !== memberId)
      setListToScratch(filter)
    } else {
      let memberData = registrationsByDay.filter((registration) => registration.id === memberId)
      if (memberData.length && memberData[0].paymentStatus !== 'paid') {
        handleShowToast()
      } else if (memberData.length && !memberData[0].recipientId) {
        toastMethods.info({ message: MESSAGES_CONST.NO_RECIPIENT })
      } else {
        setListToScratch([...newstr, memberId])
      }
    }

    dispatch(setScratchEvents(updatedDataList_))
  }

  return (
    <>
      <div className="hidden md:flex items-center w-full">
        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Class</span>}
        />

        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Horse</span>}
        />

        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Trainer</span>}
        />

        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Back #</span>}
        />

        {showScratchedView ? (
          <ExhibitorRegisrationInput
            className={clsx(
              'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold w-1/5'
            )}
            data={
              <span className="flex items-center">
                Scratch
                <Tooltip title={<h1 className="tooltip_title">Scratch</h1>} placement="top" arrow>
                  <button className="">
                    <TooltipIcon color="#122B46" />
                  </button>
                </Tooltip>
              </span>
            }
          />
        ) : null}
      </div>

      {registrationsByDay && registrationsByDay.length
        ? registrationsByDay.map((currentItem, index) => {
            return (
              <DetailRow
                key={`${JSON.stringify(currentItem)}${index}`}
                title={currentItem.registrationByDayName ?? 'N/A'}
                listToScratch={listToScratch}
                member={currentItem}
                eventDate={currentItem?.eventDate ? currentItem?.eventDate : ''}
                handleScratchList={handleScratchList}
                showScratchedView={showScratchedView}
                cost={currentItem.registrationPrice}
                horses={horses.filter((h) =>
                  currentItem.horseIds && Array.isArray(currentItem.horseIds)
                    ? currentItem.horseIds?.includes(h.id || '')
                    : currentItem.horseId
                )}
              />
            )
          })
        : null}
    </>
  )
}

export default ExhibitorRegistrationAccordionNew
