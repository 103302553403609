import React, { useRef } from 'react'
import { CONST } from '../../const/const'
// import { IEventFees } from '../../models/event-fees/event-fees.interface';
import { IEventDetails } from '../../models/event-details/event-details.interface'
import SpeactatorTicketsPurchaseTicketsTab from './Tabs/SpeactatorTicketsPurchaseTicketsTab'
import SpeactatorTicketsPurchaseEmailTab from './Tabs/SpeactatorTicketsPurchaseEmailTab'
import SpeactatorTicketsPurchaseReceiptTab from './Tabs/SpeactatorTicketsPurchaseReceiptTab'
import SpeactatorTicketsPuchasePayTab from './Tabs/SpeactatorTicketsPurchasePayTab'
import { IonList } from '@ionic/react'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { TicketType } from '../../models/event-ticketing/event-ticketing.interface'
import { KeyboardArrowRight } from '@mui/icons-material'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface ShowsDetailPageTabDisplaySectionProps {
  eventTab: string
  onSetEventTab: (tab: string) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  EventDetails: IEventDetails
  EventTickets: TicketType[]
  isLoggedIn: boolean
  loading: boolean
  setLoading: any
}

const SpectatorTicketsPurchaseDisplayTabs: React.FC<ShowsDetailPageTabDisplaySectionProps> = (
  props
) => {
  const { isLoggedIn, onSetEventTab, loading, setLoading } = props
  const saveRef: any = useRef()
  const nextRef: any = useRef()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  const selectTab = (event_tab: string) => {
    switch (event_tab) {
      case CONST.UI.SPECTATOR_TABS.TABS.TICKETS:
        return (
          <SpeactatorTicketsPurchaseTicketsTab
            EventDetails={props.EventDetails}
            eventTickets={props.EventTickets}
            props={props}
            saveRef={saveRef}
            nextRef={nextRef}
            onSetEventTab={onSetEventTab}
            setLoading={setLoading}
          />
        )
      case CONST.UI.SPECTATOR_TABS.TABS.EMAIL:
        return (
          <SpeactatorTicketsPurchaseEmailTab
            EventDetails={props.EventDetails}
            EventTickets={props.EventTickets}
            saveRef={saveRef}
            nextRef={nextRef}
            onSetEventTab={onSetEventTab}
            setLoading={setLoading}
          />
        )
      case CONST.UI.SPECTATOR_TABS.TABS.RECEIPT:
        return (
          <SpeactatorTicketsPurchaseReceiptTab
            EventDetails={props.EventDetails}
            EventTickets={props.EventTickets}
            saveRef={saveRef}
            nextRef={nextRef}
            onSetEventTab={onSetEventTab}
            setLoading={setLoading}
          />
        )
      case CONST.UI.SPECTATOR_TABS.TABS.PAY:
        return (
          <SpeactatorTicketsPuchasePayTab
            EventDetails={props.EventDetails}
            EventTickets={props.EventTickets}
            saveRef={saveRef}
            nextRef={nextRef}
            onSetEventTab={onSetEventTab}
            handleModal={props.handleModal}
            setLoading={setLoading}
          />
        )
    }
  }

  const handleSave = () => {
    if (nextRef?.current) {
      saveRef.current()
    }
  }

  const handleTabChange = () => {
    if (nextRef?.current) {
      nextRef.current()
    }
  }
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <IonList className="mt-5 bg-white home_root_ion_list pb-[25px] rounded-[20px] relative pt-0">
        {props.eventTab !== CONST.UI.SPECTATOR_TABS.TABS.PAY ? (
          <div className="px-[20px] absolute right-2 top-2">
            <div className="flex items-center justify-end mt-4">
              {isLoggedIn ? (
                <button
                  onClick={handleSave}
                  disabled={loading}
                  className={`rounded-lg border-[1px] border-SeabiscuitMainThemeColor border-solid text-SeabiscuitMainThemeColor py-2 px-3 w-[120px] hover:bg-SeabiscuitMainThemeColor hover:text-white`}
                >
                  {loading ? (
                    <AutorenewIcon fontSize="small" className="animate-spin" />
                  ) : props.eventTab === CONST.UI.REGISTER.TABS.TEAM ? (
                    'Exit'
                  ) : (
                    'Save & Exit'
                  )}
                </button>
              ) : (
                ''
              )}

              <button
                disabled={loading}
                onClick={() => {
                  handleTabChange()
                }}
                className="rounded-lg border-[1px] ml-2 border-SeabiscuitMainThemeColor border-solid text-SeabiscuitMainThemeColor py-2 px-3 w-[120px] hover:bg-SeabiscuitMainThemeColor hover:text-white"
              >
                {loading ? (
                  <AutorenewIcon fontSize="small" className="animate-spin" />
                ) : props.eventTab === CONST.UI.SPECTATOR_TABS.TABS.PAY ? (
                  'Pay'
                ) : props.eventTab === CONST.UI.SPECTATOR_TABS.TABS.TICKETS ? (
                  'Next'
                ) : (
                  'Next'
                )}
                {!loading && (
                  <KeyboardArrowRight fontSize="small" className="!mb-[2px] ml-1 !w-5" />
                )}
              </button>
            </div>
          </div>
        ) : (
          ''
        )}

        {selectTab(props.eventTab)}
      </IonList>
    </>
  )
}

export default SpectatorTicketsPurchaseDisplayTabs
