import clsx from 'clsx'
import React, { useContext, Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { IEventReviewPublish } from '../../../models/event-review-publish/event-review-publish.interface'
import Button from './Button'
import { Clear } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import {
  IPublishEvent,
  ManageEventContext,
} from '../../events/forms/header/EventFormHeaderComponent'
import { ispublished } from '../../../store/events/eventsSlice'
import { useAppSelector } from '../../../store/hooks'
import {
  selectUserBankConnected,
  selectUserId,
  selectUserStripeAccountStatus,
  setStripeAccountId,
  setStripeAccountMessage,
  setStripeAccountStatus,
} from '../../../store/user/userSlice'
import { httpService } from '../../../services/httpService'
import { RootState } from '../../../store/store'
import { CountryList } from '../../../fakeData/countryList'
import { MESSAGES_CONST } from '../../../const/messages-const'
import helpers from '../../../commonHelpers/helpers'
import useProfileHook from '../../../hooks/users/competitor/profile/useProfileHook'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore'
import FirebaseApp from '../../../services/firebaseApp'
import { CONST } from '../../../const/const'
import { UserModel } from '../../../models/users/user.model'
import FirestoreService from '../../../services/firestoreService'
import { DATA_FIRESTORE_V01_CONST } from '../../../const/data/v01/firestore-v01-const'
import { getConvertedData } from '../../../models/interface.helper'
import { RegistrationTeamModel } from '../../../models/registeration-teams/registration-teams.models'

const EventFormFooterButtonCommonComponent: React.FC<{
  onClick: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void
  caption: string
  loading?: boolean
  publishButton?: boolean
  icon?: string
  isPublished?: boolean
  icon_class?: string
  disabled?: boolean
}> = (props) => {
  const { onClick, caption, icon, loading, publishButton, icon_class, disabled } = props

  return (
    <Button
      disabled={disabled}
      outlined={true}
      onClick={onClick}
      loading={loading}
      label={caption}
      textColor="#F7074F"
      className={clsx(
        !publishButton ? 'min-w-[80px] md:min-w-[120px]' : 'min-w-[80px] md:min-w-[120px]',
        'flex gap-2 items-center justify-center h-11 whitespace-nowrap mt-0 mb-1'
      )}
    >
      <>
        <span className="whitespace-nowrap normal-case">{caption}</span>
        {caption === 'Next' ? (
          <span className="text-SeabiscuitMainThemeColor text-[25px] whitespace-nowrap">
            {' '}
            {`>`}{' '}
          </span>
        ) : (
          icon && <img src={icon} alt="icon" className={`ml-2 mb-0.5 ${icon_class}`} />
        )}
      </>
    </Button>
  )
}

const PopupModal: React.FC<{
  isVisible: boolean
  onClose: () => void
  content: JSX.Element | string
  processPublish: () => void
  accountStatus: 'pending' | 'connected' | null | undefined
  userBankConnected: boolean
  updateProfile: (id: string) => void
}> = ({ isVisible, onClose, processPublish, accountStatus, userBankConnected, updateProfile }) => {
  const userReducer = useSelector((state: RootState) => state.user)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (userBankConnected) {
      onClose()
      processPublish()
    }
  }, [userBankConnected])

  if (!isVisible) return null

  const handleOpenBank = async () => {
    setIsLoading(true)

    const userEmail = userReducer?.profileDetails?.userEmail
    let userNationality = userReducer?.profileDetails?.userNationality
    const nationality = CountryList.filter((data) => {
      return data.value === userNationality
    })
    userNationality = nationality.length
      ? nationality[0].ccode
      : userReducer?.profileDetails?.userNationality

    const result = await httpService({
      url: 'stripeAccount',
      method: 'POST',
      data: {
        email: userEmail,
        country: userNationality,
      },
    })
    const url = result.accountLink.url
    const id = result.data.id

    if (id) updateProfile(id)

    if (url) window.open(url, '_blank')

    setIsLoading(false)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <Transition appear show={isVisible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={(_) => onClose()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full xl:w-[32%] xl:max-w-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <span
                    onClick={(_) => onClose()}
                    className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
                  >
                    {/* <img src="/assets/og_icons/Close.svg" className="w-6" alt="close icon" /> */}
                    <Clear
                      fontSize={'small'}
                      style={{
                        color: 'grey',
                        fontWeight: '400',
                      }}
                    />
                  </span>

                  <div className="flex justify-center">
                    <img src="/assets/og_icons/bank_Account.svg" alt="bank account validation" />
                  </div>
                  <div className="text-SeabiscuitDark200ThemeColor">
                    <div className="flex justify-center mt-4">
                      <span className="font-bold text-lg">Must connect bank account</span>
                    </div>
                    <div className="flex justify-center mt-2">
                      <div className="flex justify-center text-center text-sm pl-[10px] ml-8 mr-8">
                        You must connect your bank account before creating an event, in order to
                        process registration payments.
                      </div>
                    </div>
                    <div className="pt-10 mx-auto w-full">
                      <button
                        disabled={isLoading}
                        className={clsx(
                          'bg-SeabiscuitMainThemeColor text-white w-full text-sm rounded-md py-3 outline-none',
                          { 'opacity-50': isLoading }
                        )}
                        onClick={handleOpenBank}
                      >
                        Connect Bank Account
                      </button>
                      <button
                        disabled={isLoading}
                        className={clsx(
                          'bg-[#E9ECF1] text-SeabiscuitGray200ThemeColor w-full rounded-md py-3 mt-1 text-sm outline-none',
                          { 'opacity-50': isLoading }
                        )}
                        onClick={(_) => onClose()}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventFormFooterCommonComponent: React.FC<{
  publishedEvent?: IEventReviewPublish | null
  publishButton?: boolean
  loading?: boolean
  onNext: (e: any, publishEvent: IPublishEvent, setLoading: (value: boolean) => void) => void
  onSaveAndExit?: (
    e: any,
    publishEvent: IPublishEvent,
    setSaveAndExitLoading: (value: boolean) => void
  ) => void
  nextEventTab?: string
  eventTab?: string
  buttonsLoading?: {
    next: boolean
    saveAndExit: boolean
  }
}> = ({
  publishButton,
  publishedEvent,
  eventTab,
  nextEventTab,
  onNext,
  onSaveAndExit,
  loading,
  buttonsLoading,
}) => {
  const toasterFunctions = useToasterHelper()
  const dispatch = useDispatch()

  const { updateUserDetails } = useProfileHook({ dontFetch: true })

  const manageEventContext = useContext(ManageEventContext)

  const accountStatus = useAppSelector(selectUserStripeAccountStatus)
  const userBankConnected = useAppSelector(selectUserBankConnected)
  const userId = useAppSelector(selectUserId)

  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    // ;(async () => {
    //   try {
    //     const users: UserModel[] = []
    //     const usersSnaps = await FirestoreService.filterItems(
    //       DATA_FIRESTORE_V01_CONST.COLLECTIONS.USERS.NAME
    //     )
    //
    //     usersSnaps.docs.forEach((currDoc) => {
    //       users.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()) as UserModel)
    //     })
    //     console.log('=>(EventFormFooterCommonComponent.tsx:279) start', 0)
    //     for (const user of users) {
    //       await FirestoreService.updateItem(
    //         DATA_FIRESTORE_V01_CONST.COLLECTIONS.USERS.NAME,
    //         user.id,
    //         {
    //           userBankConnected: user.userStripeAccountStatus === 'connected',
    //         }
    //       )
    //     }
    //
    //     console.log('=>(EventFormFooterCommonComponent.tsx:279) finish', 1)
    //   } catch (e) {
    //     console.log('=>(EventFormFooterCommonComponent.tsx:284) e', e)
    //   }
    // })()
    let unsubscribe: Unsubscribe | null = null

    if (userId) {
      unsubscribe = onSnapshot(
        doc(FirebaseApp.firestore, CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME, userId),
        (doc) => {
          if (doc.exists()) {
            let user = UserModel.fromFirestoreDoc(doc)
            dispatch(setStripeAccountStatus(user.userStripeAccountStatus))
            dispatch(setStripeAccountMessage(user.userConnectAccountStatusMessage))
          }
        }
      )
    }

    return () => {
      unsubscribe?.()
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (nextEventTab !== '' && nextEventTab !== eventTab) {
      saveUpdates()
    }
  }, [nextEventTab])

  const isPublished = useAppSelector(ispublished)
  // Functions
  /**
   * @info Scrolls the page to next on next button click
   *
   */

  const scrollToTop = (e?: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    saveUpdates(e)
  }

  const saveUpdates = (event?: any) => {
    let main = document.querySelector('.eventDetailTitle')
    if (main && !publishButton) main?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    if (manageEventContext?.publishEvent)
      onNext(event, manageEventContext?.publishEvent, manageEventContext.setLoading)
  }

  const onSaveAndExit_ = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    if (manageEventContext?.publishEvent && onSaveAndExit)
      onSaveAndExit(e, manageEventContext?.publishEvent, manageEventContext.setSaveAndExitLoading)
  }

  const handlePublishClick = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    if (userBankConnected) {
      // Prevent the default action and show the modal
      e.preventDefault()
      setIsModalVisible(true)
    } else {
      // Proceed with the original publish action
      showPublishModal()
    }
  }

  const showPublishModal = () => {
    if (manageEventContext?.publishEvent)
      onNext('', manageEventContext?.publishEvent, manageEventContext.setLoading)
  }

  const updateProfile = async (accountId: string) => {
    try {
      const res = await updateUserDetails({
        userStripeAccountId: accountId,
        userStripeAccountStatus: 'pending',
      })

      if (res.status) {
        toasterFunctions.success({
          message: 'Stripe account created successfully',
        })
      }

      dispatch(setStripeAccountId(accountId))
      dispatch(setStripeAccountStatus('pending'))
    } catch (error) {
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  return (
    <>
      <div className="flex gap-2 justify-end absolute md:relative bottom-0 left-0 w-full md:w-[initial] p-4 md:p-0">
        {!isPublished && onSaveAndExit ? (
          <EventFormFooterButtonCommonComponent
            caption="Save & Exit"
            onClick={onSaveAndExit_}
            loading={
              manageEventContext?.saveAndExitLoading || loading || buttonsLoading?.saveAndExit
            }
          />
        ) : null}

        {publishButton ? (
          <EventFormFooterButtonCommonComponent
            caption={
              publishedEvent ? 'Update Event >' : !isPublished ? 'Publish >' : 'Save changes'
            }
            loading={manageEventContext?.loading || loading}
            publishButton={publishButton}
            // icon={nextIcon}
            onClick={handlePublishClick}
          />
        ) : (
          <EventFormFooterButtonCommonComponent
            caption={!isPublished ? 'Next >' : 'Save Changes'}
            loading={manageEventContext?.loading || loading || buttonsLoading?.next}
            // icon={nextIcon}
            icon_class="w-[20px] h-[20px]"
            onClick={scrollToTop}
          />
        )}
      </div>
      {isModalVisible && (
        <PopupModal
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          content="Your custom content here based on the account status."
          processPublish={showPublishModal}
          accountStatus={accountStatus}
          userBankConnected={!!userBankConnected}
          updateProfile={updateProfile}
        />
      )}
    </>
  )
}

export default EventFormFooterCommonComponent
