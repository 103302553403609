// ############################################################
/**
 * Contains the model to hold the events (and perform migrations
 * if it comes the case)
 */
// ############################################################

import { cloneDeep } from 'lodash'
import { interfaceHelper, objsSelectValuesToString, selectObjToString } from '../interface.helper'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import {
  IMembershipsInterface,
  INotificationSettings,
  ITeamMember,
  IUserDocuments,
  IUserInterface,
  TUserDocumentAndExpiration,
  TUserPocDetails,
} from './user.interface'

const InitalUserDocument = {
  membershipStatus: '',
  startDate: null,
  endDate: null,
  validTill: null,
  documentFullName: '',
  documentNumber: '',
  image: '',
}

const InitalUserDocumentAndExpiration = {
  file: '',
  validTill: '',
}

export type IUserLocationInterface = {
  lat: number | null
  lng: number | null
}
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */

export class UserModel extends ModelBaseModel<IUserInterface> implements IModelBaseModel {
  public v: number
  public id: string
  public userSummary: string
  public userFirstName: string
  public userName: IUserInterface['userName']
  public userLastName: string | null
  public vendorname: string | null
  public userEmail: string
  public userCategory: string
  public userDescription: string
  public userProfilePicture: string
  public userType: string
  public userSubRoles: string[]
  public userDOB: Date | any
  public userNationality: string
  public userZone: string
  public userAmateur?: string
  public userDiscipline: string
  public userAddress: string
  public userLong: string
  public userLat: string
  public userState: string
  public userPhoneNumber: string
  public userAlternativeNumber: string
  public userCreated: Date
  public userModified: Date | null
  public userNameNGram: string[]
  public userStripeId: string
  public userAccountPrivacy?: any
  public userStripeAccountId: string | null
  public userBankConnected: boolean
  public userNotificationsSettings: INotificationSettings
  public userPreviousStripeConnectIds: IUserInterface['userPreviousStripeConnectIds']
  public userConnectAccountStatusMessage: IUserInterface['userConnectAccountStatusMessage']
  public userPhoneCode: IUserInterface['userPhoneCode']
  public userTeamMembers: ITeamMember[]
  public userDefaultRole: string
  // public userHorses: any[]
  public userSocialSecurityNumber: string
  public userEndorsementNumberLetter: string
  public userSafeSupportStatus: string | null
  public userSafeSupportExpiry: Date | null
  public userBackgroundCheckStatus: string | null
  public userBackgroundCheckExpiry: Date | null
  public userJudgingLicense?: IUserInterface['userJudgingLicense']

  public userFEI?: IUserDocuments
  public userUSEF?: IUserDocuments

  public userUSDF?: IUserDocuments
  public userUSEA?: IUserDocuments
  public userAHHS?: IUserDocuments
  public userAMHA?: IUserDocuments
  public userASHA?: IUserDocuments
  public userUPHA?: IUserDocuments
  public userWDAA?: IUserDocuments
  public userARHPA?: IUserDocuments
  public userUSHJA?: IUserDocuments

  public userOtherDocument?: TUserDocumentAndExpiration
  public userExtraDocument?: IUserDocuments[] | []
  public userBrandImages?: string[] | []
  public userBrandVideos?: string[] | []
  public userStripeAccountStatus: IUserInterface['userStripeAccountStatus']
  public userForeignEndorsement: TUserDocumentAndExpiration

  public userPocDetails?: TUserPocDetails

  public userWebsite?: string
  public userTwitter?: string
  public userFacebook?: string
  public userInstagram?: string
  public userLinkedin?: string

  public userLegalPolicyName?: string

  public userLocation: IUserLocationInterface
  public websiteUrl: string | null

  public userBusinessName: string | null

  public userMemberships?: IMembershipsInterface[] | []

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Constructs an instance of the class with an object that adheres to the
   * IUserInterface interface
   *
   * @param obj
   * Object that adheres to the IUserInterface interface
   */
  public constructor(obj?: IUserInterface) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? ''
    this.userLat = obj?.userLat ?? ''
    this.userLong = obj?.userLong ?? ''
    this.userType = obj?.userType ?? ''
    this.userState = obj?.userState ?? ''
    this.userEmail = obj?.userEmail ?? ''
    this.userAddress = obj?.userAddress ?? ''
    this.userSubRoles = obj?.userSubRoles ?? []
    this.userLastName = obj?.userLastName ?? ''
    this.userStripeId = obj?.userStripeId ?? ''
    this.userBankConnected = obj?.userBankConnected ?? false
    this.userSummary = obj?.userSummary ?? ''
    this.userCategory = obj?.userCategory ?? ''
    this.userPhoneCode = obj?.userPhoneCode ?? ''
    this.userFirstName = obj?.userFirstName ?? ''
    this.vendorname = obj?.vendorname ?? ''
    this.userNameNGram = obj?.userNameNGram ?? []
    this.userPhoneNumber = obj?.userPhoneNumber ?? ''
    this.userDefaultRole = obj?.userDefaultRole ?? 'Owner'
    this.userZone = selectObjToString(obj?.userZone) ?? ''
    this.userProfilePicture = obj?.userProfilePicture ?? ''
    this.userDescription = obj?.userDescription ?? ''
    this.userStripeAccountId = obj?.userStripeAccountId ?? null
    this.userPreviousStripeConnectIds = obj?.userPreviousStripeConnectIds ?? []
    this.userAlternativeNumber = obj?.userAlternativeNumber ?? ''
    this.userAmateur = selectObjToString(obj?.userAmateur) ?? ''
    this.userDiscipline = selectObjToString(obj?.userDiscipline) ?? ''
    this.userSocialSecurityNumber = obj?.userSocialSecurityNumber ?? ''
    this.userNationality = selectObjToString(obj?.userNationality) ?? ''
    this.userEndorsementNumberLetter = obj?.userEndorsementNumberLetter ?? ''
    this.userDOB = interfaceHelper.getFirestoreDate(obj?.userDOB as Date, false)
    this.userConnectAccountStatusMessage = obj?.userConnectAccountStatusMessage ?? null
    this.userName = !!obj?.userName ? (obj.userName?.toLocaleLowerCase?.() ?? obj?.userName) : ''
    this.userTeamMembers = interfaceHelper.getStructuredTeamMembersList(obj?.userTeamMembers ?? [])
    this.userStripeAccountStatus = this.getUserStripeAccountStatus(
      obj?.userStripeAccountStatus
    ) as any
    this.userNotificationsSettings =
      interfaceHelper.getNotificationSettings(obj?.userNotificationsSettings) ?? null
    this.userAccountPrivacy = obj?.userAccountPrivacy ?? null
    this.userLocation = obj?.userLocation ?? { lat: null, lng: null }
    this.websiteUrl = obj?.websiteUrl ?? null
    this.userBrandImages = obj?.userBrandImages ?? []
    this.userBrandVideos = obj?.userBrandVideos ?? []

    this.userSafeSupportExpiry = obj?.userSafeSupportExpiry ?? null
    this.userBackgroundCheckExpiry = obj?.userBackgroundCheckExpiry ?? null
    this.userSafeSupportStatus = selectObjToString(obj?.userSafeSupportStatus) ?? null
    this.userBackgroundCheckStatus = selectObjToString(obj?.userBackgroundCheckStatus) ?? null

    this.userExtraDocument = obj?.userExtraDocument
      ? obj?.userExtraDocument.map((currDoc) => {
          return objsSelectValuesToString(currDoc)
        })
      : []

    this.userFEI = obj?.userFEI
      ? this.structureNumberType(obj?.userFEI, 'userFEI')
      : InitalUserDocument
    this.userASHA = obj?.userASHA
      ? this.structureNumberType(obj?.userASHA, 'userASHA')
      : InitalUserDocument
    this.userAHHS = obj?.userAHHS
      ? this.structureNumberType(obj?.userAHHS, 'userAHHS')
      : InitalUserDocument
    this.userUSEF = obj?.userUSEF
      ? this.structureNumberType(obj?.userUSEF, 'userUSEF')
      : InitalUserDocument
    this.userUSDF = obj?.userUSDF
      ? this.structureNumberType(obj?.userUSDF, 'userUSDF')
      : InitalUserDocument
    this.userAMHA = obj?.userAMHA
      ? this.structureNumberType(obj?.userAMHA, 'userAMHA')
      : InitalUserDocument
    this.userUSEA = obj?.userUSEA
      ? this.structureNumberType(obj?.userUSEA, 'userUSEA')
      : InitalUserDocument
    this.userUPHA = obj?.userUPHA
      ? this.structureNumberType(obj?.userUPHA, 'userUPHA')
      : InitalUserDocument
    this.userWDAA = obj?.userWDAA
      ? this.structureNumberType(obj?.userWDAA, 'userWDAA')
      : InitalUserDocument
    this.userUSHJA = obj?.userUSHJA
      ? this.structureNumberType(obj?.userUSHJA, 'userUSHJA')
      : InitalUserDocument
    this.userARHPA = obj?.userARHPA
      ? this.structureNumberType(obj?.userARHPA, 'userARHPA')
      : InitalUserDocument

    this.userJudgingLicense = selectObjToString(obj?.userJudgingLicense) ?? ''

    this.userOtherDocument = obj?.userOtherDocument ?? InitalUserDocumentAndExpiration
    this.userForeignEndorsement = obj?.userForeignEndorsement ?? InitalUserDocumentAndExpiration

    this.userTwitter = obj?.userTwitter ?? ''
    this.userWebsite = obj?.userWebsite ?? ''
    this.userFacebook = obj?.userFacebook ?? ''
    this.userInstagram = obj?.userInstagram ?? ''
    this.userLinkedin = obj?.userLinkedin ?? ''
    this.userBusinessName = obj?.userBusinessName ?? ''

    this.userPocDetails = obj?.userPocDetails ?? {
      pocFirstName: '',
      pocLastName: '',
      pocFullName: '',
      pocPhone: '',
      pocEmail: '',
    }

    this.userLegalPolicyName = obj?.userLegalPolicyName ?? ''

    this.userMemberships = obj?.userMemberships ?? []

    this.userCreated = this.utcTimestamp({
      key: 'userCreated',
      isTimestamp: true,
      value: obj?.userCreated,
    })

    this.userModified = this.utcTimestamp({
      key: 'userModified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.userModified,
    })

    this._calculateUsernameNGrams()
  }

  public structureNumberType(doc: IUserDocuments, documentName = '') {
    let localDoc
    localDoc = InitalUserDocument

    if (!doc) return localDoc

    if (typeof localDoc === 'object') {
      localDoc = objsSelectValuesToString(doc)
      localDoc.documentFullName = documentName.replace(/user/gi, '')
    }

    if (!localDoc.membershipStatus)
      localDoc.membershipStatus = localDoc.status ? localDoc.status : ''

    return localDoc
  }

  public setUsernameAndCalculateNGram(username_to_set: string) {
    this.userName = username_to_set
    this._calculateUsernameNGrams()
  }

  public getUserStripeAccountStatus(status: any): IUserInterface['userStripeAccountStatus'] {
    let statuses = ['pending', 'connected']
    let status_: IUserInterface['userStripeAccountStatus'] = status ?? null
    if (statuses.includes(status_ as any)) return status_
    else return null
  }

  static fromFirestoreDoc(doc: any) {
    return new UserModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  private _calculateUsernameNGrams() {
    let userName = !!this.userName ? (this?.userName?.toLocaleLowerCase?.() ?? this?.userName) : ''

    let reg = new RegExp(/\s+/, 'g')
    userName = userName?.replace(reg, '')

    const words = userName?.split(' ') ?? []
    const lowercase_words = words.map((word) => word.toLowerCase())

    let ngram_words = new Set<string>()

    lowercase_words.forEach((word) => {
      let word_iterator = cloneDeep(word)

      while (word_iterator.length > 0) {
        ngram_words.add(cloneDeep(word_iterator))
        word_iterator = word_iterator.slice(0, -1)
      }
    })

    if (this.userNameNGram.length > 0) {
      this.userNameNGram = []
    }

    ngram_words.forEach((value) => {
      this.userNameNGram.push(value)
    })
  }
}
