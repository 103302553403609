import React, { useState } from 'react'
import { EventQuestionsFormComponent } from '../../../components/events/forms/questions/EventQuestionsFormComponent'
import { useAppSelector } from '../../../store/hooks'
import { selectedEvent } from '../../../store/events/eventsSlice'
import { useIonRouter } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'

interface IProps {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn?: any
  mainEvent?: any
  nextEventTab: string
  eventTab: string
}

export const ShowsDetailPageEventQuestions: React.FC<IProps> = (props) => {
  const [data, setData] = useState<any>(null)
  const selectedEventR = useAppSelector(selectedEvent)
  const eventId = selectedEventR.Event?.id ?? ''
  const router = useIonRouter()
  const history = useHistory()

  const validFormHandler = async (fields: any) => {
    let updated = true

    await new Promise((resolve) => {
      FirestoreService.updateItem(CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME, eventId, {
        ...fields,
      })
        .then((_: any) => {
          setData(fields)
        })
        .catch((reason) => {
          updated = false
          console.error('validFormHandler:' + reason)
        })
        .finally(() => {
          resolve(true)
        })
    })

    return {
      updated,
      onSetEventTab:
        props.nextEventTab === '' ? props.onSetEventTab : props.onSetEventTab(props?.nextEventTab),
      onSetNextEventTab: props.onSetNextEventTab(''),
    }
  }

  const validAndValidFormHandler = async (data: any) => await validFormHandler(data)

  const invalidFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    alert('invalid')
  }

  const invalidAndValidFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    alert('invalid')
    router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
    history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
  }

  return (
    <EventQuestionsFormComponent
      data={data}
      onValid={validFormHandler}
      onInvalid={invalidFormHandler}
      onValidAnExit={validAndValidFormHandler}
      onInvalidAndExit={invalidAndValidFormHandler}
      nextEventTab={props.nextEventTab}
      eventTab={props.eventTab}
    />
  )
}
