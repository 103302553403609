export const horseBreedList = [
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'American Paint Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'americanpainthorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'American Quarter Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'americanquarterhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'American Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'americanwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Appaloosa',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'appaloosa',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Arabian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'arabian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Belgian Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'belgianwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Connemara',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'connemara',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Dutch Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'dutchwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Friesian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'friesian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Gypsy Vanner',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'gypsyvanner',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Haflinger',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'haflinger',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Hanoverian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'hanoverian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Holsteiner',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'holsteiner',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Irish Sport Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'irishsporthorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Lusitano',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'lusitano',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Missouri Fox Trotter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'missourifoxtrotter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Morgan',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'morgan',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Mustang',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'mustang',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Oldenburg',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'oldenburg',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Pony of the Americas',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'ponyoftheamericas',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Percheron',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'percheron',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Selle Francais',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'sellefrancais',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Standardbred',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'standardbred',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Tennessee Walking Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'tennesseewalkinghorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Thoroughbred',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'thoroughbred',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Trakehner',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'trakehner',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Welsh',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'welsh',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Westphalian Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'westphalianwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Disabled',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'disabled',
    isDisabled: true,
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Abtenauer',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'abtenauer',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Abyssinian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'abyssinian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Aegidienberger',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'aegidienberger',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Akhal-Teke',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'akhalteke',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Albanian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'albanian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Altai',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'altai',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Alter Real',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'alterreal',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'American Belgian Draft',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'americanbelgiandraft',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'American Cream Draft',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'americancreamdraft',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'American Indian Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'americanindianhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'American Saddlebred',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'americansaddlebred',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Andalusian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'andalusian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Andravida',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'andravida',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Anglo-Arabian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'angloarabian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Anglo-Arabo Sardo',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'angloarabosardo',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Anglo-Kabarda',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'anglokabarda',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Ardennes',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'ardennes',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Arenberg-Nordkirchen',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'arenbergnordkirchen',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Argentine Criollo',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'argentinecriollo',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Asturcon',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'asturcon',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Augeron',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'augeron',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Asian wild horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'asianwildhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Assateague horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'assateaguehorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Australian Brumby',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'australianbrumby',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Australian Draught Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'australiandraughthorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Australian Stock Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'australianstockhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Austrian Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'austrianwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Auvergne',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'auvergne',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Auxois',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'auxois',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Azerbaijan',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'azerbaijan',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Azteca',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'azteca',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Baise',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'baise',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Bale',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'bale',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Balearic',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'balearic',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Balikun',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'balikun',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Baluchi',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'baluchi',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Banker',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'banker',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Barb',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'barb',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Bardigiano',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'bardigiano',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Bashkir',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'bashkir',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Bashkir Curly',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'bashkircurly',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Basque Mountain Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'basquemountainhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Bavarian Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'bavarianwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Belgian Draught',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'belgiandraught',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Belgian Sport Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'belgiansporthorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Belgian Trotter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'belgiantrotter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Bhutia',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'bhutia',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Black Forest Coldblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'blackforestcoldblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Blazer',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'blazer',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Boerperd',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'boerperd',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Borana',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'borana',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Bosnian Mountain',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'bosnianmountain',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Boulonnais',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'boulonnais',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Brabant',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'brabant',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Brandenburger',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'brandenburger',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Brazilian Sport Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'braziliansporthorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Breton',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'breton',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Brumby',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'brumby',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Budyonny',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'budyonny',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Burguete',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'burguete',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Burmese',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'burmese',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Byelorussian Harness Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'byelorussianharnesshorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Calabrese',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'calabrese',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Camargue',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'camargue',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Camarillo White Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'camarillowhitehorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Campeiro',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'campeiro',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Campolina',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'campolina',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Canadian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'canadian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Canadian Pacer',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'canadianpacer',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Carolina Marsh Tacky',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'carolinamarshtacky',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Carthusian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'carthusian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Caspian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'caspian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Castilian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'castilian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Castillonnais',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'castillonnais',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Catria',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'catria',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Cavallo Romano della Maremma Laziale',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'cavalloromanodellamaremmalaziale',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Cerbat Mustang',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'cerbatmustang',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Chickasaw',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'chickasaw',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Chilean Corralero',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'chileancorralero',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Choctaw',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'choctaw',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Cleveland Bay',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'clevelandbay',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Clydesdale',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'clydesdale',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Cob',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'cob',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Colonial Spanish Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'colonialspanishhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Colorado Ranger',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'coloradoranger',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Coldblood trotter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'coldbloodtrotter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Comtois',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'comtois',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Corsican',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'corsican',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Costa Rican Saddle Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'costaricansaddlehorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Cretan',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'cretan',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Criollo',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'criollo',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Croatian Coldblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'croatiancoldblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Cuban Criollo',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'cubancriollo',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Cumberland Island',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'cumberlandisland',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Curly',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'curly',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Czech Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'czechwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Daliboz',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'daliboz',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Danish Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'danishwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Danube Delta',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'danubedelta',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Dole Gudbrandsdal',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'dolegudbrandsdal',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Don',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'don',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Dongola',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'dongola',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Draft Trotter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'drafttrotter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Dutch Harness Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'dutchharnesshorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Dutch Heavy Draft',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'dutchheavydraft',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Dzungarian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'dzungarian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'East Bulgarian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'eastbulgarian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'East Friesian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'eastfriesian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Estonian Draft',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'estoniandraft',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Estonian Native',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'estoniannative',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Ethiopian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'ethiopian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Falabela',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'falabela',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Faroese',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'faroese',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Finnhorse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'finnhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Flemish',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'flemish',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Fleuve',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'fleuve',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Fjord',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'fjord',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Florida Cracker Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'floridacrackerhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Foutanké',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'foutank',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Frederiksborger',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'frederiksborger',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Freiberger',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'freiberger',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'French Trotter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'frenchtrotter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Friesian cross',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'friesiancross',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Friesian horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'friesianhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Friesian Sporthorse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'friesiansporthorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Furioso-North Star',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'furiosonorthstar',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Galiceno or Galiceno',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'galicenoorgaliceno',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Galician Pony (Caballo de pura raza Gallega)',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'galicianpony(caballodepurarazagallega)',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Gelderland',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'gelderland',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Georgian Grande Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'georgiangrandehorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'German Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'germanwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Giara Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'giarahorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Gidran',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'gidran',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Groningen Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'groningenhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Heck',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'heck',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Heihe',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'heihe',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Henson',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'henson',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Hequ',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'hequ',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Hirzai',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'hirzai',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Hispano-Breton',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'hispanobreton',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Hispano-Arabe',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'hispanoarabe',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Horro',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'horro',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Hungarian Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'hungarianwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Icelandic horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'icelandichorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Indian Country-bred',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'indiancountrybred',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Iomud',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'iomud',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Irish Draught',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'irishdraught',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Italian Heavy Draft',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'italianheavydraft',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Italian Trotter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'italiantrotter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Jaca Navarra',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'jacanavarra',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Jeju horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'jejuhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Jutland horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'jutlandhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kabarda',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kabarda',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kafa',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kafa',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kaimanawa',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kaimanawa',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kalmyk',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kalmyk',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Karabair',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'karabair',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Karabakh',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'karabakh',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Karossier',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'karossier',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Karachai',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'karachai',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kathiawari',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kathiawari',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kazakh',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kazakh',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kentucky Mountain Saddle Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kentuckymountainsaddlehorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kiger Mustang',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kigermustang',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kinsky',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kinsky',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Konik',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'konik',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kyrgyz',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kyrgyz',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kisber Felver',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kisberfelver',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kiso',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kiso',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kladruber',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kladruber',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Knabstrupper',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'knabstrupper',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kundudo',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kundudo',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Kustanair',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'kustanair',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Latvian horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'latvianhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Lipizzan or Lipizzaner',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'lipizzanorlipizzaner',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Lithuanian Heavy Draught',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'lithuanianheavydraught',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Ljutomer Trotter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'ljutomertrotter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Lokai',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'lokai',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Losino',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'losino',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Luxembourg Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'luxembourgwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Lyngshest',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'lyngshest',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: "M'Bayar",
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'mbayar',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: "M'Par",
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'mpar',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Malopolski',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'malopolski',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Mallorquin',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'mallorquin',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Mangalarga',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'mangalarga',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Mangalarga Marchador',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'mangalargamarchador',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Maremmano',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'maremmano',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Marismeno',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'marismeno',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Marsh Tacky',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'marshtacky',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Marwari horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'marwarihorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Mecklenburger',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'mecklenburger',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Medimurje',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'medimurje',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Menorquin',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'menorquin',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Merens',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'merens',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Messara',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'messara',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Mezohegyesi sport-horse (sportlo)',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'mezohegyesisporthorse(sportlo)',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Metis Trotter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'metistrotter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Miniature',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'miniature',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Misaki',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'misaki',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Monchino',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'monchino',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Mongolian Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'mongolianhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Mongolian Wild Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'mongolianwildhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Monterufolino',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'monterufolino',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Morab',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'morab',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Mountain Pleasure Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'mountainpleasurehorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Moyle horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'moylehorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Murakozi',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'murakozi',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Murgese',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'murgese',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Namib Desert Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'namibdeserthorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Nangchen',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'nangchen',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'National Show Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'nationalshowhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Nez Perce Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'nezpercehorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Nivernais horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'nivernaishorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Nokota',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'nokota',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Noma',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'noma',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Nonius',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'nonius',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Nooitgedachter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'nooitgedachter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Nordlandshest',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'nordlandshest',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Noriker',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'noriker',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Norman Cob',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'normancob',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Norsk Kaldblodstraver',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'norskkaldblodstraver',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'North American Single-footing Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'northamericansinglefootinghorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'North Swedish Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'northswedishhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Norwegian Fjord',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'norwegianfjord',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Novoolexandrian Draught',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'novoolexandriandraught',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Novokirghiz',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'novokirghiz',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Oberlander Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'oberlanderhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Ogaden',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'ogaden',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Online',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'online',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Orlov Trotter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'orlovtrotter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Ostfriesen',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'ostfriesen',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Pampa',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'pampa',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Paso Fino',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'pasofino',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Pentro',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'pentro',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Persano',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'persano',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Peruvian Paso',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'peruvianpaso',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Pintabian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'pintabian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Pleven',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'pleven',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Poitevin horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'poitevinhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Posavac',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'posavac',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Pottok',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'pottok',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Priob',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'priob',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Pryor Mountain Mustang',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'pryormountainmustang',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: "Przewalski's",
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'przewalskis',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Pura Raza Espanola',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'purarazaespanola',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Purosangue Orientale',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'purosangueorientale',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Qatgani',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'qatgani',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Quarab',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'quarab',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Racking',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'racking',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Retuerta',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'retuerta',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Rhenish German Coldblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'rhenishgermancoldblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Rhinelander',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'rhinelander',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Riwoche',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'riwoche',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Rocky Mountain',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'rockymountain',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Romanian Sporthorse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'romaniansporthorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Rottaler',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'rottaler',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Russian Don',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'russiandon',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Russian Heavy Draft',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'russianheavydraft',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Russian Trotter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'russiantrotter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Saddlebred',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'saddlebred',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Salernitano',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'salernitano',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Samolaco',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'samolaco',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Sanfratellano',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'sanfratellano',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Santa Cruz Island horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'santacruzislandhorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Sarcidano',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'sarcidano',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Sardinian Anglo-Arab',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'sardinianangloarab',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Schleswig Coldblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'schleswigcoldblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Schwarzwalder Kaltblut',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'schwarzwalderkaltblut',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Selale',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'selale',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Sella Italiano',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'sellaitaliano',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Senner',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'senner',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Shagya Arabian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'shagyaarabian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Shan Myinn',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'shanmyinn',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Shire',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'shire',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Siciliano indigeno',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'sicilianoindigeno',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Silesian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'silesian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Sindhi',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'sindhi',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Slovenian Cold-blood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'sloveniancoldblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Sorraia',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'sorraia',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Sokolski',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'sokolski',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'South German Coldblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'southgermancoldblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Soviet Heavy Draft',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'sovietheavydraft',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Spanish Barb',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'spanishbarb',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Spanish Jennet',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'spanishjennet',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Spanish Mustang',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'spanishmustang',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Spanish-Norman',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'spanishnorman',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Spanish Tarpan',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'spanishtarpan',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Spanish Trotter',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'spanishtrotter',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Spiti',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'spiti',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Spotted Saddle',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'spottedsaddle',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Suffolk Punch',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'suffolkpunch',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Svensk Kallblodstravare',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'svenskkallblodstravare',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Swedish Ardennes',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'swedishardennes',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Swedish Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'swedishwarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Swiss Warmblood',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'swisswarmblood',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Taishu',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'taishu',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Takhi',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'takhi',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Tawleed',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'tawleed',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Tchernomor',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'tchernomor',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Tersk',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'tersk',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Tinker',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'tinker',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Tiger',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'tiger',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Tokara',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'tokara',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Tolfetano',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'tolfetano',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Tori',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'tori',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Trait Du Nord',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'traitdunord',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Tsushima',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'tsushima',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Tuigpaard',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'tuigpaard',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Ukrainian Riding Horse',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'ukrainianridinghorse',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Unmol',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'unmol',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Uzunyayla',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'uzunyayla',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Ventasso',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'ventasso',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Virginia highlander',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'virginiahighlander',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Vlaamperd',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'vlaamperd',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Vladimir Heavy Draft',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'vladimirheavydraft',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Vyatka',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'vyatka',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Waler',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'waler',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Walkaloosa',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'walkaloosa',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Warlander',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'warlander',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Wielkopolski',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'wielkopolski',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Wurttemberger',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'wurttemberger',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Xilingol',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'xilingol',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Yakutian',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'yakutian',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Yili',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'yili',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Yonaguni',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'yonaguni',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Zangersheide',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'zangersheide',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Zaniskari',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'zaniskari',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Zweibrucker',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'zweibrucker',
  },
  {
    dark_icon: '/assets/cp_icons/Biotech-1.svg',
    label: 'Zemaitukas',
    light_icon: '/assets/cp_icons/Biotech.svg',
    value: 'zemaitukas',
  },
]
