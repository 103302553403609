import { useIonRouter } from '@ionic/react'
import { AutorenewRounded } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import helpers from '../../commonHelpers/helpers'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import FirestoreService from '../../services/firestoreService'

import { selectedEvent, setEventMailSend, setisPublished } from '../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectProfileData, selectUserBankConnected } from '../../store/user/userSlice'
import DrawerComponent from '../events/forms/review-publish/Components/DrawerComponent'

import { where } from 'firebase/firestore'
import { UserLegalClausesModel } from '../../models/user-legal-clauses/user-legal-clauses.model'
import { UserModel } from '../../models/users/user.model'

import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import fakeDocumentList, { IDocument } from '../../fakeData/fakeDocumentList'
import { getUserFullName } from '../../helpers/helpers'
import { IEventStaffInterface } from '../../models/event-staff/event-staff.interface'
import { EventStaffModel } from '../../models/event-staff/event-staff.model'
import { EventModel } from '../../models/events/event.model'
import { getConvertedData } from '../../models/interface.helper'
import { RiderTeamMemberModel } from '../../models/rider-team-member/riderTeamMember.model'
import { USDFLiabilityWaiverPDFTemplate } from '../../templates/pdf/USDFLiabilityWaiver/USDFLiabilityWaiverPDFTemplate'
import { USEALiabilityWaiverPDFTeamplate } from '../../templates/pdf/USEALiabilityWaiver/USEALiabilityWaiverPDFTeamplate'
import { USEFLiabilityWaiverPDFTemplate } from '../../templates/pdf/USEFLiabilityWaiver/USEFLiabilityWaiverPDFTemplate'
import { USHJALiabilityWaiverPDFTeamplate } from '../../templates/pdf/USHJALiabilityWaiverPDFTeamplate/USHJALiabilityWaiverPDFTeamplate'
import { GeneralLiabilityWaiverPDFTeamplate } from '../../templates/pdf/generalLiabilityWaiver/GeneralLiabilityWaiverPDFTeamplate'
import { EventDetailsModel } from '../../models/event-details/event-details.model'
import { httpService } from '../../services/httpService'
import { useHistory } from 'react-router-dom'

const LittileButton: React.FC<{
  caption: string
  onClick: () => void
  className: string
  loading?: boolean
}> = ({ caption, className, onClick, loading }) => (
  <button className={`${className}`} onClick={onClick}>
    {loading ? (
      <AutorenewRounded fontSize="small" className="animate-spin mx-auto" />
    ) : (
      <span>{caption}</span>
    )}
  </button>
)

const PublishDoneModal: React.FC<{ show: boolean; handleModal: any; dataToPassOn: any }> = ({
  show,
  handleModal,
  dataToPassOn,
}) => {
  const [step, setStep] = useState('1')
  const [loading, setLoading] = useState(false)
  const [clauses, setClauses] = useState<any[]>([])

  const router = useIonRouter()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const toasterFunctions = useToasterHelper()

  const selectEvent: any = useAppSelector(selectedEvent)

  const eventFees = selectEvent?.EventFees ?? null
  const eventDetails = selectEvent?.EventDetails ?? null
  const onSetEventTab = dataToPassOn?.onSetEventTab ?? (() => {})
  const { EventStaff, EventDetails, EventPaperwork } = selectEvent
  const userDetails = useAppSelector(selectProfileData)
  const userStripeStatus = userDetails.userStripeAccountStatus
  const userBankConnected = useAppSelector(selectUserBankConnected)

  const handleStaffemailsend = async () => {
    let eventStaff = cloneDeep(EventStaff) as IEventStaffInterface
    let staffData: any = eventStaff.eventStaff ?? []
    const otherstaff = eventStaff.otherStaff ?? []
    if (staffData.find((data: any) => data.title !== '')) {
      staffData = [...staffData, ...otherstaff]
    }
    let promises: Promise<any>[] = []
    let docarray: string[] = []
    let userIds: string[] = []
    let currindex = -1

    Object.keys(EventPaperwork).forEach((data) => {
      if (data === 'USDFWaiverAndReleaseOfLiability' && EventPaperwork[data]) {
        docarray.push('usdf')
      } else if (data === 'USEAWaiverAndReleaseOfLiability' && EventPaperwork[data]) {
        docarray.push('usea')
      } else if (data === 'USEFEntryAgreement' && EventPaperwork[data]) {
        docarray.push('usef')
      } else if (data === 'USEFWaiverAndReleaseOfLiability' && EventPaperwork[data]) {
        docarray.push('usef')
      } else if (data === 'USHJAWaiverAndReleaseOfLiability' && EventPaperwork[data]) {
        docarray.push('ushja')
      } else if (data === 'universalOrganizerWaver' && EventPaperwork[data]) {
        docarray.push('general')
      }
    })
    await asyncWhileLoop({
      loopCount: staffData?.length,
      functionToFire: async (index) => {
        currindex = index
        let staffrole =
          staffData[currindex].title !== '' ? staffData[currindex].title : 'Other Staff'

        const currData = staffData[index].value
        await asyncWhileLoop({
          loopCount: currData?.length,
          functionToFire: async (innerindex) => {
            const innerData = currData[innerindex]

            const doc = await FirestoreService.getItem(
              CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
              innerData
            )
            const userData = UserModel.fromFirestoreDoc(doc).toObject()

            if (!userData.id) return

            userIds.push(userData.id)

            let ridersTeamMember = getConvertedData({
              ...new RiderTeamMemberModel({
                eventId: dataToPassOn.eventId,
                registrationDocId: userData.id ?? null,
                registrationByDayDocId: userData.id ?? null,
                registrationByDayUuid: '',
                userId: userData.id,
                signedStatus: 'Shared',
                mailLog: [],
                teamMemberId: userData.id,
                teamMemberName: getUserFullName(userData),
                teamMemberEmail: userData.userEmail,
                teamMemberCountry: userData.userState,
                teamMemberPhoneNumber: userData.userPhoneNumber,
                teamMemberPhoneCode: userData.userPhoneCode,
                teamMemberDob: userData.userDOB,
                teamMemberRole: staffrole,
                update: false,
                create: true,
                delete: false,
                eventName: EventDetails.competitionName,
                riderId: userData.id,
                riderNameNGram: [],
                riderDob: userData.userDOB,
                riderName: getUserFullName(userData) ?? null,
                riderEmail: userData.userEmail,
                riderCountry: userData.userState,
                riderPhoneNumber: userData.userPhoneNumber,
                riderPhoneCode: userData.userPhoneCode,
                riderProfilePicture: userData.userProfilePicture,
                teamMemberNameNGram: [],
                teamMemberProfilePicture: userData.userProfilePicture,
                created: new Date(),
                modified: new Date(),
                isrider: false,
              }).toObject(),
            })

            const data = {
              eventId: dataToPassOn.eventId,
              userData: userDetails,
              EventDetails,
              organizerDetails: userDetails,
              eventDraftId: '',
            }
            ridersTeamMember = { ...ridersTeamMember, isrider: false } as any

            await httpService({
              url: `staff_mail_send`,
              method: 'POST',
              data: {
                memberAdded: userDetails.userName,
                competitorName: ridersTeamMember.teamMemberName,
                receiverMail: userData.userEmail,
              },
            })
            docarray.forEach((types) => {
              switch (types) {
                case 'general':
                  promises.push(
                    GeneralLiabilityWaiverPDFTeamplate({
                      ...data,
                      ridersTeamMember: ridersTeamMember,
                      paperwork: fakeDocumentList.find(
                        (data) => data.document_type === types
                      ) as IDocument,
                      isMature: true,
                      clauses,
                      stringArray: ['Staff'],
                      isStaff: true,
                    })
                  )
                  break

                case 'usdf':
                  promises.push(
                    USDFLiabilityWaiverPDFTemplate({
                      ...data,
                      ridersTeamMember: ridersTeamMember,
                      paperwork: fakeDocumentList.find(
                        (data) => data.document_type === types
                      ) as IDocument,
                      isMature: true,
                      stringArray: ['Staff'],
                      isStaff: true,
                    })
                  )
                  break

                case 'usef':
                  promises.push(
                    USEFLiabilityWaiverPDFTemplate({
                      ...data,
                      ridersTeamMember: ridersTeamMember,
                      isMature: true,
                      paperwork: fakeDocumentList.find(
                        (data) => data.document_type === types
                      ) as IDocument,
                      stringArray: ['Staff'],
                      isStaff: true,
                    })
                  )
                  break

                case 'usea':
                  promises.push(
                    USEALiabilityWaiverPDFTeamplate({
                      ...data,
                      ridersTeamMember: ridersTeamMember,
                      isMature: true,
                      paperwork: fakeDocumentList.find(
                        (data) => data.document_type === types
                      ) as IDocument,
                      stringArray: ['Staff'],
                      isStaff: true,
                    })
                  )
                  break

                case 'ushja':
                  promises.push(
                    USHJALiabilityWaiverPDFTeamplate({
                      ...data,
                      ridersTeamMember: ridersTeamMember,
                      isMature: true,
                      paperwork: fakeDocumentList.find(
                        (data) => data.document_type === types
                      ) as IDocument,
                      stringArray: ['Staff'],
                      isStaff: true,
                    })
                  )
                  break
              }
            })

            await Promise.all(promises)
          },
        })

        staffData[currindex] = {
          ...staffData[currindex],
          isMailSend: userIds,
        }
      },
    })

    eventStaff.eventStaff = staffData
    const updated = new EventStaffModel(eventStaff)

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_STAFF.NAME,
      dataToPassOn.eventId,
      updated.toFirestore()
    )

    dispatch(setEventMailSend(eventStaff))
  }

  const updateEventStatus = async (updateEventStatus: any) => {
    const tomorrowsDate = new Date()
    const registrationOpenDate = eventDetails?.competitionRegistrationOpenDate
    tomorrowsDate.setDate(tomorrowsDate.getDate() + 1)

    if (!registrationOpenDate) {
      setLoading(false)
      toasterFunctions.error({
        message: MESSAGES_CONST.INVALID_REGISTRATION_OPEN_DATE,
      })
      return
    }

    setLoading(true)

    if (!userBankConnected) {
      setLoading(false)
      return toasterFunctions.error({
        message:
          userStripeStatus === null
            ? MESSAGES_CONST.PLEASE_CONNECT_STRIPE
            : MESSAGES_CONST.CONNECT_STATUS_IN_PENDING,
      })
    }

    let updatedEvent = new EventModel({
      ...updateEventStatus,
      isPublished: true,
    })

    try {
      updatedEvent.status = 'current'

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
        dataToPassOn.eventId,
        updatedEvent.toFirestore()
      )

      await handleStaffemailsend()

      setStep('2')
      toasterFunctions.info({ message: 'Mails sent successfully' })
      toasterFunctions.success({ message: 'Your event is now live' })

      dispatch(setisPublished(true))

      return true
    } catch (error) {
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
    }
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const handleSubmit = async (submitType: string) => {
    let availiablespots: number = 0
    try {
      setLoading(true)

      if (!dataToPassOn.eventId)
        return toasterFunctions.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      if (submitType === 'saveAndExit') {
        dataToPassOn.submissionType = 'saveAndExit'
      } else {
        dataToPassOn.updated_data.status = 'current'
        dataToPassOn.updated_data.u = true
      }

      const { EventDetails, EventFees } = dataToPassOn.updated_data
      const { registrationFees } = EventFees
      if (registrationFees.length) {
        registrationFees.forEach((data: any) => {
          if (data.status) {
            availiablespots = availiablespots + data.available
          }
        })
      }

      const mutatedEventDetails = { ...EventDetails, registrationAvailableCount: availiablespots }
      dataToPassOn.updated_data.EventDetails = mutatedEventDetails
      dataToPassOn.mainEvent.registrationAvailableCount = availiablespots
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_DETAILS.NAME,
        dataToPassOn.eventId,
        new EventDetailsModel(mutatedEventDetails).toFirestore()
      )

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        dataToPassOn.eventId,
        dataToPassOn.updated_data.toFirestore()
      )

      if (dataToPassOn.submissionType === 'publish') {
        updateEventStatus(dataToPassOn.mainEvent)
      } else {
        handleModal(false, MODAL_CONSTS.EVENT_PUBLISH_DONE_MODAL)
        router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${dataToPassOn.eventId}`)
        history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${dataToPassOn.eventId}`)
      }
    } catch (reason) {
      toasterFunctions.error({ message: 'Something went wrong! please try again later' })
      helpers.logger({
        isError: true,
        message: reason,
      })
    }
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onViewEventButtonPressed = (goToInvitesPage?: boolean) => {
    handleModal(false, MODAL_CONSTS.EVENT_PUBLISH_DONE_MODAL, null)

    if (goToInvitesPage)
      return dataToPassOn.onSetEventTab?.(CONST.UI.EVENTS.CREATE_EDIT.TABS.SEND_NOTIFICATIONS.VALUE)

    const EVENT_DETAIL_PAGE_LINK = `${CONST.ROUTES.EVENT_DETAILS.URL}/${dataToPassOn.eventId}`
    router.push(EVENT_DETAIL_PAGE_LINK)
    history.push(EVENT_DETAIL_PAGE_LINK)
  }

  async function getClauseData(ownerId: string) {
    const data: any[] = []
    const result = await FirestoreService.filterItems(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_LEGAL_CLAUSES.NAME,
      [where('ownerId', '==', ownerId)]
    )

    if (result.size) {
      result?.forEach((doc) => {
        let clauseModelData = UserLegalClausesModel.fromFirestoreDoc(doc).toObject()
        data.push(clauseModelData)
      })
    }
    setClauses(data)
  }

  useEffect(() => {
    if (userDetails) {
      getClauseData(userDetails.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <DrawerComponent
      show={show}
      onHide={() => handleModal(false, MODAL_CONSTS.EVENT_PUBLISH_DONE_MODAL)}
      title=""
      maxWidth={540}
    >
      {step === '1' ? (
        <div className="text-center text-[#122B46]">
          <img
            src="/assets/cp_icons/eventPlaceholder.svg"
            alt="success"
            className="mx-auto w-4/5"
          />
          <div className="text-[30px]">
            <h2 className="text-2xl my-6 font-bold">Publish event?</h2>
            <p className="text-base mb-2 px-10">
              Click publish to make your event visible to the public.
            </p>
            <p className="text-base mb-2 px-10">
              The public will not be able to register until your start registration date.
            </p>
            {/* <p className="text-base mb-2 px-10">
                                Sponsors and vendors will be able to purchase products immediately.
                            </p> */}
          </div>
          <div className="flex flex-col items-center gap-2 justify-center my-8">
            <LittileButton
              className={clsx(
                'bg-SeabiscuitMainThemeColor hover:bg-[#D70443] rounded-2xl text-white px-6 py-3 w-4/5',
                loading && 'pointer-events-none bg-[#D70443] text-[#1F417380]'
              )}
              caption="Publish"
              loading={dataToPassOn.submissionType === 'publish' && loading}
              onClick={() => handleSubmit('publish')}
            />
            <LittileButton
              className={clsx(
                'bg-[#1F41731A] text-[#1F417380] rounded-2xl px-6 py-3 w-4/5',
                loading && 'pointer-events-none'
              )}
              caption="Save & Exit"
              loading={dataToPassOn.submissionType === 'saveAndExit' && loading}
              onClick={() => handleSubmit('saveAndExit')}
            />
          </div>
        </div>
      ) : (
        <div className="text-center text-SeabiscuitDark200ThemeColor">
          <img
            src="/assets/placeholders/publisheventsuccess.svg"
            alt="success"
            className="mx-auto w-60"
          />
          <div className="text-[30px] mt-12">
            <h2 className="text-[30px] font-bold">Success</h2>
            <p className="text-[19px] my-3">Your event is now live</p>
          </div>
          {/* <div className="flex-col items-center gap-2 justify-center mb-[65px]"> */}
          <div className="my-2 mt-10">
            <LittileButton
              className="bg-SeabiscuitMainThemeColor hover:bg-[#d70443] rounded-lg text-white px-6 h-12 w-80"
              caption="View Event"
              onClick={() => onViewEventButtonPressed()}
            />
          </div>
          <div className="mb-4">
            <LittileButton
              className="border border-solid hover:text-[#d70443] border-SeabiscuitMainThemeColor hover:border-[#d70443] rounded-lg text-SeabiscuitMainThemeColor px-6 h-12 w-80"
              caption="Send invites"
              onClick={() => onViewEventButtonPressed(true)}
            />
          </div>
        </div>
      )}
    </DrawerComponent>
  )
}
export default PublishDoneModal

type IAsyncWhileLoopArgs = {
  loopCount: number
  functionToFire: (index: number) => Promise<any>
}

type IAsyncWhileLoopFn = (args: IAsyncWhileLoopArgs) => Promise<void>

const asyncWhileLoop: IAsyncWhileLoopFn = async (args) => {
  let currLoopIndex = 0

  let { loopCount, functionToFire } = args

  while (currLoopIndex < loopCount) {
    await functionToFire(currLoopIndex)
    currLoopIndex++
  }
}
