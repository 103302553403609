import clsx from 'clsx'
import { FC } from 'react'

interface MembershipsTabsProps {
  setActiveTab: (tab: 'Memberships' | 'Payments') => void
  activeTab: 'Memberships' | 'Payments'
}
export const MembershipsTabs: FC<MembershipsTabsProps> = ({ activeTab, setActiveTab }) => {

  return (
    <div className="flex items-center gap-2 mt-6 pb-6 border-b bordr-b-SeabiscuitLightThemeColorD3">
      <div className="flex flex-wrap items-center gap-2">
        <button
          className={clsx(
            'py-2 px-4 rounded-xl hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor transition-all',
            activeTab === 'Memberships' &&
              'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor'
          )}
          onClick={() => setActiveTab('Memberships')}
        >
          Memberships
        </button>
        <button
          className={clsx(
            'py-2 px-4 rounded-xl hover:bg-SeabiscuitMainThemeColor/5 hover:text-SeabiscuitMainThemeColor transition-all',
            activeTab === 'Payments' &&
              'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor'
          )}
          onClick={() => setActiveTab('Payments')}
        >
          Payments
        </button>
      </div>
    </div>
  )
}
