import CompetitorBillsRootPage from '../../pages/Biils/CompetitorBills/CompetitorBillsRootPage'
import ComingSoon from '../../pages/comingsoon/comingSoon'
import HelpRootPage from '../../pages/help/root/HelpRootPage'
import PaperworkRootPage from '../../pages/paperwork/PaperworkRootPage'
import NotificationsRootPage from '../../pages/notifications/NotificationsRootPage'
import MessagesPage from '../../pages/messages/MessagesPage'
/**
 * @TODO todo
 */
const IMPORTS = {
  PAGES: {
    HELP_PAGE: HelpRootPage,
    COMING_SOON: ComingSoon,
    PAPERWORK: PaperworkRootPage,
    BILLS: CompetitorBillsRootPage,
    NOTIFICATION_PAGE: NotificationsRootPage,
    MESSAGES: MessagesPage,
  },
}

export default IMPORTS
