import React from 'react'

interface IProps {}

export const IconAttach: React.FC<IProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 10 10">
      <path
        className={'opacity-50 group-hover:fill-[#F7074F] group-hover:opacity-100'}
        fill="#122B46"
        d="M7.106.01C6.4.01 5.692.278 5.154.816L.109 5.86a.375.375 0 10.53.53l5.045-5.044a2.006 2.006 0 012.845 0 2.006 2.006 0 010 2.845l-4.76 4.76a1.034 1.034 0 01-1.47 0 1.034 1.034 0 010-1.47l3.84-3.84a.375.375 0 10-.53-.53L1.77 6.95a1.795 1.795 0 000 2.53 1.795 1.795 0 002.53 0l4.76-4.76a2.767 2.767 0 000-3.905A2.753 2.753 0 007.107.01z"
      ></path>
    </svg>
  )
}
