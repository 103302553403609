import { homeOutline, homeSharp, settingsOutline } from 'ionicons/icons'
import { CONST } from '../const/const'

interface AppPage {
  url: string
  iosIcon: string
  mdIcon: string
  title: string
  activeIcon?: string
  modal?: string
  icon?: string
}

const PAGES = {
  COMPETITOR: [
    {
      title: CONST.ROUTES.HOME.TITLE,
      url: CONST.ROUTES.HOME.URL,
      iosIcon: homeOutline,
      mdIcon: homeSharp,
      activeIcon: 'assets/cp_icons/Search-2.svg',
      icon: 'assets/cp_icons/Search-4.svg',
    },
    {
      title: CONST.ROUTES.FOLLOWING_USER.TITLE,
      url: CONST.ROUTES.FOLLOWING_USER.URL,
      iosIcon: homeOutline,
      mdIcon: homeSharp,
      activeIcon: 'assets/cp_icons/People-2.svg',
      icon: 'assets/cp_icons/People-4.svg',
    },
    {
      title: CONST.ROUTES.MY_EVENT.TITLE,
      url: CONST.ROUTES.MY_EVENT.URL,
      iosIcon: homeOutline,
      mdIcon: homeSharp,
      activeIcon: 'assets/cp_icons/Calendar-Check-Active.svg',
      icon: 'assets/cp_icons/Calendar-Check.svg',
    },
    {
      title: CONST.ROUTES.PROFILE.TITLE,
      url: CONST.ROUTES.ALL_EVENT.URL,
      iosIcon: settingsOutline,
      mdIcon: settingsOutline,
      activeIcon: 'assets/cp_icons/User-2.svg',
      icon: 'assets/cp_icons/User-4.svg',
    },
    {
      title: CONST.ROUTES.BILLS.TITLE,
      url: CONST.ROUTES.BILLS.URL,
      iosIcon: settingsOutline,
      mdIcon: settingsOutline,
      activeIcon: 'assets/cp_icons/Bill-2.svg',
      icon: 'assets/cp_icons/Bill-4.svg',
    },
    {
      title: CONST.ROUTES.PAPERWORK.TITLE,
      url: CONST.ROUTES.PAPERWORK.URL,
      iosIcon: settingsOutline,
      mdIcon: settingsOutline,
      activeIcon: 'assets/cp_icons/Quill Pen-2.svg',
      icon: 'assets/cp_icons/Quill Pen-4.svg',
    },
    {
      title: CONST.ROUTES.RULEBOOK.TITLE,
      url: CONST.ROUTES.RULEBOOK.URL,
      modal: CONST.ROUTES.RULEBOOK.MODAL,
      iosIcon: settingsOutline,
      mdIcon: settingsOutline,
      activeIcon: 'assets/cp_icons/icon-rulebook.svg',
      icon: 'assets/cp_icons/icon-rulebook.svg',
    },
    {
      title: CONST.ROUTES.HELP.TITLE,
      url: CONST.ROUTES.HELP.URL,
      iosIcon: settingsOutline,
      mdIcon: settingsOutline,
      activeIcon: 'assets/og_icons/Help-2.svg',
      icon: 'assets/og_icons/Help-4.svg',
    },
  ] as AppPage[],
  ORGANIZER: [
    {
      title: CONST.ROUTES.ORGANIZER_HOME.TITLE,
      url: CONST.ROUTES.ORGANIZER_HOME.URL,
      iosIcon: homeOutline,
      mdIcon: homeSharp,
      activeIcon: 'assets/cp_icons/Home-2.svg',
      icon: 'assets/cp_icons/Home-4.svg',
    },
    {
      title: CONST.ROUTES.PROFILE.TITLE,
      url: CONST.ROUTES.ALL_EVENT.URL,
      iosIcon: settingsOutline,
      mdIcon: settingsOutline,
      activeIcon: 'assets/cp_icons/User-2.svg',
      icon: 'assets/cp_icons/User-4.svg',
    },
    {
      title: CONST.ROUTES.HELP.TITLE,
      url: CONST.ROUTES.HELP.URL,
      iosIcon: settingsOutline,
      mdIcon: settingsOutline,
      activeIcon: 'assets/og_icons/Help-2.svg',
      icon: 'assets/og_icons/Help-4.svg',
    },
  ] as AppPage[],
  PUBLIC: [
    {
      title: CONST.ROUTES.SEARCH.TITLE,
      url: CONST.ROUTES.SEARCH.URL,
      iosIcon: settingsOutline,
      mdIcon: settingsOutline,
      activeIcon: 'assets/cp_icons/Search-2.svg',
      icon: 'assets/cp_icons/Search-4.svg',
    },
    {
      title: CONST.ROUTES.HELP.TITLE,
      url: CONST.ROUTES.HELP.URL,
      iosIcon: settingsOutline,
      mdIcon: settingsOutline,
      activeIcon: 'assets/og_icons/Help-2.svg',
      icon: 'assets/og_icons/Help-4.svg',
    },
  ] as AppPage[],
}

export { PAGES }
