import React, { useState } from 'react'
import { useAppSelector } from '../../../store/hooks'
import { selectedEvent } from '../../../store/events/eventsSlice'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { where } from 'firebase/firestore'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { ExhibitorAnswerInput } from './components/ExhibitorAnswerInput'
import { IQuestion } from '../../../models/events/event.interface'
import { IAnswer } from '../../../models/event-registered-users/event-registered-users.interface'
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { getConvertedData } from '../../../models/interface.helper'

interface IProps {
  userId: string
  isExhibitor?: boolean
}

export const ExhibitorProfileAnswersTab: React.FC<IProps> = ({ userId, isExhibitor }) => {
  const event = useAppSelector(selectedEvent)
  const [registeredUser, setRegisteredUser] = useState<EventRegisteredUsersModel | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const currentEvent = event?.Event
  const [addCommentQuestionId, setAddCommentQuestionId] = useState<string | null>(null)
  React.useEffect(() => {
    ;(async () => {
      try {
        const registeredUsers: any = []
        const registeredUsersSnaps = await FirestoreService.filterItems(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
          [
            where(
              CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY,
              '==',
              event?.Event?.id || ''
            ),
            where(
              CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY,
              '==',
              userId
            ),
          ]
        )

        registeredUsersSnaps.forEach((currDoc) => {
          registeredUsers.push(
            getConvertedData(EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject())
          )
        })

        if (registeredUsers[0]) setRegisteredUser(registeredUsers[0])
      } catch (e) {
        console.log('=>(AnswersModal.tsx:34) e', e)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const saveHandler = async (questionId: string, value: string, files: string[]) => {
    try {
      if (!registeredUser || !registeredUser.answers) return null
      const newRegisteredUser = {
        ...registeredUser,
        answers: [...registeredUser.answers],
      } as typeof registeredUser

      if (!newRegisteredUser.answers) return null

      const currentCommentIndex = newRegisteredUser.answers.findIndex(
        (c) => c.questionId === questionId
      )

      if (currentCommentIndex < 0) {
        newRegisteredUser.answers = [
          ...newRegisteredUser.answers,
          {
            id: `${new Date().getTime()}`,
            questionId: questionId,
            answer: value,
            files,
          },
        ]
      } else {
        newRegisteredUser.answers[currentCommentIndex] = {
          ...newRegisteredUser.answers[currentCommentIndex],
          answer: value,
          files,
        }
      }

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        newRegisteredUser.id,
        {
          answers: newRegisteredUser.answers,
        }
      )

      setAddCommentQuestionId(null)
      setRegisteredUser(newRegisteredUser)
    } catch (e) {
      console.log('=>(ExhibitorProfileAnswersTab.tsx:71) e', e)
    }
  }

  const renderComment = (comments: IAnswer[], question: IQuestion) => {
    if (!comments[0] && addCommentQuestionId !== question.id) {
      return (
        <div className={'flex gap-10'}>
          <span>No answers</span>
          {isExhibitor ? (
            <button
              className={'underline text-[#f7074f] cursor-pointer'}
              onClick={() => setAddCommentQuestionId(question.id)}
            >
              Add answer
            </button>
          ) : (
            ''
          )}
        </div>
      )
    }

    if (addCommentQuestionId === question.id) {
      return (
        <ExhibitorAnswerInput
          editMode={!!addCommentQuestionId}
          comment={''}
          cancelHandler={() => setAddCommentQuestionId(null)}
          onChange={() => null}
          files={[]}
          question={question}
          isExhibitor={isExhibitor}
          saveHandler={(value, files) => {
            return saveHandler(question.id, value, files)
          }}
        />
      )
    }

    return comments.map((cc) => (
      <ExhibitorAnswerInput
        key={cc.id}
        cancelHandler={() => setAddCommentQuestionId(null)}
        editMode={false}
        comment={cc.answer}
        onChange={() => null}
        files={cc.files || []}
        question={question}
        isExhibitor={isExhibitor}
        saveHandler={(value, files) => {
          return saveHandler(question.id, value, files)
        }}
      />
    ))
  }

  const questionMemo = () => {
    if (!currentEvent || !currentEvent.questions) return <></>

    return currentEvent.questions!.map((q, index) => {
      const qc = registeredUser?.answers?.filter((c) => c.questionId === q.id) || []
      return (
        <div key={index} className={q.id}>
          <h3 className={'text-[#122B46] text-[16px] mb-4 font-bold'}>{q.value}</h3>
          {renderComment(qc, q)}
        </div>
      )
    })
  }

  if (loading) {
    return (
      <div className={'w-full h-full relative min-h-[60vh]'}>
        <ViewsLoader
          isPageLoader={true}
          className="absolute !w-[100%] !h-[100%] mt-[-5vh]"
          size="sm"
          color="red"
        />
      </div>
    )
  }
  if (!currentEvent) {
    return <>No Event Found</>
  }
  if (!Array.isArray(currentEvent.questions) || !currentEvent.questions[0]) {
    return <h2>Does not have questions</h2>
  }
  if (!registeredUser) {
    return <h2>Rider is not selected</h2>
  }

  return <div className={'flex flex-col gap-6 mt-4'}>{questionMemo()}</div>
}
