import React, { FC, useContext, useEffect, useState } from 'react'

import moment from 'moment/moment'
import clsx from 'clsx'
import { where } from 'firebase/firestore'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { cloneDeep } from 'lodash'

import InfiniteScrollDataTable from '../../../../../../common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../../../../loader/ViewsLoader'
import { IconMessaging } from '../../../../../../icons/IconMessaging'

import { useAppSelector } from '../../../../../../../store/hooks'

import { UserModel } from '../../../../../../../models/users/user.model'
import { getConvertedData } from '../../../../../../../models/interface.helper'
import { IUserInterface, MEMBERSHIP_TYPE } from '../../../../../../../models/users/user.interface'
import {
  ISubscribersInterface,
  ISubscription,
  ISubscriptionStatus,
} from '../../../../../../../models/subscribers/subscribers.interface'
import { SubscribersModel } from '../../../../../../../models/subscribers/subscribers.model'

import FirestoreService from '../../../../../../../services/firestoreService'

import { getUserFullName } from '../../../../../../../helpers/helpers'

import { customStyles } from '../../../../../../../pages/allEvents/root/AllEventsTableStyles'

import { selectProfileData } from '../../../../../../../store/user/userSlice'

import { CONST } from '../../../../../../../const/const'
import { IMAGE_CONSTS } from '../../../../../../../const/image-const'
import { MODAL_CONSTS } from '../../../../../../../const/modal-const'
import { HandleModalContext } from '../../../../../../../layout/mainlayout/MainLayout'
import { ROUTES_CONST } from '../../../../../../../const/routes-const'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const sortByOptions = [
  { value: '', label: 'All' },
  { value: 'Annual', label: 'Annual Subscription' },
  { value: 'Monthly', label: 'Monthly subscription' },
  { value: 'Board', label: 'Board' },
  { value: 'Payment', label: 'One time payment' },
  { value: 'Free', label: 'Free membership' },
]

interface PaymentsItemsProps {
  isOrganiser?: boolean
}

export const PaymentsItems: FC<PaymentsItemsProps> = ({ isOrganiser }) => {
  const handleModalContext = useContext(HandleModalContext)

  const [loading, setLoading] = useState(false)
  const userProfile = useAppSelector(selectProfileData)
  const [searchValue, setSearchValue] = React.useState('')
  const [sortBy, setSortBy] = React.useState('')

  const [payments, setPayments] = useState<ISubscription[] | []>([])
  const [filteredPayments, setFilteredPayments] = useState<ISubscription[] | []>([])

  const getPayments = async () => {
    let subscribers: ISubscribersInterface[] = []
    let users: IUserInterface[] = []
    setLoading(true)
    try {
      const usersSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME)

      usersSnaps.docs.forEach((currDoc) => {
        users.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
      })

      const subscribersSnaps = await FirestoreService.filterItems(COLLECTIONS.SUBSCRIBERS.NAME, [
        isOrganiser
          ? where(COLLECTIONS.SUBSCRIBERS.FIELDS.OWNER_ID.KEY, '==', userProfile.id)
          : where(COLLECTIONS.SUBSCRIBERS.FIELDS.USER_ID.KEY, '==', userProfile.id),
      ])

      subscribersSnaps.docs.forEach((currDoc) => {
        subscribers.push(getConvertedData(SubscribersModel.fromFirestoreDoc(currDoc).toObject()))
      })

      const payments_ = subscribers?.map((subscription) => {
        const organiser = users.find((user) => user.id === subscription.ownerId)

        const customer = users.find((user) => {
          const email = isOrganiser ? subscription.userEmail : subscription.ownerEmail
          return user.userEmail === email
        })
        const membership = organiser?.userMemberships?.find(
          (userMembership) => userMembership.id === subscription.membershipId
        )

        const status =
          membership?.type === MEMBERSHIP_TYPE.FREE
            ? (COLLECTIONS.SUBSCRIBERS.FIELDS.STATUS.VALUE.CURRENT as ISubscriptionStatus)
            : membership?.type === MEMBERSHIP_TYPE.PAYMENT
              ? (COLLECTIONS.SUBSCRIBERS.FIELDS.STATUS.VALUE.PAID as ISubscriptionStatus)
              : subscription?.status

        const periodStart = subscription.lastPayment
          ? moment.isDate(new Date(subscription.lastPayment))
            ? moment(subscription.lastPayment).format('DD MMM, YYYY')
            : 'N/A'
          : 'N/A'

        const periodEnd =
          membership?.type === MEMBERSHIP_TYPE.FREE || membership?.type === MEMBERSHIP_TYPE.PAYMENT
            ? 'N/A'
            : subscription.nextPayment
              ? moment.isDate(new Date(subscription.nextPayment))
                ? moment(subscription.nextPayment).format('DD MMM, YYYY')
                : 'N/A'
              : 'N/A'

        return {
          customer: customer ?? null,
          membership: membership ?? null,
          status,
          lastPayment: periodStart,
          nextPayment: periodEnd,
          subscription,
        }
      })

      setPayments(payments_)
    } catch (error) {
      console.log(error, 'error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getPayments().then()
  }, [])

  useEffect(() => {
    let filtered = cloneDeep(payments)
    if (searchValue.length > 0) {
      filtered = filtered.filter((payment) => {
        return payment.customer
          ? getUserFullName(payment.customer).toLowerCase().includes(searchValue.toLowerCase()) ||
              payment.customer?.userFirstName?.toLowerCase().includes(searchValue.toLowerCase()) ||
              payment.customer?.userLastName?.toLowerCase().includes(searchValue.toLowerCase())
          : false
      })
    }

    if (sortBy.length > 0) {
      filtered = filtered.filter((payment) => payment.membership?.type === sortBy)
    }
    setFilteredPayments(filtered)
  }, [payments, searchValue, sortBy])

  const columns = [
    {
      name: '',
      cell: (cell: ISubscription) => (
        <Link
          to={`${ROUTES_CONST.USER_PROFILE.URL}/${cell.customer?.id}`}
          className="flex items-center py-2 transition-all hover:opacity-70"
        >
          <img
            src={
              cell.customer?.userProfilePicture
                ? cell.customer?.userProfilePicture
                : IMAGE_CONSTS.PLACEHOLDERS.USER
            }
            alt="avatar"
            className="w-[45px] h-[45px] rounded-full mt-1"
          />
          <div className="ml-3">
            <div className="text-SeabiscuitDark200ThemeColor font-bold text-base">
              {cell.customer ? getUserFullName(cell.customer) : 'N/A'}
            </div>
            <span className="text-base text-SeabiscuitDark200ThemeColor/50">
              {cell?.membership?.name}
            </span>
          </div>
        </Link>
      ),
    },
    {
      name: <div className="w-full opacity-50 md:text-center">Status</div>,
      width: '15%',
      cell: (cell: ISubscription) => (
        <div className="w-full md:text-center text-[16px] capitalize">
          <span
            className={clsx(
              'py-1.5 px-7 rounded-full whitespace-nowrap',
              cell.status === COLLECTIONS.SUBSCRIBERS.FIELDS.STATUS.VALUE.ACTIVE ||
                cell.status === COLLECTIONS.SUBSCRIBERS.FIELDS.STATUS.VALUE.CURRENT
                ? 'text-SeabiscuitGreenThemeColor bg-SeabiscuitGreenThemeColor/10'
                : cell.status === COLLECTIONS.SUBSCRIBERS.FIELDS.STATUS.VALUE.CANCELED ||
                    cell.status === COLLECTIONS.SUBSCRIBERS.FIELDS.STATUS.VALUE.PAUSED
                  ? 'text-SeabiscuitRedThemeColor bg-SeabiscuitRedThemeColor/5'
                  : 'bg-SeabiscuitGrayThemeColor'
            )}
          >
            {cell.status}
          </span>
        </div>
      ),
    },
    {
      name: <div className="w-full opacity-50 md:text-center">Payment</div>,
      width: '10%',
      cell: (cell: ISubscription) => (
        <div className="w-full md:text-center text-[16px]">
          <span>${cell.membership?.price ?? 0}</span>{' '}
          <span className="opacity-50">{cell.membership?.type?.toLowerCase()}</span>
        </div>
      ),
    },
    {
      name: <div className="w-full opacity-50 md:text-center">Last payment</div>,
      width: '10%',
      // selector: 'lastPayment',
      // sortable: true,
      cell: (cell: ISubscription) => (
        <div className="w-full md:text-center text-[16px]">{cell.lastPayment ?? 'N/A'}</div>
      ),
    },
    {
      name: <div className="w-full opacity-50 md:text-center">Next payment</div>,
      width: '10%',
      cell: (cell: ISubscription) => (
        <div className="w-full md:text-center text-[16px]">{cell.nextPayment ?? 'N/A'}</div>
      ),
    },
    {
      name: <div className="w-full md:text-right opacity-50">Manage</div>,
      width: '12%',
      cell: (cell: ISubscription) => (
        <div className="w-full flex justify-end items-center md:text-right text-[16px] whitespace-nowrap leading-none">
          {cell?.status === COLLECTIONS.SUBSCRIBERS.FIELDS.STATUS.VALUE.ACTIVE && (
            <>
              <button
                type="button"
                className="underline hover:no-underline px-2 border-solid border-r border-r-black"
                onClick={() => {
                  handleModalContext?.handleModal(true, MODAL_CONSTS.CANCEL_MEMBERSHIP, {
                    subscription: cell.subscription,
                    membership: cell.membership,
                    onHandleCancel: async () => await getPayments(),
                  })
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="underline hover:no-underline px-2"
                onClick={() => {
                  handleModalContext?.handleModal(true, MODAL_CONSTS.PAUSE_RENEW_MEMBERSHIP, {
                    subscription: cell.subscription,
                    membership: cell.membership,
                    type: 'pause',
                    onHandlePauseRenew: async () => await getPayments(),
                  })
                }}
              >
                Pause
              </button>
            </>
          )}
          {cell?.status === COLLECTIONS.SUBSCRIBERS.FIELDS.STATUS.VALUE.PAUSED && (
            <button
              type="button"
              className="underline hover:no-underline px-2"
              onClick={() => {
                handleModalContext?.handleModal(true, MODAL_CONSTS.PAUSE_RENEW_MEMBERSHIP, {
                  subscription: cell.subscription,
                  membership: cell.membership,
                  type: 'renew',
                  onHandlePauseRenew: async () => await getPayments(),
                })
              }}
            >
              Renew
            </button>
          )}
          {/*<button type="button" className="underline hover:no-underline pl-2 border-solid">*/}
          {/*  Print*/}
          {/*</button>*/}
        </div>
      ),
    },
    {
      name: <div className="w-full md:text-right opacity-50">Message</div>,
      width: '8%',
      cell: (cell: ISubscription) => (
        <div className="w-full py-2 flex md:justify-end">
          <button
            type="button"
            className="rounded-lg w-[45px] h-45px flex items-center justify-center p-2 bg-SeabiscuitGrayThemeColor transition-all hover:opacity-70"
            onClick={() => {
              handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
                emailId: cell.customer?.userEmail,
                user_name: cell.customer ? getUserFullName(cell.customer) : null,
                profilePicture: cell.customer?.userProfilePicture,
              })
            }}
          >
            <IconMessaging fill="#122B46" />
          </button>
        </div>
      ),
    },
  ]

  return (
    <div className="w-full">
      <div className="ml-auto lg:w-1/2 mb-2 relative lg:mt-[-60px] z-20 flex items-center lg:justify-end gap-2 flex-1">
        <input
          type="search"
          className="w-full max-w-[380px] rounded-lg border-SeabiscuitLightThemeColorD3 py-2 px-3"
          placeholder="Search members"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
        />

        <Select
          value={sortByOptions.find((option) => option.value === sortBy)}
          options={sortByOptions}
          className="w-full max-w-[229px]"
          onChange={(selectedOption) => {
            if (selectedOption?.value) {
              setSortBy(selectedOption.value)
            } else {
              setSortBy('')
            }
          }}
        />
      </div>
      <InfiniteScrollDataTable
        data={filteredPayments}
        hasMore={false}
        columns={columns}
        fixedHeader={true}
        progressPending={loading}
        customStyles={customStyles}
        progressComponent={
          <ViewsLoader
            className="flex items-center w-full justify-center my-10"
            size="md"
            color="#F7074F"
          />
        }
      />
    </div>
  )
}
