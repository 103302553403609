// ############################################################
/**
 * @todo Document this
 */
// ############################################################
import { useEffect } from 'react'

import { useIntercom } from 'react-use-intercom'
import { useSelector } from 'react-redux'

import { RootState } from '../store/store'
import { useAppSelector } from '../store/hooks'

import { capitalize } from '../helpers/helpers'
import { selectDisplayName } from '../store/user/userSlice'
import FirebaseApp from '../services/firebaseApp'
import MainLayout from './mainlayout/MainLayout'
import { httpService } from '../services/httpService'

const AppLayout: React.FC = () => {
  let displayName = useAppSelector(selectDisplayName)
  const userReducer = useSelector((state: RootState) => state.user)

  const { update, shutdown, boot } = useIntercom()

  const uid = FirebaseApp.auth.currentUser?.uid ?? null
  const email = FirebaseApp.auth.currentUser?.email ?? null
  const photoUrl = FirebaseApp.auth.currentUser?.photoURL ?? null
  const displayName_ = FirebaseApp.auth.currentUser?.displayName ?? null

  const capitalizeUserType = (userType: string | undefined) => {
    if (!userType) return ''
    return userType.charAt(0).toUpperCase() + userType.slice(1)
  }

  const fetchIntercomData = async () => {
    if (uid) {
      boot()

      const result = await httpService({
        url: `getAffiliateObject?email=${encodeURIComponent(email || '')}`,
        method: 'GET',
      })

      update({
        userId: uid,
        email: email ?? undefined,
        name: capitalize(displayName_ ?? displayName ?? '', {
          capitalizeAll: false,
        }),
        avatar: {
          type: 'avatar',
          imageUrl: photoUrl ?? undefined,
        },
        customAttributes: {
          account_type: capitalizeUserType(userReducer?.userType),
          affiliate_link: result?.data[0]?.links[0]?.url,
        },
      })
    } else {
      boot()
    }
  }

  useEffect(() => {
    fetchIntercomData()
  }, [boot, update, shutdown, uid, displayName, displayName_, email, photoUrl])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return <MainLayout />
}

export default AppLayout
