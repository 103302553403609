import { useEffect, useState } from 'react'
import type { ChannelFilters } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'
import { useChat } from './DashboardChat'

export default function InfoLabels() {
  const { eventId, userId } = useChat()
  const { client } = useChatContext()
  const [data, setData] = useState({ unread: 0, total: 0 })

  useEffect(() => {
    const response = client.on((event) => {
      if (event.unread_channels !== undefined) {
        setData((d) => ({ ...d, unread: event.unread_channels ?? 0 }))
      }
    })

    return () => response.unsubscribe()
  }, [client])

  useEffect(() => {
    async function getTotalChannels() {
      const all_filters: ChannelFilters = {
        type: 'messaging',
        members: { $in: [userId] },
        eventId: eventId ? { $eq: eventId } : undefined,
      }
      return client.queryChannels(all_filters).then((channels) => channels.length)
    }

    getTotalChannels().then((total) => setData((d) => ({ ...d, total })))
  }, [client, userId, eventId])

  return (
    <>
      <div className="border rounded-md w-[50px] h-[50px] flex flex-col items-center justify-center text-[#122B4680]">
        <p className="text-center font-extrabold">{data.unread}</p>
        <p className="text-xs text-center">Unread</p>
      </div>
      <div className="border rounded-md w-[50px] h-[50px] flex flex-col items-center justify-center text-[#122B4680]">
        <p className="text-center font-extrabold">{data.total}</p>
        <p className="text-xs text-center">Read</p>
      </div>
    </>
  )
}
