// Third party
import React, { FC, useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'

// Type imports
import { IClinicTabData } from '../../../../types/competitor_types'

// Styles
import { customStyles } from '../../../../components/customStyles/ReactSelectCustomStyle'

// Component imports
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'

import clsx from 'clsx'
import CustomReactSelect from '../../../../components/common/select/react-select/CustomReactSelect'
import { IRegistrationTabs } from '../../../../models/event-registered-users/event-registered-users.interface'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectRegisterTabData,
  selectRegistrationByDay,
  setRegisterTabData,
} from '../../../../store/registration/registrationSlice'
import './clinicNOtherReviewAndPayTab.css'

import { CONST } from '../../../../const/const'
import IUseEventRegistrationTabsTypes from '../../event-registration-tabs/hooks/useEventRegistrationTabs.types'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import { cloneDeep } from 'lodash'
import FirestoreService from '../../../../services/firestoreService'
import { RegistrationByDayModel } from '../../../../models/registrations-by-day/registrationByDay.model'
import { DaysTabs } from '../../../../components/events/common/DaysTabs/DaysTabs'
import { daysOfWeek } from '../../../../helpers/time'
import { selectedEvent } from '../../../../store/events/eventsSlice'
import { IEventReviewPublish } from '../../../../models/event-review-publish/event-review-publish.interface'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IClinicNOtherRegisterHorseTabProps = {
  title?: string
  description?: string
  clinicTabsData: IClinicTabData
  onRegistrationByDayHorseChange: IUseEventRegistrationTabsTypes['IOnRegistrationByDayHorseChangeFn']
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
}

const HeaderItems: FC<{ EventFees: IEventReviewPublish['EventFees'] | null }> = ({ EventFees }) => {
  return (
    <div className="mb-2 ml-auto gap-6 justify-end flex items-center">
      {EventFees?.rideTimeRequirement?.isEnable && (
        <Tooltip
          title={
            <h4 className="text-[12px]">
              Do you want to waive the required break between ride times?
            </h4>
          }
          placement="top"
          arrow
        >
          <p className="text-[14px] text-SeabiscuitDark200ThemeColor/50 cursor-pointer underline hover:no-underline">
            Waive break?
          </p>
        </Tooltip>
      )}
      {EventFees?.qualifyingClasses?.isEnable && (
        <div className="underline">
          <Tooltip
            title={<h4 className="text-[12px]">Do you want to make this a qualifying ride?</h4>}
            placement="top"
            arrow
          >
            <p className="text-[14px] text-SeabiscuitDark200ThemeColor/50 cursor-pointer underline hover:no-underline">
              Qual?
            </p>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

const ClinicNOtherRegisterHorseTab = (props: IClinicNOtherRegisterHorseTabProps) => {
  const dispatch = useAppDispatch()

  // Hooks and vars
  const registerTabData_ = useAppSelector(selectRegisterTabData)
  const registrationsByDay = useAppSelector(selectRegistrationByDay)
  const selectedEventR = useAppSelector(selectedEvent)
  const [registerData, setRegisterData] = useState<IRegistrationTabs['IRegisterTab'][]>([])
  const [days, setDays] = useState<string[]>([])
  const [activeDay, setActiveDay] = useState<string>('All')
  const [innerHorses, setInnerHorses] = React.useState<any>({})
  const horseTabData = props.clinicTabsData
  let { horses: userHorses } = horseTabData

  userHorses = [...userHorses]

  const handleHorseChange = ({
    newValue,
    currentRow,
    riderId,
  }: {
    newValue: any
    currentRow: IRegistrationByDayInterface
    riderId: string
  }) => {
    props.onRegistrationByDayHorseChange({
      currentRow,
      horses: newValue,
      riderId,
      horseId:
        newValue && Array.isArray(newValue)
          ? newValue[0]?.id || '00000000000000000000'
          : newValue?.id || '00000000000000000000',
      isManage: props.isManage,
      manageInfo: props.manageInfo,
      setManageInfo: props.setManageInfo,
    })
  }

  useEffect(() => {
    if (props.isManage) {
      setRegisterData(props.manageInfo.register)
    } else {
      setRegisterData(registerTabData_)
    }
  }, [props.isManage, props.manageInfo.register, registerTabData_, registrationsByDay])

  const updateRegistrationByDayKey = async ({
    currRegistrationByDay,
    key,
  }: {
    currRegistrationByDay: IRegistrationByDayInterface
    key: 'isBreak' | 'isQualify'
  }) => {
    const registerTabData_ = cloneDeep(registerData)

    const findIndex = registerData.findIndex(
      (register) => register.uuid === currRegistrationByDay.uuid
    )
    if (findIndex !== -1) {
      const registrationByDayIndex = registerData[findIndex].registrationsByDay.findIndex(
        (registrationByDay) => registrationByDay.riderId === currRegistrationByDay.riderId
      )
      if (registrationByDayIndex !== -1) {
        registerTabData_[findIndex].registrationsByDay[registrationByDayIndex][key] =
          !registerTabData_[findIndex].registrationsByDay[registrationByDayIndex][key]
      }

      setRegisterData([...registerTabData_])

      if (props.isManage) {
        props.setManageInfo({ ...props.manageInfo, register: registerTabData_ })
      } else {
        dispatch(setRegisterTabData(registerTabData_))

        await FirestoreService.updateItem(
          COLLECTIONS.REGISTRATION_BY_DAY.NAME,
          currRegistrationByDay.id,
          new RegistrationByDayModel({
            ...currRegistrationByDay,
            [key]: !currRegistrationByDay[key],
          }).toFirestore()
        )
      }
    }
  }

  useEffect(() => {
    const days_: string[] = []
    registerData.forEach((fee) => {
      const day = daysOfWeek[new Date(fee.startDate)?.getDay()]
      if (!days_.includes(day)) days_.push(day)
    })

    setDays(
      days_.sort((a, b) => {
        return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b)
      })
    )
  }, [registerData])

  useEffect(() => {
    const horseData: any = {}
    registerData.forEach((currentRow) => {
      const registrationsByDayChildren: IRegistrationByDayInterface[] = []

      currentRow.children?.forEach((children) => {
        if (children.registrationsByDay.length > 0) {
          registrationsByDayChildren.push(...children.registrationsByDay)
        }
      })
      const registrationsByDayAll = [
        ...currentRow.registrationsByDay,
        ...registrationsByDayChildren,
      ]

      registrationsByDayAll.forEach((currRegistrationByDay, currRegistrationByDayIndex) => {
        if (
          daysOfWeek[new Date(currentRow.startDate)?.getDay()] === activeDay ||
          activeDay === 'All'
        ) {
          const horses = userHorses.filter((horseDetail) =>
            currRegistrationByDay.horseIds?.find((horseid) => horseid === horseDetail.id)
          )

          if (currRegistrationByDay && currRegistrationByDay.id) {
            horseData[currRegistrationByDay.id] = horses
          }
        }
      })
    })

    setInnerHorses(horseData)
  }, [registerData])

  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <DaysTabs activeDay={activeDay} setActiveDay={setActiveDay} days={days} />
      {(registerData.filter((register) => register.registrationsByDay.length > 0).length > 0 ||
        registerData.filter(
          (register) =>
            register.children?.filter((children) => children.registrationsByDay.length > 0).length
        ).length > 0) && (
        <div className="hidden md:block">
          <HeaderItems EventFees={selectedEventR?.EventFees} />
        </div>
      )}
      <div className="rtc flex flex-col gap-2">
        {registerData.filter((register) => register.registrationsByDay.length > 0).length > 0 ||
        registerData.filter(
          (register) =>
            register.children?.filter((children) => children.registrationsByDay.length > 0).length
        ).length > 0 ? (
          registerData.map((currentRow) => {
            const registrationsByDayChildren: IRegistrationByDayInterface[] = []

            currentRow.children?.forEach((children) => {
              if (children.registrationsByDay.length > 0) {
                registrationsByDayChildren.push(...children.registrationsByDay)
              }
            })
            const registrationsByDayAll = [
              ...currentRow.registrationsByDay,
              ...registrationsByDayChildren,
            ]
            return registrationsByDayAll.map(
              (currRegistrationByDay, currRegistrationByDayIndex) => {
                if (
                  daysOfWeek[new Date(currentRow.startDate)?.getDay()] === activeDay ||
                  activeDay === 'All'
                ) {
                  return (
                    <div
                      key={currRegistrationByDayIndex}
                      className={clsx(
                        `w-full flex justify-between flex-wrap lg:mb-0 gap-2 text-SeabiscuitDark200ThemeColor`
                      )}
                    >
                      <div
                        className={clsx(
                          'w-full md:w-auto md:flex-1 gap-2 text-base flex flex-wrap items-center pr-4 py-3 pl-4 border rounded-lg border-SeabiscuitLightThemeColorD3'
                        )}
                      >
                        {currRegistrationByDay.registrationByDayName}{' '}
                        {currRegistrationByDay.order ? (
                          <span className="text-[14px] text-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor/5 rounded-full py-0.5 px-3">
                            {currRegistrationByDay.order}
                          </span>
                        ) : null}
                        <div className="ml-auto flex flex-wrap items-center justify-end text-SeabiscuitDark200ThemeColor/50">
                          <span className="text-SeabiscuitDark200ThemeColor mr-1">
                            {currRegistrationByDay.qualifyFee &&
                            selectedEventR?.EventFees?.qualifyingClasses?.isEnable
                              ? currRegistrationByDay.qualifyFee
                              : '$0'}
                          </span>{' '}
                          qual fee •{' '}
                          <span className="mx-1 text-SeabiscuitDark200ThemeColor mr-1">
                            {currRegistrationByDay.breakTime ?? '0'} min
                          </span>{' '}
                          break •{' '}
                          <span className="ml-1 text-SeabiscuitMainThemeColor">
                            {currRegistrationByDay.riderName}
                          </span>
                        </div>
                      </div>
                      <div
                        className={clsx(
                          'w-full md:w-[20%] min-w-[180px] flex items-center border border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor text-sm rounded-lg relative'
                        )}
                      >
                        <CustomReactSelect
                          isMulti={true}
                          targetId="rtc"
                          onChange={(newValue: any) => {
                            console.log('=>(ClinicNOtherHorseTab.tsx:301) newValue', newValue)
                            const updatedValue = newValue.map((val: any) => {
                              console.log('=>(ClinicNOtherHorseTab.tsx:303) val', val)
                              const horse = userHorses.find((h: any) => h.horseId === val.value)

                              if (!val.horseId) {
                                console.log('=>(ClinicNOtherHorseTab.tsx:335) horse', horse)
                                if (horse)
                                  return {
                                    ...horse,
                                    id: horse.id,
                                    label: val.label,
                                    value: val.value,
                                  }
                              }
                              return val
                            })
                            const newInnerHorses = { ...innerHorses }

                            if (
                              (currRegistrationByDay && currRegistrationByDay.id) ||
                              currRegistrationByDay.uuid
                            )
                              newInnerHorses[
                                currRegistrationByDay.id
                                  ? currRegistrationByDay.id
                                  : currRegistrationByDay.uuid!
                              ] = updatedValue

                            console.log(
                              '=>(ClinicNOtherHorseTab.tsx:331) newInnerHorses',
                              newInnerHorses
                            )

                            setInnerHorses(newInnerHorses)

                            handleHorseChange({
                              newValue: updatedValue,
                              currentRow: currRegistrationByDay,
                              riderId: currRegistrationByDay?.riderId ?? '',
                            })
                          }}
                          options={
                            userHorses
                              .filter((h) => {
                                if (!currRegistrationByDay) {
                                  return true
                                }
                                if (!currRegistrationByDay.id) {
                                  return true
                                }
                                if (!Array.isArray(innerHorses[currRegistrationByDay.id])) {
                                  return true
                                }
                                if (!innerHorses[currRegistrationByDay.id].length) {
                                  return true
                                }

                                const horsesIds = innerHorses[currRegistrationByDay.id].map(
                                  (innerHorse: any) => innerHorse.id
                                )

                                return !horsesIds.includes(h.id)
                              })
                              .map((currHorse) => {
                                return {
                                  ...currHorse,
                                  label: currHorse.horseName ?? '',
                                  value: currHorse.id ?? '',
                                }
                              })
                            // userHorses.length
                            //   ? userHorses.map((currHorse) => {
                            //       return {
                            //         ...currHorse,
                            //         label: currHorse.horseName ?? '',
                            //         value: currHorse.id ?? '',
                            //       }
                            //     })
                            //   : [{ label: 'No Horse', value: 'No Horse' }]
                          }
                          value={
                            currRegistrationByDay &&
                            currRegistrationByDay.id &&
                            innerHorses[currRegistrationByDay.id]
                              ? innerHorses[currRegistrationByDay.id].map((horse: any) => ({
                                  label: horse.horseName ?? '',
                                  value: horse.horseId ?? '',
                                }))
                              : []
                            // undefined
                            // !!currRegistrationByDay.horseId
                            //   ? {
                            //       label: currRegistrationByDay.horseName ?? '',
                            //       value: currRegistrationByDay.horseId ?? '',
                            //     }
                            //   : currRegistrationByDay.noHorseSelected
                            //     ? {
                            //         label: 'No Horse',
                            //         value: 'No Horse',
                            //       }
                            //     : undefined
                          }
                          isClearable={false}
                          isSearchable={false}
                          styles={
                            {
                              ...customStyles,
                              valueContainer: (provided: any) => ({
                                ...provided,
                              }),
                              menu: (provided: any, state: any) =>
                                ({
                                  ...(typeof customStyles['menu'] === 'function' && {
                                    ...customStyles['menu'](provided, state),
                                  }),
                                }) as any,
                            } as any
                          }
                          placeholder="Select Horse"
                          className={`w-full rounded-lg searchableComponent focus:ring-0 p-0 focus:ring-transparent h-full flex items-center capitalize ${!userHorses.length ? 'bg-[#f8f8ff]' : ''}`}
                        />
                      </div>
                      <div className="ml-auto flex flex-col">
                        <div className="block md:hidden">
                          <HeaderItems EventFees={selectedEventR?.EventFees} />
                        </div>
                        <div className="flex-1 flex gap-2">
                          {selectedEventR?.EventFees?.rideTimeRequirement?.isEnable && (
                            <div
                              className={clsx(
                                'ml-auto group w-[55px] min-h-[55px] flex items-center justify-center border border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor text-sm rounded-lg relative',
                                Number(currRegistrationByDay?.breakTime) > 0 && 'cursor-pointer'
                              )}
                              onClick={() => {
                                if (Number(currRegistrationByDay?.breakTime) > 0)
                                  updateRegistrationByDayKey({
                                    currRegistrationByDay,
                                    key: 'isBreak',
                                  })
                              }}
                            >
                              <div
                                className={clsx(
                                  'w-5 h-5 rounded-full select-none',
                                  Number(currRegistrationByDay?.breakTime) > 0
                                    ? 'border-SeabiscuitDark200ThemeColor cursor-pointer group-hover:group-hover:opacity-70'
                                    : 'border-SeabiscuitDark200ThemeColor/20',
                                  !currRegistrationByDay.isBreak && 'border-2'
                                )}
                              >
                                {currRegistrationByDay.isBreak && (
                                  <img className="w-full" src="/assets/cp_icons/Ok-3.svg" alt="" />
                                )}
                              </div>
                            </div>
                          )}
                          {selectedEventR?.EventFees?.qualifyingClasses?.isEnable && (
                            <div
                              className={clsx(
                                'group w-[55px] min-h-[55px] flex items-center justify-center border border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor text-sm rounded-lg relative',
                                Number(currRegistrationByDay?.qualifyFee) > 0 && 'cursor-pointer'
                              )}
                              onClick={() => {
                                if (Number(currRegistrationByDay?.qualifyFee) > 0)
                                  updateRegistrationByDayKey({
                                    currRegistrationByDay,
                                    key: 'isQualify',
                                  })
                              }}
                            >
                              <div
                                className={clsx(
                                  'w-5 h-5 rounded-full select-none',
                                  Number(currRegistrationByDay?.qualifyFee) > 0
                                    ? 'border-SeabiscuitDark200ThemeColor cursor-pointer group-hover:opacity-70'
                                    : 'border-SeabiscuitDark200ThemeColor/20',
                                  !currRegistrationByDay.isQualify && 'border-2'
                                )}
                              >
                                {currRegistrationByDay.isQualify && (
                                  <img className="w-full" src="/assets/cp_icons/Ok-3.svg" alt="" />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return null
                }
              }
            )
          })
        ) : (
          <DataNotAvailable
            mode="text"
            containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
            text="Please add a rider before adding a horse"
          />
        )}
      </div>
    </CompetitorEventRegisterWrapper>
  )
}

export default ClinicNOtherRegisterHorseTab
