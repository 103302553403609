import { useState } from 'react'

import FormHeader from '../../../../ui/form/form-header/FormHeader'
import { MembershipsTabs } from './components/MembershipsTabs/MembershipsTabs'
import { MembershipsItems } from './components/MembershipsItems/MembershipsItems'
import { PaymentsItems } from './components/PaymentsItems/PaymentsItems'

export const OrganizerMembershipsTab = () => {
  const [activeTab, setActiveTab] = useState<'Memberships' | 'Payments'>('Memberships')

  return (
    <>
      <FormHeader
        title="Memberships"
        description="Create membership products and manage members"
        removeBreakLine
      />
      <MembershipsTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 'Memberships' && <MembershipsItems />}
      {activeTab === 'Payments' && <PaymentsItems isOrganiser />}
    </>
  )
}
