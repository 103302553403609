// import {DATA_FIRESTORE_V01_CONST} from "./v01/firestore-v01-const";

export const DATA_STORAGE_CONST = {
  EVENTS: {
    CARD_COVER_URL: {
      PREFIX: 'event/card-cover/',
    },
    EVENT_IMAGES: {
      PREFIX: 'event/images/',
    },
    EVENT_VIDEO: {
      PREFIX: 'event/video/',
    },
    EVENT_COURSEMAP: {
      PREFIX: 'event/coursemap/',
    },
    EVENT_SCORE: {
      PREFIX: 'event/score/',
    },
  },
  USERS: {
    COMPETITOR_PROFILE_IMAGE_URL: {
      PREFIX: 'user/competitor-profile',
    },
    ENDORSEMENT_URL: {
      PREFIX: 'user/endrosement-letter',
    },
    NUMBERS_URL: {
      PREFIX: 'user/numbers',
    },
    MEMBERS_URL: {
      PREFIX: 'user/members',
    },
    ANSWERS_URL: {
      PREFIX: 'user/answers',
    },
  },
  HORSE: {
    HORSE_PROFILE_IMAGE_URL: {
      PREFIX: 'horse/horse-profile',
    },
    HORSE_COGGNIS_CERTIFICATE_URL: {
      PREFIX: 'horse/horse-coggins-certificate',
    },
  },
}
