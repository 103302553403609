import { useEffect, useState } from 'react'
import { KnockProvider, KnockFeedProvider } from '@knocklabs/react'
import { useAppSelector } from '../../store/hooks'
import { selectProfileData } from '../../store/user/userSlice'
import { httpsCallable } from 'firebase/functions'
import FirebaseApp from '../../services/firebaseApp'

export const withKnockProvider = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const WithKnockProvider: React.FC<P> = (props) => {
    const profileData = useAppSelector(selectProfileData)
    const userId = profileData.id

    const [signInToken, setSignInToken] = useState<string | undefined>(undefined)

    useEffect(() => {
      const fetchToken = async () => {
        const generateKnockSignInToken = httpsCallable(
          FirebaseApp.functions,
          'generateKnockSignInToken'
        )

        try {
          const result = await generateKnockSignInToken()
          const { token } = result.data as { token: string }
          return token
          // Configura un temporizador para renovar el token antes de que expire
        } catch (error) {
          console.error('Error obteniendo el token de Knock:', error)
          throw error
        }
      }

      if (userId && process.env.REACT_APP_PROJECT_ID === 'pegasus-ems') {
        fetchToken().then((token) => {
          setSignInToken(token)
        })
      }
    }, [userId])

    if (process.env.REACT_APP_PROJECT_ID === 'pegasus-ems' && !signInToken) return null

    return (
      <KnockProvider
        apiKey={process.env.REACT_APP_KNOCK_PUBLIC_API_KEY!}
        userId={userId}
        userToken={signInToken}
      >
        <KnockFeedProvider feedId="163b032c-aa93-4901-b60e-aa4feb711912">
          <WrappedComponent {...props} />
        </KnockFeedProvider>
      </KnockProvider>
    )
  }

  return WithKnockProvider
}
