import { ModelBaseModel } from '../model-base/model-base.model'
import { ISubscribersInterface } from './subscribers.interface'
import { IModelBaseModel } from '../model-base/model-base.interface'

export class SubscribersModel
  extends ModelBaseModel<ISubscribersInterface>
  implements IModelBaseModel
{
  public v: ISubscribersInterface['v']
  public id: ISubscribersInterface['id']
  public membershipId: ISubscribersInterface['membershipId']
  public ownerId: ISubscribersInterface['ownerId']
  public ownerEmail: ISubscribersInterface['ownerEmail']
  public userId: ISubscribersInterface['userId']
  public userEmail: ISubscribersInterface['userEmail']
  public lastPayment: ISubscribersInterface['lastPayment']
  public nextPayment: ISubscribersInterface['nextPayment']
  public status: ISubscribersInterface['status']
  public userStripeAccountId: ISubscribersInterface['userStripeAccountId']
  public userStripeId: ISubscribersInterface['userStripeId']
  public stripeProductId: ISubscribersInterface['stripeProductId']
  public stripeSubscriptionId: ISubscribersInterface['stripeSubscriptionId']
  public created: ISubscribersInterface['created']
  public modified: ISubscribersInterface['modified']

  public constructor(obj?: ISubscribersInterface) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.membershipId = obj?.membershipId ?? null
    this.ownerId = obj?.ownerId ?? null
    this.ownerEmail = obj?.ownerEmail ?? null
    this.userId = obj?.userId ?? null
    this.userEmail = obj?.userEmail ?? null
    this.lastPayment = obj?.lastPayment ?? null
    this.nextPayment = obj?.nextPayment ?? null
    this.status = obj?.status ?? null
    this.userStripeAccountId = obj?.userStripeAccountId ?? null
    this.userStripeId = obj?.userStripeId ?? null
    this.stripeProductId = obj?.stripeProductId ?? null
    this.stripeSubscriptionId = obj?.stripeSubscriptionId ?? null
    this.created = obj?.created ?? new Date()
    this.modified = obj?.modified ?? new Date()
  }

  static fromFirestoreDoc(doc: any) {
    return new SubscribersModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
