/* eslint-disable react-hooks/exhaustive-deps */
// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useEffect, useState } from 'react'

import { CONST } from '../../../const/const'
import { MODAL_CONSTS } from '../../../const/modal-const'

import { PillButtonElement } from '../../elements/buttons/pill/PillButtonElement'

import { Clear } from '@mui/icons-material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import moment from 'moment'

import { useSelector } from 'react-redux'
import {
  DEFAULT_CATEGORY,
  DEFAULT_STATUS_ORGANIZER,
  initialSystemStateFilters,
  selectedfilters,
  selectedOrganizerFilter,
  setActiveFilters,
  setOrganizerFilters,
} from '../../../store/filters/filterSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { IAppState } from '../../../store/system/systemSlice'

import Autocomplete from 'react-google-autocomplete'
import { handleLocation } from '../../../helpers/helpers'
import useDebounce from '../../../hooks/debounce'
import { IEventInterface } from '../../../models/events/event.interface'
import { resetEvents, selectEvents } from '../../../store/events/eventsSlice'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

// Constants

const SEARCH_ICON = '/assets/cp_icons/Search-1.svg'
const SEARCH_ACTIVE_ICON = '/assets/cp_icons/Search-2.svg'
const EVENTS_CONSTS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface HomeRootPageContentCategoriesSectionProps {
  onTagsChanged?: any
  searchHandler?: (data: { value: string; filters: IFilters; tags: (string | null)[] }) => void
  filtersHandler?: (data: any) => void
  handleModal?: any
  useMode: IAppState['useMode']
  contentType: TContentType
  openCreateEventModal?: () => void
}

export type IFilters = {
  inString: string[]
  withKeys: { [type: string]: string[] }[]
}

export type TContentType = 'events' | 'organizers'
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const HomeCategoriesControlComponent: React.FC<HomeRootPageContentCategoriesSectionProps> = (
  props
) => {
  const [searchText, setSearchText] = useState<string>('')
  const [filters, setFilters] = useState<IFilters>({
    inString: [],
    withKeys: [],
  })
  const [tags, setTags] = useState<(string | null)[]>([])

  const dispatch = useAppDispatch()
  const filterData = useSelector(selectedfilters)
  const existFilters = useAppSelector(selectedfilters)
  const orgFilters = useAppSelector(selectedOrganizerFilter)
  const debouncedSearchTerm = useDebounce(searchText, 500)
  const eventsR = useAppSelector(selectEvents)

  const showMap = existFilters.map.status

  const [showRest, setShowReset] = useState(false)

  let mounted = true
  let isOrganizerLoggedIn = props.useMode === 'organizer'
  let loading = eventsR.fetchMoreStatus === 'loading' || eventsR.status === 'loading'

  useEffect(() => {
    if (existFilters?.statusFilter) {
      setTags([...existFilters.groupFilters.categoriesFilter, existFilters.statusFilter])
    } else {
      setTags([...existFilters.groupFilters.categoriesFilter])
    }
  }, [existFilters])

  useEffect(() => {
    if (!props.searchHandler)
      dispatch(
        setActiveFilters({
          ...existFilters,
          search: {
            status: debouncedSearchTerm.length > 0,
            searchValue: debouncedSearchTerm,
          },
        })
      )
  }, [debouncedSearchTerm])

  useEffect(() => {
    setSearchText(filterData.search.searchValue)
  }, [])

  useEffect(() => {
    let filters: IFilters = { withKeys: [], inString: [] }
    let endDateIsValid: boolean
    let startDateIsValid: boolean

    const groupFilters = filterData.groupFilters

    let { endDate, startDate, priceRange, disciplineFilter, dateCategoryFilter } = groupFilters

    let dateRangeFilter: string = ''
    endDateIsValid = (endDate && moment.isDate(new Date(endDate))) ?? false
    startDateIsValid = (startDate && moment.isDate(new Date(startDate))) ?? false

    if (startDateIsValid)
      dateRangeFilter = `${moment(startDate).format('MMM D')}${endDateIsValid ? ' - ' : ''}`

    if (endDateIsValid)
      dateRangeFilter = dateRangeFilter.concat(`${moment(endDate).format('MMM D')}`)

    if (disciplineFilter.includes('All')) disciplineFilter = ['All']

    filters.withKeys = [
      { disciplineFilter: disciplineFilter ?? [] },
      {
        dateCategoryFilter: dateCategoryFilter[0] ? dateCategoryFilter : [],
      },
      { dateRangeFilter: dateRangeFilter === '' ? [] : [dateRangeFilter] },
      { priceRange: priceRange ? [`Max price $${priceRange}`] : [] },
    ]

    filters.inString = filters.withKeys
      .reduce((acc: any[], currFilter) => {
        acc.push(Object.values(currFilter))
        return acc
      }, [])
      .flat(2)
      .filter((c) => c !== '')

    setFilters(filters)
    if (
      filters.inString.length ||
      filterData.location.status ||
      filterData.search.status ||
      orgFilters.search.status ||
      orgFilters.location.status ||
      orgFilters.groupFilters.status ||
      Number(filterData.location.radius) > 0 ||
      Number(orgFilters.location.radius) > 0
    )
      setShowReset(true)
    else setShowReset(false)

    return () => {
      mounted = false
    }
  }, [filterData, orgFilters])

  const handleOnTagsChanged = (tag: IEventInterface['status']) => {
    if (!tag) return null

    if (props.onTagsChanged) {
      props.onTagsChanged(tag)
    }

    if (mounted) {
      const STATUS_FILTERS_OBJ = EVENTS_CONSTS.FIELDS.STATUS.VALUE
      const statusFilters = [
        STATUS_FILTERS_OBJ.CURRENT,
        STATUS_FILTERS_OBJ.DRAFT,
        STATUS_FILTERS_OBJ.PAST,
      ]
      const isStatusFilter = statusFilters.includes(tag)

      dispatch(resetEvents())

      dispatch(
        setActiveFilters({
          ...existFilters,
          groupFilters: {
            ...existFilters.groupFilters,
            ...(!isStatusFilter && { categoriesFilter: [tag] }),
          },
          ...(isStatusFilter && {
            statusFilter: tag,
          }),
        })
      )
    }
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    props.searchHandler && props.searchHandler({ value: e.currentTarget.value, filters, tags })
  }

  /** @info Clears all the filters */
  const clearAllFilters = () => {
    let updatedFilters = cloneDeep(initialSystemStateFilters.activeFilters)
    if (isOrganizerLoggedIn) updatedFilters.statusFilter = DEFAULT_STATUS_ORGANIZER
    else updatedFilters.groupFilters.categoriesFilter = [DEFAULT_CATEGORY]
    setSearchText('')
    dispatch(setActiveFilters(updatedFilters))
    dispatch(setOrganizerFilters(null))
  }

  /** @info Handles removeFilter */
  const handleRemoveFilter = (filter: { [type: string]: string }) => {
    let indexOfFilterToRemove = -1
    let [typeOfFilter, valueToRemove] = Object.entries(filter)[0]
    let currentlyAppliedFilters = cloneDeep(existFilters.groupFilters)

    switch (typeOfFilter) {
      case 'dateRangeFilter':
        currentlyAppliedFilters.endDate = null
        currentlyAppliedFilters.startDate = null
        break

      case 'priceRange':
        currentlyAppliedFilters.priceRange = null
        break

      case 'categoriesFilter':
        let cf = currentlyAppliedFilters.categoriesFilter
        indexOfFilterToRemove = cf.indexOf(valueToRemove)
        if (indexOfFilterToRemove !== -1)
          currentlyAppliedFilters.categoriesFilter.splice(indexOfFilterToRemove, 1)
        break

      case 'disciplineFilter':
        let df = currentlyAppliedFilters.disciplineFilter
        indexOfFilterToRemove = df.indexOf(valueToRemove)
        if (valueToRemove === 'All') currentlyAppliedFilters.disciplineFilter = []
        if (indexOfFilterToRemove !== -1)
          currentlyAppliedFilters.disciplineFilter.splice(indexOfFilterToRemove, 1)
        break

      case 'dateCategoryFilter':
        currentlyAppliedFilters.dateCategoryFilter =
          currentlyAppliedFilters.dateCategoryFilter.filter(
            (value) => value !== filter.dateCategoryFilter
          )
        break
    }

    dispatch(
      setActiveFilters({
        ...existFilters,
        groupFilters: currentlyAppliedFilters,
      })
    )
  }

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onLocationChange = (
    place: google.maps.places.PlaceResult | React.FormEvent<HTMLInputElement>
  ) => {
    const result = handleLocation(place)
    let modified = { ...filterData }

    if (!result) return

    const { lat, lng } = result

    modified = {
      ...modified,
      location: {
        ...modified.location,
        ...(!showMap && {
          lat,
          lng,
        }),
      },
      map: {
        ...modified.map,
        focusOnSearchedLocation: true,
        ...(showMap && {
          lat,
          lng,
        }),
      },
    }

    dispatch(setActiveFilters(modified))
  }

  return (
    <div
      className={clsx(
        'overflow-x-scroll hide-scroll-bar bg-white 2xl:pt-6 2xl:py-5 2xl:px-6 xl:pt-4 xl:py-3 xl:px-4 mb-4 drop-shadow-[0_10px_10px_rgba(163,171,185,0.2)] p-3',
        showMap && '!mb-0'
      )}
    >
      <div className="flex flex-wrap gap-2 justify-between">
        <div className="hidden md:flex grid-cols-1 gap-2 xl:w-fit w-full overflow-y-auto">
          {props.contentType === 'organizers' ? (
            <>
              <PillButtonElement
                text={'All'}
                visible={props.useMode === 'competitor' || !props.useMode}
                onButtonClicked={() => {
                  dispatch(
                    setOrganizerFilters({
                      ...orgFilters,
                      groupFilters: {
                        ...orgFilters.groupFilters,
                        categoriesFilter: [],
                      },
                    })
                  )
                }}
                value={'All'}
                selected={!orgFilters.groupFilters.categoriesFilter[0]}
                Class="text-SeabiscuitDark200ThemeColor !mr-0 !w-[80px] !min-w-[80px]"
              />
              {/*<PillButtonElement*/}
              {/*  text={'Associations'}*/}
              {/*  visible={props.useMode === 'competitor' || !props.useMode}*/}
              {/*  onButtonClicked={() => null}*/}
              {/*  value={'Associations'}*/}
              {/*  selected={false}*/}
              {/*  Class="text-SeabiscuitDark200ThemeColor !mr-0 !w-[130px] !min-w-[130px]"*/}
              {/*/>*/}
              {/*<PillButtonElement*/}
              {/*  text={'Facilities'}*/}
              {/*  visible={props.useMode === 'competitor' || !props.useMode}*/}
              {/*  onButtonClicked={() => null}*/}
              {/*  value={'Facilities'}*/}
              {/*  selected={false}*/}
              {/*  Class="text-SeabiscuitDark200ThemeColor !mr-0 !w-[120px] !min-w-[120px]"*/}
              {/*/>*/}
              {/*<PillButtonElement*/}
              {/*  text={'Barns'}*/}
              {/*  visible={props.useMode === 'competitor' || !props.useMode}*/}
              {/*  onButtonClicked={() => null}*/}
              {/*  value={'Barns'}*/}
              {/*  selected={false}*/}
              {/*  Class="text-SeabiscuitDark200ThemeColor !mr-0 !w-[120px] !min-w-[120px]"*/}
              {/*/>*/}
              <PillButtonElement
                text={'Following'}
                visible={props.useMode === 'competitor' || !props.useMode}
                onButtonClicked={() => {
                  dispatch(
                    setOrganizerFilters({
                      ...orgFilters,
                      groupFilters: {
                        ...orgFilters.groupFilters,
                        categoriesFilter: orgFilters.groupFilters.categoriesFilter.includes(
                          'following'
                        )
                          ? orgFilters.groupFilters.categoriesFilter.filter(
                              (itm) => itm !== 'following'
                            )
                          : [...orgFilters.groupFilters.categoriesFilter, 'following'],
                      },
                    })
                  )
                }}
                value={'following'}
                selected={orgFilters.groupFilters.categoriesFilter.includes('following')}
                Class="text-SeabiscuitDark200ThemeColor !mr-0 !w-[120px] !min-w-[120px]"
              />
            </>
          ) : (
            <>
              <PillButtonElement
                text={'All'}
                visible={props.useMode === 'competitor' || !props.useMode}
                onButtonClicked={handleOnTagsChanged}
                value={EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.ALL}
                selected={
                  tags?.includes(EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.ALL) || tags?.length <= 0
                }
                Class="text-SeabiscuitDark200ThemeColor !mr-0 !w-[80px] !min-w-[80px]"
              />
              <PillButtonElement
                text={'Licensed'}
                visible={props.useMode === 'competitor' || !props.useMode}
                onButtonClicked={handleOnTagsChanged}
                value={EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.LICENSED}
                selected={tags?.includes(EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.LICENSED)}
                Class="text-SeabiscuitDark200ThemeColor !mr-0 !w-[85px] !min-w-[85px]"
              />
              <PillButtonElement
                text={'Unlicensed'}
                visible={props.useMode === 'competitor' || !props.useMode}
                onButtonClicked={handleOnTagsChanged}
                value={EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.UNLICENSED}
                selected={tags?.includes(EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.UNLICENSED)}
                Class="text-SeabiscuitDark200ThemeColor !mr-0 !w-[105px] !min-w-[105px]"
              />
              <PillButtonElement
                text={'Clinic'}
                visible={props.useMode === 'competitor' || !props.useMode}
                onButtonClicked={handleOnTagsChanged}
                value={EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.CLINIC}
                selected={tags?.includes(EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.CLINIC)}
                Class="text-SeabiscuitDark200ThemeColor !mr-0 !w-[80px] !min-w-[80px]"
              />
              <PillButtonElement
                text={'Other'}
                visible={props.useMode === 'competitor' || !props.useMode}
                onButtonClicked={handleOnTagsChanged}
                value={EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.OTHER}
                selected={tags?.includes(EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.OTHER)}
                Class="text-SeabiscuitDark200ThemeColor !mr-0 !w-[80px] !min-w-[80px]"
              />

              <PillButtonElement
                text={'Following'}
                visible={props.useMode === 'competitor'}
                onButtonClicked={handleOnTagsChanged}
                value={EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.FOLLOWING}
                selected={tags?.includes(EVENTS_CONSTS.FIELDS.CATEGORIES.VALUES.FOLLOWING)}
                Class="text-SeabiscuitDark200ThemeColor !mr-0 !w-[100px] !min-w-[100px]"
              />

              <PillButtonElement
                text={'Current events'}
                visible={props.useMode === 'organizer'}
                onButtonClicked={handleOnTagsChanged}
                value={EVENTS_CONSTS.FIELDS.STATUS.VALUE.CURRENT as string}
                selected={tags?.includes(EVENTS_CONSTS.FIELDS.STATUS.VALUE.CURRENT)}
                Class="text-SeabiscuitDark200ThemeColor !mr-0 min-w-[100px]"
              />

              <PillButtonElement
                text={'Draft events'}
                visible={props.useMode === 'organizer'}
                onButtonClicked={handleOnTagsChanged}
                value={EVENTS_CONSTS.FIELDS.STATUS.VALUE.DRAFT as string}
                selected={tags?.includes(EVENTS_CONSTS.FIELDS.STATUS.VALUE.DRAFT)}
                Class="text-SeabiscuitDark200ThemeColor !mr-0 min-w-[100px]"
              />

              <PillButtonElement
                text={'Past events'}
                visible={props.useMode === 'organizer'}
                onButtonClicked={handleOnTagsChanged}
                value={EVENTS_CONSTS.FIELDS.STATUS.VALUE.PAST as string}
                selected={tags?.includes(EVENTS_CONSTS.FIELDS.STATUS.VALUE.PAST)}
                Class="text-SeabiscuitDark200ThemeColor !mr-0 min-w-[100px]"
              />
            </>
          )}
        </div>

        <div className="relative flex items-center flex-row flex-auto shrink-0 w-min">
          <div className="relative flex items-center w-full rounded-[12px] overflow-hidden h-full">
            {showMap ? (
              <Autocomplete
                aria-disabled={loading}
                className={clsx(
                  'rounded-md border-0 focus:ring-0 focus:border-0 focus:outline-none pl-12 pr-4 text-sm w-full h-full',
                  'bg-[#F6F7FB] placeholder:text-SeabiscuitDark200ThemeColor'
                )}
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
                placeholder="Search location..."
                defaultValue={filterData?.location?.location}
                onPlaceSelected={onLocationChange}
                onChange={onLocationChange}
                options={CONST.UI.MAP.AUTO_COMPLETE_INPUT_OPTIONS}
              />
            ) : (
              <input
                type="text"
                value={searchText}
                className={clsx(
                  'rounded-md border-0 focus:ring-0 focus:border-0 focus:outline-none pl-12 pr-4 text-sm w-full h-full',
                  filterData.search.status
                    ? 'bg-[#F7074F0D] placeholder:text-SeabiscuitMainThemeColor !text-SeabiscuitMainThemeColor border-[#f7074f00]'
                    : 'bg-[#F6F7FB] placeholder:text-SeabiscuitDark200ThemeColor'
                )}
                onChange={handleSearch}
                placeholder={
                  props.contentType === 'organizers' ? 'Search organizers' : 'Search events...'
                }
              />
            )}

            <img
              src={filterData.search.status && !showMap ? SEARCH_ACTIVE_ICON : SEARCH_ICON}
              alt="seach"
              className="absolute left-4 max-w-6 max-h-6"
            />
          </div>
        </div>

        {!isOrganizerLoggedIn ? (
          <>
            <div
              className={`relative flex items-center flex-row p-2 px-[13px] cursor-pointer md:w-max 2xl:h-[50px] xl:h-[45px] 2xl:w-[160px] xl:w-[140px] rounded-[12px] ${
                filterData.location.status || orgFilters?.location?.status
                  ? 'bg-[#F7074F0D] '
                  : 'bg-[#F6F7FB]'
              }`}
              onClick={() =>
                props.handleModal(true, MODAL_CONSTS.LOCATION, {
                  isOrganizes: props.contentType === 'organizers',
                })
              }
            >
              <img
                src={`assets/og_icons/Location-${filterData.location.status || orgFilters?.location?.status ? 2 : 8}.svg`}
                alt="selected location"
                className="mr-1 w-6 h-6"
              />
              <p
                className={clsx(
                  'hidden md:block ml-1 w-[70%] 2xl:text-[16px] xl:text-[14px] overflow-hidden text-ellipsis  text-center whitespace-nowrap',
                  filterData?.location?.status || orgFilters?.location?.status
                    ? 'text-SeabiscuitMainThemeColor border-[#f7074f00]'
                    : 'text-SeabiscuitDark200ThemeColor'
                )}
                title={filterData?.location?.location}
              >
                {props.contentType === 'organizers'
                  ? orgFilters?.location?.status
                    ? orgFilters?.location?.location
                    : 'Location'
                  : filterData?.location?.status
                    ? filterData?.location?.location
                    : 'Location'}
              </p>
            </div>
            <div
              className={`relative flex items-center flex-row rounded-[12px] p-2 px-[13px] cursor-pointer md:w-max 2xl:h-[50px] xl:h-[45px] 2xl:w-[140px] xl:w-[120px] ${
                (filterData.groupFilters.status && showRest) ||
                (orgFilters.groupFilters.status && showRest)
                  ? 'bg-[#F7074F0D] '
                  : 'bg-[#F6F7FB]'
              }`}
              onClick={() =>
                props.handleModal(
                  true,
                  props.contentType === 'organizers'
                    ? MODAL_CONSTS.FILTERS_ORGANIZERS
                    : MODAL_CONSTS.FILTERS
                )
              }
            >
              <img
                src={`assets/og_icons/HomeFIlter${
                  (filterData.groupFilters.status && showRest) ||
                  (orgFilters.groupFilters.status && showRest)
                    ? '-1'
                    : ''
                }.svg`}
                alt="HomeFIlter"
                className="mr-1 w-6 h-6"
              />
              <p
                className={`hidden md:block ml-1 2xl:text-[16px] xl:text-[14px] w-[70%] text-center text-SeabiscuitDark200ThemeColor ${
                  (filterData.groupFilters.status && showRest) ||
                  (orgFilters.groupFilters.status && showRest)
                    ? 'text-SeabiscuitMainThemeColor'
                    : ''
                }`}
              >
                Filters
              </p>
            </div>

            {/* <div
              className={clsx(
                "relative flex items-center flex-row rounded-[12px] p-2 px-[13px] cursor-pointer md:w-max 2xl:h-[50px] xl:h-[45px] 2xl:w-[110px] xl:w-[100px]",
                showMap
                  ? "text-SeabiscuitMainThemeColor border-[#f7074f00] bg-[#F7074F0D]"
                  : "text-SeabiscuitDark200ThemeColor bg-[#F6F7FB]"
              )}
              onClick={onMapButtonClick}
            >
              <img
                src={`assets/cp_icons/Map-${showMap ? 4 : 2}.svg`}
                alt="Map"
                className="mr-1 w-6 h-6"
              />
              <p className="ml-1 2xl:text-[16px] xl:text-[14px] w-[70%] text-center">
                {showMap ? "Close" : "Map"}
              </p>
            </div> */}
          </>
        ) : null}

        {showRest ? (
          <div
            className="relative bg-[#F6F7FB] flex items-center justify-center flex-row rounded-[12px] p-2 px-[13px] cursor-pointer w-full sm:w-max 2xl:h-[50px] xl:h-[45px] 2xl:w-[140px] xl:w-[100px] text-SeabiscuitDark200ThemeColor "
            onClick={clearAllFilters}
          >
            <p className="ml-1">Reset</p>
          </div>
        ) : null}

        {isOrganizerLoggedIn ? (
          <div
            className="relative bg-[#F6F7FB] hover:bg-[#fef5f6] hover:text-SeabiscuitMainThemeColor flex items-center flex-row rounded-[12px] p-2 px-[15px] cursor-pointer md:w-max 2xl:h-[50px] xl:h-[45px] min-w-[120px] justify-center"
            onClick={() => props?.openCreateEventModal && props.openCreateEventModal()}
          >
            <AddCircleOutlineIcon fontSize={'small'} className=" mr-1" />
            <p className="ml-1 2xl:text-[16px] xl:text-[14px]">New Event</p>
          </div>
        ) : null}
      </div>
      {filters.withKeys.filter(
        (current) => Object.values(current).filter((single) => single.length > 0).length > 0
      ).length > 0 && (
        <div className="flex flex-row-reverse">
          <ul className="flex flex-wrap mt-3">
            {filters.withKeys.map((currFilter, lOneIndex) => {
              return Object.values(currFilter).map((singleFilter, lTwoIndex) => {
                return singleFilter.map((c, lThreeIndex) => {
                  return (
                    <li
                      key={`${c}${lOneIndex}${lTwoIndex}${lThreeIndex}`}
                      className="bg-[#F7074F0D] capitalize text-SeabiscuitMainThemeColor px-2 py-1 rounded-3xl flex mr-2 mb-2 border border-solid border-[#F7074F0D] text-nr mt-2"
                    >
                      {c}
                      <Clear
                        fontSize={'small'}
                        className="cursor-pointer"
                        onClick={() =>
                          handleRemoveFilter({
                            [Object.keys(currFilter)[0]]: c,
                          })
                        }
                      />
                    </li>
                  )
                })
              })
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

HomeCategoriesControlComponent.defaultProps = {
  onTagsChanged: null,
}

export default HomeCategoriesControlComponent
