import { useCallback, useContext, useEffect, useRef, useState } from 'react'

// Constants
import { MODAL_CONSTS } from '../../const/modal-const'

// Third party
import { useHistory } from 'react-router-dom'

// Components
import BillDetailsFeesData from './accordionData/BillDetailsFeesData'
import BillDetailsGrandTotalData from './accordionData/BillDetailsGrandTotalData'
import BillDetailsTicketsData from './accordionData/BillDetailsTicketsData'

import BillDetailsRegistrationData from './accordionData/BillDetailsRegistrationData'

// Helpers
import { getConvertedData } from '../../models/interface.helper'

// Redux
import { useIonRouter } from '@ionic/react'
import clsx from 'clsx'
import domToImage from 'dom-to-image'
import { QueryFieldFilterConstraint, where } from 'firebase/firestore'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { StandardFonts } from 'pdf-lib'
import helpers from '../../commonHelpers/helpers'
import { CONST } from '../../const/const'
import { IMAGE_CONSTS } from '../../const/image-const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { ROUTES_CONST } from '../../const/routes-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { CustomError, capitalize, createString, getUserFullName } from '../../helpers/helpers'
import { EVENT_REGISTERED_CONST } from '../../models/event-registered-users/event-registered-users.constants'
import { EventRegisteredUsersModel } from '../../models/event-registered-users/event-registered-users.model'
import { IHorseData } from '../../models/horse/horse.interface'
import { ModelBaseModel } from '../../models/model-base/model-base.model'
import { RecipientModel } from '../../models/recipients/recipients'
import { IRecipientInterface } from '../../models/recipients/recipients.interface'
import { IRegistrationFeesInterface } from '../../models/registration-fees/registrationFees.interface'
import { RegistrationFeesModel } from '../../models/registration-fees/registrationFees.model'
import { IRegistrationTicketInterface } from '../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../models/registration-tickets/registrationTicket.model'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../models/registrations-by-day/registrationByDay.model'
import { IUserCards, IUserInterface } from '../../models/users/user.interface'
import { UserModel } from '../../models/users/user.model'
import ExhibitorCommentAccordion from '../../pages/organizer/exhibitorProfile/accordions/ExhibitorCommentAccordion'
import ExhibitorProfileRefundAccordion from '../../pages/organizer/exhibitorProfile/accordions/ExhibitorProfileRefundAccordion'
import EventService from '../../services/eventService'
import FirestoreService from '../../services/firestoreService'
import { httpService } from '../../services/httpService'
import PdfService from '../../services/pdfService'
import UserService from '../../services/userService'
import {
  selectCountDetails,
  selectHorses,
  selectUsers,
  setSelectedEvent,
  setSelectedEventFees,
  setSelectedRegistration,
} from '../../store/bills/billsSlice'
import { selectedEvent as selectedEventGetter } from '../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectUserId, selectUserStripeId, selectUserType } from '../../store/user/userSlice'
import { FEES_CATEGORY_CONST } from '../events/views/details/EventDetailsViewComponentFees'
import ViewsLoader from '../loader/ViewsLoader'
import MainModal from './common/MainModal'
import useGetEventData from '../../hooks/users/common/useGetEventData'
import { TEventRegisteredUsers } from '../../models/event-registered-users/event-registered-users.interface'
import { EventRegistrationContext } from '../../pages/competitor/event-registration-tabs/EventRegistrationTabs'
import { EventReviewPublishModel } from '../../models/event-review-publish/event-review-publish.model'
import { RegistrationFeesType } from '../../models/event-fees/event-fees.interface'
import { IEventReviewPublish } from '../../models/event-review-publish/event-review-publish.interface'
import { IUserInterfaceExtended } from '../../store/storeHelpers/userSlice/types'
import { SelectPaymentMethod } from '../payment/SelectPaymentMethod/SelectPaymentMethod'

// Types
type IStepTab = 'paymentDetails' | 'viewReciept'
type ICustomRecipient = IRecipientInterface & {
  isrecipient: boolean
}

type IUserParams = {
  userId: string
  eventId: string
  registrationId: string
}

// Constants

const UNKNOWN = 'Unknown'
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const generateName = (fullName: string) => {
  return fullName
    .split(' ')
    .map((name) => name[0])
    .join('')
    .toUpperCase()
}

const FILE_NAME = 'BillDetailsModal'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const BillDetailsModal = ({
  show,
  handleModal,
  dataToPassOn,
}: {
  show: boolean
  handleModal: any
  dataToPassOn: {
    bill: ICustomRecipient
    registrationByDay: IRegistrationByDayInterface
  } & Record<any, any>
}) => {
  // Hooks and vars
  const { getAllData } = useGetEventData()

  const saveAllTabs = useContext(EventRegistrationContext)?.saveAllTabs ?? (() => {})
  const { memberName, currentUser, setSortStyle, sortstyle } = dataToPassOn
  const [bill, setBill] = useState<ICustomRecipient>(dataToPassOn.bill)
  const { amountScratched } = dataToPassOn.bill
  const showRefund = amountScratched > 0
  const dispatch = useAppDispatch()
  const users = useAppSelector(selectUsers)
  const horses = useAppSelector(selectHorses)
  const toasterFunctions = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const router = useIonRouter()
  const history = useHistory()
  const userType = useAppSelector(selectUserType)
  const paymentOptionRef = useRef<string | null>(null)

  const stripeId = useAppSelector(selectUserStripeId)
  const selectedEvent = useAppSelector(selectedEventGetter)
  const countDetails = useAppSelector(selectCountDetails)
  const selectedRegistration = useAppSelector((state) => state.bills.selected).registration

  const [feesData, setFeesData] = useState<IRegistrationFeesInterface[]>([])
  const [ticketData, setTicketData] = useState<IRegistrationTicketInterface[]>([])
  const [registrationsByDay, setRegistrationsByDay] = useState<IRegistrationByDayInterface[]>([])
  const [loading, setLoading] = useState(false)
  const [recieptLoading, setRecieptLoading] = useState(false)
  const [stripeRes, setStripeRes] = useState<any>(null)
  const [eventLoading] = useState(false)
  const [eventLogo, setEventLogo] = useState<string>('')
  const [userProfilePicture, setUserProfilePicture] = useState<string>('')
  const [openedMenu, setOpenedMenu] = useState<string[]>([])
  const [eventLocation, setEventLocation] = useState<string>('')
  const [step, setStep] = useState<IStepTab>('paymentDetails')
  const [selectedCard, setSelectedCard] = useState<IUserCards | null>(null)
  const [feeTotalState, setFeeTotalState] = useState<number>(0)
  const [refundFeeTotalState, setRefundFeeTotalState] = useState<number>(0)
  const [refundgovernanceTotalState, setRefundGovernanceTotalState] = useState<number>(0)
  const [refundticketTotalState, setRefundTicketTotalState] = useState<number>(0)
  const [refundregistrationTotalState, setRefundRegistrationTotalState] = useState<number>(0)
  const [governanceTotalState, setGovernanceTotalState] = useState<number>(0)
  const [ticketTotalState, setTicketTotalState] = useState<number>(0)
  const [registrationTotalState, setRegistrationTotalState] = useState<number>(0)
  const [taxTotalState, setTaxTotalState] = useState<number>(0)
  const [modalThemeColor, setModalThemeColor] = useState<`#${string}`>('#FFD96440')
  const [userData, setUserData] = useState<IUserInterfaceExtended | null>(null)
  const [organiserData, setOrganiserData] = useState<IUserInterfaceExtended | null>(null)

  const horsesRef = useRef<IHorseData[]>([])
  const usersRef = useRef<IUserInterface[]>([])

  let title = 'Receipt'

  useEffect(() => {
    usersRef.current = users
    horsesRef.current = horses
  }, [users, horses])

  useEffect(() => {
    if (bill) {
      if (bill.refundStatus === 'pending') {
        setModalThemeColor('#FFD96440')
      } else {
        setModalThemeColor('#00B6AA26')
      }
      setRecieptLoading(true)
      getEventDetails().then()
    }
  }, [bill])

  useEffect(() => {
    setBill(dataToPassOn.bill)

    if (!!dataToPassOn.bill.eventId) {
      getAllData(dataToPassOn.bill.eventId, ['v01_event_payments_settings']).then(
        ({ v01_event_payments_settings }) => {
          paymentOptionRef.current = v01_event_payments_settings.paymentOption
        }
      )
    }
  }, [dataToPassOn.bill, getAllData])

  useEffect(() => {
    const { Event, EventFees } = selectedEvent

    if (!Event || !EventFees) return

    dispatch(setSelectedEvent(Event))
    dispatch(setSelectedEventFees(EventFees))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent.Event, selectedEvent.EventFees])

  useEffect(() => {
    if (bill.id) {
      dispatch(setSelectedRegistration(bill))
    }
  }, [bill])

  const feesTotal = (feesData: IRegistrationFeesInterface[]) => {
    let feesTotal = 0
    let refundTotal = 0

    feesData.forEach((item) => {
      if (item.feesItemCategory !== FEES_CATEGORY_CONST.GOVERNANCE) {
        feesTotal += item.feesPrice * item.selectedUnitsCount
        refundTotal += item.amountRefunded
      }
    })

    return {
      feesTotal: new ModelBaseModel().getNum(feesTotal),
      refundTotal: new ModelBaseModel().getNum(refundTotal),
    }
  }

  const governanceTotal = (feesData: IRegistrationFeesInterface[]) => {
    let feesTotal = 0
    let refundTotal = 0

    feesData.forEach((item) => {
      if (item.feesItemCategory === FEES_CATEGORY_CONST.GOVERNANCE) {
        feesTotal += item.feesPrice * item.selectedUnitsCount
        refundTotal += item.amountRefunded
      }
    })
    return {
      feesTotal: new ModelBaseModel().getNum(feesTotal),
      refundTotal: new ModelBaseModel().getNum(refundTotal),
    }
  }

  const ticketsTotal = (ticketData: IRegistrationTicketInterface[]) => {
    let ticketTotal = 0
    let refundTotal = 0
    ticketData.forEach((item) => {
      ticketTotal += item.ticketPrice * item.selectedUnitsCount
      refundTotal += item.amountRefunded
    })

    return {
      ticketTotal: parseFloat(ticketTotal.toFixed(2)),
      refundTotal: parseFloat(refundTotal.toFixed(2)),
    }
  }

  const registrationTotal = (registrationData: IRegistrationByDayInterface[]) => {
    let registrationTotal = 0
    let refundTotal = 0
    let qualifyFee = 0
    registrationData.forEach((item) => {
      if (item.registrationPrice) registrationTotal += item.registrationPrice
      if (item.isQualify) qualifyFee += Number(item?.qualifyFee) ?? 0
      refundTotal += item.amountRefunded
    })

    return { registrationTotal: registrationTotal + qualifyFee, refundTotal }
  }

  const salesTotal = (
    registrationTotal: number,
    ticketTotal: number,
    feesTotal: number,
    totalgovernance: number
  ) => {
    let salesTotal = registrationTotal + ticketTotal + feesTotal + totalgovernance
    salesTotal = salesTotal * (selectedEvent?.EventPaymentSettings?.chargeSalesTax ? 0.02 : 0)
    return salesTotal
  }

  const handleGetRegistrationData = useCallback(
    async (
      userId: IUserParams['userId'],
      registrationId: IUserParams['registrationId'],
      eventId: IUserParams['eventId']
    ) => {
      let isDirectRegisterant = bill.isPaidByOwner
      let registrationsfees: IRegistrationFeesInterface[] = []
      let registrationsByDay_: IRegistrationByDayInterface[] = []
      let registrationsTickets: IRegistrationTicketInterface[] = []
      let registrationByDay: IRegistrationByDayInterface | null = null
      let registrationfees: IRegistrationFeesInterface | null = null
      let registrationTicket: IRegistrationTicketInterface | null = null
      let publishedEventInDb: IEventReviewPublish | null = null

      let commonQueries = [
        where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.REGISTRATION_DOC_ID.KEY, '==', registrationId),
      ]

      if (!isDirectRegisterant) {
        commonQueries.push(
          where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.RECIPIENT_ID.KEY, '==', userId)
        )
      }

      let queryConstraint = {
        Fees: {
          query: [] as QueryFieldFilterConstraint[],
          collection: COLLECTIONS.REGISTRATION_FEES.NAME,
        },
        Tickets: {
          query: [] as QueryFieldFilterConstraint[],
          collection: COLLECTIONS.REGISTRATION_TICKET.NAME,
        },
        Classes: {
          query: [] as QueryFieldFilterConstraint[],
          collection: COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        },
      }

      queryConstraint.Classes.query = commonQueries
      queryConstraint.Fees.query = commonQueries
      queryConstraint.Tickets.query = commonQueries

      if (queryConstraint.Classes.query.length) {
        const commonSnaps = await FirestoreService.filterItems(
          queryConstraint.Classes.collection,
          queryConstraint.Classes.query
        )

        commonSnaps.docs.forEach((currDoc) => {
          registrationByDay = getConvertedData(
            RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject()
          )
          if (isDirectRegisterant) {
            if (registrationByDay.recipientId === null || registrationByDay.recipientId === userId)
              registrationsByDay_.push(registrationByDay)
          } else {
            registrationsByDay_.push(registrationByDay)
          }
        })
      }

      const publishedEventInDbSnapShot = await FirestoreService.getItem(
        COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        eventId
      )

      if (publishedEventInDbSnapShot.exists()) {
        publishedEventInDb = getConvertedData(
          EventReviewPublishModel.fromFirestoreDoc(publishedEventInDbSnapShot).toObject()
        )

        const { EventFees: { registrationFees } = {} } = publishedEventInDb
        registrationsByDay_ = registrationsByDay_.map((registrationByDay) => {
          const filtered = (registrationFees as RegistrationFeesType[]).filter(
            (fee) => fee.uuid === registrationByDay.uuid
          )
          if (filtered.length > 0) {
            return { ...registrationByDay, registrationByDayName: filtered[0].name ?? null }
          } else {
            return registrationByDay
          }
        })
      }

      if (queryConstraint.Tickets.query.length) {
        const commonSnaps = await FirestoreService.filterItems(
          queryConstraint.Tickets.collection,
          queryConstraint.Tickets.query
        )

        commonSnaps.docs.forEach((currDoc) => {
          registrationTicket = getConvertedData(
            RegistrationTicketModel.fromFirestoreDoc(currDoc).toObject()
          )
          if (isDirectRegisterant) {
            if (
              registrationTicket.recipientId === null ||
              registrationTicket.recipientId === userId
            )
              registrationsTickets.push(registrationTicket)
          } else {
            registrationsTickets.push(registrationTicket)
          }
        })
      }

      if (queryConstraint.Fees.query.length) {
        const commonSnaps = await FirestoreService.filterItems(
          queryConstraint.Fees.collection,
          queryConstraint.Fees.query
        )

        commonSnaps.docs.forEach((currDoc) => {
          registrationfees = getConvertedData(
            RegistrationFeesModel.fromFirestoreDoc(currDoc).toObject()
          )
          if (isDirectRegisterant) {
            if (registrationfees.recipientId === null || registrationfees.recipientId === userId)
              registrationsfees.push(registrationfees)
          } else {
            registrationsfees.push(registrationfees)
          }
        })
      }

      setFeesData(registrationsfees)
      setTicketData(registrationsTickets)

      setRegistrationsByDay(registrationsByDay_)
      let totalfees = feesTotal(registrationsfees)
      setFeeTotalState(totalfees.feesTotal)
      setRefundFeeTotalState(totalfees.refundTotal)
      let totalgovernance = governanceTotal(registrationsfees)
      setGovernanceTotalState(totalgovernance.feesTotal)
      setRefundGovernanceTotalState(totalgovernance.refundTotal)
      let totalticket = ticketsTotal(registrationsTickets)
      setTicketTotalState(totalticket.ticketTotal)
      setRefundTicketTotalState(totalticket.refundTotal)
      let totalregister = registrationTotal(registrationsByDay_)
      setRegistrationTotalState(parseFloat(totalregister.registrationTotal.toFixed(2)))
      setRefundRegistrationTotalState(parseFloat(totalregister.refundTotal.toFixed(2)))
      let totaltax = salesTotal(
        totalregister.registrationTotal,
        totalticket.ticketTotal,
        totalfees.feesTotal,
        totalgovernance.feesTotal
      )
      setTaxTotalState(parseFloat(totaltax.toFixed(2)))
      setRegistrationsByDay(registrationsByDay_)
    },
    [bill.isPaidByOwner, selectedEvent]
  )

  useEffect(() => {
    if (dataToPassOn.bill) {
      const bill = dataToPassOn.bill

      if (bill.recipientId && bill.registrationDocId && bill.eventId)
        handleGetRegistrationData(bill.recipientId, bill.registrationDocId, bill.eventId)
    }
  }, [
    dataToPassOn.bill,
    handleGetRegistrationData,
    dataToPassOn.bill.recipientId,
    dataToPassOn.bill.registrationDocId,
    dataToPassOn.bill.eventId,
  ])

  // Functions
  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.BILL_BRIEFE)
  }

  const onRefundButtonClick = () => {
    const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
      'bill.eventId': bill.eventId,
      'bill.registrationDocId': bill.registrationDocId,
    })

    if (emptyVarName) {
      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      helpers.logger({
        message: CustomError.somethingWentWrong({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `${emptyVarName} is ${emptyVarValue}`,
        }),
      })

      return
    }

    router.push(
      `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${userId}/${bill.eventId}/${bill.registrationDocId}`
    )
    history.push(
      `${ROUTES_CONST.COMPETITOR_REGISTERED_EVENT.URL}/${userId}/${bill.eventId}/${bill.registrationDocId}`
    )
    closeModal()
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleToggle = (value?: any) => {
    const exist = openedMenu.includes(value)

    if (exist) {
      setOpenedMenu(() => {
        return openedMenu.filter((current: any) => {
          return current !== value
        })
      })
    } else {
      setOpenedMenu((prevState: any) => [...prevState, value])
    }
  }

  const downloadReciept = async () => {
    let openMenu_ = [...cloneDeep(openedMenu)]
    let imageWidth = 0
    let imageHeight = 0

    setRecieptLoading(true)
    setOpenedMenu(['1', '2', '3', '4', '5'])

    await new Promise((resolve) => {
      setTimeout(resolve, 500)
    })

    try {
      let receiptContainer = document.querySelector('#receipt')

      if (!receiptContainer)
        throw CustomError.somethingWentWrong({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `receiptContainer is ${receiptContainer}`,
        })

      const base64Code = await domToImage.toPng(receiptContainer, {
        bgcolor: 'white',
      })
      const img = new Image()
      img.src = base64Code

      await new Promise((resolve) => {
        img.onload = function () {
          imageWidth = img.width
          imageHeight = img.height
          resolve(true)
        }
      })

      const pdfService = new PdfService({
        pageWidth: imageWidth + 100,
        pageHeight: imageHeight + 100,
        distanceBetweenLines: 20,
        pageVerticalStartPoint: imageWidth,
        pageHorizontalStartPoint: 45,
        pageHorizontalEndPoint: imageWidth,
        pageVerticalEndPoint: 80,
      })

      await pdfService.embedFont(StandardFonts.TimesRomanBold)
      const pdfPage = await pdfService.addNewPdfPage()
      const png = await pdfService.embedPng(base64Code)

      pdfPage.drawImage(png, {
        x: 50,
        y: 50,
        width: imageWidth,
        height: imageHeight,
      })

      const pdfBytes = await pdfService.generatedPdf
      const blob_ = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })
      const blobUrl = URL.createObjectURL(blob_)
      const downloadLink = document.createElement('a')

      downloadLink.href = blobUrl
      downloadLink.download = `Pegasus reciept-${selectedRegistration?.id}.pdf`
      downloadLink.click()

      document.body.appendChild(img)
      document.body.removeChild(img)
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: error?.devMessage ?? error?.message,
          fileName: 'BillDetailsModal',
          moduleName: 'downloadReciept',
        }),
      })

      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setOpenedMenu(openMenu_)
      setRecieptLoading(false)
    }
  }

  const getEventDetails = async () => {
    try {
      if (!bill.eventId)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          devMessage: `selectedRegistration?.eventId is ${bill?.eventId}`,
        })

      const eventService = new EventService(bill?.eventId)

      const eventLocation = await eventService.getEventLocation

      const eventOwner = await eventService.getPublishedEvent
      const ownerSnapshot = await FirestoreService.getItem(
        COLLECTIONS.USERS.NAME,
        eventOwner?.owner ?? ''
      )
      const owner = UserModel.fromFirestoreDoc(ownerSnapshot).toObject()

      const eventName = await eventService.getEventKey('competitionName')

      const userSnapshot = await UserService.getUserInfoById(bill.recipientId)
      const user = UserModel.fromFirestoreDoc(userSnapshot).toObject()

      let eventLogo_ = eventOwner?.EventDetails.eventLogo
        ? eventOwner?.EventDetails.eventLogo
        : `https://ui-avatars.com/api/?name=${generateName(eventName ?? UNKNOWN)}&background=f1f3f7&color=122b46`

      const userProfilePicture_ = user.userProfilePicture
        ? user.userProfilePicture
        : `https://ui-avatars.com/api/?name=${generateName(getUserFullName(user))}`

      setEventLogo(eventLogo_)
      setUserProfilePicture(userProfilePicture_)
      setEventLocation(eventLocation.combinedLocation)
      setUserData(user)
      setOrganiserData(owner)
    } catch (error: any) {
      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        message: error?.devMessage ?? error?.message,
      })
    } finally {
      setRecieptLoading(false)
    }
  }

  const onPayByCash = async () => {
    handleModal(false, MODAL_CONSTS.BILL_BRIEFE)
    handleModal(true, MODAL_CONSTS.ORGANIZER_MARK_PAID, {
      saveAllTabs,
      bill,
      registrationsByDay: registrationsByDay,
    })
  }

  const payByCard = async (eventId: string, selectedRegistration: TEventRegisteredUsers) => {
    if (!selectedCard) return toasterFunctions.info({ message: MESSAGES_CONST.PLEASE_SELECT_CARD })
    if (!eventId || !selectedRegistration)
      throw CustomError.somethingWentWrong({
        ...customErrorProps,
        devMessage: `eventId or selectedRegistration is null`,
      })

    const res = await httpService({
      url: 'create_payment_intents',
      method: 'POST',
      data: {
        userId,
        amount: bill.amountPaid ?? 0,
        pmId: selectedCard?.pmId ?? '',
        customerId: stripeId,
        paymentType: 'pay_registration_bill',
        description: 'Event registration',
        eventId: eventId,
        recipientDocId: bill.id,
        userEmail: userData?.userEmail,
        userName: userData ? getUserFullName(userData) : '',
        userPhone: userData?.userPhoneNumber,
        userStripeAccountId: organiserData?.userStripeAccountId,
      },
    })

    if ('paymentIntent' in (res as any)) {
      setStripeRes((res as any).paymentIntent)

      const updatedDocRecipient = new RecipientModel({
        ...bill,
        paymentStatus: 'paid',
        cardNo: selectedCard?.cardNumber,
      }).toFirestore()

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.NAME,
        bill.id,
        updatedDocRecipient
      )

      await httpService({
        url: 'on_recipient_bill_payment',
        method: 'POST',
        data: {
          registrationDocId: selectedRegistration.id,
          recipientDocId: bill.recipientId,
          paymentStatus: 'paid',
        },
      })

      const updatedDoc = new EventRegisteredUsersModel({
        ...new EventRegisteredUsersModel(selectedRegistration).toFirestore(),
        paymentStatus: EVENT_REGISTERED_CONST.PAYMENT_STATUS.PAID,
      }).toFirestore()

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        selectedRegistration.id,
        updatedDoc
      )
    } else {
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG_PAYMENT })
    }
    if (selectedRegistration.id) {
      await httpService({
        method: 'get',
        url: `update_registration_status_keys/${selectedRegistration.id}`,
      })
      await httpService({
        method: 'get',
        url: `update_recipient_paperwork_status/${userId}/${selectedRegistration.id}`,
      })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Pays the grand total amount of the grand total
   */
  const onPayAmount = async () => {
    try {
      if (bill.paymentStatus === 'paid')
        return toasterFunctions.info({ message: 'Amount has already been paid' })

      const eventId = bill.eventId ?? null

      if (!bill.registrationDocId)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          devMessage: `bill.registrationDocId is ${bill.registrationDocId} `,
        })

      setLoading(true)

      const doc = await FirestoreService.getItem(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        bill.registrationDocId
      )

      const selectedRegistration = EventRegisteredUsersModel.fromFirestoreDoc(doc).toObject()

      if (eventId) {
        await payByCard(eventId, selectedRegistration)
      }

      if (userId && selectedRegistration?.id) {
        setStep('viewReciept')

        return toasterFunctions.success({ message: MESSAGES_CONST.PAYMENT_DONE })
      } else {
        throw new Error(MESSAGES_CONST.SOMETHING_WENT_WRONG)
      }
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
      if (setSortStyle) setSortStyle(sortstyle === 'Newest' ? 'Oldest' : 'Newest')
    }
  }

  const onViewReceiptClick = () => {
    const registrationId = bill.id

    if (!registrationId) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'onViewReceiptClick',
          devMessage: `${registrationId} is[${registrationId}]`,
        }),
      })

      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      return
    }

    setBill({
      ...bill,
      paymentStatus: 'paid',
    })
    setStep('paymentDetails')
  }

  const openAddNewCardModal = () => {
    handleModal(false, MODAL_CONSTS.BILL_BRIEFE)
    handleModal(true, MODAL_CONSTS.CARD, {
      add_card: true,
      re_open_modal: true,
      modal_name: MODAL_CONSTS.BILL_BRIEFE,
      ...dataToPassOn,
      organizerId: organiserData?.id,
    })
  }

  if (step === 'viewReciept' && stripeRes) title = 'Success!'

  const totalNum = parseFloat(
    (
      taxTotalState +
      feeTotalState +
      governanceTotalState +
      ticketTotalState +
      registrationTotalState
    ).toFixed(2)
  )

  return (
    <MainModal
      show={show}
      title={title}
      type="BILL_BRIEFE"
      titleClassName="text-base pl-2 pb-2"
      className={`relative${recieptLoading ? ' pointer-events-none' : ''}`}
      setHeightAsPerContent={true}
      size={step === 'viewReciept' ? 'sm' : '4xl'}
      buttons={[
        ...(step === 'paymentDetails' &&
        bill.paymentStatus !== 'paid' &&
        userType === CONST.USE_MODE.COMPETITOR
          ? [
              {
                loading,
                label: 'Pay $' + totalNum,
                fullWidth: true,
                disabled: loading,
                onClick: onPayAmount,
                bgClass: '!bg-SeabiscuitMainThemeColor',
                textClass: '!text-SeabiscuitWhiteThemeColor',
              },
              {
                label: 'Close',
                fullWidth: true,
                bgClass: '!bg-SeabiscuitLightThemeColor',
                borderClass: 'border border-transparent',
                textClass: '!text-SeabiscuitLightTextColor',
                onClick: closeModal,
              },
            ]
          : []),
        ...(step === 'viewReciept' && stripeRes && userType === CONST.USE_MODE.COMPETITOR
          ? [
              {
                label: 'View Receipt',
                fullWidth: true,
                bgClass: 'bg-transparent',
                borderClass: 'border border-SeabiscuitMainThemeColor',
                textClass: 'text-SeabiscuitMainThemeColor',
                onClick: onViewReceiptClick,
              },
              {
                label: 'Close',
                fullWidth: true,
                bgClass: '!bg-SeabiscuitLightThemeColor',
                borderClass: 'border border-transparent',
                textClass: '!text-SeabiscuitLightTextColor',
                onClick: closeModal,
              },
            ]
          : []),
      ]}
    >
      {recieptLoading || eventLoading ? (
        <div className="absolute w-full h-full bg-white z-10 flex justify-center items-center">
          <ViewsLoader className="flex items-center w-full justify-center" size="xl" color="red" />
        </div>
      ) : null}
      {!eventLoading ? (
        <div className="text-SeabiscuitDark200ThemeColor">
          {selectedRegistration?.id && step === 'paymentDetails' ? (
            <div className={clsx('', recieptLoading && 'py-5')} id="receipt">
              {recieptLoading ? (
                <>
                  <div className="relative h-[70px] flex items-center opacity-[.8]">
                    <div className="mb-2 text-lg text-gray-400 absolute top-0">
                      Pegasus App Inc.
                    </div>
                    <img
                      src="/assets/Pegasus_app_logo.png"
                      alt="Pegasus app logo"
                      className="w-[70px] h-[70px] absolute right-0"
                    />
                    <hr className="bg-SeabiscuitMainThemeColor w-full h-[2px]" />
                  </div>

                  <div className="h-[120px]">
                    <h1 className="font-extrabold text-3xl mb-1">Receipt from Pegasus</h1>
                    <div className="text-xl">
                      Receipt ID:{' '}
                      {capitalize(createString(selectedRegistration.id), {
                        fullyCapitalize: true,
                      })}
                      . {moment(selectedRegistration?.created).format('MMMM Do, YYYY')}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="flex flex-col md:flex-row md:justify-between pb-2">
                <div>
                  <div className="flex items-center my-3">
                    <img
                      className="rounded-md mr-4 h-9 w-9 object-cover"
                      src={eventLogo ? eventLogo : IMAGE_CONSTS.PLACEHOLDERS.EVENT}
                      alt="eventImage"
                    />

                    <p className="text-md font-semibold text-SeabiscuitDark200ThemeColor">
                      {' '}
                      <div className="opacity-50 text-sm font-normal">Event</div>
                      {selectedRegistration?.eventName}
                    </p>
                  </div>
                  <div className="flex items-center my-3">
                    <img
                      className="rounded-md mr-4 h-9 w-9 object-cover"
                      src={userProfilePicture}
                      alt="userIcon"
                    />
                    <p className="text-md text-SeabiscuitDark200ThemeColor capitalize gap-1">
                      <div className="opacity-50 text-xs">From </div>{' '}
                      {memberName ?? selectedRegistration?.userName}
                    </p>
                  </div>

                  {/* <div className="flex items-center my-2">
                                        <span className="mr-4 w-7 h-7 pl-1 flex items-center">
                                            <img className="" src="/assets/cp_icons/Calendar-1.svg" alt="CalendarIcon" />
                                        </span>
                                        <p className="text-md text-SeabiscuitDark200ThemeColor ml-2">

                                        </p>
                                    </div> */}

                  <div className="flex items-center my-3">
                    <span className="mr-2 flex items-center">
                      <img
                        className="bg-[#fef5f6] rounded-lg p-[0.4rem]"
                        src="/assets/cp_icons/Location-2.svg"
                        alt="locationIcon"
                      />
                    </span>
                    <p className="text-md text-SeabiscuitDark200ThemeColor ml-2 gap-1">
                      <div className="opacity-50 text-xs">
                        {moment(selectedRegistration?.created).format('MMM Do, YYYY')}{' '}
                      </div>{' '}
                      {eventLocation}
                    </p>
                  </div>
                </div>

                <div className="mb-4 flex md:justify-end">
                  <div className="flex-col md:justify-end">
                    <p
                      className={`text-lg ${selectedRegistration.paymentStatus === 'paid' ? 'text-[#00B6aa]' : 'text-[#f7074f]'} font-bold rounded-md flex md:justify-end`}
                      // style={{
                      //     background: modalThemeColor
                      // }}>
                    >
                      $
                      {selectedRegistration.paymentStatus === 'paid'
                        ? selectedRegistration?.amountPaid?.toFixed(2) + ' paid'
                        : totalNum + ' to be paid'}
                    </p>
                    <div className="flex my-1 mb-2 justify-end">
                      <p className="text-SeabiscuitDark200ThemeColor opacity-50">
                        ${selectedRegistration?.amountScratched} scratched,
                      </p>
                      <p className="text-SeabiscuitDark200ThemeColor ml-1">
                        ${selectedRegistration?.amountRefunded} refunded
                      </p>
                    </div>
                    <div className="flex md:justify-end">
                      {bill.paymentStatus === 'paid' && !recieptLoading ? (
                        <>
                          <button
                            type="button"
                            onClick={downloadReciept}
                            disabled={selectedRegistration?.paymentStatus !== 'paid'}
                            className=""
                          >
                            <div className="text-SeabiscuitDark200ThemeColor opacity-50 hover:opacity-100 underline text-sm">
                              Download Receipt
                            </div>
                          </button>
                        </>
                      ) : null}
                      {userType === CONST.USE_MODE.COMPETITOR &&
                      selectedRegistration?.paymentStatus === 'paid' ? (
                        <>
                          <div className="text-SeabiscuitDark200ThemeColor px-2 opacity-50 text-sm">
                            |
                          </div>
                          <button onClick={onRefundButtonClick}>
                            <div className="text-SeabiscuitDark200ThemeColor opacity-50 hover:opacity-100 underline text-sm">
                              Request Refund
                            </div>
                          </button>
                        </>
                      ) : null}
                      {userType === CONST.USE_MODE.ORGANIZER &&
                        (selectedRegistration?.paymentStatus === 'pending' ? (
                          <button
                            type="button"
                            onClick={async () => {
                              await onPayByCash()
                            }}
                          >
                            <div className="text-SeabiscuitDark200ThemeColor opacity-50 hover:opacity-100 underline text-sm">
                              Mark As Paid
                            </div>
                          </button>
                        ) : (
                          bill.paymentType &&
                          bill.paymentDate && (
                            <>
                              <div className="text-SeabiscuitDark200ThemeColor px-2 opacity-50 text-sm">
                                |
                              </div>
                              <div className="text-SeabiscuitDark200ThemeColor opacity-50 text-sm">
                                Paid by {bill.paymentType}{' '}
                                {moment(bill.paymentDate).format('DD MMM, YYYY')}
                              </div>
                            </>
                          )
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="h-[1px] bg-[#D3DAEE] my-2 mb-6"></div>

              {/* Registration accordion */}
              {
                <div
                  className={`mt-2 border-[#D3DAEE] border p-4 rounded-lg ${openedMenu.includes('1') ? 'activeAccordion' : ''}`}
                >
                  <div
                    className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${openedMenu.includes('1') ? 'font-semibold' : 'font-normal'}`}
                  >
                    Classes
                    {!recieptLoading ? (
                      <div className="flex flex-col md:flex-row md:items-center">
                        <div
                          className="status text-SeabiscuitGreenThemeColor rounded-full p pl-4"
                          onClick={() => handleToggle('1')}
                        >
                          {openedMenu.includes('1') ? (
                            <div className={'add_icon cursor-pointer'}>
                              <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                            </div>
                          ) : (
                            <div className={'remove_icon cursor-pointer'}>
                              <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className={`faqs-content ${openedMenu.includes('1') ? 'activeTab' : ''}`}>
                    <div className="faqs-content-inside mt-4">
                      <BillDetailsRegistrationData
                        countDetails={countDetails}
                        modalThemeColor={modalThemeColor}
                        currentUser={currentUser}
                        registrationsByDay={registrationsByDay ?? []}
                        refundregistrationTotalState={refundregistrationTotalState}
                        showRefund={showRefund}
                      />
                    </div>
                  </div>
                </div>
              }

              {/* Fees accordion */}
              <div
                className={`mt-2 border-[#D3DAEE] border p-4 rounded-lg ${openedMenu.includes('2') ? 'activeAccordion' : ''}`}
              >
                <div
                  className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${openedMenu.includes('2') ? 'font-semibold' : 'font-normal'}`}
                >
                  Fees
                  {!recieptLoading ? (
                    <div className="flex items-center">
                      <div
                        className="status text-SeabiscuitGreenThemeColor rounded-full p pl-4"
                        onClick={() => handleToggle('2')}
                      >
                        {openedMenu.includes('2') ? (
                          <div className={'add_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                          </div>
                        ) : (
                          <div className={'remove_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={`faqs-content ${openedMenu.includes('2') ? 'activeTab' : ''}`}>
                  <div className="faqs-content-inside mt-4">
                    <BillDetailsFeesData
                      data={
                        feesData.length
                          ? feesData.filter(
                              (data) => data.feesItemCategory !== FEES_CATEGORY_CONST.GOVERNANCE
                            )
                          : []
                      }
                      modalThemeColor={modalThemeColor}
                      showRefund={showRefund}
                      refundFeeTotalState={refundFeeTotalState}
                    />
                  </div>
                </div>
              </div>
              {/* Mandatory accordion */}
              <div
                className={`mt-2 border-[#D3DAEE] border p-4 rounded-lg ${openedMenu.includes('5') ? 'activeAccordion' : ''}`}
              >
                <div
                  className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${openedMenu.includes('5') ? 'font-semibold' : 'font-normal'}`}
                >
                  Mandatory Fees
                  {!recieptLoading ? (
                    <div className="flex items-center">
                      <div
                        className="status text-SeabiscuitGreenThemeColor rounded-full p pl-4"
                        onClick={() => handleToggle('5')}
                      >
                        {openedMenu.includes('5') ? (
                          <div className={'add_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                          </div>
                        ) : (
                          <div className={'remove_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={`faqs-content ${openedMenu.includes('5') ? 'activeTab' : ''}`}>
                  <div className="faqs-content-inside mt-4">
                    <BillDetailsFeesData
                      modalThemeColor={modalThemeColor}
                      data={
                        feesData.length
                          ? feesData.filter(
                              (data) => data.feesItemCategory === FEES_CATEGORY_CONST.GOVERNANCE
                            )
                          : []
                      }
                      showRefund={showRefund}
                      refundFeeTotalState={refundgovernanceTotalState}
                    />
                  </div>
                </div>
              </div>

              {/* Tickets accordion */}
              <div
                className={`mt-2 border-[#D3DAEE] border p-4 rounded-lg ${openedMenu.includes('3') ? 'activeAccordion' : ''}`}
              >
                <div
                  className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${openedMenu.includes('3') ? 'font-semibold' : 'font-normal'}`}
                >
                  Tickets
                  {!recieptLoading ? (
                    <div className="flex items-center">
                      <div
                        className="status text-SeabiscuitGreenThemeColor rounded-full p pl-4"
                        onClick={() => handleToggle('3')}
                      >
                        {openedMenu.includes('3') ? (
                          <div className={'add_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                          </div>
                        ) : (
                          <div className={'remove_icon cursor-pointer'}>
                            <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={`faqs-content ${openedMenu.includes('3') ? 'activeTab' : ''}`}>
                  <div className="faqs-content-inside mt-4">
                    <BillDetailsTicketsData
                      data={ticketData}
                      modalThemeColor={modalThemeColor}
                      showRefund={showRefund}
                      refundgovernanceTotalState={refundticketTotalState}
                    />
                  </div>
                </div>
              </div>

              {/* Grand Total accordion */}
              {bill.amountScratched < 1 && (
                <div
                  className={`mt-2 border-[#D3DAEE] border p-4 rounded-lg ${openedMenu.includes('4') ? 'activeAccordion' : ''}`}
                >
                  <div
                    className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${openedMenu.includes('4') ? 'font-semibold' : 'font-normal'}`}
                  >
                    Grand Total
                    {!recieptLoading ? (
                      <div className="flex items-center">
                        <div
                          className="status text-SeabiscuitGreenThemeColor rounded-full p pl-4"
                          onClick={() => handleToggle('4')}
                        >
                          {openedMenu.includes('4') ? (
                            <div className={'add_icon cursor-pointer'}>
                              <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                            </div>
                          ) : (
                            <div className={'remove_icon cursor-pointer'}>
                              <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className={`faqs-content ${openedMenu.includes('4') ? 'activeTab' : ''}`}>
                    <div className="faqs-content-inside mt-4">
                      <BillDetailsGrandTotalData
                        modalThemeColor={modalThemeColor}
                        paymentOption={paymentOptionRef.current}
                        totalPayments={{
                          tax: taxTotalState || 0,
                          fees: feeTotalState || 0,
                          governance: governanceTotalState ?? 0,
                          tickets: ticketTotalState ?? 0,
                          registrations: registrationTotalState ?? 0,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {userType === CONST.USE_MODE.COMPETITOR ? (
                <>
                  {bill.paymentStatus !== 'paid' && (
                    <SelectPaymentMethod
                      selectedCard={selectedCard}
                      setSelectedCard={setSelectedCard}
                      openAddNewCardModal={openAddNewCardModal}
                      userProfile={userData}
                      organiserProfile={organiserData}
                    />
                  )}

                  {bill.paymentStatus === 'paid' && bill.amountScratched < 1 && (
                    <div className="border border-[#D3DAEE] py-3.5 rounded-lg p-2 flex items-center mb-2">
                      <img
                        src="/assets/cp_icons/Calendar-1.svg"
                        className="w-6 h-6 mr-4"
                        alt="card"
                      />
                      <span className="text-SeabiscuitDark200ThemeColor">
                        Paid: {moment(bill.modified).format('MMM DD, YYYY')}
                      </span>
                    </div>
                  )}
                  {bill.paymentStatus === 'paid' &&
                    bill.amountScratched < 1 &&
                    bill.amountRefunded === 0 && (
                      <div className="border py-3.5 border-[#D3DAEE] rounded-lg p-2 flex items-center">
                        <img
                          src="/assets/cp_icons/MagneticCard-1.svg"
                          className="w-6 h-6 mr-4"
                          alt="card"
                        />
                        <span className="text-SeabiscuitDark200ThemeColor">
                          XXXX-XXXX-XXXX-
                          {bill.cardNo ?? 'XXXX'}
                        </span>
                      </div>
                    )}

                  {bill.amountScratched > 0 && bill.comments.length ? (
                    <>
                      <div className="mt-2 border-[#D3DAEE] border p-4 rounded-lg ">
                        {bill.amountScratched && (
                          <>
                            <ExhibitorProfileRefundAccordion
                              total={bill.amountPaid}
                              refundTotal={bill.amountRefunded}
                            />
                          </>
                        )}
                      </div>
                      <div className="mt-2">
                        <ExhibitorCommentAccordion
                          hide={false}
                          commenttext={bill.comments.find((data) => data.comment)}
                        />
                      </div>

                      <hr />
                      <div id="container" className="mt-2 border-[#d3daee] border p-4 rounded-lg">
                        <h1 className="text-SeabiscuitDark200ThemeColor font-semibold w-3/4 mt-2 mb-3">
                          Refunded to
                        </h1>
                        <div className="border border-[white] bg-SeabiscuitGrayThemeColor rounded-lg p-2 flex items-center">
                          <img
                            src="/assets/cp_icons/MagneticCard-1.svg"
                            className="w-6 h-6 mr-4"
                            alt="card"
                          />
                          <span className="text-SeabiscuitDark200ThemeColor">
                            XXXX-XXXX-XXXX-
                            {bill.cardNo ?? 'XXXX'}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          ) : null}
          {userType === CONST.USE_MODE.COMPETITOR && step === 'viewReciept' && stripeRes ? (
            <p className="text-SeabiscuitDark200ThemeColor py-6">Your bill has been paid.</p>
          ) : null}
        </div>
      ) : null}
    </MainModal>
  )
}

export default BillDetailsModal
