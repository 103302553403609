// Third party
import _, { cloneDeep } from 'lodash'
import Select from 'react-select'
import clsx from 'clsx'

// Type imports
import { ITeamMember } from '../../../../models/users/user.interface'

// Styles
import { customStyles } from '../../../../components/customStyles/ReactSelectCustomStyle'

import React, { FC, useEffect, useState } from 'react'
import { IUserInterface } from '../../../../models/users/user.interface'
import './clinicNOtherReviewAndPayTab.css'

import { getUserFullName } from '../../../../helpers/helpers'
import {
  IRegisterTab,
  IRegisterTabItem,
  IRegistrationTabs,
} from '../../../../models/event-registered-users/event-registered-users.interface'
import { IEventFees } from '../../../../models/event-fees/event-fees.interface'

import { selectUsersHolder } from '../../../../store/holders/holdersSlice'
import { useAppSelector } from '../../../../store/hooks'
import {
  selectRegisterTabData,
  selectUserTeamMembersR,
} from '../../../../store/registration/registrationSlice'
import { selectedEvent } from '../../../../store/events/eventsSlice'

import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
import { DaysTabs } from '../../../../components/events/common/DaysTabs/DaysTabs'

import { daysOfWeek } from '../../../../helpers/time'

import IUseEventRegistrationTabsTypes from '../../event-registration-tabs/hooks/useEventRegistrationTabs.types'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'

import { MODAL_CONSTS } from '../../../../const/modal-const'

type IClinicNOtherRegisterTabProps = {
  title?: string
  description?: string
  usersHolder: IUserInterface[]
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  onRegistrationMemberChange: IUseEventRegistrationTabsTypes['IOnRegistrationMemberChangeFn']
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
  eventId: string
}

interface ItemProps {
  row: IRegisterTab
  teamMembersWithUser: ITeamMember[]
  handleTeamMateChange: ({
    newValue,
    actionMeta,
    currentRow,
    isChildren,
  }: {
    newValue: any
    actionMeta: any
    currentRow: IRegisterTab
    isChildren?: boolean
  }) => void
  isManage?: boolean
  manageInfo: IManageInfo
  isChildren?: boolean
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  eventId: string
  activeDay: string
  EventFees: IEventFees | null
}
const Item: FC<ItemProps> = ({
  row,
  teamMembersWithUser,
  handleTeamMateChange,
  isManage,
  manageInfo,
  isChildren,
  handleModal,
  eventId,
  activeDay,
  EventFees,
}) => {
  let currentRow = row
  if (isManage) {
    let filtered: IRegisterTabItem[] = []

    if (isChildren) {
      manageInfo.register.forEach((register) =>
        register.children?.forEach((children) => {
          if (children.uuid === currentRow.uuid) {
            filtered.push(children)
          }
        })
      )
    } else {
      filtered = manageInfo.register.filter((register) => register.uuid === currentRow.uuid)
    }

    if (filtered && filtered.length > 0) {
      currentRow = { ...row, registrationsByDay: filtered[0].registrationsByDay }
    } else {
      currentRow = { ...row, registrationsByDay: [] }
    }
  }

  if (daysOfWeek[new Date(row.startDate)?.getDay()] === activeDay || activeDay === 'All') {
    return (
      <div
        className={clsx(
          `w-full flex justify-between flex-wrap lg:mb-0 gap-2 text-SeabiscuitDark200ThemeColor`,
          row.children &&
            row.children.length > 0 &&
            'border border-SeabiscuitLightThemeColorD3 rounded-md p-4'
        )}
      >
        <div
          className={clsx(
            'w-full md:w-auto flex-1 gap-2 text-base flex flex-wrap items-center  pr-4',
            row.children && row.children.length > 0
              ? ''
              : 'py-3 pl-4 border rounded-lg border-SeabiscuitLightThemeColorD3'
          )}
        >
          {row.registrationByDayName}{' '}
          {row.order && (
            <span className="text-[14px] text-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor/5 rounded-full py-0.5 px-3">
              {row.order}
            </span>
          )}
          <div className="ml-auto flex flex-wrap items-center justify-end text-SeabiscuitDark200ThemeColor/50">
            <span className="text-SeabiscuitDark200ThemeColor mr-1">{row.priceAlias ?? '$0'}</span>{' '}
            entry fee •{' '}
            <span className="text-SeabiscuitDark200ThemeColor mx-1">
              {row.qualifyFee && EventFees?.qualifyingClasses?.isEnable ? row.qualifyFee : '$0'}
            </span>{' '}
            qual fee •{' '}
            <button
              className="ml-1 underline hover:no-underline"
              onClick={() => handleModal(true, MODAL_CONSTS.CLASS_MORE, { row, eventId })}
            >
              More
            </button>
          </div>
        </div>
        <div
          className={clsx(
            'w-full md:w-[20%] flex items-center border border-SeabiscuitLightThemeColorD3 min-w-[180px] text-SeabiscuitDark200ThemeColor text-sm rounded-lg relative'
          )}
        >
          <Select
            isMulti
            menuPlacement="auto"
            menuPortalTarget={document.body}
            onChange={(newValue, actionMeta) => {
              handleTeamMateChange({
                newValue,
                actionMeta,
                currentRow,
                isChildren,
              })
            }}
            value={currentRow.registrationsByDay?.map((currRegistrationByDay, i) => {
              if (!currRegistrationByDay?.delete)
                return {
                  value: currRegistrationByDay?.riderId
                    ? currRegistrationByDay.riderId + '_' + (i + 1)
                    : UNKNOWN,
                  label: currRegistrationByDay?.riderName
                    ? currRegistrationByDay.riderName
                    : UNKNOWN,
                }
              return null
            })}
            isClearable={false}
            isSearchable={false}
            styles={
              {
                ...customStyles,
                valueContainer: (provided: any) => ({
                  ...provided,
                  fontSize: '12px',
                  padding: '0',
                }),
                menu: (provided: any, state: any) =>
                  ({
                    ...(typeof customStyles['menu'] === 'function' && {
                      ...customStyles['menu'](provided, state),
                    }),
                  }) as any,
                option: (provided: any, state: any) =>
                  ({
                    ...(typeof customStyles['option'] === 'function' && {
                      ...customStyles['option'](provided, state),
                      textAlign: 'center',
                    }),
                  }) as any,
              } as any
            }
            options={
              teamMembersWithUser.map((curr) => ({
                label: curr.memberName,
                value: curr.memberId,
              })) as any
            }
            placeholder="Select riders"
            getOptionLabel={(props: any) => {
              const { label } = props
              return (<span>{label}</span>) as unknown as string
            }}
            className="w-full rounded-lg searchableComponent focus:ring-0 p-0 focus:ring-transparent min-h-[52px] flex items-center"
          />
        </div>
        {row.children?.map((children, childrenIndex) => (
          <Item
            key={childrenIndex}
            row={children}
            teamMembersWithUser={teamMembersWithUser}
            handleTeamMateChange={handleTeamMateChange}
            isManage={isManage}
            manageInfo={manageInfo}
            handleModal={handleModal}
            isChildren
            eventId={eventId}
            activeDay={activeDay}
            EventFees={EventFees}
          />
        ))}
      </div>
    )
  } else {
    return null
  }
}

// Constants
const UNKNOWN = 'Unknown'

const ClinicNOtherRegisterTab = (props: IClinicNOtherRegisterTabProps) => {
  // Hooks and vars
  const usersHolder = useAppSelector(selectUsersHolder)
  const registerTabData = useAppSelector(selectRegisterTabData)
  const teamMembersWithUser = useAppSelector(selectUserTeamMembersR)
  const selectedEventR = useAppSelector(selectedEvent)

  const [days, setDays] = useState<string[]>([])
  const [activeDay, setActiveDay] = useState<string>('All')
  const [currentRegisterTabData, setCurrentRegisterTabData] = useState<IRegisterTab[] | null>(null)

  const handleTeamMateChange = (args: {
    newValue: any
    actionMeta: any
    currentRow: IRegistrationTabs['IRegisterTab']
    isChildren?: boolean
  }) => {
    let memberName: string = ''
    let remove: boolean
    let memberId: string | null = null
    let member: ITeamMember | null
    let teamMember: ITeamMember | null = null
    let foundUser: IUserInterface | null = null
    let foundTeamMember: ITeamMember | null = null
    let indexToRemove: number | undefined = undefined
    const { currentRow } = args
    let newValue = args.newValue as { label: string; value: string }[]

    remove = args.actionMeta?.action === 'remove-value'
    memberId = args?.actionMeta?.option?.value ? args.actionMeta.option.value : ''

    if (!memberId && remove) {
      memberId = args.actionMeta?.removedValue?.value
    }
    // newValue = newValue.filter((currNewValue) => currNewValue.value === memberId)

    if (!newValue.length) member = null
    else
      member = (function () {
        foundTeamMember =
          _.find(teamMembersWithUser, {
            memberId: memberId ? memberId.split('_')[0] : newValue[0].value.split('_')[0],
          }) ?? null
        foundUser =
          usersHolder.find((user) =>
            user.id === memberId ? memberId.split('_')[0] : newValue[0].value.split('_')[0]
          ) ?? null

        if (foundUser) {
          memberName = getUserFullName(foundUser)
        } else {
          memberName = foundTeamMember?.memberName ?? 'Unknown'
        }

        if (foundUser && foundTeamMember) {
          teamMember = {
            memberName,
            memberId: foundUser.id ?? null,
            memberDob: foundUser.userDOB ?? null,
            memberRole: foundTeamMember?.defaultRole,
            memberPhoneNumber: foundUser?.userPhoneNumber,
            defaultRole: foundUser?.userDefaultRole ?? '',
            memberProfilePicture: foundUser?.userProfilePicture ?? null,
            memberEmail: foundUser?.userEmail,
          }
          return teamMember
        } else return null
      })()

    const newManageInfo = { ...props.manageInfo }

    if (!newManageInfo.register[0] && currentRegisterTabData) {
      newManageInfo.register = cloneDeep(currentRegisterTabData)
    }

    if (remove) {
      newManageInfo.register.forEach((regByDay, index) => {
        const removedIndex = +args.actionMeta?.removedValue?.value.split('_')[1] - 1

        if (regByDay.uuid === currentRow.uuid && removedIndex >= 0) {
          indexToRemove = removedIndex
          newManageInfo.register[index].registrationsByDay.splice(removedIndex, 1)
        }
      })

      props.setManageInfo(newManageInfo)
    }

    props.onRegistrationMemberChange({
      currentRow,
      member,
      remove,
      memberId,
      isManage: props.isManage,
      manageInfo: newManageInfo,
      setManageInfo: props.setManageInfo,
      isChildren: args.isChildren,
      removedIndex: indexToRemove,
    })
  }

  useEffect(() => {
    const days_: string[] = []
    currentRegisterTabData?.forEach((fee) => {
      const day = daysOfWeek[new Date(fee.startDate)?.getDay()]
      if (!days_.includes(day)) days_.push(day)
    })

    setDays(
      days_.sort((a, b) => {
        return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b)
      })
    )
  }, [currentRegisterTabData])

  useEffect(() => {
    if (props.isManage) {
      setCurrentRegisterTabData(props.manageInfo.register)
    } else {
      setCurrentRegisterTabData(registerTabData)
    }
  }, [props.isManage, props.manageInfo.register, registerTabData])

  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <DaysTabs activeDay={activeDay} setActiveDay={setActiveDay} days={days} />
      <div className="flex flex-col gap-2">
        {currentRegisterTabData && currentRegisterTabData.length > 0 ? (
          currentRegisterTabData.map((row, index) => {
            return (
              <Item
                key={index}
                row={row}
                teamMembersWithUser={teamMembersWithUser}
                handleTeamMateChange={handleTeamMateChange}
                isManage={props.isManage}
                manageInfo={props.manageInfo}
                handleModal={props.handleModal}
                eventId={props.eventId}
                activeDay={activeDay}
                EventFees={selectedEventR?.EventFees}
              />
            )
          })
        ) : (
          <DataNotAvailable
            mode="text"
            containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
            text="No Classes have been added to this event"
          />
        )}
      </div>
    </CompetitorEventRegisterWrapper>
  )
}

export default ClinicNOtherRegisterTab
