import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { KeyboardBackspace } from '@mui/icons-material'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'

// Component imports
import ManageClinicNOtherDisplayTabs from './ManageClinicNOtherDisplayTabs'
import WrapperContainer from '../../../../components/common/wrappers/WrapperContainer'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import ManageClinicNOtherTabs from './ManageClinicNOtherTabs'

// Types
import { IEventInterface } from '../../../../models/events/event.interface'

import useGetBasicEventDetails from '../../../../hooks/users/common/useGetBasicEventDetails'
import useGetEventData from '../../../../hooks/users/common/useGetEventData'

import { selectEventLoading, selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../store/hooks'

// Constants
import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { ROUTES_CONST } from '../../../../const/routes-const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

export const ICONS_SRC = {
  SPONSOR: '/assets/cp_icons/Medal-3.svg',
  EXHIBITOR: '/assets/cp_icons/UserGroups-3.svg',
  SPECTATOR: '/assets/cp_icons/Two Tickets-3.svg',
  VENDOR: '/assets/cp_icons/Department Shop-3.svg',
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types

type Props = {
  handleClick?: any
  menuToggle?: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn?: any
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const ManageClinicNOtherRootPage = (props: Props) => {
  // Hooks and vars
  const { getAllData } = useGetEventData()
  const { eventId } = useParams<{ eventId: string }>()
  const { Event: event } = useAppSelector(selectedEvent)
  const eventsLoading = useAppSelector(selectEventLoading)
  const eventTitleNodeRef = useRef<HTMLSpanElement>(null)
  const [eventTab, setEventTab] = useState(CONST.UI.EVENTS.MANAGE_EVENT.TABS.ADMIN.VALUE)
  const eventInRedux = useAppSelector(selectedEvent).Event as IEventInterface
  const query = useQuery()
  const finances = query.get(CONST.UI.EVENTS.MANAGE_EVENT.TABS.FINANCES.VALUE)

  useEffect(() => {
    if (finances && finances === '1') {
      setEventTab(CONST.UI.EVENTS.MANAGE_EVENT.TABS.FINANCES.VALUE)
    }
  }, [finances])
  const maxTagsToShow = 4
  const eventTags =
    event?.tags && event?.tags?.length > maxTagsToShow
      ? [...event?.tags]?.splice(0, 3)
      : event?.tags
  let elemWidth = `md:max-w-[calc(100vw-${
    (eventTitleNodeRef?.current?.clientWidth ?? 0) + 10 + 200
  }px)] max-w-[calc(100vw-40px)]  md:max-w-[calc(100vw-120px)]`

  useGetBasicEventDetails(eventId)
  useEffect(() => {
    let value = false
    if (eventId && !value) {
      value = true
      getAllData(eventId, [
        'v01_event_details',
        'v01_event_registered_users',
        'v01_spectator_tickets',
        'v01_events',
        'v01_event_ticketing',
        'v01_event_fees',
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  // Functions

  // Returns header
  const getCustomHeader = (): ReactJSXElement => {
    if (eventsLoading) return <></>

    return (
      <div className="text-SeabiscuitDark200ThemeColor event_comp flex flex-col items-center md:items-start px-[20px] pt-[30px] pb-[18px] 2xl:pb-[30px]">
        {/*<h1 className="text-SeabiscuitDark200ThemeColor text-[20px] font-[400] mb-5">*/}
        {/*  <Link to={ROUTES_CONST.ORGANIZER_HOME.URL}>*/}
        {/*    <KeyboardBackspace className="textSeabiscuitDark200ThemeColor" />*/}
        {/*  </Link>{' '}*/}
        {/*  Back2*/}
        {/*</h1>*/}
        {event ? (
          <div className="flex md:flex-nowrap flex-wrap items-center mb-4">
            <span
              ref={eventTitleNodeRef}
              className="mr-4 xl:text-[22px] 2xl:text-2xl min-w-fit pt-0 md:pt-2 lg:pt-2 xl:pt-1"
            >
              <h1
                title={event?.eventName}
                className="text-SeabiscuitDark200ThemeColor text-[22px] 2xl:text-[28px] font-bold ellipsis capitalize"
              >
                {event?.eventName}
              </h1>
            </span>

            {event?.category ? (
              <div className={clsx('overflow-x-auto flex items-start pt-1 pb-2', elemWidth)}>
                <div className="border border-SeabiscuitDark200ThemeColor capitalize rounded-full py-0.5 px-4 mr-4 inline-block mt-1 text-nr md:text-base">
                  {event?.category}
                </div>
                {eventTags && eventTags.length
                  ? eventTags.map((currentItem: string, index: number) => {
                      return (
                        <div
                          title={currentItem}
                          key={`${currentItem}${index}`}
                          onClick={() => {
                            if (
                              eventInRedux.category !==
                              COLLECTIONS.EVENTS.FIELDS.CATEGORIES.VALUES.UNLICENSED
                            ) {
                              props.handleModal(true, MODAL_CONSTS.UPDATE_DISCIPLINE, {
                                eventInRedux,
                                eventId,
                              })
                            }
                          }}
                          className="border border-SeabiscuitDark200ThemeColor capitalize rounded-full py-0.5 px-4 mr-4 inline-block whitespace-nowrap max-w-[150px] overflow-hidden text-ellipsis mt-1 min-w-fit md:min-w-[unset] text-nr md:text-base cursor-pointer"
                        >
                          {currentItem}
                        </div>
                      )
                    })
                  : null}
              </div>
            ) : null}

            {eventTags && event && eventTags?.length < (event?.tags?.length ?? 0) ? (
              <div className="hidden md:block h-fit text-[30px] leading-[1]">...</div>
            ) : null}
          </div>
        ) : (
          <ViewsLoader size="xl" color="red" />
        )}
        <ManageClinicNOtherTabs eventId={eventId} setEventTab={setEventTab} eventTab={eventTab} />
      </div>
    )
  }

  return (
    <WrapperContainer
      noBg={true}
      removeShadow={true}
      customHeader={getCustomHeader()}
      removePadding={true}
    >
      <>
        {eventsLoading ? (
          <div className="flex justify-center items-center h-[80vh]">
            <ViewsLoader
              className="flex items-center w-full justify-center h-screen"
              size="xl"
              color="red"
            />
          </div>
        ) : (
          <div className="flex md:flex-row flex-wrap flex-col 23">
            <ManageClinicNOtherDisplayTabs eventTab={eventTab} eventId={eventId} />
          </div>
        )}
      </>
    </WrapperContainer>
  )
}

export default ManageClinicNOtherRootPage
