import clsx from 'clsx'
import React, { createContext, useState } from 'react'
import { useParams } from 'react-router'
import helpers from '../../../../commonHelpers/helpers'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { CustomError } from '../../../../helpers/helpers'
import { EventDetailsModel } from '../../../../models/event-details/event-details.model'
import { EventFeesModel } from '../../../../models/event-fees/event-fees.model'
import { EventNearbyModel } from '../../../../models/event-nearby/event-nearby.model'
import { EventPaperworkModel } from '../../../../models/event-paperwork/event-paperwork.model'
import { EventPoliciesModel } from '../../../../models/event-policies/event-policies.model'
import { EventReviewPublishModel } from '../../../../models/event-review-publish/event-review-publish.model'
import { EventSchedulingModel } from '../../../../models/event-scheduling/event-scheduling.model'
import { EventSponsorsModel } from '../../../../models/event-sponsors/event-sponsors.model'
import { EventStaffModel } from '../../../../models/event-staff/event-staff.model'
import { EventTicketingModel } from '../../../../models/event-ticketing/event-ticketing.model'
import { EventVendorsModel } from '../../../../models/event-vendors/event-vendors.model'
import FirestoreService from '../../../../services/firestoreService'
import HeadingElement from './heading/HeadingElement'
import moment from 'moment'
import EventPaymentSettingsModel from '../../../../models/event-payment-settings/event-payment-settings.model'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  ISelectedEvent,
  ispublished,
  selectedEvent,
  setSelectedEvent,
  setSelectedEventKey,
} from '../../../../store/events/eventsSlice'
import { getConvertedData } from '../../../../models/interface.helper'
import { useIonRouter } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { EventRequiredFieldsModel } from '../../../../models/event-required-fields/event-required-fields.model'
import { EventQuestionsModel } from '../../../../models/event-questions/event-questions.model'

type IProps = {
  title: string
  description?: string
  children?: React.ReactElement
}

type ITabNames =
  | 'EventDetails'
  | 'EventStaff'
  | 'EventFees'
  | 'EventSchedule'
  | 'EventPolicies'
  | 'EventPaperwork'
  | 'EventTickets'
  | 'EventSponsors'
  | 'EventVendors'
  | 'EventNearby'
  | 'EventPaymentSettings'
  | 'EventRegistrations'
  | 'EventRequiredFields'
  | 'EventQuestions'

type IValidFormHandlerReturnValue = {
  onSetEventTab: (tab: string) => void
  updated: boolean
}

export type IPublishEvent = (args: {
  dataToSave?: any
  tabName: ITabNames
  publish?: false
  validFormHandler: (dataToSave: any) => Promise<IValidFormHandlerReturnValue>
}) => Promise<void>

type IMangeEventContext = {
  publishEvent: IPublishEvent
  loading: boolean
  setLoading: (value: boolean) => void
  saveAndExitLoading: boolean
  setSaveAndExitLoading: (value: boolean) => void
} | null

export const ManageEventContext = createContext<IMangeEventContext>(null)

const EventFormHeaderComponent = (props: IProps) => {
  // Hooks and vars
  const router = useIonRouter()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [saveAndExitLoading, setSaveAndExitLoading] = useState(false)
  const selectEvent = useAppSelector(selectedEvent)
  const isPublished = useAppSelector(ispublished)
  const eventId = useParams<{ eventId: string }>()?.eventId
  const dispatch = useAppDispatch()

  // Functions
  /**
   * @info Scrolls the page to next on next button click
   */

  const toastFunctions = useToasterHelper()

  const publishEvent: IPublishEvent = async (args) => {
    const { validFormHandler, tabName } = args
    let errorOccured = false
    let dataToSave = args.dataToSave ?? null

    let collectionName: keyof ISelectedEvent = 'Event'
    let nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.VALUE
    let activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.VALUE
    let validFormHandlerResult: IValidFormHandlerReturnValue = null as any
    let isEventPublished = args.publish === false
    try {
      if (isEventPublished) {
        setSaveAndExitLoading(true)
      } else {
        setLoading(true)
      }

      const publishedEventSnapShot = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        eventId
      )

      if (!publishedEventSnapShot.exists()) return

      const publishedEvent =
        EventReviewPublishModel.fromFirestoreDoc(publishedEventSnapShot).toObject()

      let eventDate = publishedEvent.EventDetails.competitionStartDate

      switch (tabName) {
        case 'EventDetails':
          dataToSave = new EventDetailsModel(dataToSave).toFirestore()
          eventDate = dataToSave.competitionStartDate
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS.VALUE
          collectionName = 'EventDetails'
          break

        case 'EventPaymentSettings':
          dataToSave = new EventPaymentSettingsModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.PAYMENTS.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
          collectionName = 'EventPaymentSettings'
          break

        case 'EventRegistrations':
          dataToSave = new EventFeesModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.VALUE
          collectionName = 'EventFees'
          break

        case 'EventFees':
          dataToSave = new EventFeesModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING.VALUE
          collectionName = 'EventFees'
          break

        case 'EventTickets':
          dataToSave = new EventTicketingModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.TICKETING.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF.VALUE
          collectionName = 'EventTickets'
          break

        case 'EventStaff':
          dataToSave = new EventStaffModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.STAFF.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.VALUE
          collectionName = 'EventStaff'
          break

        case 'EventSchedule':
          dataToSave = new EventSchedulingModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES.VALUE
          collectionName = 'EventSchedule'
          break

        case 'EventPolicies':
          dataToSave = new EventPoliciesModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.POLICIES.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK.VALUE
          collectionName = 'EventPolicies'
          break

        case 'EventPaperwork':
          dataToSave = new EventPaperworkModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.PAPERWORK.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.REQUERED_FIELDS.VALUE
          collectionName = 'EventPaperwork'
          break

        case 'EventRequiredFields':
          dataToSave = new EventRequiredFieldsModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.REQUERED_FIELDS.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.QUESTIONS.VALUE
          collectionName = 'EventRequiredFields'
          break

        case 'EventQuestions':
          dataToSave = new EventQuestionsModel(dataToSave).toFirestore()

          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.QUESTIONS.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.REVIEW_PUBLISH.VALUE
          collectionName = 'EventQuestions'
          break

        case 'EventSponsors':
          dataToSave = new EventSponsorsModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.SPONSORS.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.VALUE
          collectionName = 'EventSponsers'
          break

        case 'EventVendors':
          dataToSave = new EventVendorsModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.VENDORS.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.VALUE
          collectionName = 'EventVendors'
          break

        case 'EventNearby':
          dataToSave = new EventNearbyModel(dataToSave).toFirestore()
          activeTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.NEARBY_BUSINESSES.VALUE
          nextTab = CONST.UI.EVENTS.CREATE_EDIT.TABS.FEES.VALUE
          collectionName = 'EventNearbyBusines'
          break
      }

      if (validFormHandler) {
        validFormHandlerResult = (await validFormHandler?.(dataToSave)) ?? {}
        if (!validFormHandlerResult?.updated) {
          throw new Error(MESSAGES_CONST.SOMETHING_WENT_WRONG)
        }
      }

      if (isPublished) toastFunctions.info({ message: `Event Updated successfully` })

      if (tabName === 'EventPaymentSettings') {
        // update "Entries" tab. Price for classes
        const dataToSaveFees = new EventFeesModel({
          ...publishedEvent.EventFees,
          paymentOption: dataToSave.paymentOption,
        }).toFirestore()

        dispatch(setSelectedEvent({ ...selectEvent, EventPaymentSettings: dataToSave }))
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_FEES.NAME,
          eventId,
          {
            paymentOption: dataToSave.paymentOption,
          }
        )

        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_TICKETING.NAME,
          eventId,
          {
            paymentOption: dataToSave.paymentOption,
          }
        )

        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
          eventId,
          {
            [collectionName]: dataToSave,
            EventFees: dataToSaveFees,
            paymentOption: dataToSave.paymentOption,
          }
        )
      } else {
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
          eventId,
          { ...publishedEvent, [collectionName]: dataToSave }
        )
      }

      await FirestoreService.updateItem(CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.NAME, eventId, {
        eventFormState: activeTab,
      })

      if (moment(eventDate).isSameOrBefore(moment(new Date()))) {
        setLoading(false)
        setSaveAndExitLoading(false)
        return
      }

      dispatch(
        setSelectedEventKey({
          key: collectionName,
          value: getConvertedData(dataToSave),
        })
      )
    } catch (error: any) {
      errorOccured = true
      helpers.logger({
        message: CustomError.somethingWentWrong({
          message: error,
          devMessage: error?.message,
          moduleName: 'publishEvent',
          fileName: 'EventFormHeaderComponent',
        }),
      })

      toastFunctions.error({
        message: error,
      })

      setSaveAndExitLoading(false)
    } finally {
      setLoading(false)
      setSaveAndExitLoading(false)
      if (!errorOccured) {
        if (!isEventPublished && validFormHandlerResult?.updated) {
          validFormHandlerResult?.onSetEventTab?.(nextTab)
        } else if (isEventPublished) {
          router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
          history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
        }
      }
      return
    }
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <HeadingElement
            classname={clsx(
              'tw-title text-SeabiscuitMainThemeColor font-bold text-xl flex items-center',
              !props.description && 'min-h-[44px]',
              props.children && 'mb-0.5'
            )}
            title={props.title}
          />

          {props.description && (
            <p className="text-SeabiscuitDark200ThemeColor text-[14px]">{props.description}</p>
          )}
        </div>
        <ManageEventContext.Provider
          value={{
            publishEvent,
            loading,
            saveAndExitLoading,
            setLoading,
            setSaveAndExitLoading,
          }}
        >
          {props.children}
        </ManageEventContext.Provider>
      </div>
      <hr className="my-4" />
    </>
  )
}

export default EventFormHeaderComponent
