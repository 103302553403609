import React, { useContext } from 'react'

import { MembershipIcon } from '../../../../../../membership/MembershipIcon/MembershipIcon'

import { HandleModalContext } from '../../../../../../../layout/mainlayout/MainLayout'

import { useAppSelector } from '../../../../../../../store/hooks'
import { selectProfileData } from '../../../../../../../store/user/userSlice'

import { MODAL_CONSTS } from '../../../../../../../const/modal-const'

export const MembershipsItems = () => {
  const handleModalContext = useContext(HandleModalContext)

  const userData = useAppSelector(selectProfileData)

  if (userData.userStripeAccountStatus !== 'connected') {
    return (
      <div className="flex flex-col items-center justify-center gap-10 py-10 md:py-20">
        <p className="text-center">You need to connect your stripe account to add memberships</p>
      </div>
    )
  }

  return userData.userMemberships && userData.userMemberships.length > 0 ? (
    <div className="flex flex-wrap gap-x-[2%] gap-y-6 py-6">
      {userData.userMemberships.map((membership, index) => (
        <div
          key={index}
          className="w-full sm:w-[49%] xl:w-[23.5%] p-5 border border-SeabiscuitLightThemeColorD3 rounded-xl"
        >
          <div className="mb-5 flex justify-between items-center gap-2">
            <div className="text-SeabiscuitDark200ThemeColor/50">
              <span className="text-lg text-SeabiscuitDark200ThemeColor">
                ${membership.price ?? 0}
              </span>{' '}
              {/*•{' '}*/}
              {/*<Tooltip*/}
              {/*  title={*/}
              {/*    <p className="text-[10px] max-w-[200px]">*/}
              {/*      Total charged to member (+5%). Includes credit card processing and Pegasus fee.*/}
              {/*    </p>*/}
              {/*  }*/}
              {/*  placement="top"*/}
              {/*  arrow*/}
              {/*>*/}
              {/*  <span className="text-[14px]">*/}
              {/*    ${((membership.default_price?.unit_amount ?? 0) / 100) * 1.05}*/}
              {/*  </span>*/}
              {/*</Tooltip>*/}
              <p className="text-[12px]">{membership?.type ?? 'N/A'}</p>
            </div>
            <div className="bg-SeabiscuitGrayThemeColor rounded-full w-10 p-2">
              <MembershipIcon membershipType={membership?.type} />
            </div>
          </div>
          <h6 className="font-bold text-[18px]">{membership.name}</h6>
          <p className="text-[12px] text-SeabiscuitDark200ThemeColor/50">
            {membership?.pricingDescription ?? 'N/A'}
          </p>
          <hr className="my-5 border-SeabiscuitLightGrayThemeColor" />
          <p className="text-[14px]">{membership.description}</p>
        </div>
      ))}
      <div
        className="min-h-[310px] w-full sm:w-[49%] xl:w-[23.5%] p-5 border border-SeabiscuitLightThemeColorD3 rounded-xl flex items-center justify-center flex-col gap-2 cursor-pointer group"
        onClick={() => handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_MEMBERSHIP)}
      >
        <div className="border border-SeabiscuitLightThemeColorD3 rounded-full w-10 p-2.5 group-hover:scale-110 transition-all">
          <img src="/assets/og_icons/plus-math-1.svg" className="w-full" alt="" />
        </div>
        <p>Add membership</p>
      </div>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center gap-10 py-10 md:py-20">
      <p className="text-center">You have not added any memberships</p>
      <img
        className="w-10/12 max-w-[800px]"
        src="/assets/img/profile/memberships-empty.svg"
        alt=""
      />
      <button
        type="button"
        className="flex flex-wrap justify-center items-center gap-2 text-SeabiscuitMainThemeColor hover:opacity-70 transition-all"
        onClick={() => handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_MEMBERSHIP)}
      >
        <img className="w-5" src="/assets/og_icons/plus-icon-2.svg" alt="" />
        Add your first membership now
      </button>
    </div>
  )
}
