/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { AddCircleOutline, AutorenewRounded, ChevronRight } from '@mui/icons-material'
import { useEffect, useState } from 'react'

// Constants
import { ITEAMMEMBER_MODAL_TYPES } from '../../components/home/modals/UserProfileTeamTabAddTeamMember'
import { MODAL_CONSTS } from '../../const/modal-const'

// Redux
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectUserId } from '../../store/user/userSlice'

// Types
import { UseIonRouterResult, useIonRouter } from '@ionic/react'
import { cloneDeep } from 'lodash'
import { useParams } from 'react-router'
import helpers from '../../commonHelpers/helpers'
import WrapperWithHeader from '../../components/common/wrappers/WrapperWithHeader'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { IHorseTeamInterface } from '../../models/horse-team/horseTeam.interface'
import { HorseTeamModel } from '../../models/horse-team/horseTeam.model'
import { IHorseData } from '../../models/horse/horse.interface'
import { getConvertedData } from '../../models/interface.helper'
import FirestoreService from '../../services/firestoreService'
import {
  horseSliceThunks,
  selectHorseReducer,
  setSelectedHorseTeam,
  setSelectedHorseTeamInDb,
  setTeamMemberInMappings,
  updateHorse,
  updateHorseTeamMember,
} from '../../store/horses/horseSlice'
import { ITeamMemberRoles } from '../../types/competitor_types'

import ViewsLoader from '../../components/loader/ViewsLoader'
import { getUserFullName } from '../../helpers/helpers'
import { IUserInterfaceExtended } from '../../store/storeHelpers/userSlice/types'
import { useHistory } from 'react-router-dom'

// Types

type Props = {
  addHorse: boolean
  setActiveTab?: any
  horseId: string | null
  selectedHorse: IHorseData | null
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

type IRemoveTeamMemberArgs = {
  index?: number
  update?: boolean
  type: ITeamMemberRoles
  id: IHorseTeamInterface['memberId']
  selectedMember: IHorseTeamInterface
  data?: { defaultRole: ITeamMemberRoles }
}

type IUserProfileProps = {
  index?: number
  hideCross: boolean
  user: IHorseTeamInterface
  router: UseIonRouterResult
  history: any
  removeTeamMember: ({ type, id }: IRemoveTeamMemberArgs) => void
  handleModal?: any
}

type IProfile = { membersArr: IHorseTeamInterface[]; type: ITeamMemberRoles }

// Constants

const OWNER = 'Owner'
const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES
const HORSE_TEAM_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_TEAM

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
export const ownerFilter = (
  teamMembers: IHorseTeamInterface[],
  userId: string | null,
  profileDetails: IUserInterfaceExtended
) => {
  let horseOwner: IHorseTeamInterface | null = null

  ;[horseOwner] = teamMembers.filter((item) => {
    return item.memberRole === OWNER
  })

  if (!horseOwner) {
    horseOwner = new HorseTeamModel()
    horseOwner.memberId = userId
    horseOwner.memberRole = 'Owner'
    horseOwner.memberName = getUserFullName(profileDetails)
    horseOwner.memberProfilePicture = profileDetails.userProfilePicture
  }

  return horseOwner ? [horseOwner] : []
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
export const riderFilter = (teamMembers: IHorseTeamInterface[]) => {
  const data = teamMembers.filter((item) => {
    return item.memberRole === TEAM_MEMBER_ROLES.RIDER
  })
  return data
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
export const TrainerFilter = (teamMembers: IHorseTeamInterface[]) => {
  const data = teamMembers.filter((item) => {
    return item.memberRole === TEAM_MEMBER_ROLES.TRAINER
  })
  return data
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
export const CoachFilter = (teamMembers: IHorseTeamInterface[]) => {
  const data = teamMembers.filter((item) => {
    return item.memberRole === TEAM_MEMBER_ROLES.COACH
  })
  return data
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
export const teamMemberFilter = (teamMembers: IHorseTeamInterface[]) => {
  const data = teamMembers.filter((item) => {
    return item.memberRole === TEAM_MEMBER_ROLES.TEAM_MEMBER
  })
  return data
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
export const groomMemberFilter = (teamMembers: IHorseTeamInterface[]) => {
  const data = teamMembers.filter((item) => {
    return item.memberRole === TEAM_MEMBER_ROLES.GROOM
  })
  return data
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const HorseRootEditTeamMemberTab = (props: Props) => {
  // Hooks and vars

  const params = useParams()
  const router = useIonRouter()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const profileDetails = useAppSelector((state) => state.user.profileDetails)

  const horseReducer = useAppSelector(selectHorseReducer)

  const selectedHorse = horseReducer.selected.horse.data
  const selectedHorseTeam = horseReducer.selected.team.data
  const selectedHorseMapping = horseReducer.selected.mapping.data
  const horseTeamMembersInDb = horseReducer.selected.team.dataInDb

  const [loading, setLoading] = useState(false)
  const [categorizedTeamMembers, setCategorizedTeamMembers] = useState<IProfile[]>([])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!!!horseReducer.selected.mapping.data?.id || horseReducer.selected.team.dataInDb.length)
      return

    dispatch(
      horseSliceThunks.getHorseTeamMembersThunk({
        userHorseMappingDocId: horseReducer.selected.mapping.data?.id,
      })
    )
  }, [horseReducer.selected.mapping.data?.id])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const openAddTeamMemberModal = () => {
    if (!validateAction()) return

    props.handleModal(true, MODAL_CONSTS.TEAM, {
      modal_type: ITEAMMEMBER_MODAL_TYPES.HORSE,
      userHorseMappingId: (params as any).userHorseMappingId,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const removeTeamMember = (args: IRemoveTeamMemberArgs) => {
    let memberIndex = -1
    let updatedHorse = cloneDeep(selectedHorse)
    let updatedTeamMembers = cloneDeep([...selectedHorseTeam])
    let updatedTeamMember: IHorseTeamInterface | null = null

    memberIndex = updatedTeamMembers.findIndex((currMember) => currMember.memberId === args.id)

    if (memberIndex === -1 || !updatedTeamMembers[memberIndex])
      return toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

    if (!args?.update) {
      updatedTeamMembers.splice(memberIndex, 1)
    } else {
      updatedTeamMember = {
        ...updatedTeamMembers[memberIndex],
        mutated: true,
        memberRole: args.data?.defaultRole ?? null,
      }
      updatedTeamMembers[memberIndex] = updatedTeamMember

      if (updatedTeamMember) dispatch(updateHorseTeamMember(updatedTeamMember))
    }

    if (updatedHorse) {
      updatedHorse.horseTeamMembers_ = updatedTeamMembers
      dispatch(updateHorse({ data: updatedHorse }))
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    setCategorizedTeamMembers([
      {
        membersArr: ownerFilter(selectedHorseTeam, userId, profileDetails),
        type: TEAM_MEMBER_ROLES.OWNER,
      },
      { membersArr: teamMemberFilter(selectedHorseTeam), type: TEAM_MEMBER_ROLES.TEAM_MEMBER },
      { membersArr: CoachFilter(selectedHorseTeam), type: TEAM_MEMBER_ROLES.COACH },
      { membersArr: riderFilter(selectedHorseTeam), type: TEAM_MEMBER_ROLES.RIDER },
      { membersArr: TrainerFilter(selectedHorseTeam), type: TEAM_MEMBER_ROLES.TRAINER },
      { membersArr: groomMemberFilter(selectedHorseTeam), type: TEAM_MEMBER_ROLES.GROOM },
    ])
  }, [selectedHorseTeam, profileDetails])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const validateAction = (): boolean => {
    if (!selectedHorse) {
      toastFunctions.error({
        message: MESSAGES_CONST.PLEASE_CREATE_HORSE_FIRST,
      })
      return false
    }

    if (!!!selectedHorse?.id || !!!selectedHorseMapping?.id) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      return false
    }

    return true
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onSubmit = async () => {
    let existsInDb = false
    let currLoopIndex = 0
    let promisesList: Promise<any>[] = []
    let teamMembersToCreate: IHorseTeamInterface[] = []
    let teamMembersToUpdate: IHorseTeamInterface[] = []
    let teamMembersToDelete: IHorseTeamInterface[] = []
    let updatedTeamMembersInDb: IHorseTeamInterface[] = []
    let currHorseTeamDoc: IHorseTeamInterface | null = null

    if (!validateAction()) return

    setLoading(true)

    try {
      selectedHorseTeam.forEach((currMember) => {
        existsInDb = !!horseTeamMembersInDb.find(
          (currMemberInDb) => currMemberInDb.memberId === currMember.memberId
        )
        if (existsInDb) teamMembersToUpdate.push(currMember)
        else teamMembersToCreate.push(currMember)

        updatedTeamMembersInDb.push(currMember)
      })

      teamMembersToDelete = horseTeamMembersInDb.filter((currMemberInDb) => {
        return !!selectedHorseTeam.every(
          (currMember) => currMemberInDb.memberId !== currMember.memberId
        )
      })

      teamMembersToUpdate = teamMembersToUpdate.filter((currMember) => currMember.mutated)

      // Create horses
      while (currLoopIndex < teamMembersToCreate.length) {
        currHorseTeamDoc = teamMembersToCreate[currLoopIndex] ?? null

        if (!currHorseTeamDoc) {
          currLoopIndex++
          break
        }

        const result = await FirestoreService.createItem(
          HORSE_TEAM_COLLECTION.NAME,
          new HorseTeamModel(currHorseTeamDoc).toFirestore()
        )

        updatedTeamMembersInDb = updatedTeamMembersInDb.map((currMemberInDb) => {
          if (currMemberInDb.memberId === currHorseTeamDoc?.memberId) {
            return {
              ...currMemberInDb,
              id: result.id,
            }
          } else return currMemberInDb
        })

        currLoopIndex++
      }

      currLoopIndex = 0

      // Update horses
      while (currLoopIndex < teamMembersToUpdate.length) {
        currHorseTeamDoc = teamMembersToUpdate[currLoopIndex] ?? null

        if (!currHorseTeamDoc || !currHorseTeamDoc.id) {
          currLoopIndex++
          break
        }

        promisesList.push(
          FirestoreService.updateItem(
            HORSE_TEAM_COLLECTION.NAME,
            currHorseTeamDoc.id,
            new HorseTeamModel(currHorseTeamDoc).toFirestore()
          )
        )
        currLoopIndex++
      }

      currLoopIndex = 0

      // Delete members
      while (currLoopIndex < teamMembersToDelete.length) {
        currHorseTeamDoc = teamMembersToDelete[currLoopIndex] ?? null

        if (!currHorseTeamDoc || !currHorseTeamDoc.id) {
          currLoopIndex++
          break
        }

        promisesList.push(
          FirestoreService.deleteItem(HORSE_TEAM_COLLECTION.NAME, currHorseTeamDoc.id)
        )
        currLoopIndex++
      }

      updatedTeamMembersInDb = updatedTeamMembersInDb.filter((currMember) => {
        return !teamMembersToDelete.find(
          (currMemberToDel) => currMemberToDel.memberId === currMember.memberId
        )
      })

      await Promise.all(promisesList)

      updatedTeamMembersInDb = updatedTeamMembersInDb.map((currentMember) => {
        return getConvertedData(new HorseTeamModel(currentMember).toObject())
      })

      dispatch(setSelectedHorseTeamInDb(updatedTeamMembersInDb))
      dispatch(setSelectedHorseTeam(updatedTeamMembersInDb))
      dispatch(
        setTeamMemberInMappings({
          mappingDocId: selectedHorseMapping?.id!,
          teamMembers: updatedTeamMembersInDb,
        })
      )

      toastFunctions.success({ message: MESSAGES_CONST.TEAM_UPDATED_SUCCESSFULLY })
    } catch (err) {
      helpers.logger({
        isError: true,
        message: err,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <WrapperWithHeader
      title="Team members"
      description="Add, edit or remove horses team members"
      headerButtonsContainer={
        <button
          onClick={onSubmit}
          type="button"
          className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor saveBtn"
        >
          {loading ? (
            <AutorenewRounded fontSize="small" className="animate-spin" />
          ) : (
            'Save Changes'
          )}
        </button>
      }
    >
      {horseReducer.selected.team.status === 'loading' ? (
        <ViewsLoader className="flex items-center w-full justify-center" size="xl" color="red" />
      ) : (
        <>
          {categorizedTeamMembers.map((curr, index) => {
            if (curr.membersArr.length)
              return (
                <div key={`${curr.type}${index}`}>
                  <>
                    {Array.isArray(curr.membersArr)
                      ? curr.membersArr.map((item, index) => {
                          return (
                            <div key={`${curr.type}${index}`}>
                              <UserProfile
                                user={item}
                                index={index}
                                router={router}
                                history={history}
                                removeTeamMember={removeTeamMember}
                                handleModal={props.handleModal}
                                hideCross={item.memberId === userId}
                              />
                            </div>
                          )
                        })
                      : null}
                    <hr className="my-4" />
                  </>
                </div>
              )
            else return null
          })}

          {selectedHorseTeam.length === 0 ? (
            <div className=" mt-4 min-h-[400px] flex justify-center items-center flex-col">
              <p className="text-[#000] cursor-pointer mb-6">You have not add any team members</p>
              <img src="/assets/placeholders/teamMemberPlaceholder.svg" alt="placeholderImage" />
              <p onClick={openAddTeamMemberModal} className="text-[#F7074F] cursor-pointer mt-4">
                Add your first team member <ChevronRight />
              </p>
            </div>
          ) : (
            <div
              onClick={openAddTeamMemberModal}
              className="horseAdd flex items-center cursor-pointer my-6"
            >
              <AddCircleOutline className="text-SeabiscuitMainThemeColor" />
              <p className="ml-2 text-SeabiscuitMainThemeColor">Add Team Member</p>
            </div>
          )}
        </>
      )}
    </WrapperWithHeader>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
export const UserProfile = (props: IUserProfileProps) => {
  // Hooks and vars
  const { user, removeTeamMember, hideCross, index, router, history, handleModal } = props

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const removeTeamMemberFn = (update = false, e?: React.ChangeEvent<HTMLSelectElement>) => {
    if (e?.target?.value) {
      let data = { defaultRole: e?.target.value as ITeamMemberRoles }
      removeTeamMember({
        data,
        index,
        update,
        selectedMember: user,
        type: user?.memberRole as ITeamMemberRoles,
        id: user?.memberId,
      })
    } else
      removeTeamMember({
        index,
        selectedMember: user,
        id: user?.memberId,
        type: user?.memberRole as ITeamMemberRoles,
      })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleProfileRouteTransfer = (value: any, router: any, history: any) => {
    router.push(CONST.ROUTES.ALL_EVENT.URL, 'none', 'push', {
      as: value,
    })
    history.push(CONST.ROUTES.ALL_EVENT.URL, 'none', 'push', {
      as: value,
    })
  }

  const handleOpenRemoveteamModal = (row: IHorseTeamInterface) => {
    handleModal(true, MODAL_CONSTS.REMOVE_TEAM_MEMBER, {
      removingHorse: row.id,
      memberId: row.memberId,
      mappingId: row.userHorseMappingId,
      modal_type: 'forHorses',
    })
  }

  return (
    <div className="flex userList">
      <div className="flex flex-wrap w-full items-center">
        <div className="flex items-center w-1/6 mr-2 my-2">
          <img src="/assets/og_icons/UserGroups-1.svg" className="mr-2" alt="userIcon" />
          <p className="text-SeabiscuitDark200ThemeColor">
            {hideCross
              ? 'You'
              : user.memberRole?.toLowerCase() === 'coach'
                ? user.memberRole + 'es'
                : user.memberRole + 's'}
          </p>
        </div>

        {/* User card */}
        <div className="user flex flex-row items-center rounded-lg py-1 px-2 pl-1 border border-SeabiscuitLightThemeColorD3 mr-2 w-full md:w-[300px]">
          <span className="w-[42px] h-[42px] mr-2">
            <img
              src={
                user?.memberProfilePicture && user?.memberProfilePicture !== ''
                  ? user?.memberProfilePicture
                  : '/assets/og_icons/User-1.svg'
              }
              className="w-full h-full object-cover rounded-md cursor-pointer"
              alt="demoUser"
              onClick={() => handleProfileRouteTransfer('team', router, history)}
            />
          </span>
          <div className="hourseDetails w-10/12">
            <div className="hourseTitle text-SeabiscuitDark200ThemeColor capitalize text-md">
              {user.memberName}
            </div>
          </div>
        </div>

        <div className="user flex flex-row items-center mr-2 my-2 w-full md:w-[300px]">
          <select
            className={`form-select h-[3.3rem] block w-full bg-white border border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor text-md rounded-lg p-3.5 removeDownArrow !outline-0 focus:border-[#d3daee]   !shadow-none !ring-0`}
            aria-label="Default select example"
            value={user.memberRole ?? ''}
            disabled={user.memberRole === OWNER}
            onChange={(e) => removeTeamMemberFn(true, e)}
          >
            <option value="Team member">Select team member</option>
            <option value="Rider">Rider</option>
            <option value="Coach">Coach</option>
            <option value="Groom">Groom</option>
            {user.memberRole === OWNER ? <option value={OWNER}>Owner</option> : null}
            <option value="Trainer">Trainer</option>
          </select>
        </div>

        {!hideCross ? (
          <img
            src="assets/og_icons/Cancel.svg"
            alt="cancel"
            className="cursor-pointer"
            onClick={() => {
              handleOpenRemoveteamModal(user)
            }}
          />
        ) : null}
      </div>
    </div>
  )
}

export default HorseRootEditTeamMemberTab

// let horse, horseTeamMembersList: ITeamMember[];
// if (update === true && data?.defaultRole) {
//     if (type !== data.defaultRole) {
//         return props?.handleModal(true, MODAL_CONSTS.TEAM_MEMBER_ROLE_CHANGE_WARNING, { memberId: id, horseIndex: index, newRole: data.defaultRole, selectedMember })
//     }
//     // return

//     horseTeamMembersList = teamMembers.map((curr) => {
//         if (curr.memberId === id) return { ...curr, ...data }
//         else return curr
//     })

//     horse = { ...selectedHorse }
//     horse.horseTeamMembers = horseTeamMembersList
//     return dispatch(updateHorse({ data: horse }))
// }

// props?.handleModal(true, MODAL_CONSTS.REMOVE_TEAM_MEMBER, { type, id, modal_type: ITEAMMEMBER_MODAL_TYPES.HORSE, index })

// await FirestoreService.updateItem(
//     CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
//     userId,
//     profileDetails
// );
