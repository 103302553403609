import React, { FC, useState } from 'react'
import { Control, Controller, UseFormWatch } from 'react-hook-form'
import Select from 'react-select'
import clsx from 'clsx'
import { Add, HighlightOff } from '@mui/icons-material'

import { IHorseCompetitorDocument } from '../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IHorseData } from '../../../models/horse/horse.interface'

import { customStyles } from '../../../components/customStyles/ReactSelectCustomStyle'
import { IOnRemoveImageButtonClickProps } from '../HorsePaperwork'

import AddCertificate from '../../../components/modals/AddCertificate'

const HorseHealthStatus = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

interface HealthStatusCurrentProps {
  control: Control<IHorseCompetitorDocument>
  saved: boolean
  selectedHorse: IHorseData | null
  imageSetter: (key: any, imageUrls: string[]) => void
  getImage: (image: string) => { url: string; type: string }
  watch: UseFormWatch<IHorseCompetitorDocument>
  handleManageToastConfirm: (
    index: number,
    type: IOnRemoveImageButtonClickProps['type'],
    rowIndex?: number
  ) => Promise<void>
}
export const Health: FC<HealthStatusCurrentProps> = ({
  control,
  saved,
  selectedHorse,
  imageSetter,
  watch,
  handleManageToastConfirm,
  getImage,
}) => {
  const [loading, setLoading] = useState(false)
  const [addCertificateModalShow, setAddCertificateModalShow] = useState(false)

  const onUploadCertificateClick = () => {
    if (!selectedHorse?.id || saved) return
    setAddCertificateModalShow(true)
  }
  return (
    <>
      {addCertificateModalShow && (
        <AddCertificate
          handleCloseModal={() => setAddCertificateModalShow(false)}
          dataToPassOn={{
            fileUploadType: 'horseHealthRecordCertificatesImages',
            data: watch(),
            setter: imageSetter,
          }}
        />
      )}
      <div className="flex items-center mt-2 w-full gap-4">
        <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
          <img src="/assets/img/dark/Cloud.png" className="mr-4 w-6" alt="measurementCard" />
          Upload health certificate records
        </label>

        <div className="wrapper flex flex-col gap-4 flex-grow py-2">
          <div className="flex flex-wrap rounded-md relative gap-2">
            {(watch('horseHealthRecordCertificatesImages') ?? []).map(
              (item: string, index: number) => {
                return (
                  <div
                    key={`horseHealthRecordCertificatesImages${item}${index}`}
                    className={clsx(
                      'image-preview rounded-md w-24 h-24 z-[0] relative',
                      {
                        border: getImage(item).type === 'image',
                      },
                      loading && 'pointer-events-none opacity-70'
                    )}
                  >
                    <img
                      src={getImage(item).url}
                      className="w-full h-full object-cover mr-2 shrink-0 cursor-pointer rounded-md"
                      alt="horseHealthRecordCertificatesImages"
                      onClick={() => window.open(item, '_blank')}
                    />
                    {!saved ? (
                      <button
                        onClick={async () => {
                          setLoading(true)
                          await handleManageToastConfirm(
                            index,
                            'horseHealthRecordCertificatesImages'
                          )
                          setLoading(false)
                        }}
                        type="button"
                        className="absolute top-[-9px] opacity-100 cursor-pointer hover:opacity-100 transition-all right-[-9px] flex items-center justify-center"
                      >
                        <HighlightOff className="text-SeabiscuitDark200ThemeColor !z-[9999] transition-all hover:opacity-70" />
                      </button>
                    ) : null}
                  </div>
                )
              }
            )}
          </div>

          {!saved ? (
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={onUploadCertificateClick}
            >
              <div className="p-1 border border-[#E6EAF2] rounded-md flex items-center ">
                <Add className="text-SeabiscuitDark200ThemeColor flex items-center" />
              </div>
              <div className="text-SeabiscuitDark200ThemeColor text-sm">Add image</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex items-center mt-2 w-full gap-4">
        <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
          <img src="/assets/img/dark/Certificate.png" className="mr-4 w-6" alt="measurementCard" />
          Health status current?
        </label>
        <div className="wrapper flex flex-wrap w-4/5">
          <Controller
            name="horseHealthStatus"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                isMulti={false}
                onChange={onChange}
                onBlur={onBlur}
                options={HorseHealthStatus}
                value={HorseHealthStatus.find((c) => c.value === ((value as any)?.value ?? value))}
                placeholder="Select..."
                isDisabled={saved}
                className={`border-[#D3DAEE] border rounded-md w-60 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                isClearable={false}
                styles={customStyles}
                isSearchable={false}
                menuPlacement="auto"
                menuPortalTarget={document.body}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}
