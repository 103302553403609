import React, { ComponentType, useEffect, useState } from 'react'
import { useAppSelector } from '../../../store/hooks'
import FirebaseApp from '../../../services/firebaseApp'
import { httpsCallable } from 'firebase/functions'
import { selectProfileData } from '../../../store/user/userSlice'

import type {
  DefaultGenerics,
  ExtendableGenerics,
  OwnUserResponse,
  StreamChatOptions,
  TokenOrProvider,
  UserResponse,
  StreamChat,
} from 'stream-chat'
import { StreamChat as StreamChatT } from 'stream-chat'

export const useCreateChatClient = <SCG extends ExtendableGenerics = DefaultGenerics>({
  apiKey,
  options,
  userData,
}: {
  apiKey: string
  userData: OwnUserResponse<SCG> | UserResponse<SCG>
  options?: StreamChatOptions
}) => {
  const [chatClient, setChatClient] = useState<StreamChat<SCG> | null>(null)
  const [cachedUserData, setCachedUserData] = useState(userData)

  if (userData.id !== cachedUserData.id) {
    setCachedUserData(userData)
  }

  const [cachedOptions] = useState(options)

  useEffect(() => {
    const client = new StreamChatT<SCG>(apiKey, undefined, cachedOptions)
    let didUserConnectInterrupt = false

    const getStreamUserToken = httpsCallable(
      FirebaseApp.functions,
      'ext-auth-chat-getStreamUserToken'
    )

    const connectionPromise = getStreamUserToken()
      .then(async (result) => {
        const streamUserToken = result.data as string
        return streamUserToken
      })
      .then((tokenOrProvider) => {
        client.connectUser(cachedUserData, tokenOrProvider).then(() => {
          if (!didUserConnectInterrupt) setChatClient(client)
        })
      })

    return () => {
      didUserConnectInterrupt = true
      setChatClient(null)
      connectionPromise
        .then(() => client.disconnectUser())
        .then(() => {
          console.log(`Connection for user "${cachedUserData.id}" has been closed`)
        })
    }
  }, [apiKey, cachedUserData, cachedOptions])

  return chatClient
}

interface WithStreamClientProps {
  client: StreamChat<DefaultGenerics>
}

function withStreamClient<P extends WithStreamClientProps>(WrappedComponent: ComponentType<P>) {
  const WithStreamClient: React.FC<Omit<P, keyof WithStreamClientProps>> = (props) => {
    const profileData = useAppSelector(selectProfileData)
    const client = useCreateChatClient({
      apiKey: process.env.REACT_APP_GETSTREAM_KEY!,
      userData: {
        id: profileData.id,
        name: profileData.userName,
        userProfileImageUrl: profileData.userProfilePicture,
      },
    })

    if (!client) {
      return null
    }

    return <WrappedComponent {...(props as P)} client={client} />
  }

  return WithStreamClient
}

export default withStreamClient
