/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

// Third party
import { AutorenewRounded } from '@mui/icons-material'

// Types
import { ITeamMember, IUserInterface } from '../../../../models/users/user.interface'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectTeamMembers, setUserProfileComplete } from '../../../../store/user/userSlice'

// Constants
import { MESSAGES_CONST } from '../../../../const/messages-const'

// Custom hooks
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import helpers from '../../../../commonHelpers/helpers'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { CONST } from '../../../../const/const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../../helpers/helpers'
import useProfileHook from '../../../../hooks/users/competitor/profile/useProfileHook'
import { getConvertedData } from '../../../../models/interface.helper'
import { UserModel } from '../../../../models/users/user.model'
import FirestoreService from '../../../../services/firestoreService'
import { RootState } from '../../../../store/store'
import formFunctions from '../../../ui/form/form-functions/formFunctions'
import FormHeader from '../../../ui/form/form-header/FormHeader'
import ProfileTeamTabCompetitorDisplayTabs from './Tabs/ProfileTeamTabCompetitorDisplayTabs'
import ProfileTeamTabCompetitorTabs from './Tabs/ProfileTeamTabCompetitorTabs'

// Types
type Props = {
  title?: string
  description?: string
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const ProfileTeamTabCompetitor = (props: Props) => {
  // Hooks and vars
  const toastFunctions = useToasterHelper()
  const teamMembersList = useAppSelector(selectTeamMembers) as ITeamMember[]
  const handleModalContext = useContext(HandleModalContext)
  const dispatch = useAppDispatch()

  const { profileDetails } = useAppSelector((state: RootState) => state.user)
  const { getModelledUserDetails, updateUserDetails, loading } = useProfileHook({ dontFetch: true })

  const [eventTab, setEventTab] = useState(CONST.UI.USER.TEAM.TABS.AUTHORIZE.VALUE)

  const teamMembersFetched = useRef(false)
  const usersHolderRef = useRef<IUserInterface[]>([])
  const [teamMembers, setTeamMembers] = useState<ITeamMember[]>([])
  const [teamMembersLoading, setTeamMembersLoading] = useState(false)

  const formStyles = formFunctions.getFormStyles({ noPaddingRt: true })

  useEffect(() => {
    let owner: ITeamMember | null = null
    let teamMembersList_: ITeamMember[] = []

    const getTeamMemberDetails = async () => {
      let userIdsList: string[] = []
      let memberName: string | null = null
      let usersList: IUserInterface[] = []
      let currUser: IUserInterface | null = null
      let teamMembersListLocal: ITeamMember[]
      let usersSnaps: QueryDocumentSnapshot<DocumentData>[]

      const ACTIVE = '1'
      const UNKNOWN = COLLECTIONS.USERS.FIELDS.FIRST_NAME.VALUES.UNKNOWN
      const DEFAULT_ROLE = COLLECTIONS.USERS.FIELDS.USER_DEFAULT_ROLE.VALUES.OWNER

      owner = (() => {
        memberName = profileDetails ? getUserFullName(profileDetails) : UNKNOWN

        return {
          memberStatus: ACTIVE,
          memberName: memberName,
          memberShipActive: false,
          defaultRole: DEFAULT_ROLE,
          memberprofileSynced: false,
          memberSafeSupportTraining: false,
          memberId: profileDetails.id ?? '',
          memberEmail: profileDetails?.userEmail ?? '',
          memberAddress: profileDetails?.userAddress ?? '',
          memberCountry: profileDetails?.userNationality ?? '',
          memberPhoneNumber: profileDetails?.userPhoneNumber ?? '',
          memberProfilePicture: profileDetails?.userProfilePicture ?? '',
          memberDob: profileDetails?.userDOB ? getConvertedData(profileDetails.userDOB) : '',
        }
      })()

      teamMembersList_ = [...teamMembersList]

      teamMembersListLocal = [owner, ...teamMembersList]
      userIdsList = []

      teamMembersListLocal.forEach((currMember) => {
        if (currMember.memberId) {
          userIdsList.push(currMember.memberId)
        }
      })

      userIdsList = [...new Set(userIdsList)]
      userIdsList = userIdsList.reduce((acc: string[], curr) => {
        if (!usersHolderRef.current.find((c) => c.id === curr)) acc.push(curr)
        return acc
      }, [])

      usersSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIdsList)
      usersSnaps.forEach((curr) => {
        currUser = getConvertedData(UserModel.fromFirestoreDoc(curr).toObject())
        usersList.push(currUser)
        usersHolderRef.current.push(currUser)
      })

      teamMembersListLocal = teamMembersListLocal.map((currMember) => {
        currUser = usersHolderRef.current.find((c) => c.id === currMember.memberId) ?? null
        if (!currUser)
          currUser = usersList.find((currUser_) => currUser_?.id === currMember.memberId) ?? null
        if (!currUser) return currMember
        else
          return {
            ...currMember,
            memberName: getUserFullName(currUser),
            memberEmail: currUser?.userEmail,
            memberProfilePicture: currUser?.userProfilePicture,
          }
      })

      teamMembersList_ = teamMembersListLocal
    }

    if (!teamMembersFetched.current) setTeamMembersLoading(true)

    getTeamMemberDetails()
      .then(() => {
        teamMembersFetched.current = true
        if (teamMembersFetched.current) setTeamMembersLoading(false)
        setTeamMembers(teamMembersList_)
      })
      .catch((error) => {
        helpers.logger({
          isError: true,
          message: error,
        })
      })
  }, [teamMembersList])

  // Saves team members data in DB
  const saveTeamMemberToDB = async () => {
    const structuredData: IUserInterface = getModelledUserDetails(profileDetails)

    if (structuredData.userEmail) {
      structuredData.userTeamMembers = teamMembersList.filter((member) => {
        return !(member.memberRole?.toLocaleLowerCase() === 'owner' || !member.memberRole)
      })

      const response = await updateUserDetails(structuredData)

      if (response.status) {
        toastFunctions.success({ message: MESSAGES_CONST.TEAM_MEMBERS_LIST_UPDATED })
      } else {
        toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      }
    }
  }

  const handleTeamInvite = () => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, {
      type: 5,
      maxWidth: 430,
    })

    return dispatch(setUserProfileComplete(false))
  }

  return (
    <>
      <FormHeader
        title="Team Settings"
        removeBreakLine={true}
        headerButtonsContainer={
          <div className="flex items-center gap-2">
            <button
              type="submit"
              disabled={loading}
              onClick={() => handleTeamInvite()}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
            >
              {loading ? (
                <AutorenewRounded fontSize="small" className="animate-spin" />
              ) : (
                'Invite friends'
              )}
            </button>
            <button
              type="submit"
              disabled={loading}
              onClick={saveTeamMemberToDB}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
            >
              {loading ? (
                <AutorenewRounded fontSize="small" className="animate-spin" />
              ) : (
                'Save changes'
              )}
            </button>
          </div>
        }
      />

      <div className={formStyles.className} style={formStyles.styles}>
        <ProfileTeamTabCompetitorTabs eventTab={eventTab} setEventTab={setEventTab} />

        <ProfileTeamTabCompetitorDisplayTabs
          eventTab={eventTab}
          teamMembers={teamMembers}
          handleModal={props.handleModal}
          teamMembersLoading={teamMembersLoading}
        />
      </div>
    </>
  )
}

export default ProfileTeamTabCompetitor
