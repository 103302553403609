import { useIonRouter } from '@ionic/react'
import { AutorenewRounded } from '@mui/icons-material'
import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import { CONST } from '../../../../const/const'
import useHorses from '../../../../hooks/users/competitor/profile/useHorses'
import { IHorseData } from '../../../../models/horse/horse.interface'
import customImageComponent from '../../../common/CustomImageComponent'
import ViewsLoader from '../../../loader/ViewsLoader'
import PROFILE_DETAIL_CARD_CONST from './profileDetailCard.const'
import useProfileDetailCard from './useProfileDetailCard'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../../store/hooks'
import { selectUserId } from '../../../../store/user/userSlice'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

import { MODAL_CONSTS } from '../../../../const/modal-const'
import { getUserFullName } from '../../../../helpers/helpers'

type IProps = {
  showHorseData?: IHorseData[]
  setActiveTab?: (value: React.SetStateAction<number>) => void
}
const DEFAULT_HORSE_PIC = '/assets/og_icons/YearofHorse.svg'

const ProfileDetailsCard = ({ showHorseData, setActiveTab }: IProps) => {
  const handleModalContext = useContext(HandleModalContext)

  const userId = useAppSelector(selectUserId)

  const { getMapping } = useHorses(userId)

  const router = useIonRouter()
  const history = useHistory()

  const {
    profileDetails,
    alreadyFollowed,
    loading,
    isMyProfile,
    following,
    messageUser,
    onFollowBtnClick,
  } = useProfileDetailCard()

  const [mappingId, setMappingId] = useState<any>('')
  const [horseId, setHorseId] = useState<any>('')

  const handleGetMapping = async (owner: string) => {
    return await getMapping(owner)
  }

  useEffect(() => {
    if (showHorseData?.length) {
      if (showHorseData?.[0]?.horseOwnerId) {
        handleGetMapping(showHorseData[0].horseOwnerId).then((res) => {
          setMappingId(res?.[0]?.id)
          setHorseId(res?.[0]?.horseId)
          if (setActiveTab) setActiveTab(3)
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHorseData])

  if (loading || alreadyFollowed === null)
    return (
      <div className="min-h-[204px] flex items-center justify-center">
        <ViewsLoader size="lg" color="#F7074F" />
      </div>
    )

  if (showHorseData && showHorseData.length) {
    return (
      <>
        <span className="w-[110px] h-[110px] rounded-full border-[3px] border-white overflow-hidden">
          {customImageComponent(
            showHorseData[0]?.horseProfilePicture
              ? showHorseData[0]?.horseProfilePicture
              : DEFAULT_HORSE_PIC,
            showHorseData[0].horseName,
            'object-cover w-full bg-white h-full'
          )}
        </span>

        <span className="font-bold text-xl my-3 text-SeabiscuitDark200ThemeColor capitalize">
          {showHorseData[0].horseName}
        </span>

        {isMyProfile ? (
          <button
            type="button"
            className=" mt-3 mb-3 items-center  h-[45px] border-solid border !border-[#122B46] rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitDark200ThemeColor w-[80%] min-w-[150px] bg-white hover:bg-[#122B46] focus:bg-[#122B46] active:bg-[#122B46]  hover:text-white focus:text-white active:text-white "
            onClick={() => {
              router.push(`${CONST.ROUTES.HORSES.SUBMENU.SUB_URL}/${mappingId}/${horseId}`)
              history.push(`${CONST.ROUTES.HORSES.SUBMENU.SUB_URL}/${mappingId}/${horseId}`)
            }}
          >
            Edit Profile
          </button>
        ) : null}
      </>
    )
  }

  return (
    <>
      <span className="w-[110px] h-[110px] rounded-full flex justify-center items-center border-[3px] bg-white border-white overflow-hidden">
        {customImageComponent(
          profileDetails?.userProfilePicture
            ? profileDetails.userProfilePicture
            : PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC,
          profileDetails?.userName,
          '!w-[calc(100%-5px)] bg-white !h-[calc(100%-5px)] rounded-full'
        )}
      </span>

      <span className="font-bold text-xl my-3 text-SeabiscuitDark200ThemeColor capitalize">
        {getUserFullName(profileDetails!)}
      </span>

      {isMyProfile ? (
        <button
          type="button"
          className=" mt-3 mb-3 items-center  h-[45px] border-solid border !border-[#122B46] rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitDark200ThemeColor w-[80%] min-w-[150px] bg-white hover:bg-[#122B46] focus:bg-[#122B46] active:bg-[#122B46] hover:text-white focus:text-white active:text-white "
          onClick={() => {
            router.push(CONST.ROUTES.PROFILE_SETTINGS.URL)
            history.push(CONST.ROUTES.PROFILE_SETTINGS.URL)
          }}
        >
          Edit Profile
        </button>
      ) : (
        <div className="flex flex-wrap justify-center gap-1.5 w-full mb-2">
          {profileDetails?.userMemberships && profileDetails?.userMemberships.length > 0 && (
            <button
              type="button"
              disabled={loading}
              onClick={() =>
                handleModalContext?.handleModal(true, MODAL_CONSTS.JOIN, {
                  organiserProfile: profileDetails,
                  memberships: profileDetails?.userMemberships,
                })
              }
              className={clsx(
                'h-[35px] border border-solid rounded-full flex items-center text-[12px] px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor w-[48%] sm:w-[32%] justify-center gap-1 hover:opacity-70',
                loading && 'pointer-events-none'
              )}
            >
              <img
                src="/assets/og_icons/Add User Male-1.svg"
                alt="add user"
                className="h-[20px] w-[20px]"
              />
              Join
            </button>
          )}
          <button
            type="button"
            disabled={following || loading}
            onClick={onFollowBtnClick}
            className={clsx(
              'h-[35px] border border-solid rounded-full flex items-center text-[12px] px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor w-[48%] sm:w-[32%] justify-center gap-1 hover:opacity-70',
              loading && 'pointer-events-none'
            )}
          >
            {following ? (
              <AutorenewRounded fontSize="small" className="animate-spin mx-auto w-[30px]" />
            ) : (
              <>
                <img
                  src="/assets/og_icons/Bookmark-1.svg"
                  alt="add user"
                  className="h-[20px] w-[20px]"
                />
                {alreadyFollowed ? 'Unfollow' : 'Follow'}
              </>
            )}
          </button>

          <button
            type="button"
            disabled={loading}
            onClick={messageUser}
            className="h-[35px] border border-solid rounded-full text-[12px] flex items-center px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor w-[48%] sm:w-[32%] justify-center gap-1 hover:opacity-70"
          >
            <img
              src="/assets/og_icons/Speech Bubble-1.svg"
              alt="message"
              className="h-[20px] w-[20px]"
            />
            <span className="flex flex-col justify-end">
              <span>Message</span>
            </span>
          </button>
        </div>
      )}
    </>
  )
}

export default ProfileDetailsCard
