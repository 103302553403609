import React, { useContext, useEffect, useRef, useState } from 'react'

// Third party
import { yupResolver } from '@hookform/resolvers/yup'
import { AddCircleOutline, Autorenew, AutorenewRounded, CameraAlt } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import Select from 'react-select'

// Models
import { UserModel } from '../../../../models/users/user.model'

// Services
import FirestoreService from '../../../../services/firestoreService'
import FirebaseStorageService from '../../../../services/storageService'
import UserService from '../../../../services/userService'

// Constants
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'

// Styles
import { customStyles } from '../../../customStyles/ReactSelectCustomStyle'
import './styles/ProfileRootPageProfileTab.css'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectCompetitorProfileData,
  selectUserId,
  setCompetitorProfileData,
} from '../../../../store/user/userSlice'

// Schemas
import { competitorProfileDetailSchema } from '../../../../validations'

// Helpers
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { getConvertedData, getReactPickerDate } from '../../../../models/interface.helper'

// Dummy Data
import { Status } from '../../../../fakeData/StatusList'
import { zoneList } from '../../../../fakeData/ZoneList'
import { CountryList } from '../../../../fakeData/countryList'
import { disciplineData } from '../../../../fakeData/disciplineList'
import { JudgingLicence } from '../../../../fakeData/judgingLicence'
// Types

// Redux
import { RootState } from '../../../../store/store'
import { setProfileDetails } from '../../../../store/user/userSlice'

// Custom hooks
import useCompetitorProfileHook from '../../../../hooks/users/competitor/profile/useCompetitorProfileHook'
import useProfileHook from '../../../../hooks/users/competitor/profile/useProfileHook'

// Utils
import MessageHelperComp from '../../../../helpers/MessageHelper'

// Models
import { CompetitorProfileModel } from '../../../../models/competitor-profile/competitor-profile.model'

// Types

// Components
import helpers from '../../../../commonHelpers/helpers'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { amateurList } from '../../../../fakeData/amateurList'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { IUserInterface } from '../../../../models/users/user.interface'
import { setFilesToUpload } from '../../../../store/files/filesSlice'
import CustomDatePicker from '../../../common/inputs/CustomDatePicker'
import PhoneInput from '../../../common/inputs/PhoneInput'
import GlobalInputComponent from '../../../events/forms/detail/Components/GlobalInputComponent'

import { Tooltip } from '@mui/material'
import { cloneDeep } from 'lodash'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import TimeLib from '../../../../lib/Time'
import formFunctions from '../../../ui/form/form-functions/formFunctions'
import FormHeader from '../../../ui/form/form-header/FormHeader'
import { updateProfile } from 'firebase/auth'
import { getUserFullName } from '../../../../helpers/helpers'
import FirebaseApp from '../../../../services/firebaseApp'
import { IconDelete2 } from '../../../icons/IconDelete2'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
const CURRENT_STATUS = [
  { label: 'Current', value: '1' },
  { label: 'Expired', value: '0' },
]

export const PROFILE_IMAGE_TYPES_CONST = {
  FOREIGN_ENDORESEMENT_LETTER: 'foreignEndorsementLetter',
  PROFILE_PIC_COMPETITOR: 'profilePicCompetitor',
}

// Types

type ICompetitorProfileRootPageProfileTabProps = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

type IOnChangeArgs = {
  length: number
  isValid: boolean
  phoneNumber: string
  countryCode: string
}

const addText = <span className={'text-[#122B4680] text-[14px]'}>Add image of card...</span>

interface ImageWrapperProps {
  url: string
  removeHandler: () => void
  editMode?: boolean
}

interface CustomControllerProps {
  control: any
  placeholder: string
  name: string
  saved: boolean
}

const CustomController = ({ name, placeholder, control, saved }: CustomControllerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Select
            onChange={onChange}
            value={Status.find((c) => c.value === value || c.value === value)}
            onBlur={onBlur}
            options={Status}
            isDisabled={saved}
            placeholder={placeholder}
            className={`border-solid border-[#D3DAEE] border rounded-xl mt-3 md:mt-0 w-full selectWithSearch  focus:ring-[#D3DAEE] focus:border-[#D3DAEE] selectWithSearch p-[2px] h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
            styles={
              {
                ...customStyles,
                valueContainer: (provided: any) => ({
                  ...provided,
                  textAlign: 'left',
                  justifyContent: 'start',
                }),
                singleValue: (provided: any, state: any) => ({
                  ...(customStyles?.singleValue && customStyles?.singleValue(provided, state)),
                  fontSize: '14px !important',
                  color: state.isDisabled ? '#122b46' : provided.color,
                  opacity: state.isDisabled ? '1' : provided.opacity,
                }),
                dropdownIndicator: (provided: any, state: any) => ({
                  ...provided,
                  display: state.isDisabled ? 'none' : '',
                }),
              } as any
            }
            isClearable={false}
            isMulti={false}
            isSearchable={false}
          />
        )
      }}
    />
  )
}
const ImageWrapper: React.FC<ImageWrapperProps> = ({ url, removeHandler, editMode }) => {
  const [error, setError] = React.useState(false)

  return (
    <div className={'relative'}>
      {error || !url ? (
        addText
      ) : (
        <>
          <img
            className={'w-auto h-[35px] rounded'}
            onError={() => setError(true)}
            alt={''}
            onClick={() => window.open(url, '_blank')}
            src={url}
          />
          {editMode && (
            <button className={'absolute top-[-5px] right-[-15px]'} onClick={removeHandler}>
              <IconDelete2 />
            </button>
          )}
        </>
      )}
    </div>
  )
}

const CompetitorProfileRootPageProfileTab = (props: ICompetitorProfileRootPageProfileTabProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const fileInputRef = useRef() as any
  const toastFunctions = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const errorRef = useRef({ isValid: true, message: '' })
  const phoneInputRef = useRef<HTMLDivElement | null>(null)
  const handleModalContext = useContext(HandleModalContext)
  const competitiorProfileData_ = useAppSelector(selectCompetitorProfileData)

  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState<IUserInterface | null>(null)
  const [imgLoading, setImgLoading] = useState(false)
  const [saved, isSaved] = useState(true)
  const { updateUserDetails, getModelledUserDetails } = useProfileHook({})
  const { getModelledCompetitorProfileData, updateCompetitorProfile } = useCompetitorProfileHook({})

  const formStyles = formFunctions.getFormStyles({ noPaddingRt: true })
  const userReducer = useAppSelector((state: RootState) => state.user)

  const { profileDetails, competitorProfileData } = userReducer

  const {
    reset,
    watch,
    control,
    register,
    setError,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserInterface>({
    mode: 'onChange',
    resolver: yupResolver(competitorProfileDetailSchema),
  })

  const { append, fields, remove, update } = useFieldArray({
    control,
    name: 'userExtraDocument',
  })

  useEffect(() => {
    if (!userData) {
      const getUserData = async () => {
        let user_exists = false
        let user_doc = null

        while (!user_exists) {
          user_doc = await UserService.getUserInfoById(userId)
          user_exists = user_doc.exists()
        }
        reset({
          ...UserModel.fromFirestoreDoc(user_doc).toObject(),
        })
        setUserData(UserModel.fromFirestoreDoc(user_doc).toObject())
      }
      getUserData().then()
    }
  }, [competitorProfileData, profileDetails, reset, userData, userId])

  // Functions
  const phoneOnChange = (args: IOnChangeArgs) => {
    let { phoneNumber, countryCode } = args
    setValue('userPhoneCode', countryCode)
    setValue('userPhoneNumber', phoneNumber)
  }

  const onSubmit = async (dataForm: any) => {
    const data: any = { ...dataForm }

    if (!errorRef.current.isValid) {
      setError('userPhoneNumber', {
        message: errorRef.current.message,
      })

      phoneInputRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })

      return 0
    }

    setLoading(true)

    try {
      // Updating profile details
      let profileDetails = new UserModel(data).toObject()
      profileDetails = getConvertedData(profileDetails)

      const dataToThrow = getModelledUserDetails({ ...userReducer, ...profileDetails })

      if (FirebaseApp.auth.currentUser) {
        await updateProfile(FirebaseApp.auth.currentUser, {
          displayName: getUserFullName(profileDetails),
          photoURL: profileDetails.userProfilePicture ?? null,
        })
      }

      await updateUserDetails(dataToThrow)

      // Updating competitor profile data
      const dataToUpdate = getModelledCompetitorProfileData({
        ...competitiorProfileData_,
        competitorOwnerId: userId,
      })

      if (userId) {
        dataToUpdate.competitorOwnerId = userId
        dispatch(setCompetitorProfileData(dataToUpdate))
        await updateCompetitorProfile(dataToUpdate)
      }

      dispatch(setProfileDetails(profileDetails))

      toastFunctions.success({ message: MESSAGES_CONST.PROFILE_UPDATED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
      isSaved(true)
    }
  }

  const onError = () => {
    toastFunctions.error({
      message: 'Please fill all the required fields',
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const setter = (key: string, imageUrls: string[]) => {
    if (key === 'foreignEndorsementLetter') {
      const newProfileData = new CompetitorProfileModel(competitorProfileData).cloneDeep()
      newProfileData.competitorEndorsementNumberLetter = [
        ...(newProfileData.competitorEndorsementNumberLetter ?? []),
        ...imageUrls,
      ]

      // setValue("competitorEndorsementNumberLetter",newProfileData.competitorEndorsementNumberLetter )
      updateEndoresementLetters(newProfileData.competitorEndorsementNumberLetter)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function handleEndorsementFiles() {
    handleModalContext?.handleModal(true, MODAL_CONSTS.UPLOAD_EVENT_IMAGES, {
      setter,
      id: userId,
      dontShowMessage: true,
      type: PROFILE_IMAGE_TYPES_CONST.FOREIGN_ENDORESEMENT_LETTER,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const updateEndoresementLetters = async (filesToUpload: string[] = [], removed = false) => {
    try {
      const newProfileData = new CompetitorProfileModel(competitorProfileData).cloneDeep()

      newProfileData.competitorEndorsementNumberLetter = filesToUpload

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.COMPETITOR_PROFILE.NAME,
        userId,
        newProfileData.toFirestore()
      )

      dispatch(setCompetitorProfileData(getConvertedData(newProfileData.toObject())))

      if (!removed)
        toastFunctions.success({
          message: MESSAGES_CONST.ENDORSEMENT_LETTER_UPLOAD,
        })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      dispatch(setFilesToUpload(null))
    }
  }

  const removeEndorsementLetter = async (url: string) => {
    if (!url) return 0

    let update = false

    const newProfileData = new CompetitorProfileModel(competitorProfileData).cloneDeep()
    newProfileData.competitorEndorsementNumberLetter =
      newProfileData.competitorEndorsementNumberLetter?.filter((c) => {
        if (c !== url) return true

        update = true
        return false
      })

    try {
      if (!update) return

      await updateEndoresementLetters(newProfileData.competitorEndorsementNumberLetter, true)
      await FirebaseStorageService.deleteFile(url)

      toastFunctions.success({
        message: MESSAGES_CONST.ENDORSEMENT_LETTER_REMOVED,
      })
    } catch (error: any) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  const updateProfilePic = async (event: any) => {
    const currFormValues = watch()
    const files = event.target.files
    const file = files[0]
    const PATH = CONST.DATA.STORAGE.USERS.COMPETITOR_PROFILE_IMAGE_URL.PREFIX

    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    setImgLoading(true)

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${PATH}/${userId}`,
        (percent: number) => {
          if (0) console.log(percent)
        }
      )

      const newUserData = new UserModel({
        ...userData,
        ...currFormValues,
      })

      newUserData.userProfilePicture = (downloadUrl ?? '') as string

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        newUserData.id,
        {
          userProfilePicture: newUserData.userProfilePicture ?? null,
          userModified: TimeLib.utcTimestamp(),
        }
      )

      setUserData(newUserData)
      setValue('userProfilePicture', newUserData.userProfilePicture)
      toastFunctions.success({ message: MESSAGES_CONST.PIC_UPDATED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setImgLoading(false)
    }
  }

  const removeImage = async (url: string, fieldName: any, isExtraFields?: boolean) => {
    const currFormValues = watch()

    try {
      await FirebaseStorageService.deleteFile(url)

      if (
        isExtraFields &&
        currFormValues.userExtraDocument &&
        currFormValues.userExtraDocument[+fieldName]
      ) {
        update(+fieldName, {
          ...currFormValues.userExtraDocument[+fieldName],
          image: ``,
        })
      } else if (!isExtraFields) {
        const newUserData = new UserModel({
          ...currFormValues,
          ...userData,
        })

        if ((newUserData as any)[fieldName as any]) {
          ;(newUserData as any)[fieldName as any].image = ''
        }

        setValue(fieldName, {
          ...(currFormValues as any)[fieldName],
          image: '',
        })

        setUserData(newUserData)
      }

      toastFunctions.success({
        message: MESSAGES_CONST.IMAGE_REMOVED,
      })
    } catch (e) {
      console.log('=>(CompetitorProfileRootPageProfileTab.tsx:420) e', e)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }
  const addNumberImage = async (event: any, fieldName: any, isExtraFields?: boolean) => {
    const currFormValues = watch()
    const files = event.target.files
    const file = files[0]
    const PATH = CONST.DATA.STORAGE.USERS.NUMBERS_URL.PREFIX
    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${PATH}/${userId}/${new Date().getTime()}`,
        (percent: number) => {
          if (0) console.log(percent)
        }
      )
      if (
        isExtraFields &&
        currFormValues.userExtraDocument &&
        currFormValues.userExtraDocument[+fieldName]
      ) {
        update(+fieldName, {
          ...currFormValues.userExtraDocument[+fieldName],
          image: `${downloadUrl || ''}`,
        })
      } else if (!isExtraFields) {
        const newUserData = new UserModel({
          ...currFormValues,
          ...userData,
        })

        if ((newUserData as any)[fieldName as any]) {
          ;(newUserData as any)[fieldName as any].image = (downloadUrl ?? '') as string
        }

        setValue(fieldName, {
          ...(currFormValues as any)[fieldName],
          image: `${downloadUrl || ''}`,
        })

        setUserData(newUserData)
      }

      toastFunctions.success({ message: MESSAGES_CONST.PIC_UPLOADED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setImgLoading(false)
    }
  }

  /**
   * @TODO Document this
   */
  const utils = {
    allowCharOnly: (e: any) => e.target.value,
    // allowCharOnly: (e: any) => e.target.value = e.target.value.replaceAll(/[^a-zA-Z]/gi, "")
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param place Details got from React auto complete library
   * @info Sets from values according to the details got from third party lib
   */
  const handleLocation = (values: {
    userLat: any
    userLong: any
    userState: any
    userNationality: string
  }) => {
    let stateTypes = ['administrative_area_level_1']
    let values_: any = cloneDeep(values)
    let addressComponents: any =
      values_ && Array.isArray(values_?.address_components) ? values_.address_components : []

    let state = addressComponents.find((currAddComponent: any) => {
      return (
        currAddComponent.types.includes(stateTypes[0]) ||
        currAddComponent.types.includes(stateTypes[1])
      )
    })

    state = state?.long_name || state?.short_name

    reset({
      ...getValues(),
      userState: state ?? '',
      userLat: values?.userLat ?? '',
      userLong: values?.userLong ?? '',
      // userNationality: values?.userNationality ?? ""
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const formatOptionLabel = (props: any) => (
    <div style={{ display: 'flex', textTransform: 'none' }}>
      <div>{props?.label}</div>
    </div>
  )

  const renderImageNumberInput = (field: string, isExternal?: boolean) => {
    if (!userData) return <></>

    let imgUri = ''

    if (isExternal) {
      const externalField = fields[+field]
      imgUri = externalField?.image || ''
    } else {
      const v = userData as any
      imgUri = v[field]?.image || null
    }

    return (
      <>
        <div className={'flex-1 mt-3 md:mt-0 md:pl-3 w-full md:max-w-[260px]'}>
          {saved ? (
            <ImageWrapper url={imgUri} removeHandler={() => null} />
          ) : (
            <div
              className={
                'group flex items-center justify-between px-3  w-[100%] relative border border-solid rounded-xl !ring-0 mr-2 h-[51px] !border-[#D3DAEE]'
              }
            >
              <ImageWrapper
                url={imgUri}
                removeHandler={() => {
                  handleModalContext?.handleModal(true, MODAL_CONSTS.CONFIRM_REMOVE_IMAGE, {
                    confirmDelete: () => removeImage(imgUri, field, isExternal),
                  })
                }}
                editMode={true}
              />
              <div
                className={
                  'h-[40px] w-[40px] bg-[#E6EAF2] flex items-center relative hover:bg-[#dcdfe7] cursor-pointer rounded-[4px] justify-center'
                }
              >
                <span className={'text-[#1F4173] text-[20px] cursor-pointer'}>+</span>
                <input
                  type="file"
                  name={isExternal ? 'userExtraDocument.' : '' + field + '.image'}
                  accept=".jpeg, .png, .jpg"
                  onChange={(e: any) => addNumberImage(e, field, isExternal)}
                  className="absolute h-[100%] w-[100%] opacity-0 left-0 right-0 top-0 bottom-0 cursor-pointer z-10"
                />
              </div>
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <div className="relative">
      <FormHeader
        title="Profile settings"
        description="Update your profile details"
        headerButtonsContainer={
          <div className="flex items-center gap-2 sticky top-0 ">
            {saved ? (
              <button
                type="button"
                onClick={() => isSaved(false)}
                className="items-center !sticky top-0 bg-white right-10 w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              >
                Edit
              </button>
            ) : (
              <button
                type="button"
                disabled={loading}
                onClick={handleSubmit(onSubmit, onError)}
                className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              >
                {loading ? (
                  <AutorenewRounded fontSize="small" className="animate-spin" />
                ) : (
                  'Save changes'
                )}
              </button>
            )}
          </div>
        }
      />

      <div className={formStyles.className} style={formStyles.styles}>
        <div className="relative pb-[200px] w-full text-SeabiscuitDark200ThemeColor lg:!text-nr 2xl:!text-base profile_tab">
          <div className="block md:grid gap-3">
            {/* Profile Picture */}
            <div className="flex flex-wrap items-center">
              <label className="w-full md:w-1/4 flex">
                <img src="/assets/cp_icons/Image-1.svg" alt="imgIcon" className="mr-2" />
                Profile picture
              </label>

              <div className="relative p-1 mt-3 md:mt-0  rounded-full cursor-pointer flex items-center justify-center w-[90px] h-[90px] bg-[#F0F3FA]">
                <input
                  type="file"
                  disabled={imgLoading || saved}
                  accept=".jpeg, .png, .jpg"
                  ref={fileInputRef}
                  onChange={(e: any) => updateProfilePic(e)}
                  className="UserProfilePicture  absolute h-full w-full opacity-0 left-0 right-0 top-0 bottom-0 z-40 cursor-pointer"
                />

                {imgLoading ? (
                  <Autorenew className="animate-spin" />
                ) : (
                  <img
                    src={
                      !!!userData?.userProfilePicture
                        ? '/assets/cp_icons/User-0.svg'
                        : userData?.userProfilePicture
                    }
                    className="object-cover rounded-full h-full w-full"
                    alt="profile_picture"
                  />
                )}

                {!saved ? (
                  <div className="bg-[#bec6dd] p-1.5 rounded-full absolute right-0 bottom-0">
                    <CameraAlt className="text-white text-xs" />
                  </div>
                ) : null}
              </div>
            </div>

            <hr className="my-4"></hr>

            {/* USEF number */}

            <div className="flex flex-col md:flex-row flex-wrap md:items-center">
              <label className="md:w-1/4 flex items-center mr-2">
                {' '}
                <img src="/assets/cp_icons/Parliament-1.svg" className="mr-2" alt="Parliament" />
                USEF number
              </label>

              <div className="flex flex-col md:flex-row md:max-w-[420px] md:min-w-[420px]">
                <input
                  type="number"
                  placeholder="Enter number..."
                  disabled={saved}
                  {...register('userUSEF.documentNumber')}
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                  className={`md:w-[50%] border border-solid  rounded-xl !ring-0 p-3 mt-3 md:mt-0 md:mr-2 h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeDark' : '!border-[#D3DAEE]'}`}
                />

                <div
                  onClick={() => window.open('https://www.usef.org/log-in', '_blank')}
                  className=" text-[#8C95AC] rounded-xl md:w-[50%] gap-2 flex items-center cursor-pointer relative p px-4 mt-3 md:mt-0  md:mr-2"
                >
                  <img src="/assets/og_icons/USEF.svg" className="relative mr-1" alt="circle" />
                  Open USEF portal {'>'}
                </div>

                {/* <div className='profileSyncStatus flex items-center bg-[#EB57571A] relative xl:w-52 min-w-fit 2xl:w-64 p-2 px-3 mr-2 rounded-xl'>
            <img src="/assets/img/usef-logo.svg" height="32px" width="32px" className='mr-2' alt="useflogo" />
            <div className='text-[#EB5757] flex flex-col'>
              <span>USEF record:</span>
              <span>Inactive, Expired: 2019-11-30</span>
            </div>
          </div> */}
              </div>
            </div>
            {/* USEF profile sync status */}

            <hr className="my-4"></hr>

            {/* FEI number */}
            <div className="flex flex-col md:flex-row flex-wrap md:items-center">
              <label className="md:w-1/4 flex items-center md:mr-2">
                <img
                  src="/assets/cp_icons/Parliament-1.svg"
                  className="md:mr-2"
                  alt="parliamenticons"
                />
                FEI number
              </label>
              <div className="flex flex-col md:flex-row md:max-w-[420px] md:min-w-[420px]">
                <input
                  type="number"
                  placeholder="Enter number..."
                  disabled={saved}
                  {...register('userFEI.documentNumber')}
                  className={`md:w-[50%] border border-solid  rounded-xl !ring-0 p-3 mt-3 md:mt-0 md:mr-2 h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeDark' : '!border-[#D3DAEE]'}`}
                />
                <div className="md:w-[50%]">
                  <CustomController
                    placeholder="FEI Status"
                    name="userFEI.membershipStatus"
                    control={control}
                    saved={saved}
                  />
                </div>
              </div>
            </div>

            <hr className="my-4"></hr>

            {/* USEF number */}

            <div className="flex flex-col md:flex-row flex-wrap md:items-center">
              <label className="md:w-1/4 flex items-center mr-2">
                <img src="/assets/cp_icons/Paste-1.svg" className="mr-2" alt="safe" />
                Safe sport
              </label>
              <div className="flex flex-col">
                {1 ? (
                  <div className="flex flex-col md:flex-row md:max-w-[420px] md:min-w-[420px]">
                    <div className="relative flex items-center md:mr-2 mt-3 md:mt-0  md:w-[50%]">
                      <Controller
                        control={control}
                        name="userSafeSupportStatus"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            onChange={onChange}
                            onBlur={onBlur}
                            isDisabled={saved}
                            value={CURRENT_STATUS.find(
                              (c) => c.value === ((value as any)?.value ?? value)
                            )}
                            options={CURRENT_STATUS}
                            placeholder="Current?"
                            className={`border-[#D3DAEE]  border rounded-xl  w-[100%]  selectWithSearch  focus:ring-[#D3DAEE] py-[3px] focus:border-SeabiscuitMainThemeColor ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                            styles={
                              {
                                ...customStyles,
                                valueContainer: (provided: any) => ({
                                  ...provided,
                                  textAlign: 'left',
                                  justifyContent: 'start',
                                }),
                                singleValue: (provided: any, state: any) => ({
                                  ...(customStyles?.singleValue &&
                                    customStyles?.singleValue(provided, state)),
                                  fontSize: '14px !important',
                                  color: state.isDisabled ? '#122b46' : provided.color,
                                  opacity: state.isDisabled ? '1' : provided.opacity,
                                }),
                                dropdownIndicator: (provided: any, state: any) => ({
                                  ...provided,
                                  display: state.isDisabled ? 'none' : '',
                                }),
                              } as any
                            }
                            isClearable={false}
                            isMulti={false}
                            isSearchable={true}
                          />
                        )}
                      />
                      {errors.userSafeSupportStatus && (
                        <p className="text-SeabiscuitMainThemeColor">
                          {errors.userSafeSupportStatus.message}
                        </p>
                      )}
                    </div>

                    <div
                      className={`relative flex mt-3 md:mt-0 items-center border border-solid rounded-xl md:w-[50%] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    >
                      <Controller
                        name="userSafeSupportExpiry"
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <div className="pl-4 text-[14px]">Expires: </div>
                            <CustomDatePicker
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={saved}
                              selected={getReactPickerDate(value)}
                              placeholderText="Select Date"
                              className={`rounded-xl  selectWithSearch w-[100%] border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px] `}
                            />
                          </>
                        )}
                      />
                      {!saved && (
                        <KeyboardArrowDownIcon
                          fontSize="medium"
                          className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                        />
                      )}
                      {errors.userSafeSupportExpiry && (
                        <p className="text-SeabiscuitMainThemeColor">
                          {errors.userSafeSupportExpiry.message}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <div className="bg-[#EB57571A] w-80 flex items-center border-0  text-[#EB5757] rounded-xl p-3.5 pl-12 mr-2 relative">
                      <img
                        src="/assets/og_icons/Synchronize-2.svg"
                        className="mr-2 absolute left-2"
                        alt="circle"
                      />
                      SafeSport Training Overdue - allowed on competition grounds
                    </div>
                    <div className="border border-[#EB5757] rounded-xl text-[#EB5757] w-56 flex items-center relative justify-center p px-12">
                      <img
                        src="/assets/og_icons/Synchronize-2.svg"
                        className="mr-2 absolute left-2"
                        alt="circle"
                      />
                      Renew Training
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Background check */}
            <div className="flex flex-col mt-3 md:mt-0 md:flex-row flex-wrap md:items-center">
              <label className="md:w-1/4 flex items-center mr-2">
                <img src="/assets/cp_icons/Paste-1.svg" className="mr-2" alt="safe" />
                Background check
              </label>

              <div className="flex flex-col">
                {1 ? (
                  <div className="flex mb-2 flex-col md:flex-row  md:max-w-[420px] md:min-w-[420px]">
                    <div className="relative flex items-center mt-3 md:mt-0 md:mr-2 md:w-[50%]">
                      <Controller
                        control={control}
                        name="userBackgroundCheckStatus"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            onChange={onChange}
                            onBlur={onBlur}
                            value={CURRENT_STATUS.find(
                              (c) => c.value === ((value as any)?.value ?? value)
                            )}
                            options={CURRENT_STATUS}
                            placeholder="Current?"
                            isDisabled={saved}
                            className={`border-[#D3DAEE] border rounded-xl  w-[100%] selectWithSearch  py-[3px] focus:ring-[#D3DAEE] focus:border-[#D3DAEE] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                            styles={
                              {
                                ...customStyles,
                                valueContainer: (provided: any) => ({
                                  ...provided,
                                  textAlign: 'left',
                                  justifyContent: 'start',
                                }),
                                singleValue: (provided: any, state: any) => ({
                                  ...(customStyles?.singleValue &&
                                    customStyles?.singleValue(provided, state)),
                                  fontSize: '14px !important',
                                  color: state.isDisabled ? '#122b46' : provided.color,
                                  opacity: state.isDisabled ? '1' : provided.opacity,
                                }),
                                dropdownIndicator: (provided: any, state: any) => ({
                                  ...provided,
                                  display: state.isDisabled ? 'none' : '',
                                }),
                              } as any
                            }
                            isClearable={false}
                            isMulti={false}
                            isSearchable={true}
                          />
                        )}
                      />
                      {errors.userBackgroundCheckStatus && (
                        <p className="text-SeabiscuitMainThemeColor">
                          {errors.userBackgroundCheckStatus.message}
                        </p>
                      )}
                    </div>

                    <div
                      className={`relative flex items-center mt-3 md:mt-0 md:w-[50%] border border-solid ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'} rounded-xl`}
                    >
                      <Controller
                        name="userBackgroundCheckExpiry"
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <div className="pl-4 text-[14px]">Expires: </div>
                            <CustomDatePicker
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={saved}
                              selected={getReactPickerDate(value)}
                              placeholderText="Select Date"
                              className={`rounded-xl w-[100%] selectWithSearch placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 h-[51px] !border-white`}
                            />
                          </>
                        )}
                      />
                      {!saved && (
                        <KeyboardArrowDownIcon
                          fontSize="medium"
                          className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                        />
                      )}
                      {errors.userBackgroundCheckExpiry && (
                        <p className="text-SeabiscuitMainThemeColor">
                          {errors.userBackgroundCheckExpiry.message}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <div className="bg-[#EB57571A] w-80 flex items-center border-0  text-[#EB5757] rounded-xl p-3.5 pl-12 mr-2 relative">
                      <img
                        src="/assets/og_icons/Synchronize-2.svg"
                        className="mr-2 absolute left-2"
                        alt="circle"
                      />
                      SafeSport Training Overdue - allowed on competition grounds
                    </div>
                    <div className="border border-[#EB5757] rounded-xl text-[#EB5757] w-56 flex items-center relative justify-center p px-12">
                      <img
                        src="/assets/og_icons/Synchronize-2.svg"
                        className="mr-2 absolute left-2"
                        alt="circle"
                      />
                      Renew Training
                    </div>
                  </div>
                )}
              </div>
            </div>

            <hr className="my-4"></hr>
            {/* User Personal Details */}

            <div className="flex flex-wrap items-center">
              <label className="md:w-1/4 flex items-center mr-2">
                <img src="/assets/cp_icons/User-1.svg" alt="userIcons" className="mr-2" />
                First name
              </label>
              <div className="mt-3 md:mt-0 w-full md:w-2/4 md:max-w-[420px] md:min-w-[420px]">
                <input
                  type="text"
                  {...register('userFirstName', {
                    onChange: (e) => utils.allowCharOnly(e),
                  })}
                  disabled={saved}
                  placeholder="Enter first name..."
                  className={` w-full border rounded-xl !ring-0 p-2.5 mr-2 h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                />
                {errors?.userFirstName?.message && (
                  <MessageHelperComp isError={true} message={errors.userFirstName.message} />
                )}
              </div>
            </div>
            <div className="flex mt-3 md:mt-0 flex-wrap items-center">
              <label className="md:w-1/4 flex items-center mr-2">
                <img src="/assets/cp_icons/User-1.svg" alt="userIcons" className="w-6 h-6 mr-2" />
                Last name
              </label>
              <div className="mt-3 md:mt-0 w-full md:w-2/4 md:max-w-[420px] md:min-w-[420px]">
                <input
                  type="text"
                  {...register('userLastName', {
                    onChange: (e) => utils.allowCharOnly(e),
                  })}
                  disabled={saved}
                  placeholder="Enter last name..."
                  className={`w-full border !border-[#D3DAEE] rounded-xl p-3 h-[51px] !ring-0 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                />
                {errors?.userLastName?.message && (
                  <MessageHelperComp isError={true} message={errors.userLastName.message} />
                )}
              </div>
            </div>
            <div className="flex mt-3 md:mt-0 flex-wrap items-center  ">
              <label className="w-full md:w-1/4 flex items-center mr-2">
                <img
                  src="/assets/og_icons/Globe-1.svg"
                  className="w-6 h-6 mr-2"
                  alt="nationality"
                />
                Nationality
              </label>

              <Controller
                control={control}
                name="userNationality"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    onChange={onChange}
                    onBlur={onBlur}
                    value={CountryList.find((c) => c.value === value)}
                    options={CountryList}
                    isDisabled={saved}
                    placeholder="Select your nationality..."
                    className={`mt-3 md:mt-0 border border-solid w-full md:w-[520px] selectWithSearch  rounded-xl focus:ring-[#D3DAEE] focus:border-[#D3DAEE] p-[2px] h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    styles={
                      {
                        ...customStyles,
                        valueContainer: (provided: any) => ({
                          ...provided,
                          textAlign: 'left',
                          justifyContent: 'start',
                        }),
                        singleValue: (provided: any, state: any) => ({
                          ...(customStyles?.singleValue &&
                            customStyles?.singleValue(provided, state)),
                          fontSize: '14px !important',
                          color: state.isDisabled ? '#122b46' : provided.color,
                          opacity: state.isDisabled ? '1' : provided.opacity,
                        }),
                        dropdownIndicator: (provided: any, state: any) => ({
                          ...provided,
                          display: state.isDisabled ? 'none' : '',
                        }),
                      } as any
                    }
                    isClearable={false}
                    isMulti={false}
                    isSearchable={true}
                  />
                )}
              />
              {(errors as any)?.userNationality?.message &&
                typeof (errors as any)?.userNationality?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    message={(errors.userNationality as any).message}
                  />
                )}
            </div>

            <div className="flex mt-3 md:mt-0 flex-wrap items-center">
              <label className="w-full md:w-1/4 flex items-center mr-2">
                <img
                  src="/assets/og_icons/Tear-Off Calendar-1.svg"
                  className="w-6 h-6 mr-2"
                  alt="calender"
                />
                Date of birth
              </label>
              <div className="mt-3 md:mt-0 w-full md:w-2/4  md:max-w-[420px] md:min-w-[420px]">
                <Controller
                  name="userDOB"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <CustomDatePicker
                        {...register('userDOB')}
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        peekNextMonth
                        dropdownMode="select"
                        showMonthDropdown
                        disabled={saved}
                        showYearDropdown
                        maxDate={new Date()}
                        wrapperClassName=""
                        onKeyDown={(e) => {
                          e.preventDefault()
                        }}
                        placeholderText="Select date of birth..."
                        className={`w-full rounded-xl border placeholder:text-SeabiscuitLightParagraphTextColor placeholder:opacity-1 py-3  ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                      />
                    )
                  }}
                />
                {(errors as any)?.userDOB?.message &&
                  typeof (errors as any)?.userDOB?.message === 'string' && (
                    <MessageHelperComp isError={true} message={(errors.userDOB as any).message} />
                  )}
              </div>
            </div>

            <div className="flex flex-wrap items-center">
              <label className="mt-3 md:mt-0 w-full md:w-1/4 flex items-center mr-2">
                <img src="/assets/og_icons/Location-1.svg" className="w-6 h-6 mr-2" alt="address" />
                Address
              </label>
              <div className="mt-3 md:mt-0 w-full md:w-2/4  md:max-w-[420px] md:min-w-[420px]">
                <GlobalInputComponent
                  type="location"
                  placeholder="Line 1"
                  onChange={handleLocation}
                  value={watch('userAddress') ?? ''}
                  disabled={saved}
                  className={`bg-[#0000] rounded-xl !py-[7px] border pl-0 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  inputName="userAddress"
                  control={control}
                  register={register}
                />

                {errors?.userAddress?.message && (
                  <MessageHelperComp isError={true} message={errors.userAddress.message} />
                )}
              </div>
            </div>

            <div className="flex mt-3 md:mt-0 flex-wrap items-center">
              <label className="md:w-1/4 flex items-center mr-2">
                <img src="/assets/cp_icons/Phone.svg" className="w-7 h-7 mr-2" alt="phone" />
                Phone number
              </label>
              <div className="mt-3 md:mt-0 w-full md:w-2/4  md:max-w-[420px] md:min-w-[420px]">
                <PhoneInput
                  ref={phoneInputRef}
                  name="userPhoneNumber"
                  onChange={phoneOnChange}
                  saved={saved}
                  setValue={setValue}
                  countryCode={watch('userPhoneCode') ?? ''}
                  phoneNumber={watch('userPhoneNumber') ?? ''}
                />

                {errors?.userPhoneNumber?.message && (
                  <MessageHelperComp isError={true} message={errors?.userPhoneNumber?.message} />
                )}
              </div>
            </div>

            <hr className="my-4"></hr>

            {/* Zone Details */}

            <div className="flex flex-wrap items-center">
              <label className="w-full md:w-1/4 flex items-center mr-2">
                {' '}
                <img className="mr-2" src="/assets/og_icons/YearofHorse-1.svg" alt="horse" />
                Discipline
              </label>

              <Controller
                control={control}
                name="userDiscipline"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    onChange={onChange}
                    value={disciplineData.find((c) => c.value === ((value as any)?.value ?? value))}
                    options={disciplineData}
                    onBlur={onBlur}
                    isDisabled={saved}
                    placeholder="Select your discipline..."
                    className={`border border-solid mt-3 md:mt-0 w-full md:w-[520px] selectWithSearch rounded-xl  p-[2px] h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    styles={
                      {
                        ...customStyles,
                        valueContainer: (provided: any) => ({
                          ...provided,
                          textAlign: 'left',
                          justifyContent: 'start',
                          fontSize: '14px',
                        }),
                        singleValue: (provided: any, state: any) => ({
                          ...(customStyles?.singleValue &&
                            customStyles?.singleValue(provided, state)),
                          fontSize: '14px !important',
                          color: state.isDisabled ? '#122b46' : provided.color,
                          opacity: state.isDisabled ? '1' : provided.opacity,
                        }),
                        dropdownIndicator: (provided: any, state: any) => ({
                          ...provided,
                          display: state.isDisabled ? 'none' : '',
                        }),
                      } as any
                    }
                    isClearable={false}
                    isMulti={false}
                    isSearchable={true}
                  />
                )}
              />
              {(errors as any)?.userDiscipline?.message &&
                typeof (errors as any)?.userNationality?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    message={(errors.userDiscipline as any).message}
                  />
                )}
            </div>

            <div className="flex  mt-3 md:mt-0 flex-wrap items-center">
              <label className="md:w-1/4 flex items-center mr-2">
                <img className="mr-2" src="/assets/og_icons/Mind Map-1.svg" alt="mind_map" />
                Zone
                <Tooltip title={''} placement="top" arrow>
                  <button
                    type="button"
                    onClick={() => props.handleModal(true, MODAL_CONSTS.ZONE_MODAL)}
                    className=""
                  >
                    <TooltipIcon color="#122B46" />
                  </button>
                </Tooltip>
              </label>
              <Controller
                control={control}
                name="userZone"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={onChange}
                    value={zoneList.find((c) => c.value === ((value as any)?.value ?? value))}
                    options={zoneList}
                    onBlur={onBlur}
                    ref={ref}
                    isDisabled={saved}
                    placeholder="Select your zone..."
                    className={`border-[#D3DAEE] border border-solid w-full mt-3 md:mt-0 md:w-[520px] selectWithSearch rounded-xl focus:ring-[#D3DAEE] focus:border-[#D3DAEE] p-[2px] h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    styles={
                      {
                        ...customStyles,
                        valueContainer: (provided: any) => ({
                          ...provided,
                          textAlign: 'left',
                          justifyContent: 'start',
                        }),
                        singleValue: (provided: any, state: any) => ({
                          ...(customStyles?.singleValue &&
                            customStyles?.singleValue(provided, state)),
                          fontSize: '14px !important',
                          color: state.isDisabled ? '#122b46' : provided.color,
                          opacity: state.isDisabled ? '1' : provided.opacity,
                        }),
                        dropdownIndicator: (provided: any, state: any) => ({
                          ...provided,
                          display: state.isDisabled ? 'none' : '',
                        }),
                      } as any
                    }
                    isClearable={false}
                    isMulti={false}
                    isSearchable={true}
                  />
                )}
              />
              {(errors as any)?.userZone?.message &&
                typeof (errors as any)?.userNationality?.message === 'string' && (
                  <MessageHelperComp isError={true} message={(errors.userZone as any).message} />
                )}
            </div>

            {/* Amateur Pro status */}

            <div className="flex mt-3 md:mt-0 flex-wrap items-center">
              <label className="md:w-1/4 flex items-center mr-2">
                <img className="mr-2" src="/assets/og_icons/ametur.svg" alt="Amateur" />
                Amateur Pro status
              </label>
              <Controller
                control={control}
                name="userAmateur"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={onChange}
                    value={amateurList.find((c) => c.value === value)}
                    options={amateurList}
                    onBlur={onBlur}
                    ref={ref}
                    isDisabled={saved}
                    placeholder="Select your amateur Pro status..."
                    className={`border-[#D3DAEE] border border-solid mt-3 md:mt-0 w-full md:w-[520px] selectWithSearch rounded-xl focus:ring-[#D3DAEE] focus:border-[#D3DAEE] p-[2px] h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    styles={
                      {
                        ...customStyles,
                        valueContainer: (provided: any) => ({
                          ...provided,
                          textAlign: 'left',
                          justifyContent: 'start',
                        }),
                        singleValue: (provided: any, state: any) => ({
                          ...(customStyles?.singleValue &&
                            customStyles?.singleValue(provided, state)),
                          fontSize: '14px !important',
                          color: state.isDisabled ? '#122b46' : provided.color,
                          opacity: state.isDisabled ? '1' : provided.opacity,
                        }),
                        dropdownIndicator: (provided: any, state: any) => ({
                          ...provided,
                          display: state.isDisabled ? 'none' : '',
                        }),
                      } as any
                    }
                    isClearable={false}
                    isMulti={false}
                    isSearchable={true}
                  />
                )}
              />
              {(errors as any)?.userAmateur?.message && (
                <MessageHelperComp isError={true} message={(errors as any).userAmateur.message} />
              )}
            </div>

            <div className="flex  mt-3 md:mt-0 flex-wrap items-center">
              <label className="md:w-1/4 flex items-center mr-2">
                <img className="mr-2" src="/assets/img/dark/jud.svg" alt="contact" />
                Judging license
                <Tooltip title={''} placement="top" arrow>
                  <button
                    type="button"
                    onClick={() => props.handleModal(true, MODAL_CONSTS.JUDGING_LICENCE)}
                    className=""
                  >
                    <TooltipIcon color="#122B46" />
                  </button>
                </Tooltip>
              </label>
              <Controller
                control={control}
                name="userJudgingLicense"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    onChange={onChange}
                    value={JudgingLicence.find((c) => c.value === value)}
                    options={JudgingLicence as any}
                    onBlur={onBlur}
                    isDisabled={saved}
                    formatOptionLabel={formatOptionLabel}
                    placeholder="Select your judging licence..."
                    className={` mt-3 md:mt-0 border-[#D3DAEE] border border-solid w-full md:w-[520px] selectWithSearch rounded-xl focus:ring-[#D3DAEE] focus:border-[#D3DAEE] p-[2px] h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    styles={
                      {
                        ...customStyles,
                        valueContainer: (provided: any) => ({
                          ...provided,
                          textAlign: 'left',
                          justifyContent: 'start',
                        }),
                        singleValue: (provided: any, state: any) => ({
                          ...(customStyles?.singleValue &&
                            customStyles?.singleValue(provided, state)),
                          fontSize: '14px !important',
                          color: state.isDisabled ? '#122b46' : provided.color,
                          opacity: state.isDisabled ? '1' : provided.opacity,
                        }),
                        dropdownIndicator: (provided: any, state: any) => ({
                          ...provided,
                          display: state.isDisabled ? 'none' : '',
                        }),
                      } as any
                    }
                    isClearable={false}
                    isMulti={false}
                    isSearchable={true}
                  />
                )}
              />
              {(errors as any)?.competitorJudgingLicense?.message && (
                <MessageHelperComp
                  isError={true}
                  message={(errors as any).competitorJudgingLicense.message}
                />
              )}
            </div>

            <div className="flex  mt-3 md:mt-0 flex-wrap ">
              <label className="md:w-1/4 flex mr-2 mt-1 relative">
                <img
                  className="mr-2 w-5 absolute top-0.5"
                  src="/assets/cp_icons/letter.svg"
                  alt="Letter"
                />{' '}
                <div className="ml-7">Foreign Endorsement Letter</div>
              </label>
              <div className="flex-col items-start w-full md:max-w-[420px] md:min-w-[420px]">
                <div className="flex flex-col w-[50%]">
                  <div
                    className={`usefSearch relative pr-2 py-1.5 mr-2 w-[98%] rounded-xl ${saved ? 'cursor-default pl-3.5' : 'cursor-pointer'}`}
                  >
                    <div
                      className={`flex justify-between w-full h-full ${saved ? 'cursor-default' : 'cursory-pointer'}`}
                    >
                      <div className="uploaduserDocument flex justify-between w-full items-center">
                        <div className=" flex flex-wrap align-center relative">
                          {Array.isArray(
                            competitiorProfileData_?.competitorEndorsementNumberLetter
                          ) && competitiorProfileData_?.competitorEndorsementNumberLetter.length ? (
                            competitiorProfileData_?.competitorEndorsementNumberLetter.map(
                              (currImage, index) => {
                                return (
                                  <div key={`${currImage}${index}`} className="mb-1 px-1">
                                    {!saved ? (
                                      <img
                                        src={'assets/og_icons/Cancel-1.svg'}
                                        className="absolute top-[-4px] right-[-4px]"
                                        alt="cancelIcon"
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              'Are you sure you want to remove this image?'
                                            )
                                          ) {
                                            removeEndorsementLetter(currImage)
                                          }
                                        }}
                                      />
                                    ) : null}
                                    <img
                                      src={currImage}
                                      className="w-24 h-30 border border-[#D3DAEE] rounded-xl"
                                      alt="endoresement"
                                      onClick={() => window.open(currImage, '_blank')}
                                    />
                                  </div>
                                )
                              }
                            )
                          ) : (
                            <div className="flex items-center gap-2">
                              <div
                                onClick={saved ? undefined : () => handleEndorsementFiles()}
                                className={`border flex items-center justify-center text-xl rounded-lg w-9 h-9 shrink-0 ${saved ? 'border-white text-SeabiscuitDark200ThemeColor cursor-default' : 'border-[#D3DAEE]'}`}
                              >
                                +
                              </div>
                              <div
                                className={`text-SeabiscuitDark200ThemeColor text-sm ${saved ? 'border-white text-SeabiscuitDark200ThemeColor cursor-default' : 'border-[#D3DAEE]'}`}
                              >
                                Add image
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <input
                  type="file"
                  multiple={true}
                  onChange={() => handleEndorsementFiles()}
                  className='absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer'
                  accept=".jpg, .jpeg, .png, .pdf"
                /> */}
                  </div>
                </div>

                <div
                  className={`usdfDate relative border rounded-xl flex items-center w-full md:w-[50%] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                >
                  <Controller
                    name="userForeignEndorsement.validTill"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires: </div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          disabled={saved}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Date"
                          className={` selectWithSearch w-[100%] placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 h-[51px] rounded-xl border-none`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors?.userForeignEndorsement?.validTill?.message && (
                    <MessageHelperComp
                      isError={true}
                      message={errors.userForeignEndorsement.validTill.message}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* <div className='ml-[calc(25%+8px)] flex flex-wrap'>
              {competitiorProfileData_?.competitorEndorsementNumberLetter && Array.isArray(competitiorProfileData_?.competitorEndorsementNumberLetter) ?
                competitiorProfileData_?.competitorEndorsementNumberLetter.map((currImage, index) => {
                  return (
                    <div key={`${currImage}${index}`} className="h-[51px] mr-2 mb-2 relative">
                      <img
                        src="assets/og_icons/Cancel-2.svg"
                        className='endoresement_cancel_icon'
                        alt="cancelIcon"
                        onClick={() => removeEndorsementLetter(currImage)}
                      />
                      <img src={currImage} className='w-[51px] h-[51px] border border-[#D3DAEE] rounded-xl' alt='endoresement' />
                    </div>)
                })
                : null}
            </div> */}

            <hr className="my-4"></hr>

            {/* Documentation Area */}
            <div className="flex flex-col md:flex-row flex-wrap md:items-center ">
              <label className="md:w-1/4 flex items-center mr-2">
                <img className="mr-2" src="/assets/cp_icons/Hierarchy-1.svg" alt="hierarchy" />
                USDF number
              </label>
              <div className="flex flex-col md:flex-row md:items-center md:min-w-[420px] md:max-w-[420px]">
                <div className="w-full md:w-[50%] mt-3 md:mt-0">
                  <input
                    type="number"
                    placeholder="Enter number..."
                    disabled={saved}
                    {...register('userUSDF.documentNumber')}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    className={`w-full md:w-[96%] border border-solid rounded-xl !ring-0 p-3.5 mr-2 h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                <div
                  className={` mt-3 md:mt-0 usdfDate relative flex items-center md:w-[50%] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="userUSDF.endDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires:</div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          disabled={saved}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Date"
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors?.userUSDF?.endDate?.message && (
                    <MessageHelperComp isError={true} message={errors.userUSDF?.endDate.message} />
                  )}
                </div>
              </div>
              <div className={'mt-3 md:mt-0 md:ml-3 w-full md:w-auto md:min-w-[240px]'}>
                <CustomController
                  placeholder="USDF Status"
                  name="userUSDF.membershipStatus"
                  control={control}
                  saved={saved}
                />
              </div>
              {renderImageNumberInput('userUSDF')}
            </div>

            <div className="flex flex-col md:flex-row mt-3 md:mt-0 flex-wrap md:items-center">
              <label className="w-full md:w-1/4 flex items-center mr-2">
                <img className="mr-2" src="/assets/cp_icons/Hierarchy-1.svg" alt="hierarchy" />
                USEA number
              </label>
              <div className="flex  mt-3 md:mt-0 flex-col md:flex-row md:items-center w-full md:min-w-[420px] md:max-w-[420px]">
                <div className="w-full md:w-[50%]">
                  <input
                    type="number"
                    placeholder="Enter number..."
                    disabled={saved}
                    {...register('userUSEA.documentNumber')}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    className={`w-full md:w-[96%] border border-solid rounded-xl !ring-0 p-3.5 md:mr-2 h-[51px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                <div
                  className={`relative  mt-3 md:mt-0 flex items-center w-full md:w-[50%] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="userUSEA.endDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires:</div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          disabled={saved}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Date"
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors?.userUSEA?.endDate?.message && (
                    <MessageHelperComp isError={true} message={errors.userUSEA?.endDate.message} />
                  )}
                </div>
              </div>
              <div className={' mt-3 md:mt-0 md:ml-3 w-full md:w-auto md:min-w-[240px]'}>
                <CustomController
                  placeholder="USEA Status"
                  name="userUSEA.membershipStatus"
                  control={control}
                  saved={saved}
                />
              </div>
              {renderImageNumberInput('userUSEA')}
            </div>

            <div className="flex flex-col md:flex-row mt-3 md:mt-0 flex-wrap md:items-center">
              <label className="md:w-1/4 flex items-center mr-2">
                {' '}
                <img className="mr-2" src="/assets/cp_icons/Hierarchy-1.svg" alt="hierarchy" />
                USHJA number
              </label>
              <div className="flex flex-col md:flex-row md:items-center md:min-w-[420px] md:max-w-[420px]">
                <div className="w-full mt-3 md:mt-0 md:w-[50%]">
                  <input
                    type="number"
                    placeholder="Enter number..."
                    {...register('userUSHJA.documentNumber')}
                    disabled={saved}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    className={`w-full md:w-[96%] border border-solid !border-[#D3DAEE] rounded-xl !ring-0 p-3.5 md:mr-2 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                <div
                  className={`relative flex items-center mt-3 md:mt-0 w-full md:w-[50%] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="userUSHJA.endDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires:</div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          disabled={saved}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Date"
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors?.userUSHJA?.endDate?.message && (
                    <MessageHelperComp isError={true} message={errors.userUSHJA?.endDate.message} />
                  )}
                </div>
              </div>
              <div className={'mt-3 md:mt-0 md:ml-3 min-w-[240px]'}>
                <CustomController
                  placeholder="USHJA Status"
                  name="userUSHJA.membershipStatus"
                  control={control}
                  saved={saved}
                />
              </div>
              {renderImageNumberInput('userUSHJA')}
            </div>

            {/* AHHS  */}
            <div className="flex flex-col md:flex-row mt-3 md:mt-0 flex-wrap md:items-center">
              <label className="w-full md:w-1/4 flex items-center mr-2">
                {' '}
                <img className="mr-2" src="/assets/cp_icons/Hierarchy-1.svg" alt="hierarchy" />
                AHHS number
              </label>
              <div className="flex flex-col md:flex-row mt-3 md:mt-0 md:items-center w-full md:min-w-[420px] md:max-w-[420px]">
                <div className="md:w-[50%]">
                  <input
                    type="number"
                    placeholder="Enter number..."
                    {...register('userAHHS.documentNumber')}
                    disabled={saved}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    className={`w-full md:w-[96%] border border-solid !border-[#D3DAEE] rounded-xl !ring-0 p-3.5 md:mr-2 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                <div
                  className={`relative mt-3 md:mt-3 flex items-center md:w-[50%] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="userAHHS.endDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires:</div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          disabled={saved}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Date"
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors?.userAHHS?.endDate?.message && (
                    <MessageHelperComp isError={true} message={errors.userAHHS?.endDate.message} />
                  )}
                </div>
              </div>
              <div className={'mt-3 md:mt-0 md:ml-3 w-full md:w-auto md:min-w-[240px]'}>
                <CustomController
                  placeholder="AHHS Status"
                  name="userAHHS.membershipStatus"
                  control={control}
                  saved={saved}
                />
              </div>
              {renderImageNumberInput('userAHHS')}
            </div>
            {/* AHHS  */}

            {/* AMHA  */}
            <div className="flex mt-3 md:mt-0 flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                {' '}
                <img className="mr-2" src="/assets/cp_icons/Hierarchy-1.svg" alt="hierarchy" />
                AMHA number
              </label>
              <div className="flex flex-col md:flex-row md:items-center w-full md:min-w-[420px] md:max-w-[420px]">
                <div className="mt-3 md:mt-0 md:w-[50%]">
                  <input
                    type="number"
                    {...register('userAMHA.documentNumber')}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    disabled={saved}
                    placeholder="Enter number..."
                    className={`w-full md:w-[96%] border border-solid !border-[#D3DAEE] rounded-xl !ring-0 p-3.5 mr-2 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                <div
                  className={`relative flex items-center mt-3 md:mt-0 md:w-[50%] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="userAMHA.endDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires:</div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          disabled={saved}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Date"
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors?.userAMHA?.endDate?.message && (
                    <MessageHelperComp isError={true} message={errors.userAMHA?.endDate.message} />
                  )}
                </div>
              </div>
              <div className={'mt-3 md:mt-0 md:ml-3 w-full md:w-auto md:min-w-[240px]'}>
                <CustomController
                  placeholder="AMHA Status"
                  name="userAMHA.membershipStatus"
                  control={control}
                  saved={saved}
                />
              </div>
              {renderImageNumberInput('userAMHA')}
            </div>
            {/* AMHA  */}

            {/* ARHPA  */}
            <div className="flex mt-3 md:mt-0 flex-wrap items-center">
              <label className="w-full md:w-1/4 flex items-center mr-2">
                {' '}
                <img className="mr-2" src="/assets/cp_icons/Hierarchy-1.svg" alt="hierarchy" />
                ARHPA number
              </label>
              <div className="mt-3 md:mt-0 flex flex-col md:flex-row  md:items-center w-full md:min-w-[420px] md:max-w-[420px]">
                <div className="w-full md:w-[50%]">
                  <input
                    type="number"
                    placeholder="Enter number..."
                    {...register('userARHPA.documentNumber')}
                    disabled={saved}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    className={`w-full md:w-[96%] border border-solid !border-[#D3DAEE] rounded-xl !ring-0 p-3.5 mr-2 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                <div
                  className={`relative flex items-center mt-3 md:mt-0 md:w-[50%] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="userARHPA.endDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires:</div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          disabled={saved}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Date"
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors?.userARHPA?.endDate?.message && (
                    <MessageHelperComp isError={true} message={errors.userARHPA?.endDate.message} />
                  )}
                </div>
              </div>
              <div className={'mt-3 md:mt-0 md:ml-3 w-full md:w-auto md:min-w-[240px]'}>
                <CustomController
                  placeholder="ARHPA Status"
                  name="userARHPA.membershipStatus"
                  control={control}
                  saved={saved}
                />
              </div>
              {renderImageNumberInput('userARHPA')}
            </div>
            {/* ARHPA  */}

            {/* ASHA  */}
            <div className="mt-3 md:mt-0 flex flex-wrap items-center">
              <label className="w-full md:w-1/4 flex items-center mr-2">
                {' '}
                <img className="mr-2" src="/assets/cp_icons/Hierarchy-1.svg" alt="hierarchy" />
                ASHA number
              </label>
              <div className="flex mt-3 md:mt-0 flex-col md:flex-row md:items-center w-full md:min-w-[420px] md:max-w-[420px]">
                <div className="w-full md:w-[50%]">
                  <input
                    type="number"
                    placeholder="Enter number..."
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    disabled={saved}
                    {...register('userASHA.documentNumber')}
                    className={`w-full md:w-[96%] border border-solid !border-[#D3DAEE] rounded-xl !ring-0 p-3.5 mr-2 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                <div
                  className={`relative mt-3 md:mt-0 flex items-center md:w-[50%] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="userASHA.endDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires:</div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          disabled={saved}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Date"
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors?.userASHA?.endDate?.message && (
                    <MessageHelperComp isError={true} message={errors.userASHA?.endDate.message} />
                  )}
                </div>
              </div>
              <div className={'mt-3 md:mt-0 md:ml-3 w-full md:w-auto md:min-w-[240px]'}>
                <CustomController
                  placeholder="ASHA Status"
                  name="userASHA.membershipStatus"
                  control={control}
                  saved={saved}
                />
              </div>

              {renderImageNumberInput('userASHA')}
            </div>
            {/* ASHA  */}

            {/* UPHA  */}
            <div className="flex mt-3 md:mt-0 flex-wrap items-center">
              <label className="w-full md:w-1/4 flex items-center mr-2">
                {' '}
                <img className="mr-2" src="/assets/cp_icons/Hierarchy-1.svg" alt="hierarchy" />
                UPHA number
              </label>
              <div className="flex flex-col md:flex-row mt-3 md:mt-0 md:items-center w-full md:w-auto md:min-w-[420px] md:max-w-[420px]">
                <div className="w-full md:w-[50%]">
                  <input
                    type="number"
                    placeholder="Enter number..."
                    {...register('userUPHA.documentNumber')}
                    disabled={saved}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    className={`w-full md:w-[96%] border border-solid !border-[#D3DAEE] rounded-xl !ring-0 p-3.5 mr-2 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                <div
                  className={`relative flex items-center mt-3 md:mt-0 md:w-[50%] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="userUPHA.endDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires:</div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          disabled={saved}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Date"
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors?.userUPHA?.endDate?.message && (
                    <MessageHelperComp isError={true} message={errors.userUPHA?.endDate.message} />
                  )}
                </div>
              </div>
              <div className={'md:ml-3 mt-3 md:mt-0  w-full md:w-auto md:min-w-[240px]'}>
                <CustomController
                  placeholder="UPHA Status"
                  name="userUPHA.membershipStatus"
                  control={control}
                  saved={saved}
                />
              </div>
              {renderImageNumberInput('userUPHA')}
            </div>
            {/* UPHA  */}

            {/* WDAA  */}
            <div className="flex mt-3 md:mt-0 flex-wrap md:items-center">
              <label className="w-full md:w-1/4 flex items-center mr-2">
                {' '}
                <img className="mr-2" src="/assets/cp_icons/Hierarchy-1.svg" alt="hierarchy" />
                WDAA number
              </label>
              <div className="mt-3 md:mt-0 flex flex-col md:flex-row items-center w-full md:w-auto md:min-w-[420px] md:max-w-[420px]">
                <div className="w-full md:w-[50%]">
                  <input
                    type="number"
                    placeholder="Enter number..."
                    {...register('userWDAA.documentNumber')}
                    disabled={saved}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    className={`w-full md:w-[96%] border border-solid !border-[#D3DAEE] rounded-xl !ring-0 p-3.5 mr-2 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                <div
                  className={`relative flex mt-3 md:mt-0 items-center w-full md:w-[50%] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="userWDAA.endDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires:</div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          disabled={saved}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Date"
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors?.userWDAA?.endDate?.message && (
                    <MessageHelperComp isError={true} message={errors.userWDAA?.endDate.message} />
                  )}
                </div>
              </div>
              <div className={'mt-3 md:mt-0 md:ml-3 w-full md:w-auto md:min-w-[240px]'}>
                <CustomController
                  placeholder="WDAA Status"
                  name="userWDAA.membershipStatus"
                  control={control}
                  saved={saved}
                />
              </div>
              {renderImageNumberInput('userWDAA')}
            </div>
            {/* WDAA  */}

            {/*======================*/}
            {/* Extra user fees */}
            {/*======================*/}

            {fields.map((field: any, index) => (
              <div key={field.id} className="flex mt-3 md:mt-0 flex-wrap items-center">
                <label className="w-full md:w-1/4 flex items-center md:mr-2">
                  <img className="mr-2" src="/assets/cp_icons/Hierarchy-1.svg" alt="hierarchy" />
                  <input
                    type="text"
                    disabled={saved}
                    placeholder="Enter document name..."
                    className={`w-full xl:w-52 2xl:w-64 border !ring-0 text-gray-900 rounded-xl p-3.5 md:mr-2 ${saved ? 'border-none' : '!border-[#D3DAEE]'}`}
                    {...register(`userExtraDocument.${index}.documentFullName`)}
                  />
                </label>
                <div
                  className={`flex flex-col md:flex-row w-full mt-3 md:mt-0 md:items-center ${!saved ? 'md:min-w-[520px] md:max-w-[420px]' : 'md:min-w-[520px] md:max-w-[420px]'}`}
                >
                  <div className="w-full md:w-[50%]">
                    <input
                      type="number"
                      onWheel={(e) => (e.target as HTMLElement).blur()}
                      disabled={saved}
                      placeholder="Enter number..."
                      className={`w-full md:w-[96%] border text-gray-900 rounded-xl !ring-0  p-3.5 mr-2 ${saved ? 'border-none' : '!border-[#D3DAEE]'}`}
                      {...register(`userExtraDocument.${index}.documentNumber`)}
                    />
                  </div>

                  <div
                    className={`relative mt-3 md:mt-0 flex items-center w-full md:w-[50%] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                  >
                    <>
                      <div className="pl-4 text-[14px]">Expires:</div>
                      <Controller
                        name={`userExtraDocument.${index}.endDate`}
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <CustomDatePicker
                            onChange={onChange}
                            onBlur={onBlur}
                            disabled={saved}
                            selected={getReactPickerDate(value)}
                            placeholderText="Select Date"
                            className={`rounded-xl w-[100%] selectWithSearch placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 h-[51px] border border-white`}
                          />
                        )}
                      />
                    </>
                    {!saved && (
                      <KeyboardArrowDownIcon
                        fontSize="medium"
                        className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                      />
                    )}
                    {errors.userWDAA && (
                      <p className="text-SeabiscuitMainThemeColor">{errors.userWDAA.message}</p>
                    )}
                  </div>
                </div>
                <div className={'mt-3 md:mt-0 md:ml-3 w-full md:w-auto md:min-w-[240px]'}>
                  <CustomController
                    placeholder="Status"
                    name={`userExtraDocument.${index}.membershipStatus`}
                    control={control}
                    saved={saved}
                  />
                </div>
                {renderImageNumberInput(`${index}`, true)}
                {!saved && (
                  <div
                    className="xl:w-12 2xl:w-12 ml-2 cursor-pointer"
                    onClick={() => {
                      remove(index)
                    }}
                  >
                    <img src="/assets/og_icons/Cancel.svg" className="h-6 w-6" alt="remove" />
                  </div>
                )}
                <div className="flex flex-wrap items-center w-full">
                  <div className="w-full md:w-1/4 md:mr-2">
                    {errors?.userExtraDocument &&
                    (errors as any)?.[`userExtraDocument`]?.[index]?.documentFullName ? (
                      <MessageHelperComp
                        isError={true}
                        message={MESSAGES_CONST.REQUIRED_FIELD}
                        className="md:ml-8 xl:w-52 2xl:w-64 mr-2"
                      />
                    ) : null}
                  </div>
                  <div className="w-full xl:w-52 2xl:w-64 mr-2">
                    {errors?.userExtraDocument &&
                    (errors as any)?.[`userExtraDocument`]?.[index]?.documentNumber ? (
                      <MessageHelperComp
                        isError={true}
                        message={MESSAGES_CONST.REQUIRED_FIELD}
                        className="xl:w-52 2xl:w-64 mr-2"
                      />
                    ) : null}
                  </div>
                  <div className="w-full xl:w-52 2xl:w-64 mr-2">
                    {errors?.userExtraDocument &&
                    (errors as any)?.[`userExtraDocument`]?.[index]?.endDate ? (
                      <MessageHelperComp
                        isError={true}
                        message={MESSAGES_CONST.REQUIRED_FIELD}
                        className="xl:w-52 2xl:w-64 mr-2"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ))}

            {!saved && (
              <div className="flex text-SeabiscuitMainThemeColor items-center gap-2 justify-between">
                <button
                  type="button"
                  onClick={() => {
                    if (errors && typeof errors === 'object' && errors?.userExtraDocument) return

                    append({
                      membershipStatus: '',
                      startDate: null,
                      endDate: null,
                      validTill: null,
                      documentFullName: '',
                      documentNumber: '',
                      image: '',
                    })
                  }}
                  className="flex items-center cursor-pointer mt-3 md:mt-0"
                >
                  <AddCircleOutline className="text-SeabiscuitMainThemeColor" />
                  <p className="ml-2 text-SeabiscuitMainThemeColor">Add number</p>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompetitorProfileRootPageProfileTab