// ############################################################
/**
 * Contains an interface to pass users to the UsersModel
 */
// ############################################################

import { ISignedStatuses } from '../event-drafts/event-draft.interface'
import { IRecipientPaymentStatus } from '../event-registered-users/event-registered-users.interface'

export const MEMBERSHIP_TYPE = {
  ANNUAL: 'Annual',
  MONTHLY: 'Monthly',
  BOARD: 'Board',
  PAYMENT: 'Payment',
  FREE: 'Free',
}

export const MEMBERSHIP_INTERVAL = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
}

export type IMembershipInterval = 'day' | 'week' | 'month' | 'year' | null
export type IMembershipType = 'Annual' | 'Monthly' | 'Board' | 'Payment' | 'Free' | null

export interface IMembershipsInterface {
  id: string
  name: string | null
  description: string | null
  price: number
  cancellationPeriod: number | null
  pricingDescription: string | null
  type: IMembershipType
  userStripeAccountId: string | null
  stripeProductId?: string | null
  stripePriceId?: string | null
  interval: IMembershipInterval
  amount: number
  termsAndConditions: string | null
}

export type IUserLocationInterface = {
  lat: number | null
  lng: number | null
}

export interface IRiderTeamMember {
  selected?: ITeamMember['selected']
  memberDob?: ITeamMember['memberDob']
  memberId: IUserTeamMember['memberId']
  memberName?: ITeamMember['memberName']
  memberRole?: ITeamMember['memberRole']
  defaultRole?: ITeamMember['defaultRole']
  memberEmail?: ITeamMember['memberEmail']
  signedStatus?: ITeamMember['signedStatus']
  memberProfilePicture?: ITeamMember['memberProfilePicture']
}

export type ICompetitorProfileSelectDiscipline = {
  code: string
  label: string
  value: string
}

export type ISelect = {
  label?: any
  value?: string
  light_icon?: string
  dark_icon?: string
  ccode?: string
}

export interface ICompetitorData {
  competitorProfilePictureUrl?: string
  competitorFirstName?: string
  competitorLastName?: string
  competitorNationality?: ICompetitorProfileSelectDiscipline | string
  competitorDOB?: Date | string | any
  competitorAddress?: string
  competitorPhoneNumber?: string
  competitorNumber1?: string
  competitorNumber2?: string
  competitorNumber3?: string
  competitorDiscipline?: ICompetitorProfileSelectDiscipline | string
  competitorZone?: ICompetitorProfileSelectDiscipline | string
  competitorJudging_license?: ICompetitorProfileSelectDiscipline | string

  competitorOther?: string
  competitorCompetitionDetail1?: string
  competitorCompetitionDetail2?: string
  competitorSocialSecurityNumber?: string
  competitorEndorsmentNumberLetter?: string
  competitorSubRoles?: string[]
}

export interface IUserTeamMember {
  teamMateRole: string
  teamMatePic?: string
  teamMateEmail: string
  memberId: string | null
  teamMateAddress: string
  teamMateUsername: string
  teamMateStatus?: boolean
  teamMateprofileSynced?: boolean
  teamMateMemberShipActive?: boolean
  teamMateMemberSafeSupportTraining?: boolean
}

export type INotificationSettings = {
  newMessages: boolean
  weatherUpdates: boolean
  resultUpdates: boolean
  courseUpdates: boolean
  paymentUpdates: boolean
} | null

export type ITeamMember = {
  userHorseMappingId?: string | null | undefined
  id?: string | null
  userId?: string | null
  authorized?: string | null
  selected?: boolean
  memberEmail?: string | null
  horseId?: string | null
  defaultRole?: string
  memberStatus?: string
  isScratched?: boolean
  refundAmount?: number
  memberCountry?: string
  memberAddress?: string
  recipient?: IRecipient
  memberId: string | null
  memberRole?: string | null
  memberShipActive?: boolean
  memberName?: string | null
  memberUserName?: string | null
  memberProfileSynced?: boolean
  memberprofileSynced?: boolean
  signedStatus?: ISignedStatuses
  memberDob?: string | Date | null
  memberPhoneNumber?: string | null
  horses?: IRegisterTabsSelectData[]
  memberAuthorized?: '0' | '1' | '2'
  memberSafeSupportTraining?: boolean
  memberProfilePicture?: string | null
  HorseTeamMembers?: any[]
  HorseTeamIdLIst?: string[]
  riderTeamMembers?: IRiderTeamMember[]
}

export type IRegisterTabsSelectData = {
  id: string
  name?: string
  label?: string
  value?: string
  horseProfilePicture?: string
}

export interface IRecipient {
  invoiceId?: string
  invoiceUrl?: string
  recipientName?: string
  recipientEmail?: string
  recipientId: string | null
  recipientAmountToPay: number
  recipientDefaultRole?: string
  recipientPaymentStatus?: IRecipientPaymentStatus
}

export interface IRecipientTotalItem {
  amount: number
  memberId: string
  memberName?: string
}

export interface IUserCards {
  cardNumber: string | null
  cardType: string | null
  pmId: string | null
  expiryMonth: string | null
  expiryYear: string | null
  zipCode?: string | null
  default?: boolean
  isNew?: boolean
  selectedCard?: boolean
}

export type IUserDocuments = {
  status?: string
  membershipStatus: string
  startDate: Date | null
  endDate: Date | null
  validTill: Date | null
  documentFullName: string
  documentNumber: string
  image: string
}

export type TUserPocDetails = {
  pocFirstName: string
  pocLastName: string
  pocFullName: string
  pocPhone: string
  pocEmail: string
}

export type TUserDocumentAndExpiration = {
  file: string
  validTill: Date | string
}

export interface IUserInterface {
  v?: number
  id: string
  userLat?: string
  userType?: string
  userLong?: string
  vendorname?: string | null
  userBusinessName?: string | null
  userState?: string
  userEmail: string
  userAddress?: string
  userDOB?: Date | null
  userFirstName?: string
  userSubRoles?: string[]
  userNationality?: string
  userDefaultRole?: string
  userProfilePicture?: string
  userLastName?: string | null
  userOrganizationName?: string
  userPreviousStripeConnectIds: string[]
  userStripeAccountId?: string | null
  userBankConnected?: boolean
  userConnectAccountStatusMessage?: string | null
  userStripeAccountStatus?: 'pending' | 'connected' | null
  userPhoneNumber?: string
  userPhoneCode?: string
  userAlternativeNumber?: string
  userSocialSecurityNumber?: string
  userEndorsementNumberLetter?: string
  userCreated?: Date | null
  userModified?: Date | null
  userName?: string
  userSummary?: string
  userZone?: string
  userAmateur?: string
  userDiscipline?: string
  userNameNGram?: string[]
  userStripeId?: string
  userSafeSupportExpiry?: Date | null
  userTeamMembers?: ITeamMember[]
  userSafeSupportStatus?: string | null
  userBackgroundCheckExpiry?: Date | null
  userBackgroundCheckStatus?: string | null
  userAccountPrivacy?: any
  userNotificationsSettings?: INotificationSettings | null
  userBrandImages?: string[]
  userBrandVideos?: string[]
  userCategory?: string
  userFEI?: IUserDocuments
  userUSEA?: IUserDocuments
  userUSEF?: IUserDocuments
  userUSDF?: IUserDocuments
  userAHHS?: IUserDocuments
  userAMHA?: IUserDocuments
  userASHA?: IUserDocuments
  userUPHA?: IUserDocuments
  userWDAA?: IUserDocuments
  userUSHJA?: IUserDocuments
  userARHPA?: IUserDocuments
  userJudgingLicense?: string | ISelect

  userOtherDocument?: TUserDocumentAndExpiration
  userForeignEndorsement?: TUserDocumentAndExpiration

  userExtraDocument?: IUserDocuments[] | []
  userDescription?: string
  userWebsite?: string
  userFacebook?: string
  userInstagram?: string
  userTwitter?: string
  userLinkedin?: string
  userLocation?: IUserLocationInterface
  websiteUrl?: string | null

  userPocDetails?: TUserPocDetails

  userLegalPolicyName?: string

  userMemberships?: IMembershipsInterface[]
}
