import React, { FC, ReactNode, useState } from 'react'
import clsx from 'clsx'
import { FieldErrors, SubmitHandler, useForm, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import * as yup from 'yup'
import { UseFormTrigger } from 'react-hook-form/dist/types/form'
import { yupResolver } from '@hookform/resolvers/yup'

import MessageHelperComp from '../../../helpers/MessageHelper'
import useToasterHelper from '../../../helpers/ToasterHelper'

import Input from '../../common/inputs/Input'
import AmountInput from '../../common/inputs/AmountInput'
import MainModal from '../common/MainModal'

import {
  IMembershipInterval,
  IMembershipsInterface,
  IMembershipType,
  MEMBERSHIP_TYPE,
} from '../../../models/users/user.interface'

import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectProfileData, setProfileDetails } from '../../../store/user/userSlice'

import { httpService } from '../../../services/httpService'

import { MODAL_CONSTS } from '../../../const/modal-const'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { getConvertedData } from '../../../models/interface.helper'
import { UserModel } from '../../../models/users/user.model'
import { v4 as uuidv4 } from 'uuid'

interface AddMembershipModalProps {
  handleModal: (showHide: boolean, typeOfModal: string) => void
  dataToPassOn: any
}

export interface IMembershipForm {
  interval: IMembershipInterval
  type: IMembershipType
  name: string
  description: string
  price: number
  amount: number
  cancellationPeriod: number
  pricingDescription: string
  termsAndConditions: string
}

interface SubscriptionTypeProps {
  title: string
  type: IMembershipType
  interval?: IMembershipInterval
  icon: string
  iconActive: string
  watch: UseFormWatch<IMembershipForm>
  setValue: UseFormSetValue<IMembershipForm>
  trigger: UseFormTrigger<IMembershipForm>
  disabled?: boolean
}

const SubscriptionType: FC<SubscriptionTypeProps> = ({
  type,
  title,
  icon,
  iconActive,
  watch,
  setValue,
  trigger,
  interval,
  disabled,
}) => {
  return (
    <li
      className={clsx(
        'min-w-[98px] w-[48%] sm:w-[32%] md:w-[18%] rounded-md border p-2 cursor-pointer hover:text-SeabiscuitMainThemeColor transition-all',
        watch('type') === type
          ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor border-transparent'
          : 'border-SeabiscuitLightThemeColorD3',
        disabled && 'pointer-events-none opacity-30'
      )}
      onClick={() => {
        setValue('type', type)
        if (interval) setValue('interval', interval)
        trigger().then()
      }}
    >
      {watch('type') === type ? (
        <img className="w-5 mb-3" src={iconActive} alt="" />
      ) : (
        <img className="w-5 mb-3" src={icon} alt="" />
      )}
      <p className="text-xs opacity-50">{title}</p>
      <p className="text-sm">{type}</p>
    </li>
  )
}

interface CustomFieldProps {
  name: keyof IMembershipForm
  watch: UseFormWatch<IMembershipForm>
  errors: FieldErrors<IMembershipForm>
  children: ReactNode
  label?: string
  description?: string
}

const CustomField: FC<CustomFieldProps> = ({
  watch,
  children,
  errors,
  name,
  label,
  description,
}) => {
  return (
    <div className="relative mb-2">
      <label
        className={clsx(
          'group px-5 py-2 min-h-[56px] flex flex-col sm:flex-row sm:items-center justify-between gap-2 border rounded-lg border-SeabiscuitLightThemeColorD3',
          watch(name) && 'bg-SeabiscuitGrayThemeColor'
        )}
      >
        {label && (
          <div>
            <h6 className="font-bold text-[14px]">{label}</h6>
            <p className="text-[12px]">{description}</p>
          </div>
        )}
        <div className="w-full flex-1">{children}</div>
      </label>
      {errors?.[name] && <MessageHelperComp isError={true} message={errors?.[name]?.message} />}
    </div>
  )
}
const addMembershipSchema = yup.object().shape({
  type: yup.string().required('Type is required').nullable(),
  name: yup.string().required('Name is required'),
  price: yup.mixed().when('type', {
    is: MEMBERSHIP_TYPE.FREE,
    then: yup.string(),
    otherwise: yup
      .number()
      .min(1, 'The price must be greater than 1')
      .required('Price is required'),
  }),
  amount: yup.number().required('Amount is required'),
  cancellationPeriod: yup.number(),
  pricingDescription: yup
    .string()
    .max(100, 'Max 100 characters')
    .required('Pricing description is required'),
  description: yup.string().max(500, 'Max 500 characters').required('Benefits is required'),
  termsAndConditions: yup
    .string()
    .max(500, 'Max 500 characters')
    .required('Terms and conditions is required'),
})

export const AddMembershipModal: FC<AddMembershipModalProps> = ({ handleModal }) => {
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()

  const userData = useAppSelector(selectProfileData)
  const [loading, setLoading] = useState(false)

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<IMembershipForm>({
    resolver: yupResolver(addMembershipSchema),
    defaultValues: {
      interval: null,
      type: null,
      name: '',
      price: 0,
      amount: 0,
      cancellationPeriod: 0,
      pricingDescription: '',
      description: '',
      termsAndConditions: '',
    },
    mode: 'onChange',
  })

  const onSubmit: SubmitHandler<IMembershipForm> = async (values) => {
    let newUserMembership: IMembershipsInterface

    try {
      setLoading(true)
      const data = {
        ...values,
        price: values.type !== MEMBERSHIP_TYPE.FREE ? values.price : 0,
        userStripeAccountId: userData?.userStripeAccountId ?? null,
      }

      if (values.type !== MEMBERSHIP_TYPE.PAYMENT && values.type !== MEMBERSHIP_TYPE.FREE) {
        const response = await httpService({
          url: 'create_membership_plan',
          method: 'POST',
          data,
        })
        if (!response?.product) {
          toastFunctions.error({
            message: 'Error adding membership',
          })
          return
        }

        newUserMembership = {
          ...data,
          stripeProductId: response.product.id,
          stripePriceId: response.product.default_price,
          id: uuidv4(),
        }
      } else {
        newUserMembership = { ...data, id: uuidv4() }
      }

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        userData.id,
        {
          userMemberships: [...(userData.userMemberships ?? []), newUserMembership],
        }
      )

      const usersSnaps = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        userData.id
      )

      const user = getConvertedData(UserModel.fromFirestoreDoc(usersSnaps).toObject())

      dispatch(setProfileDetails(user))

      toastFunctions.success({
        message: 'Membership added successfully',
      })
      handleModal(false, MODAL_CONSTS.ADD_MEMBERSHIP)
    } catch (error) {
      console.log(error, 'error')
      toastFunctions.error({
        message: 'Error adding membership',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <MainModal
      title="Add membership"
      show={true}
      type="ADD_MEMBERSHIP"
      size="lg"
      className="!px-0"
      buttons={[
        {
          label: 'CREATE',
          fullWidth: true,
          loading,
          disabled: loading,
          onClick: handleSubmit(onSubmit),
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitIconThemeColor/50',
          fullWidth: true,
          disabled: loading,
          onClick: () => handleModal(false, MODAL_CONSTS.ADD_MEMBERSHIP),
        },
      ]}
    >
      <div className="text-SeabiscuitDark200ThemeColor">
        <p className="text-sm">Select membership type</p>
        <ul className="flex flex-wrap gap-2 mt-3">
          <SubscriptionType
            title="Subscription"
            type="Annual"
            interval="year"
            icon="/assets/og_icons/Synchronize-1.svg"
            iconActive="/assets/og_icons/Synchronize-2.svg"
            watch={watch}
            setValue={setValue}
            trigger={trigger}
          />
          <SubscriptionType
            title="Subscription"
            type="Monthly"
            interval="month"
            icon="/assets/og_icons/Synchronize-1.svg"
            iconActive="/assets/og_icons/Synchronize-2.svg"
            watch={watch}
            setValue={setValue}
            trigger={trigger}
          />
          <SubscriptionType
            title="Monthly"
            type="Board"
            interval="month"
            icon="/assets/og_icons/Home-1.svg"
            iconActive="/assets/og_icons/Home-2.svg"
            watch={watch}
            setValue={setValue}
            trigger={trigger}
          />
          <SubscriptionType
            title="One time"
            type="Payment"
            icon="/assets/og_icons/Banknotes-1.svg"
            iconActive="/assets/og_icons/Banknotes-2.svg"
            watch={watch}
            setValue={setValue}
            trigger={trigger}
          />
          <SubscriptionType
            title="No charge"
            type="Free"
            icon="/assets/og_icons/Name Tag-1.svg"
            iconActive="/assets/og_icons/Name Tag-2.svg"
            watch={watch}
            setValue={setValue}
            trigger={trigger}
          />
        </ul>
        {errors.type && <MessageHelperComp isError={true} message={errors.type.message} />}
        <p className="text-sm mt-6 mb-2">Pricing details</p>
        <CustomField label="Name" name="name" watch={watch} errors={errors}>
          <Input
            name="name"
            value={watch('name')}
            register={register}
            type="text"
            placeholder="Name membership / payment"
            className="rounded-none border-none p-0 sm:text-right bg-transparent group-hover:opacity-80 transition-all"
          />
        </CustomField>
        <CustomField label="Price" name="price" watch={watch} errors={errors}>
          {watch('type') === MEMBERSHIP_TYPE.FREE ? (
            <div className="text-[14px] sm:text-right">Free</div>
          ) : (
            <AmountInput
              className="rounded-none"
              inputClassName="rounded-none border-none p-0 sm:text-right bg-transparent group-hover:opacity-80 transition-all"
              prefix="$"
              name="price"
              value={watch('price')}
              placeholder="$ Price"
              onChange={(value: string) => {
                const price = Number(value.replace('$', ''))
                setValue('price', price)
                trigger().then()
              }}
            />
          )}
        </CustomField>
        <CustomField
          label="Amount"
          description="Enter the number of items available. Leave empty for unlimited."
          name="amount"
          watch={watch}
          errors={errors}
        >
          <AmountInput
            className="rounded-none"
            inputClassName="rounded-none border-none p-0 sm:text-right bg-transparent group-hover:opacity-80 transition-all"
            name="amount"
            register={register}
            value={watch('amount')}
            placeholder="0"
          />
        </CustomField>
        {watch('type') !== MEMBERSHIP_TYPE.FREE && watch('type') !== MEMBERSHIP_TYPE.PAYMENT && (
          <CustomField
            label="Cancellation terms"
            description="How many days notification is required to cancel? Leave empty for 0 days."
            name="cancellationPeriod"
            watch={watch}
            errors={errors}
          >
            <div className="flex gap-1 items-center justify-end">
              <AmountInput
                className="rounded-none"
                inputClassName="w-[30px] rounded-none border-none p-0 text-right bg-transparent group-hover:opacity-80 transition-all"
                name="cancellationPeriod"
                register={register}
                value={watch('cancellationPeriod')}
                placeholder="0"
              />
              <span className="text-[14px]">days</span>
            </div>
          </CustomField>
        )}
        <CustomField
          label="Pricing details"
          name="pricingDescription"
          watch={watch}
          errors={errors}
        >
          <Input
            className="rounded-none border-none p-0 sm:text-right bg-transparent group-hover:opacity-80 transition-all"
            name="pricingDescription"
            value={watch('pricingDescription')}
            register={register}
            placeholder="Pricing description • max 100 characters"
          />
        </CustomField>
        <p className="text-sm mt-6 mb-2">Membership details</p>
        <Input
          type="textarea"
          className={clsx(
            'border-SeabiscuitLightThemeColorD3',
            watch('description') && 'bg-SeabiscuitGrayThemeColor'
          )}
          name="description"
          value={watch('description')}
          register={register}
          placeholder="Describe membership benefits"
        />
        {errors.description && (
          <MessageHelperComp isError={true} message={errors.description.message} />
        )}
        <p className="text-sm mt-6 mb-2">Terms and conditions</p>
        <Input
          type="textarea"
          className={clsx(
            'border-SeabiscuitLightThemeColorD3',
            watch('termsAndConditions') && 'bg-SeabiscuitGrayThemeColor'
          )}
          name="termsAndConditions"
          value={watch('termsAndConditions')}
          register={register}
          placeholder="Enter membership terms and conditions"
        />
        {errors.termsAndConditions && (
          <MessageHelperComp isError={true} message={errors.termsAndConditions.message} />
        )}
      </div>
    </MainModal>
  )
}
