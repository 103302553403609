import { useEffect, useState } from 'react'
import { sumBy } from 'lodash'
import { where } from 'firebase/firestore'

// Component imports
import ConfirmationCompsWrapper from './ConfirmationCompsWrapper'
import { FEES_CATEGORY_CONST } from '../../../../../../components/events/views/details/EventDetailsViewComponentFees'

import FirestoreService from '../../../../../../services/firestoreService'

import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import {
  selectFeesTabDataR,
  selectPayTabGrandTotalAccordion,
  selectRegisterTabData,
  selectRegistration,
  selectTicketTabData,
  updatePayTabGrandTotalAccordion,
} from '../../../../../../store/registration/registrationSlice'
import { selectedEvent as selectedEventGetter } from '../../../../../../store/events/eventsSlice'
import { selectUserId } from '../../../../../../store/user/userSlice'

import { IManageInfo } from '../../../../event-registration-tabs/EventRegistrationTabs'
import { IRegistrationByDayInterface } from '../../../../../../models/registrations-by-day/registrationByDay.interface'
import {
  IFeesTab,
  IRegisterTab,
  ITicketTab,
} from '../../../../../../models/event-registered-users/event-registered-users.interface'
import { IRecipientInterface } from '../../../../../../models/recipients/recipients.interface'
import { RecipientModel } from '../../../../../../models/recipients/recipients'


import { CONST } from '../../../../../../const/const'

export type TGrandTotal = {
  total: number
  tickets: number
  stallFees: number
  registrationFees: number
  salesTax: number
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const SelectedEventGrandTotal = ({
  toggle,
  splitPayment,
  manageInfo,
  isManage,
}: {
  toggle: string
  grandTotal: TGrandTotal
  splitPayment?: boolean
  manageInfo: IManageInfo
  isManage?: boolean
}) => {
  const dispatch = useAppDispatch()

  // Hooks and vars
  const [open, setOpen] = useState(false)
  const grandTotalAccordionData = useAppSelector(selectPayTabGrandTotalAccordion)
  const feesTabData = useAppSelector(selectFeesTabDataR)
  const selectedEvent = useAppSelector(selectedEventGetter)
  const registerTabData = useAppSelector(selectRegisterTabData)
  const ticketTabData = useAppSelector(selectTicketTabData)
  const userId = useAppSelector(selectUserId)
  const registration = useAppSelector(selectRegistration)

  const [recipient, setRecipient] = useState<IRecipientInterface | null>(null)

  useEffect(() => {
    if (splitPayment || !splitPayment) {
      setOpen(true)
    }
  }, [splitPayment])

  const getRecipient = async () => {
    const recipientSnapshots = await FirestoreService.filterItems(COLLECTIONS.RECIPIENT.NAME, [
      where(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.REGISTRATION_DOC_ID.KEY,
        '==',
        registration?.id
      ),
      where(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY,
        '==',
        userId
      ),
    ])
    if (recipientSnapshots.size > 0) {
      setRecipient(RecipientModel.fromFirestoreDoc(recipientSnapshots.docs[0]).toObject())
    }
  }
  useEffect(() => {
    getRecipient().then()
  }, [registration?.id, userId])

  const getGrandTotal = (
    registerTabData_: IRegisterTab[],
    feesTabData_: IFeesTab[],
    ticketsData_: ITicketTab[]
  ) => {
    let recipientIds: string[] = []
    const registrationsByDay: IRegistrationByDayInterface[] = []
    let governanceFeesItems: IFeesTab[] = []
    const horsesIds: string[] = []
    const registrationsByDayHorsesIds: string[] = []

    registerTabData_.forEach((register) => {
      register.registrationsByDay.forEach((registrationByDay) => {
        registrationsByDay.push(registrationByDay)
        if (registrationByDay.recipientId) recipientIds.push(registrationByDay.recipientId)
      })

      register.children?.forEach((child) => {
        child.registrationsByDay.forEach((registrationByDay) => {
          registrationsByDay.push(registrationByDay)
          if (registrationByDay.recipientId) recipientIds.push(registrationByDay.recipientId)
        })
      })
    })

    const ticketTotal = parseFloat(
      sumBy(
        ticketsData_,
        (ticket) =>
          (ticket.registrationTicket?.selectedUnitsCount ?? 0) *
          (ticket.registrationTicket?.ticketPrice ?? 0)
      ).toFixed(2)
    )

    const feesTotal = parseFloat(
      sumBy(
        feesTabData_,
        (ticket) =>
          (ticket.registrationFees?.selectedUnitsCount ?? 0) *
          (ticket.registrationFees?.feesPrice ?? 0)
      ).toFixed(2)
    )

    feesTabData_.forEach((currFeesTabRow) => {
      if (currFeesTabRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE)
        governanceFeesItems.push(currFeesTabRow)
    })

    registrationsByDay.forEach((registrationByDay) => {
      if (registrationByDay.horseId && !registrationByDay.isSratched) {
        registrationsByDayHorsesIds.push(registrationByDay.horseId)
      }
    })

    const getRegistrationsByDay = (registrationsByDay: IRegistrationByDayInterface[]) => {
      if (registrationsByDay)
        registrationsByDay.forEach((registrationByDay) => {
          if (
            registrationByDay.horseId &&
            !horsesIds.includes(registrationByDay.horseId) &&
            !registrationsByDayHorsesIds.includes(registrationByDay.horseId)
          )
            horsesIds.push(registrationByDay.horseId)
        })
    }

    registerTabData_.forEach((register) => {
      getRegistrationsByDay(register.registrationsByDay)
      register.children?.forEach((child) => {
        getRegistrationsByDay(child.registrationsByDay)
      })
    })

    const governanceTotal = parseFloat(
      sumBy(governanceFeesItems, (fees) => horsesIds.length * (fees?.feesPrice ?? 0)).toFixed(2)
    )

    let qualifyFee = 0
    registrationsByDay.forEach((register) => {
      if (register.isQualify) qualifyFee += Number(register.qualifyFee)
    })

    const registrationTotal = sumBy(registrationsByDay, 'registrationPrice') + qualifyFee

    let totalPrice = ticketTotal + registrationTotal + feesTotal + governanceTotal
    const salesTax = totalPrice * (selectedEvent?.EventPaymentSettings?.chargeSalesTax ? 0.02 : 0)

    const totalUnits = [...ticketsData_, ...feesTabData_, ...registrationsByDay].length

    return {
      recipientIds,
      feesTotal,
      registrationTotal,
      totalPrice,
      governanceTotal,
      ticketTotal,
      salesTax,
      totalUnits,
    }
  }

  const setGrandTotal = (
    registerTabData_: IRegisterTab[],
    feesTabData_: IFeesTab[],
    ticketsData: ITicketTab[]
  ) => {
    const {
      recipientIds,
      feesTotal,
      registrationTotal,
      totalPrice,
      ticketTotal,
      governanceTotal,
      salesTax,
      totalUnits,
    } = getGrandTotal(registerTabData_, feesTabData_, ticketsData)

    const prevTotalPrice = recipient?.amountPaid ?? 0

    dispatch(
      updatePayTabGrandTotalAccordion({
        recipientIds,
        recipientsCount: recipientIds.length,
        uniqueRecipientCount: recipientIds.length,
        registrationTotal: Number(registrationTotal.toFixed(2)),
        feesTotal: Number(feesTotal.toFixed(2)),
        ticketTotal: Number(ticketTotal.toFixed(2)),
        governanceTotal: Number(governanceTotal.toFixed(2)),
        totalItemsCount: totalUnits,
        salesTax: Number(salesTax.toFixed(2)),
        totalPriceWithoutSalesTax: Number(totalPrice.toFixed(2)),
        totalPrice: Number(totalPrice.toFixed(2)),
        prevTotalPrice: parseFloat(prevTotalPrice.toFixed(2)),
      })
    )
  }
  useEffect(() => {
    if (isManage) {
      setGrandTotal(manageInfo.register, manageInfo.fees, manageInfo.tickets)
    } else {
      setGrandTotal(registerTabData, feesTabData, ticketTabData)
    }
  }, [
    isManage,
    manageInfo.fees,
    manageInfo.register,
    manageInfo.tickets,
    registerTabData,
    feesTabData,
    ticketTabData,
    recipient,
  ])

  return (
    <ConfirmationCompsWrapper
      title="Grand Total"
      accordion={{ open, setOpen }}
      disableEditBtn={true}
      toggleAccordion={toggle}
      grandTotal={grandTotalAccordionData?.totalPrice}
      selected_units={`$${grandTotalAccordionData?.totalItemsCount}`}
      showList={!!grandTotalAccordionData?.totalItemsCount}
      colsClassName={{
        0: '!w-3/4  flex items-center',
        1: '!w-1/4  flex items-center',
      }}
      cols={[
        { title: '', tooltipText: '' },
        { title: '', tooltipText: '' },
      ]}
      hideToggleIcon={false}
    >
      <div className="flex items-start justify-start flex-col w-full mb-2">
        {isManage && (
          <div className="flex items-start justify-start w-full mb-2">
            <p
              className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
            >
              <span>Previous Payment</span>
            </p>
            <p
              className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
            >
              <span>${grandTotalAccordionData?.prevTotalPrice}</span>
            </p>
          </div>
        )}
        <div className="flex items-start justify-start w-full mb-2">
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Registrations</span>
          </p>
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
          >
            <span>${grandTotalAccordionData?.registrationTotal}</span>
          </p>
        </div>

        {/* Fees total */}
        <div className="flex items-start justify-start w-full mb-2">
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Fees</span>
          </p>

          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
          >
            <span>${grandTotalAccordionData?.feesTotal}</span>
          </p>
        </div>

        {/* governanceTotal total */}
        <div className="flex items-start justify-start w-full mb-2">
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Mandatory Fees</span>
          </p>

          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
          >
            <span>${grandTotalAccordionData?.governanceTotal}</span>
          </p>
        </div>

        {/* Tickets total */}
        <div className="flex items-start justify-start w-full mb-2">
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Tickets</span>
          </p>
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
          >
            <span>${grandTotalAccordionData?.ticketTotal}</span>
          </p>
        </div>

        {/* Sales Taxs */}
        <div className="flex items-start justify-start w-full mb-2">
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Sales Tax</span>
          </p>{' '}
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
          >
            <span>${grandTotalAccordionData?.salesTax?.toFixed(2)}</span>
          </p>
        </div>

        {/* Great grand total */}

        <div className="flex items-start justify-start w-full mb-2 font-semibold">
          <p
            className={`text-white border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGreenThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Grand total</span>
          </p>{' '}
          <p
            className={`text-white border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGreenThemeColor capitalize text-center`}
          >
            <span className={'whitespace-nowrap'}>${grandTotalAccordionData?.totalPrice}</span>
          </p>
        </div>
      </div>
    </ConfirmationCompsWrapper>
  )
}

export default SelectedEventGrandTotal
