import React, { FC } from 'react'

import {
  Control,
  Controller,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form'
import Select from 'react-select'
import clsx from 'clsx'

import Input from '../../../../common/inputs/Input'
import MessageHelperComp from '../../../../../helpers/MessageHelper'
import CustomDatePicker from '../../../../common/inputs/CustomDatePicker'
import GlobalInputComponent from '../../detail/Components/GlobalInputComponent'

import { getReactPickerDate } from '../../../../../models/interface.helper'

import { Format, Hours, Minutes } from '../../../../../fakeData/TimeList'
import { MODAL_CONSTS } from '../../../../../const/modal-const'

const TAG_OPTIONS = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Flexible', value: 'flexible' },
]

interface ScheduleItemProps {
  index: number
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  register: UseFormRegister<any>
  trigger: UseFormTrigger<any>
  control: Control<any>
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<any>
  errors: any
  remove?: UseFieldArrayRemove
  areAllFieldsValid: (index: number) => boolean
  locationOptions: { label: string; value: string }[]
  selector: 'custom' | 'registrationFees'
}

export const ScheduleItem: FC<ScheduleItemProps> = ({
  index,
  handleModal,
  register,
  trigger,
  control,
  watch,
  setValue,
  errors,
  areAllFieldsValid,
  locationOptions,
  remove,
  selector,
}) => {
  let valid = areAllFieldsValid(index)

  const openAddNotesModal = (noteInputRef: any) => {
    handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef,
      setValue: setValue,
      trigger,
    })
  }

  return (
    <div
      className={clsx(
        'mt-4 w-full relative border border-SeabiscuitLightThemeColorD3 rounded-lg flex flex-wrap gap-y-2 items-center justify-between px-4 py-2',
        valid ? 'bg-SeabiscuitGrayThemeColor' : 'bg-white border-SeabiscuitLightThemeColorD3'
      )}
    >
      <div className="relative w-full sm:w-[48%] lg:w-[25%]">
        <Input
          placeholder="Name custom time"
          register={register}
          name={`${selector}.${index}.name`}
          value={watch(`${selector}.${index}.name`) as any}
          className="p-0 border-none bg-transparent"
        />

        {!!errors?.[selector]?.[index]?.name?.message ? (
          <MessageHelperComp
            className="absolute z-0 bottom-0 left-0 translate-y-full"
            isError={true}
            message={errors?.[selector]?.[index]?.name?.message}
          />
        ) : null}
      </div>
      <div className="relative w-full sm:w-[48%] lg:w-[15%]">
        <Controller
          control={control}
          name={`${selector}.${index}.location`}
          render={({ field: { value } }) => {
            return (
              <Select
                isDisabled={locationOptions?.[0]?.label?.length <= 0}
                className="p-0 bg-transparent border-none transition-all hover:opacity-70"
                classNamePrefix="entries-select"
                isClearable={false}
                isSearchable={false}
                placeholder="Facility"
                value={value ? { label: value, value: value } : null}
                options={locationOptions}
                onChange={(newValue) => {
                  if (newValue) setValue(`${selector}.${index}.location`, newValue.value)
                }}
                menuPlacement="auto"
                menuPortalTarget={document.body}
              />
            )
          }}
        />
        {!!errors?.[selector]?.[index]?.location?.message ? (
          <MessageHelperComp
            className="absolute z-0 bottom-0 left-0 translate-y-full"
            isError={true}
            message={errors?.[selector]?.[index]?.location?.message}
          />
        ) : null}
      </div>
      <div className="relative w-full sm:w-[48%] lg:w-[13%]">
        <Controller
          name={`${selector}.${index}.startDate`}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <CustomDatePicker
                onChange={onChange}
                onBlur={onBlur}
                targetId="fieldContainer"
                selected={getReactPickerDate(value)}
                peekNextMonth
                dropdownMode="select"
                showMonthDropdown
                minDate={new Date()}
                showYearDropdown
                placeholderText="Day"
                className="p-0 border-none bg-transparent"
                dateFormat="EEEE"
              />
            )
          }}
        />

        {!!errors?.[selector]?.[index]?.startDate?.message ? (
          <MessageHelperComp
            className="absolute z-0 bottom-0 left-0 translate-y-full"
            isError={true}
            message={errors?.[selector]?.[index]?.startDate?.message}
          />
        ) : null}
      </div>
      <div className="relative w-full sm:w-[48%] lg:w-[13%]">
        <div className="min-h-[38px] flex items-center text-base start-time-wrapper max-w-[80px]">
          <Controller
            name={`${selector}.${index}.startTimeHours`}
            control={control}
            render={({ field }) => (
              <GlobalInputComponent
                className="flex-1 !p-0 flex items-center border-none outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-transparent"
                placeholder="00"
                type="select"
                value={Hours}
                control={control}
                isTimeSelector
                isdisableInput
                inputName={field.name}
              />
            )}
          />
          :
          <Controller
            name={`${selector}.${index}.startTimeMinutes`}
            control={control}
            render={({ field }) => (
              <GlobalInputComponent
                className="flex-1 !p-0 flex items-center border-none outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-transparent"
                placeholder="00"
                type="select"
                value={Minutes}
                isTimeSelector
                isdisableInput
                control={control}
                inputName={field.name}
              />
            )}
          />
          <Controller
            name={`${selector}.${index}.startTimeFormat`}
            control={control}
            render={({ field }) => (
              <GlobalInputComponent
                className="flex-1 !p-0 flex items-center border-none outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-transparent"
                placeholder="AM"
                type="select"
                value={Format}
                isTimeSelector
                isdisableInput
                control={control}
                inputName={field.name}
              />
            )}
          />
        </div>
      </div>
      <div className="relative w-full sm:w-[48%] lg:w-[13%]">
        <Controller
          name={`${selector}.${index}.tag`}
          control={control}
          render={({ field: { value } }) => {
            return (
              <Select
                classNamePrefix="entries-select"
                isClearable={false}
                isSearchable={false}
                menuPlacement="auto"
                placeholder="Tag"
                value={value ? { label: value, value: value } : null}
                options={TAG_OPTIONS}
                className="p-0 bg-transparent border-none transition-all hover:opacity-70 capitalize"
                menuPortalTarget={document.body}
                onChange={(newValue) => {
                  if (newValue) setValue(`${selector}.${index}.tag`, newValue.value)
                }}
              />
            )
          }}
        />
        {!!errors?.[selector]?.[index]?.tag?.message ? (
          <MessageHelperComp
            className="absolute z-0 bottom-0 left-0 translate-y-full"
            isError={true}
            message={errors?.[selector]?.[index]?.tag?.message}
          />
        ) : null}
      </div>
      <div className="relative w-full sm:w-[48%] lg:w-[15%]">
        <label className="text-[14px] flex items-center">
          <Input
            readOnly
            placeholder="Enter note"
            register={register}
            name={`${selector}.${index}.note`}
            onClick={(e) => openAddNotesModal(e.target)}
            value={watch(`${selector}.${index}.note`)}
            className="p-0 underline bg-transparent border-none text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md flex items-center cursor-pointer hover:no-underline overflow-hidden truncate"
          />
        </label>
      </div>
      {remove && (
        <button
          className={clsx(
            'cursor-pointer absolute top-4 -right-1 translate-x-full flex items-center w-6'
          )}
          onClick={() => {
            handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
              removeItem: function () {
                remove(index)
              },
            })
          }}
        >
          <img src="/assets/og_icons/Cancel.svg" className="w-full" alt="remove" />
        </button>
      )}
    </div>
  )
}
