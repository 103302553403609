import { Clear } from '@mui/icons-material'
import { useState } from 'react'
import { MODAL_CONSTS } from '../../const/modal-const'

type Props = {
  show: boolean
  handleModal?: any
  dataToPassOn?: { onSave: (title: string, description: string) => void }
}

const AddPoliciesModal = (props: Props) => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const handleSave = async () => {
    if (title && description) {
      props.dataToPassOn?.onSave(title, description)
      props.handleModal(false, MODAL_CONSTS.ADD_POLICIES_MODAL)
    }
  }
  return (
    <div
      className={`fixed left-0 right-0 top-0 bottom-0 z-10  transition-all ${props.show ? 'visible' : 'invisible'}`}
    >
      <div
        className={`fixed bg-white p-4 rounded-xl z-10 left-[calc(50%_-_1rem)] flex flex-col top-[calc(50%_-_1rem)] translate-x-[-50%] translate-y-[-60%] ${props.show ? '!translate-y-[-50%]' : ''} w-[calc(100%_-_2rem)] max-w-md m-2 opacity-${props.show ? '1' : '0'} transition-all`}
      >
        <div className="overflow-auto max-h-screen">
          <div className="text-right">
            <button onClick={() => props.handleModal(false, MODAL_CONSTS.ADD_POLICIES_MODAL)}>
              {/* <img src="/assets/og_icons/Close.svg" className="w-6" alt="close icon" /> */}
              <Clear
                fontSize={'small'}
                style={{
                  color: 'grey',
                  fontWeight: '400',
                }}
              />
            </button>
          </div>
          <h2 className="m-0 mb-5 text-[25px] font-normal mt-[-1rem] text-SeabiscuitDark200ThemeColor">
            Add Policy
          </h2>
          <>
            <div className="flex mt-2 gap-2 w-full mx-auto mb-3 text-[14px]">
              <input
                type="text"
                className="w-full !outline-0 !ring-0 border border-transparent text-SeabiscuitDark200ThemeColor rounded-md bg-SeabiscuitGrayThemeColor focus:border-gray-100"
                placeholder="Policy Title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <textarea
              className="w-full !outline-0 !ring-0 border border-transparent text-SeabiscuitDark200ThemeColor rounded-md bg-SeabiscuitGrayThemeColor focus:border-gray-100 h-80 placeholder:text-gray-400"
              placeholder={'Enter Policy'}
              onChange={(e) => setDescription(e.target.value)}
            />
          </>
          <div className="w-full flex justify-center">
            <button
              onClick={() => handleSave()}
              className="w-52 mb-2 m-auto flex items-center justify-center py-3 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor focus-visible:outline-none disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor mt-4"
            >
              {' '}
              Add
            </button>
          </div>
        </div>
      </div>
      <div
        className={`fixed left-0 right-0 top-0 bottom-0 bg-black/30 opacity-${props.show ? '1' : '0'} transition-all`}
      ></div>
    </div>
  )
}

export default AddPoliciesModal
