import { NotificationCellProps } from '@knocklabs/react'
import { IMAGE_CONSTS } from '../../const/image-const'
import { useRef } from 'react'

const NotificationCell = ({
  item,
  onOpenModal,
}: NotificationCellProps & { onOpenModal: (anchorEl: HTMLElement, item: any) => void }) => {
  const actor = item.actors?.[0]
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <div className="flex flex-wrap justify-between gap-3 items-center w-full text-SeabiscuitDark200ThemeColor text-[14px] py-5">
      <div className="w-[25%] flex items-center rounded-[12px]">
        <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
          <img
            src={
              actor && 'image' in actor && actor.image
                ? actor.image
                : IMAGE_CONSTS.PLACEHOLDERS.USER
            }
            className="w-11 h-11"
            alt="sender"
          />
        </span>

        <span className="capitalize text-ellipsis overflow-hidden whitespace-nowrap font-bold">
          {actor && 'name' in actor ? actor.name : 'no name'}
        </span>
      </div>

      <div className="w-[50%] flex items-center  rounded-[12px] text-ellipsis overflow-hidden">
        <span
          className=" capitalize text-ellipsis px-2 text-center overflow-hidden whitespace-nowrap"
          title={item.data?.message}
        >
          {item.data?.message}
        </span>
      </div>

      <div className="flex justify-end">
        <button
          ref={buttonRef}
          onClick={() => buttonRef.current && onOpenModal(buttonRef.current, item)}
          className="inline-flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-[14px] text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
        >
          {`Manage`}
        </button>
      </div>
    </div>
  )
}

export default NotificationCell
