import React, { useContext, useEffect, useState } from 'react'

import { AutorenewRounded } from '@mui/icons-material'

import ViewsLoader from '../../../../../loader/ViewsLoader'

import { useAppSelector } from '../../../../../../store/hooks'
import { selectUserReducer } from '../../../../../../store/user/userSlice'
import { selectVisitedUserDetailsR } from '../../../../../../store/profilePage/profilePageSlice'

// import { setPaymentMethods } from '../../../../../../helpers/payment'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'

import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'
import { httpService } from '../../../../../../services/httpService'

import { IUserCards, IUserInterface } from '../../../../../../models/users/user.interface'

import ICard from './types'

import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'

const Card = ({ saved }: { saved: boolean } & ICard['IProps']) => {
  const toastFunction = useToasterHelper()
  const handelModalContext = useContext(HandleModalContext)

  const { userType, profileDetails } = useAppSelector(selectUserReducer)
  const exhibitor = useAppSelector(selectVisitedUserDetailsR)?.profileDetails

  const [selectedCard, setSelectedCard] = useState(0)
  const [cardIdBeingRemoved, setCardIdBeingRemoved] = useState<null | string>(null)
  const [loading, setLoading] = useState(false)
  const [
    userCards,
    // setUserCards
  ] = useState<IUserCards[]>([])

  const getPaymentMethods = async (use: IUserInterface) => {
    setLoading(true)
    // const payments = await setPaymentMethods(use)
    // setUserCards(payments)
    setLoading(false)
  }

  useEffect(() => {
    if (profileDetails?.id && userType === 'organizer') getPaymentMethods(profileDetails).then()
  }, [profileDetails])

  // const onAddCardClick = () => {
  //   if (!saved) handelModalContext?.handleModal?.(true, MODAL_CONSTS.CARD, { add_card: true })
  // }

  const confirmDelete = async (pmId: string) => {
    try {
      await httpService({
        url: 'remove_payment_method',
        method: 'POST',
        data: { paymentMethodId: pmId, userStripeAccountId: exhibitor?.userStripeAccountId },
      })
      setCardIdBeingRemoved(pmId)
      // dispatch(deleteUserCard({ pmId }))
      setCardIdBeingRemoved(null)
      handelModalContext?.handleModal(false, MODAL_CONSTS.REMOVE_USER_CARD_ACCOUNT_MODAL)
    } catch (error) {
      console.log(error, 'error')
      toastFunction.error({
        message: MESSAGES_CONST.UNABLE_TO_REMOVE_CARD,
      })
      handelModalContext?.handleModal(false, MODAL_CONSTS.REMOVE_USER_CARD_ACCOUNT_MODAL)
    }
  }

  const onRemoveCardClick = (pmId: IUserCards['pmId']) => {
    if (!pmId) {
      toastFunction.error({
        message: MESSAGES_CONST.UNABLE_TO_REMOVE_CARD,
      })
    } else {
      handelModalContext?.handleModal(true, MODAL_CONSTS.REMOVE_USER_CARD_ACCOUNT_MODAL, {
        confirmDelete,
        pmId,
      })
    }
  }

  if (!userType || userType === 'organizer') return null

  if (loading) {
    return (
      <div className="relative py-2">
        <ViewsLoader />
      </div>
    )
  }

  return (
    <section className="gap-3 flex flex-col">
      <hr className="my-4"></hr>

      {/*<div className="AccountEmail max-w-[80vw] flex flex-col md:flex-row md:flex-wrap md:items-center">*/}
      {/*  <label className="mr-2 md:mr-8 md:w-1/4 flex">*/}
      {/*    <img src={'assets/og_icons/MagneticCard-1.svg'} className="w-6 h-6 mr-2" alt="cardIcon" />*/}
      {/*    Add credit card*/}
      {/*  </label>*/}
      {/*  <input*/}
      {/*    type="button"*/}
      {/*    value={saved ? 'Click edit to add card' : 'Add card'}*/}
      {/*    disabled={saved}*/}
      {/*    onClick={onAddCardClick}*/}
      {/*    className={`border text-SeabiscuitDark200ThemeColor py-[15px] h-[51px] px-6 rounded-xl text-nr ${saved ? 'border-none text-gray-400' : 'border-SeabiscuitGray500ThemeColor'}`}*/}
      {/*  />*/}
      {/*</div>*/}

      {(userCards ?? []).length > 0 ? (
        <div className="AccountEmail flex flex-wrap items-center">
          <label className="mr-8 md:w-1/4 flex">
            <img
              src={'assets/og_icons/MagneticCard-1.svg'}
              className="w-6 h-6 mr-2"
              alt="cardIcon"
            />
            Cards on file
          </label>
          <div className="allCards mt-2 md:mt-0">
            {(userCards ?? []).map((card: IUserCards, index: number) => {
              return (
                <div className="mb-2 relative flex items-center" key={`UserCard${index}`}>
                  <input
                    type="text"
                    value={`****-****-****-${card?.cardNumber}`}
                    readOnly={true}
                    disabled={saved}
                    placeholder="****-****-****-1234"
                    onFocus={() => setSelectedCard(index)}
                    className={`bg-gray-50 w-80 md:w-96 border ${saved ? 'border-white bg-white ' : 'border-[#D3DAEE] bg-gray-50'} text-SeabiscuitDark200ThemeColor text-nr rounded-xl focus:ring-SeabiscuitDark200ThemeColor focus:border-none p-3.5 ${selectedCard === index && 'border border-SeabiscuitDark200ThemeColor'}`}
                  />
                  {!saved && (
                    <span
                      className="text-SeabiscuitMainThemeColor cursor-pointer ml-4"
                      onClick={() => onRemoveCardClick(card?.pmId)}
                    >
                      {cardIdBeingRemoved === card?.pmId ? (
                        <AutorenewRounded fontSize="small" className="animate-spin" />
                      ) : (
                        <img src={'assets/og_icons/Cancel.svg'} alt="delete" />
                      )}
                    </span>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      ) : null}
    </section>
  )
}

export default Card
