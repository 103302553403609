import { Link } from 'react-router-dom'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import InfiniteScrollDataTable from '../../../../components/common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import PROFILE_DETAIL_CARD_CONST from '../../../../components/pageWise/visitedUserDetails/profile-details-card/profileDetailCard.const'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { myEventsTableStyles } from '../../../myEvent/data/myEventsTableStyles'
import useDatatable from './hooks/useDatatable'
import IUserDatatable from './hooks/useDatatable.types'
import { IconBan } from '../../../../components/icons/IconBan'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const Datatable = () => {
  const datatable = useDatatable()

  const columns = [
    {
      name: '',
      cell: (row: IUserDatatable['IDatatableRow']) => {
        return (
          <div className="flex items-center py-5">
            <span className="w-[45px] h-[45px] block shrink-0 mr-2">
              <img
                src={
                  !!!row?.followingUserProfilePicture
                    ? PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC
                    : row?.followingUserProfilePicture
                }
                className="object-cover w-full h-full rounded-full"
                alt="icon"
              />
            </span>
            <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold capitalize">
              {row?.followingUserName}
            </span>
          </div>
        )
      },
      draggable: false,
      width: '35%',
    },
    {
      name: 'Last event ',
      cell: (row: IUserDatatable['IDatatableRow']) => {
        return (
          <Link
            to={
              row?.lastEvent?.name ? `${CONST.ROUTES.EVENT_DETAILS.URL}/${row?.lastEvent?.id}` : '#'
            }
            className="text-SeabiscuitDark200ThemeColor underline flex items-center"
          >
            <img src="/assets/cp_icons/calender_check.svg" className="mr-2 w-5" alt="calender" />
            {row?.lastEvent?.name ? row?.lastEvent?.name : 'N/A'}
          </Link>
        )
      },
      draggable: false,
      width: '20%',
    },
    {
      name: 'Next event ',
      cell: (row: IUserDatatable['IDatatableRow']) => {
        return (
          <Link
            to={
              row?.nextEvent?.name ? `${CONST.ROUTES.EVENT_DETAILS.URL}/${row?.nextEvent?.id}` : '#'
            }
            className="text-SeabiscuitDark200ThemeColor underline flex items-center"
          >
            <img src="/assets/og_icons/Tear-OffCalendar.svg" className="mr-2 w-6" alt="calender" />
            {row?.nextEvent?.name ? row?.nextEvent?.name : 'N/A'}
          </Link>
        )
      },
      draggable: false,
      width: '25%',
    },
    {
      name: 'View Profile',
      cell: (row: IUserDatatable['IDatatableRow']) => {
        return (
          <Link
            to={
              row?.followingUserId
                ? `${CONST.ROUTES.USER_PROFILE.URL}/${row?.followingUserId}`
                : '#'
            }
            className="text-center mr-[-8px] w-[80px] h-[35px] flex items-center py-2 !ring-0 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-[#F6F7FB] hover:text-[#f7074f] focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-[#F7074F0D] focus:ring-SeabiscuitMainThemeColor justify-center"
          >
            {`More >`}
          </Link>
        )
      },
      draggable: false,
      width: '10%',
    },
    {
      name: 'Block User',
      cell: (row: IUserDatatable['IDatatableRow']) => {
        if (datatable.blockLoading) {
          return <p>Loading ....</p>
        }
        if (!row.colId) {
          return null
        }
        if (row.isFollowedBlocked) {
          return (
            <button
              onClick={() => datatable.blockUserToggle(false, row.colId!)}
              disabled={datatable.blockLoading}
              className={'text-[#f7074f]'}
            >
              Blocked
            </button>
          )
        }
        return (
          <button
            disabled={datatable.blockLoading}
            onClick={() => datatable.blockUserToggle(true, row.colId!)}
            className={
              'group w-[35px] min-w-[35px] h-[35px] flex items-center justify-center border-[1px] rounded-[8px] border-solid border-[#D3DAEE] hover:border-[#f7074f] [&>svg]:fill-[#122B46]'
            }
          >
            <IconBan />
          </button>
        )
      },
      width: '10%',
    },
  ]

  if (datatable.loading)
    return (
      <div className="flex justify-center items-center min-h-[300px]">
        <ViewsLoader
          className="flex items-center w-full justify-center min-h-[30vh] mt-3"
          size="lg"
          color="red"
        />
      </div>
    )

  return (
    <InfiniteScrollDataTable
      fetchMore={datatable.fetchMore}
      columns={columns}
      hasMore={datatable.hasMore}
      fixedHeader={true}
      data={datatable.data}
      progressPending={datatable.loading}
      progressComponent={<ViewsLoader size="xl" color="red" />}
      noDataComponent={
        <DataNotAvailable
          mode="graphic"
          imageAlt="persons in round frames"
          upperTextClassName="!text-SeabiscuitDark200ThemeColor"
          upperTextLink={CONST.ROUTES.PROFILE_SETTINGS.URL}
          imageSource={'assets/placeholders/teamMemberPlaceholder.svg'}
          upperText={MESSAGES_CONST.DO_NOT_HAVE_FOLLOWERS}
          bottomText={MESSAGES_CONST.ADD_FIRST_TEAM_MEMBER}
        />
      }
      className="transition-all"
      customStyles={{
        ...myEventsTableStyles,
        headRow: {
          style: {
            minHeight: 'unset',
            borderColor: '#D3DAEE',
          },
        },
      }}
      noHeader={true}
    />
  )
}

export default Datatable
