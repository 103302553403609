const MESSAGES_CONST = {
  EVENTS_LOADING: 'Loading',
  COMING_SOON: 'Feature coming soon...',
  USER_NOT_FOUND: "This account doesn't exist. Please sign up or try again",
  NO_EVENTS_FOUND: 'No events found',
  NO_HORSES_FOUND: 'No horses found',
  NO_FILE_SELECTED: 'No file selected',
  NEWS_ADDED: 'News added successfully',
  ACCOUNT_ADDED: 'Account added successfully',
  NEWS_UPDATED: 'News updated successfully',
  DEAL_ADDED: 'Deal added successfully',
  DEAL_UPDATED: 'Deal updated successfully',
  PRODUCT_ADDED: 'Product added successfully',
  PRODUCT_DELETED: 'Product deleted successfully',
  PRODUCT_UPDATED: 'Product updated successfully',
  PRODUCTS_UPDATED: 'Products updated successfully',
  DEALS_UPDATED: 'Deals updated successfully',
  INVALID_PASSWORD: 'Invalid email & password. Please try again',
  TICKETS_NOT_SENT_SINCE_DETAILS_WERENT_FILLED:
    "Tickets weren't sent you can find them on events page",
  TICKET_SENT: 'Ticket sent successfully',
  DRAFT_SAVED: 'Draft saved successfully',
  USER_NAME_REQUIRED: 'Username is required',
  EVENT_UPDATED: 'Event updated successfully',
  HORSE_REMOVED: 'Horse removed successfully',
  HORSE_CREATED: 'Horse created successfully',
  HORSE_TEAM_MEMBER_CREATED: 'Horse and Team Member created successfully',
  TEAM_MEMBER_CREATED: 'Team Member created successfully',
  USER_CREATED: 'User created successfully',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  SOMETHING_WENT_WRONG_PAYMENT: 'Something went wrong with payment info',
  USER_NAME_EXISTS: 'User name already exists',
  HORSES_REMOVED: 'Horses removed successfully',
  WILL_AVAILABLE_SOON: 'Will be available soon',
  STAFF_UPDATED: 'Staff scratched successfully',
  CANNOT_SELECT_HORSE: 'Unable to select horse',
  AMOUNT_REFUNDED: 'Amount refunded successfully',
  UNABLE_TO_OPEN_RECIEPT: 'Unable to open reciept',
  UPDATED_CLAUSES: 'Updated clauses successfully',
  PLEASE_WAIT: 'Please wait',
  EVENT_DUPLICATED: 'Event duplicated successfully',
  FOLLOWED: 'You are now a follower of [USER_NAME]',
  UNFOLLOWED: 'Successfully unfollowed [USER_NAME]',
  PASSWORD_UPDATED: 'Password updated successfully',
  TEMPORARILY_UNAVAILABLE: 'Temporarily unavailable',
  BOOKMARKED_SUCCESSFULLY: 'Bookmarked successfully',
  PIC_UPDATED: 'Profile picture updated successfully',
  PIC_UPLOADED: 'Picture uploaded successfully',
  FILE_UPLOADED: 'File uploaded successfully',
  TEAM_MEMBERS_ADDED: 'Team members added successfully',
  LOGIN_TO_BOOKMARK: 'Please login to bookmark the event',
  INVALID_REGISTRATION_PRICE: 'Registration fee is invalid',
  TEAM_MEMBER_REMOVED: 'Removed the team member successfully',
  USER_NAME_OR_EMAIL_REQUIRED: 'Username or email is required',
  STRIPE_ACCOUNT_DELETE: 'Stripe account deleted successfully',
  ITEMS_SCRATCHED_SUCCESSFULLY: 'Items scratched successfully',
  BOOKMARk_REMOVED_SUCCESSFULLY: 'Bookmark removed successfully',
  HORSE_PIC_UPDATED: 'Horse profile picture updated successfully',
  SELECT_TEAM_MEMBERS_TO_SAVE: 'Please select a team member to save',
  REMOVED_HORSE_TEAM_MEMBER: 'Successfully removed horse team member',
  UNABLE_TO_UPDATE_ACCOUNT_SETTINGS: 'Unable to update account settings',
  TEAM_MEMBERS_LIST_UPDATED: "Updated the team member's list successfully",
  FETCHED_REGISTRATION_DETAILS: 'Fetched registration details successfully',
  ENABLE_REGISTRATION_FEES: 'Registration fee is disabled please enable that',
  SINGLE_CAN_BE_SCRATCHED: 'Items of only one tab can be scratched at once',
  CONNECT_STATUS_IN_PENDING: 'Your stripe account is currently under processing',
  NO_EVENTS_FOUND_AS_PER_SEARCH: 'No events found that meet your search parameters',
  REMOVE_TEAM_MEMBERS: "Remove horse's team members first in order to remove the horse.",
  HORSE_TEAM_MEMBER_REMOVED: "Horse's team member removed successfully.",
  COUNTRY_NOT_FILLED: 'Please select your country in order to set up a Connect account.',
  CANNOT_UPDATE_HORSE: "Data can't be updated since you are not the owner of the horse.",
  FETCHING_REGISTRATION_DETAILS: 'Please wait, we are fetching your registration details',
  FILE_MORE_THAN_10MB_CANT_BE_UPLOADED: 'File of size more than 10 MB, cannot be uploaded',
  MEMBERS_WITHOUT_HORSE:
    'Not showing [COUNT] selected [MEMBER], since horse was not assigned to them',
  EVENT_PUBLISHED: 'Event published',
  PLEASE_CONNECT_STRIPE:
    'In order to publish this event, please connect to your Stripe account in settings',
  INVALID_REGISTRATION_OPEN_DATE:
    'The date for the event has expired! Please change registration start & end date.',
  ACCOUNT_SETTINGS_UPDATED: 'Account settings updated successfully',
  USER_ALREADY_EXISTS_WITH_EMAIL: 'User already exists with the given email address',
  NO_TEAM_MATES_FOUND: 'No team mates found',
  CARDADDED: 'Card added succesfully.',
  UNABLE_TO_REMOVE_CARD: 'Unable to remove card.',
  HORSE_ADDED_SUCCESSFULLY: 'Horse added successfully',
  HORSES_ADDED_SUCCESSFULLY: 'Horses added successfully',
  PLEASE_CREATE_HORSE_FIRST: 'Please create horse first',
  TEAM_UPDATED_SUCCESSFULLY: 'Team updated successfully',
  HORSE_SELECT_VALIDATION: 'Please select a horse to add',
  PLEASE_ADD_HORSES: 'Please add at least one horse to proceed next',
  LINEAGE_UPDATE_SUCCESSFULLY: 'Lineage updated successfully',
  HORSE_UPDATE_SUCCESSFULLY: 'Horse data updated successfully',
  PLEASE_ADD_TEAM_MEMBERS: 'Please add a team member to proceed next',
  COMPETITOR_UPDATE_SUCCESSFULLY: 'Competitor paperwork updated successfully',
  COMPETITOR_PAPERWORK_UPDATED_SUCCESSFULLY: 'Competitor paperwork updated successfully',
  DRAFT_EXISTS_BUT_HORSES_DOESNT: 'Please add horses in order to register',
  DRAFT_EXISTS_BUT_MEMBERS_DOESNT:
    'Resettled the tabs since you may have removed your team members',
  UNABLE_TO_REMOVE_FILE: 'Unable to remove file',
  PROFILE_UPDATED: 'Profile updated successfully',
  UNABLE_TO_UPDATE_EVENT: 'Unable to update event',
  UNABLE_TO_ADDCARD_TO_STRIPE: 'Unable to add card to stripe',
  ENDORSEMENT_LETTER_UPLOAD: 'Endorsement letter uploaded successfully',
  IMAGE_REMOVED: 'Image removed successfully',
  ENDORSEMENT_LETTER_REMOVED: 'Endorsement letter removed successfully',
  PAYMENT_DONE: 'Paid successfully',
  PLEASE_SELECT_CARD: 'Please select a card',
  REQUIRED_FIELD: 'This is a required field',
  SIGNED_SUCCESSFULLY: 'Document signed successfully',
  SEND_SIGN_EMAIL: 'Sending Email to signatories',
  TICKETS_SENT: 'Tickets sent successfully',
  SEND_TICKET_TO_RECEIVER:
    'Dont navigate or refresh, we are sending the bought tickets to receivers',
  PAID_DASH_AMOUNT: 'Paid [AMOUNT] successfully',
  REMOVING_UNSAVED_FILES: 'Removing unsaved files',
  FILE_ALREADY_REMOVED: 'File may already have been removed',
  PLEASE_ADD_UNITS: 'Please add some units to be able to pay',
  YOU_MAY_HAVE_UNSAVED_CHANGES: 'You may have unsaved changes',
  REMOVED_UNSAVED_FILES: 'Removed unsaved files successfully',
  MEMBER_ADDED_TO_WAITLIST: 'Member added to waitlist successfully',
  ERROR_MEMBER_ADD_TO_WAITLIST: 'Please add at least one member to waitlist',
  CLINIC_SCREEN_EMPTY_MSG: 'No clinics have been created',
  USER_LEGAL_CLAUSE_DOC_CREATED: 'Clause created successfully',
  USER_LEGAL_CLAUSE_DOC_UPDATED: 'Clause updated successfully',
  ADD_MINIMUM_ONE_TEAM_MEMBER: 'Please add minimum one team member',
  UNLICENCED_LICENCED_MESSAGE: 'No events have been created. Check back soon!',
  NO_ITEM_SELECTED_TO_SCRATCH: 'Please select at least one item to proceed',
  NO_SPECTATOR_TICKET_SELECTED: 'No spectator tickets selected',
  ADD_FIRST_TEAM_MEMBER: '',
  SPECTATOR_TICKET_UPDATE: 'Tickets updated successfully',
  SPECTATOR_TICKET_ADDED: 'Tickets added successfully',
  PAYOUT_REQUEST_SUBMIT_SUCCESSFULLY: 'Your payout request submitted successfully',
  FOLLOWING_NOTFOUND_UPPER_TEXT: 'You do not follow any event organizers',
  NOT_FOLLOWING_ANY_TEAM_MEMBERS: 'You are not following any riders',
  DO_NOT_HAVE_FOLLOWERS: "You don't have any followers",
  FOLLOWING_NOTFOUND_LOWER_TEXT: 'When you do, they will appear here',
  CARD_ALREADY_ADDED: 'This card already exists',
  FEES_DATA_NOT_AVAILABLE: 'Fees data not available!',
  EVENT_DATA_NOT_AVAILABLE: 'Event details data not available!',
  STAFF_DATA_NOT_AVAILABLE: 'Staff data not available!',
  SCHEDULE_DATA_NOT_AVAILABLE: 'Schedule data not available!',
  POLICIES_DATA_NOT_AVAILABLE: 'Policies data not available!',
  PAPERWORK_DATA_NOT_AVAILABLE: 'Paperwork data not available!',
  TICKETS_DATA_NOT_AVAILABLE: 'Tickets data not available!',
  PDF_NOT_FOUND: 'Pdf not found!',
  SPONSORS_DATA_NOT_AVAILABLE: 'Sponsors data not available!',
  VENDORS_DATA_NOT_AVAILABLE: 'Vendors data not available!',
  NEARBY_BUS_DATA_NOT_AVAILABLE: 'Nearby businesses data not available!',
  SCHEDULE_ADD_ERROR: 'Atleast one schedule should be added!',
  UNABLE_TO_UPDATE_EMAIL:
    "Email can't be updated, email is connected to your stripe connect account and [AVAILABLE_FUNDS] funds are available in your Connect account's [BALANCE_TYPE], Once the funds will be $0 you can update your email",
  UNABLE_TO_REMOVE_ACCOUNT:
    "Your account can not be removed, since [AVAILABLE_FUNDS] funds are available in your Connect account's [BALANCE_TYPE], Once the funds will be $0 you can remove your account",
  FEES_TAB_ERROR: 'Please resolve errors.',
  REMINDER_SENT_MESSAGE: 'Reminder Sent Successfully',
  EVENT_SCORE_UPLOAD: 'Score File is Upload Successfully',
  EVENT_SCORE_UPDATED: 'Updated Successfully',
  SCORE_CARD_NOT_SELECTED: 'Score card and Jungle is not selected',
  EVENT_ORDER_UPDATED: 'Order is Updated Successfully',
  EVENT_SCRATCHED_UPDATED: 'Scratched is Updated Successfully',
  EVENT_SCRATCHED_NOT_UPDATED: 'Scratched is not Updated',
  EVENT_START_UPDATED: 'Start Time is Updated Successfully',
  EVENT_START__NOT_UPDATED: 'Start Time is Wrong',
  EVENT_INCREMENT_UPDATED: 'Increment Time is Updated Successfully',
  VISIBILITY_UPDATED: 'Visibility updated successfully',
  EVENT_INCREMENT_NOT_UPDATED: 'Increment Time is not Updated',
  NO_RECIPIENT: 'No recipient found',
  BILL_NOT_PAYED:
    'Selected riders bill has not been paid. Bills must be paid before a scratch can be made.',
  ALREADY_SCRATCH: 'Selected member already scratched',
  REPORT_SUCCESSFULLY_DOWNLOADED: 'Report Successfully Downloaded',
  REPORT_EXPORT_IS_READY:
    'Report not ready to download, please fill in at least one Disciplines and Section Codes',
  NOT_REGISTERED: 'Remarks are only able to be viewed by the current logged in Rider.',
  EVENT_SCHEDULE_UPDATED: 'Schedule Time is Updated Successfully',
  REGISTRATION_EVENT_NOT_SIGNED: 'All documents should be  signed',
  REGISTRATION_EVENT_RIDER_IS_MINOR: 'You are logged in as a minor and have not add a Guardian in your team paperwork.',
  REGISTRATION_EVENT_GUARDIAN_IS_MINOR: 'Added Guardian is minor, please add another Guardian',
}

export { MESSAGES_CONST }
