// ############################################################
/**
 * Contains the model to hold the events (and perform migrations
 * if it comes the case)
 */
// ############################################################

import { IBreak, IEventInterface, IQuestion } from './event.interface'
import { CONST } from '../../const/const'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { cloneDeep } from 'lodash'

const COLLECTIONS_EVENTS_LATEST = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */
export class EventModel extends ModelBaseModel<IEventInterface> implements IModelBaseModel {
  public v: number
  public type: string
  public city: string
  public state: string
  public tags: string[]
  public country: string
  public category: string
  public waitlist: boolean
  public eventName: string
  public id: string | null
  public isLicensed: boolean
  public owner: string | null
  public isPublished: boolean
  public locationHash: string
  public eventImages: string[]
  public waitlistCount: number
  public eventPriceUSD: number
  public competitionLat: string
  public subType: string | null
  public eventFormState: string
  public competitionLong: string
  public registeredCount: number
  public eventNameNGram: string[]
  public eventPriceUSDRange: string
  public eventStartDateRange: string
  public eventStartDateYYYYMMDD: string
  public eventCardCoverImageUrl: string
  public registeredUsersImages: string[]
  public eventCityStateCountryKey: string
  public status: IEventInterface['status']
  public eventEndDate: string | Date | null
  public registrationAvailableCount: number
  public eventStartDate: string | Date | null
  public registrationOpenDate: string | Date | null
  public registrationCloseDate: string | Date | null
  public requiredFields: string[]
  public requiredHorseFields: string[]
  public currentFeesRevenue: IEventInterface['currentFeesRevenue']
  public ticketItemsCount: IEventInterface['ticketItemsCount']
  public expectedFeesRevenue: IEventInterface['expectedFeesRevenue']
  public soldTicketItemsCount: IEventInterface['soldTicketItemsCount']
  public currentTicketRevenue: IEventInterface['currentTicketRevenue']
  public expectedTicketRevenue: IEventInterface['expectedTicketRevenue']
  public totalRegistrationRevenue: IEventInterface['totalRegistrationRevenue']
  public currentRegistrationRevenue: IEventInterface['currentRegistrationRevenue']
  public expectedRegistrationRevenue: IEventInterface['expectedRegistrationRevenue']
  public totalStallsCount: IEventInterface['totalStallsCount']
  public soldStallsCount: IEventInterface['soldStallsCount']
  public totalFeedCount: IEventInterface['totalFeedCount']
  public soldFeedCount: IEventInterface['soldFeedCount']
  public totalBeddingCount: IEventInterface['totalBeddingCount']
  public soldBeddingCount: IEventInterface['soldBeddingCount']
  public totalOtherCount: IEventInterface['totalOtherCount']
  public soldOtherCount: IEventInterface['soldOtherCount']
  public registeredCountIncludingUnpaid: IEventInterface['registeredCountIncludingUnpaid']
  public breaks: IBreak[]
  public questions: IQuestion[]
  public created: Date
  public modified: Date

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Constructs an instance of the class with an object that adheres to the
   * IEventsInterface interface
   *
   * @param obj
   * Object that adheres to the IEventsInterface interface
   */
  public constructor(obj?: IEventInterface) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null
    this.locationHash = obj?.locationHash ?? ''
    this.eventFormState =
      obj?.eventFormState ?? CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.VALUE
    this.eventCardCoverImageUrl = obj?.eventCardCoverImageUrl ?? ''
    this.status = obj?.status ?? COLLECTIONS_EVENTS_LATEST.FIELDS.STATUS.VALUE.DRAFT
    this.type = obj?.type ?? COLLECTIONS_EVENTS_LATEST.FIELDS.TYPE.VALUE.REGULAR
    this.ticketItemsCount =
      obj?.ticketItemsCount ?? COLLECTIONS_EVENTS_LATEST.FIELDS.TICKET_ITEMS_COUNT.VALUE.DEFAULT
    this.soldTicketItemsCount =
      obj?.soldTicketItemsCount ??
      COLLECTIONS_EVENTS_LATEST.FIELDS.SOLD_TICKET_ITEMS_COUNT.VALUE.DEFAULT
    this.subType = obj?.subType ?? null
    this.category = obj?.category ?? ''
    this.tags = obj?.tags ?? []
    this.eventCityStateCountryKey =
      obj?.eventCityStateCountryKey ??
      COLLECTIONS_EVENTS_LATEST.FIELDS.EVENT_CITY_STATE_COUNTRY_KEY.VALUE.UNKNOWN
    this.eventPriceUSDRange =
      obj?.eventPriceUSDRange ??
      COLLECTIONS_EVENTS_LATEST.FIELDS.EVENT_PRICE_USD_RANGE.VALUE.UNKNOWN
    this.eventStartDateRange =
      obj?.eventStartDateRange ??
      obj?.eventStartDateYYYYMMDD ??
      COLLECTIONS_EVENTS_LATEST.FIELDS.EVENT_START_DATE_RANGE.VALUE.UNKNOWN
    this.eventStartDateYYYYMMDD =
      obj?.eventStartDateYYYYMMDD ??
      COLLECTIONS_EVENTS_LATEST.FIELDS.EVENT_START_DATE_YYYY_MM_DD.VALUE.UNKNOWN
    this.eventName = obj?.eventName ?? ''
    this.eventPriceUSD = this.getNum(obj?.eventPriceUSD)
    this.isPublished = obj?.isPublished ?? false
    this.isLicensed = obj?.isLicensed ?? false
    this.registeredCountIncludingUnpaid = this.getNum(
      obj?.registeredCountIncludingUnpaid ??
        COLLECTIONS_EVENTS_LATEST.FIELDS.REGISTERED_COUNT_INCLUDING_UNPAID.VALUE.DEFAULT
    )
    this.registeredCount =
      !isNaN(obj?.registeredCount as any) && obj?.registeredCount ? obj?.registeredCount : 0
    this.registrationAvailableCount = this.getNum(obj?.registrationAvailableCount)
    this.registeredUsersImages = obj?.registeredUsersImages ?? []
    this.waitlistCount = obj?.waitlistCount ?? 0
    this.waitlist = obj?.waitlist ?? false
    this.city = obj?.city ?? COLLECTIONS_EVENTS_LATEST.FIELDS.CITY.VALUE.UNKNOWN
    this.state = obj?.state ?? COLLECTIONS_EVENTS_LATEST.FIELDS.STATE.VALUE.UNKNOWN
    this.country = obj?.country ?? COLLECTIONS_EVENTS_LATEST.FIELDS.COUNTRY.VALUE.UNKNOWN
    this.breaks = obj?.breaks ?? []
    this.questions = obj?.questions ?? []
    this.eventImages = obj?.eventImages
      ? obj?.eventImages.filter((c) => !!c)
      : COLLECTIONS_EVENTS_LATEST.FIELDS.EVENT_IMAGES.VALUE.EMPTY
    this.competitionLong = obj?.competitionLong ?? ''
    this.competitionLat = obj?.competitionLat ?? ''
    this.eventNameNGram = obj?.eventNameNGram ?? []

    this.requiredFields = obj?.requiredFields ?? []
    this.requiredHorseFields = obj?.requiredHorseFields ?? []

    this.currentFeesRevenue = this.getNum(obj?.currentFeesRevenue)
    this.expectedFeesRevenue = this.getNum(obj?.expectedFeesRevenue)
    this.currentTicketRevenue = this.getNum(obj?.currentTicketRevenue)
    this.expectedTicketRevenue = this.getNum(obj?.expectedTicketRevenue)
    this.totalRegistrationRevenue = this.getNum(obj?.totalRegistrationRevenue)
    this.currentRegistrationRevenue = this.getNum(obj?.currentRegistrationRevenue)
    this.expectedRegistrationRevenue = this.getNum(obj?.expectedRegistrationRevenue)
    this.totalStallsCount = this.getNum(obj?.totalStallsCount)
    this.soldStallsCount = this.getNum(obj?.soldStallsCount)
    this.totalFeedCount = this.getNum(obj?.totalFeedCount)
    this.soldFeedCount = this.getNum(obj?.soldFeedCount)
    this.totalBeddingCount = this.getNum(obj?.totalBeddingCount)
    this.soldBeddingCount = this.getNum(obj?.soldBeddingCount)
    this.totalOtherCount = this.getNum(obj?.totalOtherCount)
    this.soldOtherCount = this.getNum(obj?.soldOtherCount)
    this.totalRegistrationRevenue = this.getNum(obj?.totalRegistrationRevenue)
    this.soldTicketItemsCount = this.getNum(obj?.soldTicketItemsCount)

    this.eventStartDate = this.utcTimestamp({
      key: 'eventStartDate',
      value: obj?.eventStartDate ?? null,
    })

    this.eventEndDate = this.utcTimestamp({
      key: 'eventEndDate',
      value: obj?.eventEndDate,
    })

    this.registrationOpenDate = this.utcTimestamp({
      key: 'registrationOpenDate',
      value: obj?.registrationOpenDate,
    })

    this.registrationCloseDate = this.utcTimestamp({
      key: 'registrationCloseDate',
      value: obj?.registrationCloseDate,
    })

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })

    this._calculateEventNameNGrams()
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromObject(obj: any) {
    const item = new EventModel()

    item.v = obj?.v ?? 1
    item.id = obj?.id ?? null
    item.owner = obj?.owner ?? null

    item.eventFormState =
      obj?.eventFormState ?? CONST.UI.EVENTS.CREATE_EDIT.TABS.EVENT_DETAILS.VALUE

    item.eventCardCoverImageUrl = obj?.eventCoverImageUrl ?? ''

    item.status = obj?.status ?? COLLECTIONS_EVENTS_LATEST.FIELDS.STATUS.VALUE.DRAFT

    item.type = obj?.type ?? COLLECTIONS_EVENTS_LATEST.FIELDS.TYPE.VALUE.REGULAR
    item.subType = obj?.subType ?? null

    item.category = obj?.category ?? ''

    item.tags = obj?.tags ?? []

    item.eventCityStateCountryKey =
      obj?.eventCityStateCountryKey ??
      COLLECTIONS_EVENTS_LATEST.FIELDS.EVENT_CITY_STATE_COUNTRY_KEY.VALUE.UNKNOWN
    item.eventPriceUSDRange =
      obj?.eventPriceUSDRange ??
      COLLECTIONS_EVENTS_LATEST.FIELDS.EVENT_PRICE_USD_RANGE.VALUE.UNKNOWN
    item.eventStartDateRange =
      obj?.eventStartDateRange ??
      obj?.eventStartDateYYYYMMDD ??
      COLLECTIONS_EVENTS_LATEST.FIELDS.EVENT_START_DATE_RANGE.VALUE.UNKNOWN
    item.eventStartDateYYYYMMDD =
      obj?.eventStartDateYYYYMMDD ??
      COLLECTIONS_EVENTS_LATEST.FIELDS.EVENT_START_DATE_YYYY_MM_DD.VALUE.UNKNOWN
    item.eventName = obj?.eventName ?? ''
    item.eventPriceUSD = obj?.eventPriceUSD ?? 0
    item.eventStartDate = obj?.eventStartDate ?? new Date()
    item.eventEndDate = obj?.eventEndDate ?? new Date()
    item.isPublished = obj?.isPublished ?? false
    item.isLicensed = obj?.isLicensed ?? false
    item.registeredCount = obj?.registeredCount ?? 0
    item.registrationAvailableCount = obj?.registrationAvailableCount ?? 0
    item.waitlistCount = obj?.waitlistCount ?? 0
    item.waitlist = obj?.waitlist ?? false

    item.city = obj?.city ?? COLLECTIONS_EVENTS_LATEST.FIELDS.CITY.VALUE.UNKNOWN
    item.state = obj?.state ?? COLLECTIONS_EVENTS_LATEST.FIELDS.STATE.VALUE.UNKNOWN
    item.country = obj?.country ?? COLLECTIONS_EVENTS_LATEST.FIELDS.COUNTRY.VALUE.UNKNOWN

    item.competitionLong = obj?.competitionLong ?? ''
    item.competitionLat = obj?.competitionLat ?? ''
    item.eventNameNGram = obj?.eventNameNGram ?? []

    item.registrationOpenDate = item.utcTimestamp({
      key: 'registrationOpenDate',
      value: obj?.registrationOpenDate,
    })

    item.registrationCloseDate = item.utcTimestamp({
      key: 'registrationCloseDate',
      value: obj?.registrationCloseDate,
    })

    item.eventStartDate = item.utcTimestamp({
      key: 'eventStartDate',
      value: obj?.eventStartDate ?? null,
    })

    item.eventEndDate = item.utcTimestamp({
      key: 'eventEndDate',
      value: obj?.eventEndDate,
    })

    item.created = item.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    item.modified = item.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      value: obj?.modified,
    })

    item._calculateEventNameNGrams()
    return item
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  public nGramContainsAllWords(words: string[]) {
    return words.every((value) => this.eventNameNGram.includes(value))
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public setEventName(event_name: string) {
    this.eventName = event_name ?? 'DEFAULT EVENT'
    this._calculateEventNameNGrams()
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  private _calculateEventNameNGrams() {
    const words = this.eventName.split(' ')
    const lowercase_words = words.map((word) => word.toLowerCase())

    let ngram_words = new Set<string>()

    lowercase_words.forEach((word) => {
      let word_iterator = cloneDeep(word)

      while (word_iterator.length > 0) {
        ngram_words.add(cloneDeep(word_iterator))
        word_iterator = word_iterator.slice(0, -1)
      }
    })

    if (this.eventNameNGram.length > 0) {
      this.eventNameNGram = []
    }

    ngram_words.forEach((value) => {
      this.eventNameNGram.push(value)
    })
  }
}
