import { useEffect, useState } from 'react'

// Lists
// Types
import { ITeamMember } from '../../../models/users/user.interface'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectProfileData, selectUserId } from '../../../store/user/userSlice'

import moment from 'moment'
// Styles
import './allEventRootPage.css'

// Helpers
import { useIonRouter } from '@ionic/react'
import { getUserFullName } from '../../../helpers/helpers'

import { selectVisitedUserDetailsR } from '../../../store/profilePage/profilePageSlice'
import {
  setRegisterId,
  setWaitlistId,
} from '../../../store/registerExhibitor/registerExhibitorSlice'
import CompetitorRootSideProfileDisplayTabs from './Tabs/CompetitorRootSideProfileDisplayTabs'
import ProfileHorseDataTab from './Tabs/ProfileHorseDataTab'
import TeamMembersTab from './Tabs/ProfileTeamMembersTab'
import ProfileUserProfileDataTab from './Tabs/ProfileUserProfileDataTab'
import { selectHorses, setHorses } from '../../../store/horses/horseSlice'
import { IHorseData } from '../../../models/horse/horse.interface'
import { useParams } from 'react-router'
import { HorseModel } from '../../../models/horse/horse.model'
import { getConvertedData } from '../../../models/interface.helper'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { where } from 'firebase/firestore'
import { UserHorseMappingModel } from '../../../models/user-horse-mapping/userHorseMapping.model'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'
import customImageComponent from '../../../components/common/CustomImageComponent'
import PROFILE_DETAIL_CARD_CONST from '../../../components/pageWise/visitedUserDetails/profile-details-card/profileDetailCard.const'
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */

const HORSES_MAP_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_HORSE_MAPPING
const HORSES_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES

const ChatAllEventList = ({
  isMyProfile,
  showHorseData,
  setShowHorseData,
}: {
  isMyProfile: boolean
  showHorseData: IHorseData[]
  setShowHorseData: any
}) => {
  // Hooks and vars
  const userId = useAppSelector(selectUserId)
  const [activeTab, setActiveTab] = useState(1)
  const router = useIonRouter()

  const dispatch = useAppDispatch()

  const visitedUserDetails = useAppSelector(selectVisitedUserDetailsR)
  const horseData = useAppSelector(selectHorses)
  const userDocumentData = useAppSelector(selectProfileData)
  const [teamMatesListWithMe, setTeamMatesListWithMe] = useState<ITeamMember[]>([])

  const params = useParams()
  const profileDetails = visitedUserDetails?.profileDetails ?? null
  const teamMembersList = profileDetails?.userTeamMembers ?? []

  const setImageUrl = useState<any>('')[1]
  const setUploadProgress = useState(-1)[1]

  const handlegethosedata = async (userId: any) => {
    let horses_map: IUserHorseMappingInterface[] = []
    let horses: IHorseData[] = []
    let horseIds: string[] = []
    let fetchedHorseSnaps = await FirestoreService.filterItems(HORSES_MAP_COLLECTION.NAME, [
      where(HORSES_MAP_COLLECTION.FIELDS.HORSE_OWNER_ID.KEY, '==', userId),
    ])

    fetchedHorseSnaps.forEach((currDoc) => {
      horses_map.push(getConvertedData(UserHorseMappingModel.fromFirestoreDoc(currDoc).toObject()))
    })

    horses_map.forEach((data) => {
      if (data.horseId) horseIds.push(data.horseId)
    })

    let horsesnaps = await FirestoreService.getItemsUsingIds(HORSES_COLLECTION.NAME, horseIds)

    horsesnaps.forEach((currDoc) => {
      horses.push(getConvertedData(HorseModel.fromFirestoreDoc(currDoc).toObject()))
    })

    dispatch(setHorses(horses))
  }

  useEffect(() => {
    return () => {
      dispatch(setHorses([]))
    }
  }, [])

  const paramsUserid = 'userId' in params ? params.userId : null

  useEffect(() => {
    if (paramsUserid) {
      handlegethosedata(paramsUserid)
    } else if (userId) {
      handlegethosedata(userId)
    }
  }, [userId, paramsUserid])

  useEffect(() => {
    if (profileDetails?.userProfilePicture) {
      setImageUrl(profileDetails?.userProfilePicture)
    } else {
      setUploadProgress(-1)
      setImageUrl('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails?.userProfilePicture])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const UNKNOWN = 'Unknown'
    const owner = (() => {
      const memberName = profileDetails ? getUserFullName(profileDetails) : UNKNOWN
      return {
        memberName: memberName,
        memberId: userId ?? '',
        defaultRole: 'Owner',
        memberProfilePicture: profileDetails?.userProfilePicture ?? '',
        memberStatus: '1',
        memberprofileSynced: false,
        memberAddress: profileDetails?.userAddress ?? '',
        memberEmail: profileDetails?.userEmail ?? '',
        memberShipActive: false,
        memberSafeSupportTraining: false,
        memberCountry: profileDetails?.userNationality ?? '',
      }
    })()

    if (owner) setTeamMatesListWithMe([owner, ...teamMembersList])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMembersList.length, profileDetails?.id])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const cond =
      router?.routeInfo?.routeOptions?.as === 'horse'
        ? 3
        : router?.routeInfo?.routeOptions?.as === 'user'
          ? 1
          : router?.routeInfo?.routeOptions?.as === 'team'
            ? 2
            : 1
    if (cond) {
      setActiveTab(cond)
    }

    return () => {
      dispatch(setRegisterId(null))
      dispatch(setWaitlistId(null))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const setActiveTabVal = (tabToSwitch: number) => {
    setActiveTab(tabToSwitch)
  }

  const userName = profileDetails ? getUserFullName(profileDetails) : null

  return (
    <div className="flex flex-col items-center px-5 bg-[#F6F7FB]">
      <div className="rounded-xl flex flex-col items-center justify-center px-4 py-5 w-[375px] bg-[#F6F7FB]">
        <span className="w-[110px] h-[110px] rounded-full flex justify-center items-center border-[3px] bg-white border-white overflow-hidden">
          {customImageComponent(
            profileDetails?.userProfilePicture
              ? profileDetails.userProfilePicture
              : PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC,
            userName,
            '!w-[calc(100%-5px)] bg-white !h-[calc(100%-5px)] rounded-full'
          )}
        </span>

        <span className="font-bold text-xl mt-3 text-SeabiscuitDark200ThemeColor capitalize">
          {userName}
        </span>
      </div>

      <div className="flex flex-col items-center pt-2 w-full bg-[#F6F7FB]">
        <div className="flex flex-wrap rouded-lg w-full items-start justify-center">
          <div
            className={`grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid ${activeTab === 1 ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'}`}
            onClick={() => setActiveTabVal(1)}
          >
            {activeTab === 1 ? (
              <img src="/assets/cp_icons/User-2.svg" alt="userIcons" />
            ) : (
              <img src="/assets/cp_icons/User-1.svg" alt="userIcons" />
            )}
          </div>
          <div
            className={`grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid ${activeTab === 2 ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'}`}
            onClick={() => setActiveTabVal(2)}
          >
            {activeTab === 2 ? (
              <img src="/assets/cp_icons/User_Groups-2.svg" alt="userIcons" />
            ) : (
              <img src="/assets/cp_icons/User_Groups-1.svg" alt="userIcons" />
            )}
          </div>
          <div
            className={`grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid ${activeTab === 3 ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'}`}
            onClick={() => {
              setActiveTabVal(3)
            }}
          >
            {activeTab === 3 ? (
              <img src="/assets/og_icons/YearofHorse-2.svg" alt="userIcons" />
            ) : (
              <img src="/assets/og_icons/YearofHorse-1.svg" alt="userIcons" />
            )}
          </div>
        </div>

        <div className="scrollable overflow-y-auto w-full md:h-[500px] mt-2 pt-2">
          {isMyProfile ? (
            <div className="content px-2">
              {activeTab === 1 ? (
                <ProfileUserProfileDataTab
                  profileDetails={profileDetails}
                  userDocumentData={userDocumentData}
                />
              ) : null}

              {activeTab === 2 ? (
                <TeamMembersTab tabHeading="Team" team_members={teamMatesListWithMe} />
              ) : null}

              {activeTab === 3 ? (
                !!horseData?.length ? (
                  <ProfileHorseDataTab
                    horses={horseData}
                    setShowHorseData={setShowHorseData}
                    showHorseData={showHorseData[0]}
                  />
                ) : (
                  <span className="w-full text-sm text-SeabiscuitLightParagraphTextColor text-center">
                    No data found
                  </span>
                )
              ) : null}
            </div>
          ) : (
            <div className="content px-2">
              {activeTab === 1 ? (
                <>
                  {+moment(profileDetails?.userDOB).fromNow().split(' ')[0] >= 18 ? (
                    !profileDetails?.userAccountPrivacy ? (
                      <ProfileUserProfileDataTab
                        profileDetails={profileDetails}
                        userDocumentData={userDocumentData}
                      />
                    ) : (
                      <CompetitorRootSideProfileDisplayTabs />
                    )
                  ) : null}
                </>
              ) : null}

              {activeTab === 2 ? (
                teamMatesListWithMe && !!teamMatesListWithMe.length ? (
                  <>
                    {' '}
                    {+moment(profileDetails?.userDOB).fromNow().split(' ')[0] >= 18 ? (
                      !profileDetails?.userAccountPrivacy ? (
                        <TeamMembersTab tabHeading="Team" team_members={teamMatesListWithMe} />
                      ) : (
                        <CompetitorRootSideProfileDisplayTabs />
                      )
                    ) : null}
                  </>
                ) : null
              ) : null}

              {activeTab === 3 ? (
                horseData && !!horseData.length ? (
                  <>
                    {+moment(profileDetails?.userDOB).fromNow().split(' ')[0] >= 18 ? (
                      !profileDetails?.userAccountPrivacy ? (
                        <ProfileHorseDataTab
                          horses={horseData}
                          setShowHorseData={setShowHorseData}
                          showHorseData={showHorseData[0]}
                        />
                      ) : (
                        <CompetitorRootSideProfileDisplayTabs />
                      )
                    ) : null}
                  </>
                ) : null
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChatAllEventList
