// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import HomeCategoriesControlComponent, {
  IFilters,
  TContentType,
} from '../../../components/home/controls/HomeCategoriesControlComponent'
import { IAppState } from '../../../store/system/systemSlice'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface HomeRootPageContentCategoriesSectionProps {
  onTagsChanged?: any
  handleModal?: any
  contentType?: TContentType
  useMode: IAppState['useMode']
  openCreateEventModal?: () => void
  searchHandler?: (data: { value: string; filters: IFilters; tags: (string | null)[] }) => void
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const HomeRootPageContentCategoriesSection: React.FC<HomeRootPageContentCategoriesSectionProps> = (
  props
) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <HomeCategoriesControlComponent
      useMode={props.useMode}
      searchHandler={props.searchHandler}
      contentType={props.contentType || 'events'}
      handleModal={props.handleModal}
      onTagsChanged={props.onTagsChanged}
      openCreateEventModal={props?.openCreateEventModal}
    />
  )
}

HomeRootPageContentCategoriesSection.defaultProps = {
  onTagsChanged: null,
}

export default HomeRootPageContentCategoriesSection
