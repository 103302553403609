import { useState, useEffect } from 'react'
import type { ChannelSort, ChannelFilters, ChannelOptions } from 'stream-chat'
import {
  Channel,
  ChannelList,
  MessageInput,
  MessageList,
  Window,
  useChatContext,
} from 'stream-chat-react'
import EmptyChat from './EmptyChat'
import SearchBar from './SearchBar'
import WriteMessage from './WriteMessage'
import CustomChannelPreview from './CustomChannelPreview'
import CustomChannelHeader from './CustomChannelHeader'
import { useLocation } from 'react-router'
import ProfilePanel from './ProfilePanel'
import { useChat } from './DashboardChat'

export default function ChatStream() {
  const { chatFilters, eventId, userId, showProfile, setShowProfile } = useChat()
  const { setActiveChannel, client } = useChatContext()
  const [emptyChats, setEmptyChats] = useState<boolean | undefined>(undefined)
  const [query, setQuery] = useState('')
  const location = useLocation<{ channelId?: string }>()
  const channelId = location?.state?.channelId

  const sort: ChannelSort = { updated_at: -1 }
  let filters: ChannelFilters = {
    type: 'messaging',
    'member.user.name': query ? { $autocomplete: query } : undefined,
    name: query ? { $autocomplete: query } : undefined,
    members: { $in: [userId] },
    ...chatFilters,
    eventId: eventId ? { $eq: eventId } : undefined,
  }

  const options: ChannelOptions = {
    limit: 10,
  }

  useEffect(() => {
    async function fetchChats() {
      const all_filters: ChannelFilters = {
        type: 'messaging',
        members: { $in: [userId] },
      }
      return client.queryChannels(all_filters, undefined, { limit: 1 })
    }
    fetchChats().then((channels) => setEmptyChats(channels.length === 0))

    const response = client.on((event) => {
      if (event.type === 'notification.added_to_channel') {
        if (emptyChats) setEmptyChats(false)
      }

      if (event.type === 'channel.visible') {
        if (emptyChats) setEmptyChats(false)
      }

      if (event.type === 'channel.hidden') {
        fetchChats().then((channels) => setEmptyChats(channels.length === 0))
      }
    })

    return () => response.unsubscribe()
  }, [userId, emptyChats])

  useEffect(() => {
    if (channelId) {
      const channel = client.getChannelById('messaging', channelId, {}) //.queryChannels(filter, sort, { limit: 1 });
      setActiveChannel?.(channel)
    }
  }, [channelId, setActiveChannel])

  if (emptyChats === undefined) return null

  if (emptyChats) return <EmptyChat />

  return (
    <div className="flex flex-1 flex-row border-b-[1px] border-[#F6F7FB] h-full">
      <div className="flex flex-col basis-1/4 max-w-[300px] border-r-[1px] border-[#F6F7FB] h-full">
        <div className="p-2 border-b-[1px] border-[#F6F7FB]">
          <SearchBar search={query} setSearch={setQuery} />
        </div>

        <ChannelList
          filters={filters}
          sort={sort}
          options={options}
          EmptyStateIndicator={() => null}
          Preview={CustomChannelPreview}
        />
      </div>
      <div className="flex flex-row basis-3/4 grow">
        <Channel EmptyPlaceholder={<EmptyChat />}>
          <Window>
            <CustomChannelHeader onClickPerson={setShowProfile} />
            <MessageList messageActions={['edit', 'delete']} threadList={true} />
            <MessageInput Input={WriteMessage} />
          </Window>
        </Channel>

        {showProfile && <ProfilePanel setShowProfile={setShowProfile} />}
      </div>
    </div>
  )
}
