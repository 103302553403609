import React, { useEffect, useRef, useState } from 'react'

// Third party
import { Search } from '@mui/icons-material'
import { cloneDeep } from 'lodash'
import { where } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'

import customImageComponent from '../../common/CustomImageComponent'
import MainModal from '../../modals/common/MainModal'
import ViewsLoader from '../../loader/ViewsLoader'

// Firestore
import FirestoreService from '../../../services/firestoreService'

import useToasterHelper from '../../../helpers/ToasterHelper'
import { CustomError, getUserFullName } from '../../../helpers/helpers'

// Redux
import { HorseModel } from '../../../models/horse/horse.model'
import { getConvertedData } from '../../../models/interface.helper'
import { IPaperworkTab_ } from '../../../models/event-registered-users/event-registered-users.interface'
import { IHorseTeamInterface } from '../../../models/horse-team/horseTeam.interface'
import { HorseTeamModel } from '../../../models/horse-team/horseTeam.model'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'
import { ITeamMember } from '../../../models/users/user.interface'

import {
  selectRegisterEventData,
  setRegisterPaperworkData,
} from '../../../store/events/eventsSlice'
import { useAppSelector } from '../../../store/hooks'
import {
  getSelectedHorse,
  getSelectedHorseMapping,
  selectHorses,
  selectHorseReducer,
  setSelectedHorse,
  setSelectedHorseTeam,
  updateHorse,
} from '../../../store/horses/horseSlice'
import {
  selectHorseTeamMembers,
  selectProfileData,
  selectTeamMembers,
  setTeamMembers,
} from '../../../store/user/userSlice'
import { selectPaperworkTabData } from '../../../store/registration/registrationSlice'
import { RootState } from '../../../store/store'

import helpers from '../../../commonHelpers/helpers'

// Constants
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'

type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: any
}

export interface ITeamMembeWithSelected extends ITeamMember {
  selected?: boolean
  newSelected?: boolean
}

type IModalType = {
  HORSE: boolean
  TEAM: boolean
}

type IGetSignatoryFn = (
  registrationByDayDocId: IRiderTeamMemberInterface['registrationByDayDocId']
) => IRiderTeamMemberInterface[]

// Constants
export const ITEAMMEMBER_MODAL_TYPES = {
  HORSE: 'forHorses',
  TEAM: 'forTeam',
}

const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

const USERS_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS

const FILE_NAME = 'UserProfileTeamTabAddTeamMember'

const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const UserProfileTeamTabAddTeamMember = ({ dataToPassOn, handleModal }: Props) => {
  // Hooks and vars
  let membersToRender: ITeamMembeWithSelected[]

  const toastFunctions = useToasterHelper()
  const dispatch = useDispatch()

  const types: IModalType = {
    TEAM: dataToPassOn?.modal_type === 'forTeam',
    HORSE: dataToPassOn?.modal_type === 'forHorses',
  }

  const horseData = useAppSelector(selectHorses)
  const profileData = useAppSelector(selectProfileData)
  const selectedHorse = useAppSelector(getSelectedHorse)
  const teamMembersList = useSelector(selectTeamMembers)
  const horseReducer = useAppSelector(selectHorseReducer)
  const HorseTeamList = useAppSelector(selectHorseTeamMembers) as ITeamMember[]
  const paperworkTabData = useAppSelector(selectPaperworkTabData)
  const registerFormData = useAppSelector(selectRegisterEventData)
  const selectedHorseMapping = useAppSelector(getSelectedHorseMapping)
  const userReducer = useAppSelector((state: RootState) => state.user)
  let registerDataCloned = cloneDeep(registerFormData)

  const searchBoxRef = useRef<HTMLInputElement>(null)

  const [listToShow, setListToShow] = useState<ITeamMembeWithSelected[]>()
  const [teamMember, setTeamMember] = useState<ITeamMembeWithSelected[]>([])
  const [sameTypeUsers, setSameTypeUsers] = useState<ITeamMembeWithSelected[]>()
  const [loading, setLoading] = useState(false)

  const showSearchList = searchBoxRef?.current?.value !== ''

  const getAllUsers = async () => {
    let idsList: string[] = []
    let teamMembers: ITeamMember[] = []
    let usersDetails: IUserInterface | null = null

    const usersList = await FirestoreService.filterItems(USERS_COLLECTION.NAME, [
      where(USERS_COLLECTION.FIELDS.USER_TYPE.KEY, 'in', [
        USERS_COLLECTION.FIELDS.USER_TYPE.VALUES.COMPETITOR,
        USERS_COLLECTION.FIELDS.USER_TYPE.VALUES.STAFF,
      ]),
    ])

    usersList.forEach((doc) => {
      idsList.push(doc.data().id)
    })

    const docs = await FirestoreService.getItemsUsingIds(USERS_COLLECTION.NAME, idsList)

    docs.forEach((currDoc) => {
      usersDetails = UserModel.fromFirestoreDoc(currDoc).cloneDeep()
      if (usersDetails.id === profileData.id) return
      teamMembers = [
        ...teamMembers,
        {
          memberCountry: '',
          memberId: usersDetails.id,
          memberprofileSynced: false,
          memberEmail: usersDetails.userEmail,
          memberDob: usersDetails.userDOB ?? '',
          memberUserName: usersDetails.userName,
          memberAddress: usersDetails.userAddress,
          defaultRole: usersDetails.userDefaultRole ?? '',
          memberPhoneNumber: usersDetails.userPhoneNumber,
          memberProfilePicture: usersDetails.userProfilePicture,
          memberName: getUserFullName(usersDetails),
        },
      ]
    })

    return teamMembers
  }

  const getSameTypeUsers = async (allUsers: ITeamMember[]) => {
    const roles = [CONST.UI.TEAM_MEMBERS_ROLES.TEAM_MEMBER]

    let list: ITeamMember[] = []
    let alreadyExists = false
    let existsButMarkedDeleted = false
    let user: ITeamMembeWithSelected
    let usersOfSameType: ITeamMembeWithSelected[] = []
    let ridersTeamMember: IRiderTeamMemberInterface | null = null
    let riderTeamMembers: IRiderTeamMemberInterface[] = getSignatories(
      dataToPassOn?.registrationByDayDocId
    )

    list =
      (function () {
        if (types.TEAM) return teamMembersList
        if (types.HORSE) return horseReducer.selected.team.data
        return []
      })() ?? []

    if (riderTeamMembers.length) return

    allUsers.forEach((curr) => {
      existsButMarkedDeleted = false

      alreadyExists = !!list.find((currHorseTeamMember) => {
        return curr?.memberId === currHorseTeamMember.memberId
      })

      if (curr?.memberId && (!alreadyExists || existsButMarkedDeleted)) {
        user = getConvertedData({
          ...curr,
          ...(existsButMarkedDeleted
            ? {
                id: ridersTeamMember?.id,
              }
            : {}),
          selected: false,
          memberStatus: '1',
          memberRole: roles[0],
          memberShipActive: false,
          signedStatus: 'Not sent',
          memberprofileSynced: false,
          memberSafeSupportTraining: false,
        })

        usersOfSameType = [...usersOfSameType, user]
      }
    })

    setSameTypeUsers([...usersOfSameType])
  }

  const fetchAllData = async () => {
    setLoading(true)
    try {
      const allUsers = await getAllUsers()
      await getSameTypeUsers(allUsers)
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
    }
  }

  /**
   * Gets the users of same type from db
   */
  useEffect(() => {
    fetchAllData().then()
  }, [])

  useEffect(() => {
    if (searchBoxRef.current?.value) handleUsers(searchBoxRef.current?.value)
    setTeamMember([...(sameTypeUsers?.filter((curr) => curr?.selected === true) ?? [])])
  }, [sameTypeUsers])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Returns signatories of a subdoc
   */
  const getSignatories: IGetSignatoryFn = (registrationByDayDocId) => {
    if (!registrationByDayDocId) return []

    let paperworkRowIndex = -1
    let foundPaperworkRow: IPaperworkTab_ | null = null
    let ridersTeamMembers: IRiderTeamMemberInterface[] = []

    try {
      foundPaperworkRow =
        paperworkTabData.find((currPaperworkRow, index) => {
          if (currPaperworkRow.registrationByDayDocId === registrationByDayDocId) {
            paperworkRowIndex = index
            return true
          }
          return false
        }) ?? null

      const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        registrationByDayDocId,
        foundPaperworkRow,
      })

      if (emptyVarName)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getSignatories',
          devMessage: `${emptyVarName} is ${emptyVarValue}`,
        })

      ridersTeamMembers = paperworkTabData?.[paperworkRowIndex]?.ridersTeamMembers ?? []
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return ridersTeamMembers
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Filters the user in actual
   */
  const handleUsers = (strToSearch: string) => {
    if (strToSearch === '') return setListToShow([])
    const filteredUserList = sameTypeUsers?.filter((curr) => {
      return (
        curr.memberName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        curr.memberUserName?.toLowerCase()?.includes(strToSearch?.toLowerCase()) ||
        curr.memberEmail?.toLowerCase()?.includes(strToSearch?.toLowerCase())
      )
    })
    const furtherFIlter =
      HorseTeamList.length && filteredUserList?.length
        ? filteredUserList.filter((data) =>
            HorseTeamList.find((horse) => horse.memberId !== data.memberId)
          )
        : filteredUserList

    setListToShow([...(furtherFIlter ?? [])])
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Handles click on user
   */
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    team_member: ITeamMembeWithSelected
  ) => {
    const select = e.target.checked
    if (sameTypeUsers) {
      let sameTypeUsersArrCopy = [...sameTypeUsers]
      sameTypeUsersArrCopy = sameTypeUsersArrCopy.map((currUser) => {
        if (currUser.memberId === team_member?.memberId) {
          currUser.selected = select
          currUser.newSelected = true
        }
        return currUser
      })
      setSameTypeUsers([...sameTypeUsersArrCopy])
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Filters users list acc to name
   */
  const filterUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const strToSearch = e.target.value
    handleUsers(strToSearch)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Adds the selected members to redux, but not to db
   */
  const addTeamMembersToViewOnly = async () => {
    let mappingId = selectedHorseMapping.data?.id
    if ((sameTypeUsers ?? [])?.filter((currUser) => currUser.selected).length === 0)
      return toastFunctions.info({ message: MESSAGES_CONST.SELECT_TEAM_MEMBERS_TO_SAVE })

    try {
      if (types.HORSE) {
        let horseTeamMembersList: IHorseTeamInterface[] = []
        let horse = new HorseModel(selectedHorse ?? undefined).toObject()
        let horseIndexInRedux = -1

        if (!mappingId || !horse.id)
          return toastFunctions.error({
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          })

        horseTeamMembersList = cloneDeep(horseReducer.selected.team.data)
        teamMember.forEach((currTeamMember) => {
          horseTeamMembersList.push(
            getConvertedData(
              new HorseTeamModel({
                isMyHorse: true,
                horseId: horse.id ?? null,
                horseZone: horse.horseZone,
                horseName: horse.horseName,
                userId: userReducer.userId,
                horseOwnerId: horse.horseOwnerId,
                memberId: currTeamMember.memberId,
                horseNameNGram: horse.horseNameNGram,
                memberName: currTeamMember.memberName,
                userHorseMappingId: mappingId ?? null,
                horseDiscipline: horse.horseDiscipline,
                memberRole: currTeamMember.memberRole ?? null,
                horseProfilePicture: horse.horseProfilePicture,
                memberProfilePicture: currTeamMember.memberProfilePicture,
              }).toObject()
            )
          )
        })

        horse.horseTeamMembers_ = horseTeamMembersList
        horse = getConvertedData(horse)
        horseIndexInRedux = horseData.findIndex((currHorse) => currHorse.horseId === horse.horseId)

        dispatch(setSelectedHorse(horse))
        dispatch(
          updateHorse({
            data: {
              ...horse,
              isMyHorse: userReducer.userId === horse.horseOwnerId,
            },
            index: horseIndexInRedux,
          })
        )
        dispatch(setSelectedHorseTeam(horseTeamMembersList))
      } else if (types.TEAM) {
        let updatedTeamMembersList = [...teamMembersList, ...teamMember]

        let paperworkWithMembers = Object.entries(registerDataCloned.paperwork).reduce(
          (acc: any, current: any) => {
            let key = current[0]
            acc = {
              ...registerDataCloned.paperwork,
              [key]: [...registerDataCloned.paperwork[key], ...teamMember],
            }
            return acc
          },
          {}
        )

        dispatch(setRegisterPaperworkData(paperworkWithMembers))

        dispatch(setTeamMembers(updatedTeamMembersList.map((utml) => getConvertedData(utml))))
      }

      handleModal(false, MODAL_CONSTS.TEAM)
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  const getMembersToRender = () => {
    let members: ITeamMembeWithSelected[]
    members = (showSearchList ? listToShow : teamMember) ?? []
    return members
  }

  membersToRender = getMembersToRender()

  const focusInput = () => {
    if (searchBoxRef.current) searchBoxRef.current?.focus()
  }

  return (
    <MainModal
      title="Add team member"
      show={true}
      type="TEAM"
      size="md"
      onTransitionEnd={focusInput}
      titleClassName="!font-normal"
      buttons={[
        {
          label: 'SAVE',
          bgClass: 'bg-SeabiscuitMainThemeColor',
          onClick: addTeamMembersToViewOnly,
          textClass: 'text-white',
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitLightTextColor',
          onClick: () => handleModal(false, MODAL_CONSTS.TEAM),
        },
      ]}
    >
      <>
        <div className="inputSearch mt-4 flex items-center relative">
          <input
            ref={searchBoxRef}
            type="text"
            onChange={filterUsers}
            placeholder="Search.."
            className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
          />
          <Search className="absolute text-SeabiscuitMainThemeColor right-4" />
        </div>
        {loading ? (
          <div className="flex justify-center items-center w-full h-full">
            <ViewsLoader size="xl" color="red" />
          </div>
        ) : (
          <div className="uselListWarp mt-4 flex flex-col">
            {membersToRender?.length ? (
              membersToRender?.map((item, index) => {
                return (
                  <label
                    key={index}
                    className="form-check mb-3 cursor-pointer"
                    htmlFor={'teammember' + index}
                  >
                    <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                      <div className="min-w-fit flex items-center">
                        {customImageComponent(
                          item.memberProfilePicture,
                          item.memberName,
                          'w-[40px] h-[40px] rounded-full mr-2 avatarImg object-cover'
                        )}
                        <div className="hourseDetails w-10/12">
                          <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                            <p className="text-SeabiscuitDark200ThemeColor text-lg capitalize">
                              {item?.memberName}
                            </p>
                            <p className="text-xs">@{item?.memberUserName}</p>
                          </div>
                        </div>
                      </div>

                      {item.selected ? (
                        <img
                          src={CHECKED_ICON}
                          className="relative right-[0px] top-[0px]"
                          width={20}
                          height={20}
                          alt="checked"
                        />
                      ) : null}

                      <input
                        onChange={(e) => {
                          handleChange(e, item)
                        }}
                        checked={item?.selected}
                        id={'teammember' + index}
                        className={`ml-2 form-check-input appearance-none mr-[2px] h-4 w-4 border active:bg-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer right-0 checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor ${item.selected ? 'hidden' : ''}`}
                        type="checkbox"
                      />
                    </div>
                  </label>
                )
              })
            ) : (
              <div className="w-full mt-2 text-center text-SeabiscuitDark200ThemeColor">
                {showSearchList ? 'No result found.' : null}
              </div>
            )}
          </div>
        )}
      </>
    </MainModal>
  )
}

export default UserProfileTeamTabAddTeamMember
