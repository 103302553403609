import React, { useMemo, useState, useRef } from 'react'
import { DefaultGenerics } from 'stream-chat'
import {
  useTranslationContext,
  ChannelPreviewUIComponentProps,
  DefaultStreamChatGenerics,
} from 'stream-chat-react'
import { DeleteModal } from '../modals/DeleteModal'
import ProfileImage from './ProfileImage'

export default function CustomChannelPreview(
  props: ChannelPreviewUIComponentProps<DefaultStreamChatGenerics | DefaultGenerics> & {
    editable?: boolean
  }
) {
  const {
    channel,
    displayImage,
    displayTitle,
    latestMessage,
    unread,
    active,
    setActiveChannel,
    onSelect: customOnSelectChannel,
    watchers,
    className,
  } = props
  const { userLanguage } = useTranslationContext()
  const [openEdit, setOpenEdit] = useState(false)
  const [showDeleteMessage, setShowDeleteMessage] = useState(false)
  const latestMessageAt = channel.state.last_message_at
  const isUnread = unread !== undefined && unread > 0
  const editable = props.editable ?? true
  const { vertical, user_role } = (channel.data || {}) as { vertical: string; user_role: string }

  const channelPreviewButton = useRef<HTMLDivElement | null>(null)

  const timestamp = useMemo(() => {
    if (!latestMessageAt) {
      return ''
    }
    const formatter = new Intl.DateTimeFormat(userLanguage, {
      timeStyle: 'short',
    })
    return formatter.format(latestMessageAt)
  }, [latestMessageAt, userLanguage])

  const onSelectChannel = (e: React.MouseEvent<HTMLDivElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e)
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers)
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur()
    }
  }

  return (
    <React.Fragment key={channel.id}>
      <div
        onClick={onSelectChannel}
        className={`flex items-start w-full gap-4 p-4 bg-background hover:bg-muted transition-colors relative ${active ? 'bg-[#F6F7FB]' : ''} ${className}`}
        ref={channelPreviewButton}
      >
        <ProfileImage
          src={displayImage}
          lazyLoad={true}
          className={'h-10 w-10 object-cover rounded-full hidden sm:block'}
        />

        <div className={`flex-1 min-w-0`}>
          <div className="flex items-center flex-row">
            <div className="flex-row flex-1 items-center space-x-1 flex-nowrap truncate">
              <span className={`${isUnread ? 'font-semibold' : 'font-normal'}`}>
                {displayTitle}
              </span>

              <time
                dateTime={latestMessageAt?.toISOString()}
                className="text-xs text-muted-foregroundtext-center"
              >
                {timestamp}
              </time>
            </div>
            {editable && (
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenEdit((s) => !s)
                }}
              >
                <img
                  className="h-[5px] w-[21px]"
                  src={isUnread ? '/assets/chat/dots.svg' : '/assets/chat/dots2.svg'}
                  alt="searchIcon"
                />
              </button>
            )}
          </div>
          <div className="flex flex-row items-baseline justify-between">
            <p
              className={`text-sm text-muted-foreground text-[#122B46] line-clamp-2 ${isUnread ? 'font-semibold' : 'font-normal'}`}
            >
              {latestMessage}
            </p>
            {isUnread && (
              <div className="flex flex-none items-center justify-center bg-primary text-primary-foreground rounded-full text-xs font-medium text-white bg-red-500 h-[25px] w-[25px]">
                <span>{unread}</span>
              </div>
            )}
          </div>
          {vertical && user_role && (
            <p className="text-[0.7rem] text-muted-foreground text-left">
              {`${vertical[0].toUpperCase()}${vertical.slice(1)} • ${user_role}`}
            </p>
          )}
        </div>
        {editable && openEdit && (
          <div className="absolute h-[98px] w-[184px] border-2 rounded-md right-8 bg-white flex flex-col z-10 justify-center shadow-md top-10">
            <button
              onClick={(e) => {
                e.stopPropagation()
                channel.markRead()
                setOpenEdit(false)
              }}
              className="hover:bg-[#F6F7FB] h-[40px] text-right mx-2 rounded-md text-sm p-2"
            >
              Mark as read
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation()
                //channel.hide()
                setShowDeleteMessage(true)
                setOpenEdit(false)
              }}
              className="hover:bg-[#F6F7FB] h-[40px] text-right mx-2 rounded-md text-sm p-2"
            >
              Delete
            </button>
          </div>
        )}
      </div>

      <DeleteModal
        open={showDeleteMessage}
        onDelete={() => {
          channel.hide()
          setShowDeleteMessage(false)
        }}
        onClose={() => setShowDeleteMessage(false)}
      />
    </React.Fragment>
  )
}
