import React, { FC } from 'react'
import { IMembershipType } from '../../../models/users/user.interface'

interface MembershipIconProps {
  membershipType: IMembershipType
}

export const MembershipIcon: FC<MembershipIconProps> = ({ membershipType }) => {
  return (
    <>
      {membershipType === 'Annual' || membershipType === 'Monthly' ? (
        <img src="/assets/og_icons/Synchronize-1.svg" className="w-full" alt="" />
      ) : membershipType === 'Board' ? (
        <img src="/assets/og_icons/Home-1.svg" className="w-full" alt="" />
      ) : membershipType === 'Payment' ? (
        <img src="/assets/og_icons/Banknotes-1.svg" className="w-full" alt="" />
      ) : membershipType === 'Free' ? (
        <img src="/assets/og_icons/Name Tag-1.svg" className="w-full" alt="" />
      ) : null}
    </>
  )
}
