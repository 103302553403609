import React, { ReactNode } from 'react'
import { Chat } from 'stream-chat-react'
import { StreamChat } from 'stream-chat'
import withStreamClient from './withStreamClient' // Importa tu HOC

interface ChatComponentProps {
  client: StreamChat
  children?: ReactNode
}

const ChatComponent: React.FC<ChatComponentProps> = ({ client, children }) => {
  return <Chat client={client}>{children}</Chat>
}

export default withStreamClient(ChatComponent)
