import React from 'react'
import { IconChevron } from '../../../../../components/icons/IconChevron'
import customImageComponent from '../../../../../components/common/CustomImageComponent'
import { IField, REQUIERED_FILEDS } from '../../../../../const/requeredFields'
import { UserModel } from '../../../../../models/users/user.model'
import { HorseModel } from '../../../../../models/horse/horse.model'
import { TextField } from './TextField'
import { disciplineData } from '../../../../../fakeData/disciplineList'
import { horseBreedList } from '../../../../../fakeData/horseBreedList'
import { horseHeightList } from '../../../../../fakeData/HorseHeightList'
import { SexRestOptions } from '../../../../../components/customStyles/ReactSelectCustomStyle'
import { zoneList } from '../../../../../fakeData/ZoneList'
import { horseColorList } from '../../../../../fakeData/horseColorList'
import { COUNTRIES_LIST } from '../../../../../fakeData/countriesList'
import { CountryList } from '../../../../../fakeData/countryList'
import { IHorseCompetitorDocument } from '../../../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { IErrors } from '../ClinicNOtherStatusTab'
import { IHorseData } from '../../../../../models/horse/horse.interface'

interface IProps {
  headerTitle: string
  headerImgSrc: string
  editHandler: (data: IProfileReqField, horse?: IHorseData) => void
  user?: UserModel
  horse?: HorseModel
  eventRequiredFields: string[]
  isHorse?: boolean
  saveCallBack: (value: string, key: string, id: string) => void
  horsesPaperwork: IHorseCompetitorDocument[] | null
  setTotalErrors: (value: IErrors) => void
  totalErrors: IErrors
}

export interface IProfileReqField extends IField {
  profileValue: string
  isExpired?: boolean
  isTextField?: boolean
  expiredDate?: string
}

export const ProfileFields: React.FC<IProps> = ({
  headerTitle,
  headerImgSrc,
  eventRequiredFields,
  user,
  horse,
  isHorse,
  editHandler,
  saveCallBack,
  horsesPaperwork,
  setTotalErrors,
  totalErrors,
}) => {
  const [requiredFields, setRequiredFields] = React.useState<IProfileReqField[]>([])
  const [errorsCount, setErrorsCount] = React.useState(0)

  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    const getLabel = (arr: { label: string; value: string }[], value: string) => {
      const listItm = arr.find((itm) => itm.value === value)
      if (listItm?.label) return listItm.label
      return ''
    }
    const options = { day: '2-digit', month: 'short', year: '2-digit' }
    const fields: IProfileReqField[] = []
    let _errors = 0
    let concatData: IField[]

    const convertToDate = (date: Date, text: string) => {
      if (!text) {
        return 'No Number'
      }
      return date
        ? `Current • Expires ${date.toLocaleDateString('en-GB', options as any).replace(',', '')}`
        : ''
    }

    if (isHorse) {
      const d1 = REQUIERED_FILEDS.horseMain.filter((itm) => eventRequiredFields.includes(itm.value))
      const d2 = REQUIERED_FILEDS.horseSecondary.filter((itm) =>
        eventRequiredFields.includes(itm.value)
      )
      const d3 = REQUIERED_FILEDS.horseNumbers.filter((itm) =>
        eventRequiredFields.includes(itm.value)
      )
      concatData = [...d1, ...d2, ...d3]
    } else {
      const d1 = REQUIERED_FILEDS.people.filter((itm) => eventRequiredFields.includes(itm.value))
      const d2 = REQUIERED_FILEDS.peopleNumbers.filter((itm) =>
        eventRequiredFields.includes(itm.value)
      )
      concatData = [...d1, ...d2]
    }

    concatData.forEach((d) => {
      const profileReqField: IProfileReqField = {
        ...d,
        profileValue: '',
      }

      const horsePaperwork = horsesPaperwork?.find(
        (paperwork) => paperwork.horseId === horse?.horseId
      )

      if (horsePaperwork?.horseId) {
        if (d.value === 'horseHealthCertificate') {
          profileReqField.profileValue = horsePaperwork.horseHealthStatus ?? ''
        } else if (d.value === 'horseRhinopneumonitis') {
          profileReqField.profileValue = horsePaperwork.horseRhinopneumonitisCurrentStatus ?? ''
        } else if (d.value === 'horseInfluenza') {
          profileReqField.profileValue = horsePaperwork.horseInfluenzaStatus ?? ''
        }
      }

      if (horse && d && horse[d.value as keyof HorseModel] !== undefined) {
        const field: any = horse[d.value as keyof HorseModel]
        const cutValue = d.value.replace('Number', '')
        const expirationField = horse[(cutValue + 'Expiration') as keyof HorseModel]

        profileReqField.isTextField =
          REQUIERED_FILEDS.horseMain.some((itm) => itm.value === d.value) ||
          REQUIERED_FILEDS.horseSecondary.some((itm) => itm.value === d.value)

        let profileValue = field

        if (d.value === 'horseDiscipline') {
          profileValue = getLabel(disciplineData, `${field}`)
        }
        if (d.value === 'horseBreed') {
          profileValue = getLabel(horseBreedList, `${field}`)
        }
        if (d.value === 'horseHeight') {
          profileValue = getLabel(horseHeightList, `${field}`)
        }
        if (d.value === 'horseGender') {
          profileValue = getLabel(SexRestOptions as any, `${field}`)
        }
        if (d.value === 'horseZone') {
          profileValue = getLabel(zoneList, `${field}`)
        }
        if (d.value === 'horseColor') {
          profileValue = getLabel(horseColorList, `${field}`)
        }
        if (d.value === 'horseCountry') {
          profileValue = getLabel(CountryList, `${field}`)
        }

        profileReqField.profileValue = profileValue

        if (expirationField) {
          const endDate = new Date(`${expirationField}`)
          const currentDate = new Date()
          profileReqField.isTextField = false
          profileReqField.profileValue = field ? convertToDate(endDate, field) : ''
          profileReqField.isExpired = currentDate > endDate || !expirationField
          profileReqField.expiredDate = `${expirationField}`
        } else if (!profileReqField.isTextField && d.value !== 'horseUsefNumber') {
          profileReqField.profileValue = ''
          profileReqField.isExpired = true
          profileReqField.expiredDate = ``
        }
      }

      let endDate
      const currentDate = new Date().getTime()

      if (d.value === 'horseCoggins') {
        const horseCogginsInspectionDate = horsePaperwork?.horseCogginsInspectionDate as any
        if (horseCogginsInspectionDate?.seconds) {
          endDate = new Date(horseCogginsInspectionDate?.seconds * 1000).getTime()
        } else {
          endDate = new Date(horseCogginsInspectionDate).getTime()
        }

        endDate += 1000 * 60 * 60 * 24 * 364 // 1 year
        profileReqField.isExpired = currentDate > endDate
        profileReqField.profileValue = horseCogginsInspectionDate
          ? `Current • Expires ${new Date(endDate).toLocaleDateString('en-GB', options as any).replace(',', '')}`
          : ''
      } else if (d.value === 'horseMeasurementCard') {
        const horseMeasurementDate = horsePaperwork?.horseMeasurementCards?.[0]
          ?.horseMeasurementDate as any

        if (horseMeasurementDate?.seconds) {
          endDate = new Date(horseMeasurementDate?.seconds * 1000).getTime()
        } else {
          endDate = new Date(horseMeasurementDate).getTime()
        }

        endDate += 1000 * 60 * 60 * 24 * 364 // 1 year
        profileReqField.isExpired = currentDate > endDate
        profileReqField.profileValue = horseMeasurementDate
          ? `Current • Expires ${new Date(endDate).toLocaleDateString('en-GB', options as any).replace(',', '')}`
          : ''
      }

      if (user && d) {
        const field: any = user[d.value as keyof UserModel]
        if (field && field.documentNumber) {
          const date = new Date(field.endDate)

          profileReqField.profileValue = field.endDate
            ? `Current • Expires ${date.toLocaleDateString('en-GB', options as any).replace(',', '')}`
            : ''

          if (field.endDate && field.documentNumber) {
            const endDate = new Date(field.endDate.seconds ? field.endDate.seconds : field.endDate)
            const currentDate = new Date()
            profileReqField.isExpired = currentDate > endDate

            if (currentDate <= endDate) {
              profileReqField.isExpired = !field.endDate.seconds ? !field.endDate : true
            }

            profileReqField.expiredDate = field.endDate
          }
        } else if (typeof field === 'string' || typeof field === 'number') {
          let profileValue = `${field}`
          profileReqField.isExpired = false
          // if (field.documentFullName === 'USEA' || field.documentFullName === 'FEI') {
          //   profileValue = field.documentNumber
          // }
          if (d.value === 'userDiscipline') {
            profileValue = getLabel(disciplineData, `${field}`)
          }
          if (d.value === 'userNationality') {
            profileValue = getLabel(COUNTRIES_LIST, `${field}`)
          }

          profileReqField.profileValue = profileValue
          profileReqField.isTextField = true
        }
      }

      if (!profileReqField.profileValue || profileReqField.isExpired) _errors++
      fields.push(profileReqField)
    })

    if (horse?.id) {
      totalErrors.horses[horse?.id] = _errors
    }
    if (user?.id) {
      totalErrors.users[user?.id] = _errors
    }

    setTotalErrors({ ...totalErrors })

    setErrorsCount(_errors)
    setRequiredFields(fields)
  }, [eventRequiredFields, isHorse, user, horse, horsesPaperwork])

  return (
    <div className={'border border-[#D3DAEE] w-full rounded-lg py-3 px-3'}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={'flex justify-between w-full items-center cursor-pointer'}
      >
        <div className={'flex flex-1 gap-4 items-center'}>
          <div className={'w-[45px] h-[45px] rounded-lg overflow-hidden'}>
            {customImageComponent(headerImgSrc, headerTitle, 'w-full h-full object-cover')}
          </div>
          <span>{headerTitle}</span>
        </div>
        <div className={`flex items-center gap-4 ${errorsCount ? 'text-[#E80000]' : ''}`}>
          <span>{errorsCount || 'No'} errors</span>

          {requiredFields[0] && (
            <span className={isOpen ? `rotate-180` : ``}>
              <IconChevron />
            </span>
          )}
        </div>
      </div>

      {isOpen && requiredFields[0] && (
        <div className={'flex flex-col mt-5 gap-4'}>
          {requiredFields.map((itm, index) => (
            <div
              className={`h-[55px] rounded-lg flex items-center justify-between gap-3 p-3 ${itm.profileValue && !itm.isExpired ? 'bg-[#F6F7FB] text-[#122B46]' : 'bg-[#E800000D] text-[#E80000]'}`}
              key={itm.value + index}
            >
              <p>{itm.title}</p>

              {itm.profileValue && !itm.isExpired ? (
                <span>{itm.profileValue}</span>
              ) : itm.isTextField ? (
                <TextField
                  isHorse={isHorse}
                  horseId={horse?.id}
                  keyValue={itm.value}
                  userId={user?.id}
                  cb={(value) => {
                    const newRequiredFields = [...requiredFields]
                    newRequiredFields[index] = {
                      ...requiredFields[index],
                      profileValue: value,
                    }
                    saveCallBack(value, itm.value, `${isHorse ? horse!.id : user!.id}`)
                    setRequiredFields(newRequiredFields)
                    setErrorsCount((v) => v - 1)
                  }}
                  title={itm.profileValue}
                />
              ) : (
                <div className={'flex gap-2'}>
                  <span>{itm.isExpired ? 'Expired' : 'Missing'}</span>
                  <span>•</span>
                  <button
                    onClick={() => editHandler(itm, horse)}
                    className={'underline hover:no-underline'}
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
