import { useEffect, useState } from 'react'

// Third party
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { get } from 'lodash'

// Components

// Types
import Accordion from '../../../components/accordion/common/Accordion'
import { IRegistrationFeesInterface } from '../../../models/registration-fees/registrationFees.interface'
import { IScratch } from '../../../store/events/eventsSlice'
import { setExhibitorTotals } from '../../../store/exhibitor/exhibitorSlice'
import { useAppDispatch } from '../../../store/hooks'
import { IOnRefundAmountChangeFn } from './ExhibitorProfileDisplayTab'
import ExhibitorHorseAccordion from './accordions/ExhibitorHorseAccordion'
import HorseService from '../../../services/horseService'
import { formatDate } from '../../../helpers/helpers'
import { IHorseData } from '../../../models/horse/horse.interface'
import { IHorseCompetitorDocument } from '../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import ViewsLoader from '../../../components/loader/ViewsLoader'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IExhibitorProfileHorsesTabProps = {
  isRefund: boolean
  exhibitorAllData?: any
  isRecipient?: boolean
  showScratchedView: boolean
  registrationfeesData: IRegistrationFeesInterface[]
  getScratchView: (scratchType: IScratch['scratchType']) => ReactJSXElement | null
  onRefundAmountChange?: IOnRefundAmountChangeFn
  setListToScratch: React.Dispatch<React.SetStateAction<string[]>>
  listToScratch: string[]
}

const ExhibitorProfileHorsesTab = (props: IExhibitorProfileHorsesTabProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const { listToScratch, exhibitorAllData } = props
  const registrationfeesData = props.registrationfeesData
  const userId = exhibitorAllData.userId
  const eventId = exhibitorAllData.eventId

  const [horseData, setHorseData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  console.log('=>(ExhibitorProfileHorsesTab.tsx:51) exhibitorAllData', props)

  useEffect(() => {
    let total = 0
    if (listToScratch && Array.isArray(listToScratch) && listToScratch.length) {
      listToScratch.forEach((item?: any) => {
        const Data = registrationfeesData.find((data) => data.id === item)
        if (Data) {
          total = total + Number(Data.selectedUnitsCount) * Number(Data.feesPrice)
        }
      })
    } else if (registrationfeesData.length) {
      registrationfeesData.forEach((Data?: any) => {
        if (Data) {
          total = total + Number(Data.selectedUnitsCount) * Number(Data.feesPrice)
        }
      })
    }

    dispatch(
      setExhibitorTotals({
        value: total,
        keyToUpdate: 'feesPriceTotal',
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, listToScratch, registrationfeesData])

  const getData = async (id: any) => {
    setLoading(true)
    await HorseService.getAllHorseInfo(id, eventId).then((res) => {
      const transformedArray = res.map(
        (item: IHorseData & { paperwork: IHorseCompetitorDocument }) => {
          const cogginsExpiration: { seconds: string; nanoseconds: string } | null | string | Date =
            get(item, 'paperwork.horseCogginsInspectionDate', null)
          let cogginsExpirationFormatted

          if (cogginsExpiration && typeof cogginsExpiration === 'object') {
            const seconds = (cogginsExpiration as any).seconds
            cogginsExpirationFormatted = new Date(seconds * 1000)
          } else {
            cogginsExpirationFormatted = cogginsExpiration
          }

          return {
            horseId: item.horseId,
            horseName: item.horseName,
            horseClass: item.horseDiscipline,
            horsePic: item.horseProfilePicture,
            paperwork: {
              title: 'Paperwork',
              content: [
                {
                  title: 'Coggins',
                  document: get(item, 'paperwork.horseCogginsInspectionCertificate[0]', null),
                  expiration: cogginsExpirationFormatted?.toString(),
                  problems: false,
                },
                {
                  title: 'Health Certificate',
                  document: get(item, 'paperwork.horseHealthRecordCertificatesImages[0]', null),
                  expiration: get(item, 'paperwork.horseHealthStatus', null),
                  problems: false,
                },
                {
                  title: 'Vaccination Record',
                  document: get(item, 'paperwork.horseVaccinationRecordsImages[0]', null),
                  expiration: get(item, 'paperwork.horseCurrentVaccinationStatus', null),
                  problems: false,
                },
                {
                  title: 'Rhinopneumonitis',
                  document: 'empty',
                  expiration: get(item, 'paperwork.horseRhinopneumonitisCurrentStatus', null),
                  problems: false,
                },
                {
                  title: 'Influenza',
                  document: 'empty',
                  expiration: get(item, 'paperwork.horseInfluenzaStatus', null),
                  problems: false,
                },
              ],
            },
            competitionNumbers: {
              title: 'Competition Numbers',
              content: [
                {
                  pic: 'https://via.placeholder.com/150',
                  title: 'FEI Number',
                  number: item.horseFeiNumber,
                  expiration: '',
                  problems: false,
                },
                {
                  pic: 'https://via.placeholder.com/150',
                  title: 'USEF Number',
                  number: item.horseUsefNumber,
                  expiration: '',
                  problems: false,
                },
                {
                  pic: 'https://via.placeholder.com/150',
                  title: 'USDF number',
                  number: item.horseUsdfNumber,
                  expiration: item.horseUsdfExpiration,
                  problems: false,
                },
                {
                  pic: 'https://via.placeholder.com/150',
                  title: 'USEA number',
                  number: item.horseUseaNumber,
                  expiration: item.horseUseaExpiration,
                  problems: false,
                },
                {
                  pic: 'https://via.placeholder.com/150',
                  title: 'USHJA number',
                  number: item.horseUshjaNumber,
                  expiration: item.horseUshjaExpiration,
                  problems: false,
                },
                {
                  pic: 'https://via.placeholder.com/150',
                  title: 'AHHS number',
                  number: item.horseAhhsNumber,
                  expiration: item.horseAhhsExpiration,
                  problems: false,
                },
                {
                  pic: 'https://via.placeholder.com/150',
                  title: 'AMHA number',
                  number: item.horseAmhaNumber,
                  expiration: item.horseAmhaExpiration,
                  problems: false,
                },
                {
                  pic: 'https://via.placeholder.com/150',
                  title: 'ARHPA number',
                  number: item.horseArhpaNumber,
                  expiration: item.horseArhpaExpiration,
                  problems: false,
                },
                {
                  pic: 'https://via.placeholder.com/150',
                  title: 'ASHA number',
                  number: item.horseAshaNumber,
                  expiration: item.horseAshaExpiration,
                  problems: false,
                },
                {
                  pic: 'https://via.placeholder.com/150',
                  title: 'UPHA number',
                  number: item.horseUphaNumber,
                  expiration: item.horseUphaExpiration,
                  problems: false,
                },
                {
                  pic: 'https://via.placeholder.com/150',
                  title: 'WDAA number',
                  number: item.horseWdaaNumber,
                  expiration: item.horseWdaaExpiration,
                  problems: false,
                },
                // {
                //   pic: 'https://via.placeholder.com/150',
                //   title: 'EQAU number',
                //   number: item.horseEqauNumber || 'N/A',
                //   expiration: item.horseEqauExpiration != '' ? formatDate(item.horseEqauExpiration ) : '' ,
                //   problems: false,
                // },
              ],
            },
            trackingNumbers: {
              title: 'Tracking Numbers',
              content: [
                {
                  title: 'Microchip Number',
                  number: item.horseMicrochipNumber,
                  expiration: item.horseMicrochipExpiration,
                  problems: false,
                },
                {
                  title: 'DNA Case Number',
                  number: item.horseDNACaseNumber,
                  expiration: item.horseDNACaseExpiration,
                  problems: false,
                },
                {
                  title: 'Passport Number',
                  number: item.horsePassportNumber,
                  expiration: item.horsePassportExpiration,
                  problems: false,
                },
              ],
            },
            bio: {
              title: 'Bio',
              content: [
                {
                  title: 'Name',
                  content: item.horseName,
                },
                {
                  title: 'Breed',
                  content: item.horseBreed,
                },
                {
                  title: 'Height',
                  content: item.horseHeight,
                },
                {
                  title: 'Date of Birth',
                  content: formatDate(item.horseDob),
                },
                {
                  title: 'Gender',
                  content: item.horseGender,
                },
                {
                  title: 'Coloring',
                  content: item.horseColor,
                },
                {
                  title: 'Markings',
                  content: item.horseMarking ? (item.horseMarking as string[]).join(', ') : null,
                },
                {
                  title: 'Country of Origin',
                  content: item.horseCountry,
                },
              ],
            },
          }
        }
      )
      setHorseData(transformedArray)
    })
    setLoading(false)
  }

  useEffect(() => {
    getData(userId)
  }, [userId])

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {loading ? (
        <div className="min-h-[100px] flex items-center justify-center">
          <ViewsLoader size="xl" color="red" />
        </div>
      ) : horseData.length > 0 ? (
        horseData.map((item, index) => (
          <Accordion
            key={index}
            icon={<AddCircleOutline className="add_icon text-[red]" />}
            initialState={true}
            className="mb-2"
            header={
              <div className="flex gap-2">
                <img src={item.horsePic} className="h-[45px] w-[45px] rounded-lg" alt="" />
                <div
                  className={`text-SeabiscuitDark200ThemeColor flex-col items-center ${isOpen ? 'font-normal' : 'font-normal'}`}
                >
                  {item.horseName}
                  <div className="flex items-center details font-normal text-sm opacity-50">
                    {item.horseClass}
                  </div>
                </div>
              </div>
            }
            headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
            onOpen={() => {
              setIsOpen(true)
            }}
            onClose={() => {
              setIsOpen(false)
            }}
            activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
          >
            <>
              <ExhibitorHorseAccordion {...item} />
            </>
          </Accordion>
        ))
      ) : (
        <div className="w-full flex justify-center items-center">No data found</div>
      )}
    </>
  )
}

export default ExhibitorProfileHorsesTab
