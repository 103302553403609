import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { sumBy } from 'lodash'

import Accordion from '../../../components/accordion/common/Accordion'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IScratch } from '../../../store/events/eventsSlice'
import { setExhibitorTotals } from '../../../store/exhibitor/exhibitorSlice'
import { useAppDispatch } from '../../../store/hooks'
import ExhibitorRegistrationRefundAccordion from './accordions/ExhibitorRegistrationRefundAccordion'
import { IOnRefundAmountChangeFn } from './ExhibitorProfileDisplayTab'
import ExhibitorRegistrationAccordionNew from './accordions/ExhibitorRegistrationAccordionNew'
import helpers from '../../../commonHelpers/helpers'
import useHorse from '../manage/common/table/HorsesManage/hooks/useHorse'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IExhibitorProfileTeamTabProps = {
  isRefund: boolean
  registrationsByDay?: IRegistrationByDayInterface[]
  isRecipient?: boolean
  showScratchedView: boolean
  onRefundAmountChange?: IOnRefundAmountChangeFn
  getScratchView: (scratchType: IScratch['scratchType']) => ReactJSXElement | null
  setListToScratch: React.Dispatch<React.SetStateAction<string[]>>
  listToScratch: string[]
  registeredBy: string
  eventId: string
}

const ExhibitorProfileRegistrationTabNew = (props: IExhibitorProfileTeamTabProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const {
    getScratchView,
    registrationsByDay,
    setListToScratch,
    showScratchedView,
    listToScratch,
    registeredBy,
  } = props

  const { horses, allHorses } = useHorse(props.eventId)

  const [priceTotal, setPriceTotal] = useState(0)

  useEffect(() => {
    let itemsWithoutScratched: IRegistrationByDayInterface[] = []

    if (registrationsByDay && registrationsByDay.length) {
      registrationsByDay.forEach((item) => {
        if (item.registrationPrice && !item.isSratched) {
          itemsWithoutScratched.push(item)
        }
      })

      const price = parseFloat(sumBy(itemsWithoutScratched, 'registrationPrice').toFixed(2))

      setPriceTotal(price)

      dispatch(
        setExhibitorTotals({
          keyToUpdate: 'eventsPriceTotal',
          value: price,
        })
      )
    }
  }, [registrationsByDay])

  const rider = (Array.isArray(registrationsByDay) && registrationsByDay[0]) || null

  if (!rider) return <></>

  let classes: string[] = []
  let horsesIds: string[] = []
  const scratched: string[] = []

  registrationsByDay?.forEach((rbd) => {
    if (rbd.isSratched) {
      scratched.push(`${rbd.id}`)
    }
    if (rbd.registrationByDayName && !classes.includes(rbd.registrationByDayName)) {
      classes.push(rbd.registrationByDayName)
    }
    if (rbd.horseId && !horsesIds.includes(rbd.horseId)) {
      horsesIds.push(rbd.horseId)
    }
  })

  return (
    <>
      {/* Registration Accordion */}
      <Accordion
        icon={<AddCircleOutline className="add_icon text-[red]" />}
        initialState={true}
        className="mb-2"
        header={
          <div
            className={`flex flex-col md:flex-row md:justify-between md:items-center gap-3 md:gap-0`}
          >
            <div className={'flex items-center gap-[11px]'}>
              <div
                className={'rounded-[8px] border border-solid overflow-hidden w-[44px] h-[44px]'}
              >
                <img
                  alt="icons"
                  onError={(e) =>
                    ((e.target as any).src =
                      `https://ui-avatars.com/api/?name=${helpers.generateName(`${rider.riderName || ''}`)}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                  }
                  src={rider.riderProfilePicture || ''}
                  className={'object-cover w-full h-full'}
                />
              </div>
              <div>
                <p className={'text-[#122B46] font-normal'}>{rider.riderName}</p>
                <p className={'text-[#3C4B71B2] font-normal text-[12px] normal-case'}>
                  Registered by: {registeredBy}
                </p>
              </div>
            </div>
            <div className="flex items-center details">
              <div className="font-normal text-[14px] text-SeabiscuitDark200ThemeColor">
                {classes.length} classes • {horsesIds.length} horses • {scratched.length} scratch
              </div>
            </div>
          </div>
        }
        headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
        activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
      >
        {props?.isRefund ? (
          <ExhibitorRegistrationRefundAccordion
            registrationsByDay={registrationsByDay ?? []}
            onRefundAmountChange={props.onRefundAmountChange}
          />
        ) : (
          <ExhibitorRegistrationAccordionNew
            registrationsByDay={registrationsByDay ?? []}
            registrationPrice={priceTotal}
            showScratchedView={showScratchedView}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
            horses={allHorses}
          />
        )}
        {getScratchView('event')}
      </Accordion>
    </>
  )
}

export default ExhibitorProfileRegistrationTabNew
