export type IUiExhibitorConst = {
  TABS: {
    ENTRIES: {
      VALUE: string
    }
    CLASSES: {
      VALUE: string
    }
    FEES: {
      VALUE: string
    }
    TICKETS: {
      VALUE: string
    }
    ELIGABILITY: {
      VALUE: string
    }
    PAPERWORK: {
      VALUE: string
    }
    TEAM: {
      VALUE: string
    }
    HORSES: {
      VALUE: string
    }
    ANSWERS: {
      VALUE: string
    }
  }
}

export const UI_EXHIBITOR_CONST: IUiExhibitorConst = {
  TABS: {
    ENTRIES: {
      VALUE: 'Entries',
    },
    CLASSES: {
      VALUE: 'Classes',
    },
    FEES: {
      VALUE: 'Fees',
    },
    TICKETS: {
      VALUE: 'Tickets',
    },
    ELIGABILITY: {
      VALUE: 'Eligibility',
    },
    PAPERWORK: {
      VALUE: 'Paperwork',
    },
    TEAM: {
      VALUE: 'Team',
    },
    HORSES: {
      VALUE: 'Horses',
    },
    ANSWERS: {
      VALUE: 'Answers',
    },
  },
}
