import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { UseFormRegister } from 'react-hook-form'

export interface IBasicInputProps {
  valid?: boolean
  validValueClassName?: string
  textColorOnDisabled?: boolean
  onChange?: (args?: any) => any
  onClick?: (args?: any) => any
  invalidValueClassName?: string
  register?: UseFormRegister<any>
}

type IInputProps = {
  value: any
  name?: string
  type?: string
  onFocus?: any
  rows?: number
  title?: string
  clear?: boolean
  disabled?: boolean
  readOnly?: boolean
  className?: string
  preFixIcon?: string
  placeholder?: string
  postFixIcon?: string
  showPostFixIcon?: boolean
  showPreFixIcon?: boolean
  containerClassName?: string
  postFixIconClassName?: string
  preFixIconClassName?: string
  responsive?: boolean
  defaultWidth?: string
} & IBasicInputProps

const Input = (props: IInputProps) => {
  const showIcon =
    (props.postFixIcon && props.showPostFixIcon !== false) ||
    (props.preFixIcon && props.showPreFixIcon !== false)
  const isTextArea = props.type === 'textarea'

  const spanRef = useRef<any>(null)
  const [inputWidth, setInputWidth] = useState(1)

  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(spanRef.current.offsetWidth + 2)
    }
  }, [props.value])

  return (
    <div
      className={clsx(
        'w-full',
        showIcon && !isTextArea && 'relative flex items-center',
        showIcon && isTextArea && 'relative flex items-start',
        props.containerClassName
      )}
      onClick={(e) => {
        e.currentTarget.querySelector('input')?.focus()
      }}
    >
      {props.clear ? (
        <input
          type={props?.type ?? 'text'}
          title={props.title}
          disabled={props.disabled}
          className={clsx(
            'w-full outline-0 ring-0 rounded-md focus:outline-0 focus:ring-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate',
            props.className,
            props.textColorOnDisabled,
            props.valid ? props.validValueClassName : props.invalidValueClassName
          )}
          readOnly={true}
          value={''}
          placeholder={props.placeholder}
          onFocus={(e) => props?.onFocus?.(e)}
          onClick={(e) => props?.onClick?.(e)}
        />
      ) : null}

      {props.preFixIcon && props?.showPreFixIcon !== false ? (
        <img
          src={props.preFixIcon}
          alt="icon"
          className={clsx(
            'absolute left-3 w-6 h-6',
            showIcon && isTextArea && 'my-3',
            props.preFixIconClassName
          )}
        />
      ) : null}

      {props.type !== 'textarea' ? (
        <input
          type={props?.type ?? 'text'}
          title={props.title}
          disabled={props.disabled}
          className={clsx(
            'outline-0 ring-0 rounded-md focus:outline-0 focus:ring-0 text-SeabiscuitDark200ThemeColor text-[14px] placeholder:text-[14px] cursor-pointer overflow-hidden whitespace-nowrap truncate placeholder:text-SeabiscuitDark200ThemeColor placeholder:opacity-50',
            props.className,
            props.clear && '!hidden',
            props.textColorOnDisabled,
            props.postFixIcon && props.showPostFixIcon !== false && '!pr-11',
            props.preFixIcon && props.showPreFixIcon !== false && '!pl-11',
            props.valid ? props.validValueClassName : props.invalidValueClassName
          )}
          placeholder={props.placeholder}
          readOnly={props.readOnly ?? false}
          onFocus={(e) => props?.onFocus?.(e)}
          onClick={(e) => props?.onClick?.(e)}
          {...(typeof props.register === 'function'
            ? props.name &&
              props.register(props.name, {
                onChange: (e) => props?.onChange?.(e),
              })
            : {
                onChange: (e) => props?.onChange?.(e),
                value: props.value,
              })}
          style={{
            width:
              props.responsive && props.value?.length > 0
                ? `${inputWidth}px`
                : props.defaultWidth
                  ? props.defaultWidth
                  : `100%`,
          }}
        />
      ) : (
        <textarea
          title={props.title}
          disabled={props.disabled}
          rows={props?.rows ?? 6}
          className={clsx(
            'w-full outline-0 ring-0 rounded-md focus:outline-0 focus:ring-0 text-SeabiscuitDark200ThemeColor cursor-pointer text-[14px] placeholder:text-[14px] placeholder:text-SeabiscuitDark200ThemeColor placeholder:opacity-50',
            props.className,
            props.clear && '!hidden',
            props.textColorOnDisabled,
            props.postFixIcon && props.showPostFixIcon !== false && '!pr-11',
            props.preFixIcon && props.showPreFixIcon !== false && '!pl-11',
            props.valid ? props.validValueClassName : props.invalidValueClassName
          )}
          placeholder={props.placeholder}
          readOnly={props.readOnly ?? false}
          onFocus={(e) => props?.onFocus?.(e)}
          onClick={(e) => props?.onClick?.(e)}
          {...(typeof props.register === 'function'
            ? props.name &&
              props.register(props.name, {
                onChange: (e) => props?.onChange?.(e),
              })
            : {
                onChange: (e) => props?.onChange?.(e),
                value: props.value,
              })}
        />
      )}

      {props.responsive && (
        <span
          ref={spanRef}
          style={{
            position: 'absolute',
            visibility: 'hidden',
            whiteSpace: 'pre',
          }}
        >
          {props.value || ' '}
        </span>
      )}

      {props.postFixIcon && props?.showPostFixIcon !== false ? (
        <img
          src={props.postFixIcon}
          alt="icon"
          className={clsx(
            'absolute right-3 w-6 h-6',
            showIcon && isTextArea && 'my-3',
            props.postFixIconClassName
          )}
        />
      ) : null}
    </div>
  )
}

export default Input
