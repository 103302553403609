import { UseIonRouterResult } from '@ionic/react'
import { GoogleMap } from '@react-google-maps/api'
import { where } from 'firebase/firestore'
import geohash from 'ngeohash'
import { useEffect, useRef, useState } from 'react'
import helpers from '../../../../commonHelpers/helpers'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { CustomError, getCurrentLocation } from '../../../../helpers/helpers'
import useMedia from '../../../../hooks/useMedia'
import { IEventInterface } from '../../../../models/events/event.interface'
import { EventModel } from '../../../../models/events/event.model'
import { getConvertedData } from '../../../../models/interface.helper'
import FirestoreService from '../../../../services/firestoreService'
import {
  selectMapCenterR,
  selectMapFocusOnSearchedLocationR,
  setMapCenterAc,
  setMapDataAc,
  setMapFocusOnSearchedLocationAc,
  setMapFocusedMarkedIdAc,
  setMapLoadingAc,
} from '../../../../store/filters/filterSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import './MapContainer.css'
import MapLoader from './MapLoader'
import Markers from './Markers'
import { useHistory } from 'react-router-dom'

// Constants
const EVENT_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS
const FILE_NAME = 'MapContainer'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// Types
type IUserLocationStatus = 'fulfilled' | 'pending' | 'settled' | 'rejected'

interface IProps {
  isOrganizer?: boolean
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
/**
 * @TODO Document this
 */
const MapContainer = (props: IProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()

  const timeoutRef = useRef<any>(null)
  const toastFunction = useToasterHelper()
  const googleMapRef = useRef<GoogleMap>(null)
  const history = useHistory()

  const mapCenter = useAppSelector(selectMapCenterR)
  const isXlResolution = useMedia('(min-width: 1026px)')
  const focusOnSearchedLocation = useAppSelector(selectMapFocusOnSearchedLocationR)

  const [userLocationStatus, setUserLocationStatus] = useState<IUserLocationStatus>('pending')
  const [searchedLocation, setSearchedLocation] = useState<
    typeof CONST.UI.MAP.DEFAULT_LOCATION | null
  >(null)

  const zoomLevel = isXlResolution ? 3 : 1

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
  /**
   * @TODO Document this
   */
  useEffect(() => {
    return () => {
      dispatch(setMapFocusedMarkedIdAc(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (userLocationStatus === 'settled') {
      setTimeout(() => {
        googleMapRef.current?.state?.map?.setCenter(mapCenter)
        googleMapRef.current?.state?.map?.setZoom(isXlResolution ? 4 : 2)
        setSearchedLocation(mapCenter)
        dispatch(setMapLoadingAc(false))
      }, 300)
    }

    if (userLocationStatus === 'fulfilled') setUserLocationStatus('settled')

    if (userLocationStatus !== 'pending') return

    dispatch(setMapLoadingAc(true))
    getCurrentLocation()
      .then((location) => {
        dispatch(setMapCenterAc(location))
      })
      .catch((error) => {
        helpers.logger({
          message: CustomError.somethingWentWrong({
            ...customErrorProps,
            devMessage: error?.message,
            moduleName: 'useEffect',
          }),
        })
      })
      .finally(() => {
        setUserLocationStatus('fulfilled')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocationStatus])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const { lat, lng } = mapCenter

    if (!focusOnSearchedLocation) return

    googleMapRef.current?.state?.map?.setCenter({ lat, lng })
    googleMapRef.current?.state?.map?.setZoom(7)

    dispatch(setMapFocusOnSearchedLocationAc(false))
    setSearchedLocation({ lat, lng })

    return () => {
      dispatch(setMapFocusedMarkedIdAc(null))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusOnSearchedLocation, mapCenter.lat, mapCenter.lng])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
  /**
   * @TODO Document this
   */
  const onEventMarkerClick = (router: UseIonRouterResult, eventId: IEventInterface['id']) => {
    if (!eventId) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          devMessage: `eventId is ${eventId}`,
          moduleName: 'onEventMarkerClick',
        }),
      })

      return
    }

    router.push(`${CONST.ROUTES.EVENT_DETAILS.URL}/${eventId}`)
    history.push(`${CONST.ROUTES.EVENT_DETAILS.URL}/${eventId}`)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
  /**
   * @TODO Document this
   */
  async function loadEvents(lower: string, upper: string) {
    const events_: IEventInterface[] = []
    let event: EventModel | null = null

    let docs = await FirestoreService.filterItems(EVENT_COLLECTION.NAME, [
      where(EVENT_COLLECTION.FIELDS.STATUS.KEY, '==', EVENT_COLLECTION.FIELDS.STATUS.VALUE.CURRENT),
      where(EVENT_COLLECTION.FIELDS.LOCATION_HASH.KEY, '>=', lower),
      where(EVENT_COLLECTION.FIELDS.LOCATION_HASH.KEY, '<=', upper),
    ])

    docs.forEach((doc) => {
      event = EventModel.fromFirestoreDoc(doc)
      events_.push(getConvertedData(event.toObject()))
    })
    return events_
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
  /**
   * @TODO Document this
   */
  function calculateUpperAndLowerBounds() {
    let zoomLevel = 14
    let upper: string = ''
    let lower: string = ''
    let center = CONST.UI.MAP.DEFAULT_LOCATION
    let returnNull = false

    try {
      const bounds = googleMapRef.current?.state?.map?.getBounds()
      zoomLevel = googleMapRef.current?.state?.map?.getZoom() ?? 14
      if (!bounds) return null

      center.lat = bounds.getCenter().lat()
      center.lng = bounds.getCenter().lng()

      // Assuming you have a LatLngBounds instance called 'bounds'
      const southwestLatLng = bounds.getSouthWest()
      const northeastLatLng = bounds.getNorthEast()

      // Extract the latitude and longitude values
      const south = southwestLatLng.lat()
      const west = southwestLatLng.lng()
      const north = northeastLatLng.lat()
      const east = northeastLatLng.lng()

      // Encode the coordinates to geohashes
      upper = geohash.encode(south, west)
      lower = geohash.encode(north, east)
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          devMessage: error?.message ?? error,
          moduleName: 'calculateUpperAndLowerBounds',
        }),
      })
    } finally {
      if (returnNull) return null

      return {
        upper,
        lower,
        center,
        zoomLevel,
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
  /**
   * @TODO Document this
   */
  async function getEventsAsPerViewport() {
    try {
      await new Promise((resolve) => {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(() => {
          resolve(true)
        }, 200)
      })

      const result = calculateUpperAndLowerBounds()
      if (!result) return

      dispatch(setMapDataAc([]))
      dispatch(setMapLoadingAc(true))

      await new Promise((resolve) => {
        setTimeout(resolve, 200)
      })

      const { lower, upper } = result
      const events_ = await loadEvents(upper, lower)
      dispatch(setMapFocusedMarkedIdAc(null))
      dispatch(setMapDataAc(events_))
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          devMessage: error?.message ?? error,
          moduleName: 'calculateUpperAndLowerBounds',
        }),
      })
    } finally {
      dispatch(setMapLoadingAc(false))
    }
  }

  return (
    <div className="flex overflow-x-scroll hide-scroll-bar select-none h-[75vh]">
      <div className="flex flex-wrap w-full transition-all relative">
        <MapLoader />
        {userLocationStatus === 'settled' ? (
          <GoogleMap
            ref={googleMapRef}
            zoom={googleMapRef.current?.state?.map?.getZoom() ?? zoomLevel}
            center={
              googleMapRef.current?.state?.map?.getCenter() ?? {
                lat: mapCenter.lat,
                lng: mapCenter.lng,
              }
            }
            onZoomChanged={getEventsAsPerViewport}
            onDrag={getEventsAsPerViewport}
            mapContainerClassName="h-[100%] w-full"
            options={{
              minZoom: zoomLevel,
              restriction: {
                latLngBounds: {
                  north: 85,
                  south: -85,
                  west: -180,
                  east: 180,
                },
              },
            }}
          >
            <Markers
              isOrganizers={props.isOrganizer}
              onEventMarkerClick={onEventMarkerClick}
              searchedLocation={searchedLocation}
            />
          </GoogleMap>
        ) : null}
      </div>
    </div>
  )
}

export default MapContainer
